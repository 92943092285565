
import React from "react";
import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from "../../modules/TokenHelper";
import { toast } from 'react-toastify';
import {
    displayErrorMessage,
    displaySuccessMessage,
    displayWarningMessage,
} from "../../modules/ToastMessage";
import { validateAndGetFormatedToken } from "../../modules/CheckToken";
import axios from "axios";
import { parseJwt } from "../../modules/TokenParser";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import ReactTags from "react-tag-autocomplete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "../DataPicker/index"
import Select from "react-select";
import SwiftAdditionalDetailsImg from "./../../assets/img/new-design/swift-additional-details.png";
import JobBasicImg from "./../../assets/img/new-design/swift-add-job-basics.png";
import JobDetailsImg from "./../../assets/img/new-design/swift-add-job-details.png";
import JobDescriptionImg from "./../../assets/img/new-design/swift-describe-the-job.png";
import JobSkillImg from "./../../assets/img/new-design/swift-add-skills.png";
import JobCongImg from "./../../assets/img/new-design/swift-congratulations.png";
import { Oval } from 'react-loader-spinner'
import IndeedLogo from '../../assets/svg/logos/Indeed_logo.svg'
import JoobleSeeklogo from '../../assets/svg/logos/Jooble.svg'
import PortalLogo from '../../assets/svg/logos/portal-logo.svg'
import HlsVideoPlayer from '../Shared/HlsVideoPlayer';
import { getVideoMimeType } from '../../modules/Utilities';

class CreateJob extends BaseComponent {
    constructor() {
        super();
        this.state = {
            title: "",
            description: "",
            jobManagerEmail: null,
            newjobManagerEmail: null,
            jobManager: null,
            jobManagerNew: null,
            jobManagerFirstName: null,
            jobManagerLastName: null,
            isSaveDisabled: false,

            jobVisibilityLevel: "everyone",
            jobSource: "",
            usergroupid: null,
            selectedGroupId: -1,

            wordCount: 0,
            maxWords: 15000,
            introVideoUrl: "",
            outroVideoUrl: "",
            isActive: false,
            tenantId: 0,
            createdBy: "",
            jobTitle: "",

            phoneqconstraint: "required",
            cvqconstraint: "required",
            cnicconstraint: "optional",
            specificusers: "",

            AddIntroVideo: false,
            AddOutroVideo: false,
            IntroVideos: [],
            OutroVideos: [],
            isUserCreated: false,
            status: "public",
            isStatus: true,
            isIndeed: false,
            isJooble: false,
            //For Dropdown control
            tenantUsersList: [],
            allUsersList: [],
            tenantUserGroups: [],
            userGroupsMembers: [],
            //Version 2 updates
            cities: [],
            countries: [],
            states: [],
            country: "",
            tempCountryId: "",
            state: "",
            tempStateId: "",
            city: "",
            selectedIndustry: "",
            salary: "",
            workExperience: 1,
            postalCode: "",

            /*Job Types Lookup*/
            jobTypes: [],
            /*Selected Job Type*/
            selectedJobType: "",

            salarynegotiable: "yes",
            salaryType: "-1",
            minSalary: 0,
            maxSalary: 0,

            minSalary_STD: "",
            maxSalary_STD: "",

            selectedBonuses: [],
            vacantPosition: 1,
            salaryFrequency: "monthly",
            bonuses: [],
            salaryFrequencies: [],
            salaryTypes: [],
            industries: [],

            questionSets: [],
            jobVisibilities: [],
            jobStatuses: [],
            SuccessMessage: false,
            isloadedgetVideos: false,
            isloadedgetTenantUsers: true,
            isloadedgetTenantUserGroups: true,
            isloadedgetQuestionSets: true,
            tenantSubscriptionId: 0,
            tenantSubscriptionDataPermissionJobPublishing: {},
            disableSaveBtn: false,
            isTC: false,
            currencyType: "PKR",
            newSelectedIndustry: '',
            allSkills: [],
            tags: [],
            suggestions: [],
            selectedWorkspaceNatureType: "",
            workspaceNatureTypes: [],
            selectedIndustryId: -1,
            forFemaleOnly: "no",
            selectedJobTitle: "",
            selectedJobTitleId: -1,
            jobTitles: [],
            jobTitleDefaultTemplate: null,
            hideDefaultQuestionSet: true,
            selectedQuestionSetId: -1,
            jobIsActive: true,
            isAutoInterviewSchedule: "no",
            skillIndustrySelected: -1,

            //data for react-select days
            days: [
                {
                    value: "1",
                    label: "1",
                },
                {
                    value: "2",
                    label: "2",
                },
                {
                    value: "3",
                    label: "3",
                },
                {
                    value: "4",
                    label: "4",
                },
                {
                    value: "5",
                    label: "5",
                },
                {
                    value: "6",
                    label: "6",
                },
                {
                    value: "7",
                    label: "7",
                },
                {
                    value: "8",
                    label: "8",
                },
                {
                    value: "9",
                    label: "9",
                },
                {
                    value: "10",
                    label: "10",
                },
                {
                    value: "11",
                    label: "11",
                },
                {
                    value: "12",
                    label: "12",
                },
                {
                    value: "13",
                    label: "13",
                },
                {
                    value: "14",
                    label: "14",
                },
                {
                    value: "15",
                    label: "15",
                },
                {
                    value: "16",
                    label: "16",
                },
                {
                    value: "17",
                    label: "17",
                },
                {
                    value: "18",
                    label: "18",
                },
                {
                    value: "19",
                    label: "19",
                },
                {
                    value: "20",
                    label: "20",
                },
                {
                    value: "21",
                    label: "21",
                },
                {
                    value: "22",
                    label: "22",
                },
                {
                    value: "23",
                    label: "23",
                },
                {
                    value: "24",
                    label: "24",
                },
                {
                    value: "25",
                    label: "25",
                },
                {
                    value: "26",
                    label: "26",
                },
                {
                    value: "27",
                    label: "27",
                },
                {
                    value: "28",
                    label: "28",
                },
                {
                    value: "29",
                    label: "29",
                },
                {
                    value: "30",
                    label: "30",
                },
            ],
            //data for react-select hours

            hours: [
                {
                    value: "0",
                    label: "0",
                },
                {
                    value: "1",
                    label: "1",
                },
                {
                    value: "2",
                    label: "2",
                },
                {
                    value: "3",
                    label: "3",
                },
                {
                    value: "4",
                    label: "4",
                },
                {
                    value: "5",
                    label: "5",
                },
                {
                    value: "6",
                    label: "6",
                },
                {
                    value: "7",
                    label: "7",
                },
                {
                    value: "8",
                    label: "8",
                },
                {
                    value: "9",
                    label: "9",
                },
                {
                    value: "10",
                    label: "10",
                },
                {
                    value: "11",
                    label: "11",
                },
                {
                    value: "12",
                    label: "12",
                },
                {
                    value: "13",
                    label: "13",
                },
                {
                    value: "14",
                    label: "14",
                },
                {
                    value: "15",
                    label: "15",
                },
                {
                    value: "16",
                    label: "16",
                },
                {
                    value: "17",
                    label: "17",
                },
                {
                    value: "18",
                    label: "18",
                },
                {
                    value: "19",
                    label: "19",
                },
                {
                    value: "20",
                    label: "20",
                },
                {
                    value: "21",
                    label: "21",
                },
                {
                    value: "22",
                    label: "22",
                },
                {
                    value: "23",
                    label: "23",
                },
            ],
            //data for react-select minutes

            minutes: [
                {
                    value: "00",
                    label: "00",
                },
                {
                    value: "01",
                    label: "01",
                },
                {
                    value: "02",
                    label: "02",
                },
                {
                    value: "03",
                    label: "03",
                },
                {
                    value: "04",
                    label: "04",
                },
                {
                    value: "05",
                    label: "05",
                },
                {
                    value: "06",
                    label: "06",
                },
                {
                    value: "07",
                    label: "07",
                },
                {
                    value: "08",
                    label: "08",
                },
                {
                    value: "09",
                    label: "09",
                },
                {
                    value: "10",
                    label: "10",
                },
                {
                    value: "11",
                    label: "11",
                },
                {
                    value: "12",
                    label: "12",
                },
                {
                    value: "13",
                    label: "13",
                },
                {
                    value: "14",
                    label: "14",
                },
                {
                    value: "15",
                    label: "15",
                },
                {
                    value: "16",
                    label: "16",
                },
                {
                    value: "17",
                    label: "17",
                },
                {
                    value: "18",
                    label: "18",
                },
                {
                    value: "19",
                    label: "19",
                },
                {
                    value: "20",
                    label: "20",
                },
                {
                    value: "21",
                    label: "21",
                },
                {
                    value: "22",
                    label: "22",
                },
                {
                    value: "23",
                    label: "23",
                },
                {
                    value: "24",
                    label: "24",
                },
                {
                    value: "25",
                    label: "25",
                },
                {
                    value: "26",
                    label: "26",
                },
                {
                    value: "27",
                    label: "27",
                },
                {
                    value: "28",
                    label: "28",
                },
                {
                    value: "29",
                    label: "29",
                },
                {
                    value: "30",
                    label: "30",
                },
                {
                    value: "31",
                    label: "31",
                },
                {
                    value: "32",
                    label: "32",
                },
                {
                    value: "33",
                    label: "33",
                },
                {
                    value: "34",
                    label: "34",
                },
                {
                    value: "35",
                    label: "35",
                },
                {
                    value: "36",
                    label: "36",
                },
                {
                    value: "37",
                    label: "37",
                },
                {
                    value: "38",
                    label: "38",
                },
                {
                    value: "39",
                    label: "39",
                },
                {
                    value: "40",
                    label: "40",
                },
                {
                    value: "41",
                    label: "41",
                },
                {
                    value: "42",
                    label: "42",
                },
                {
                    value: "43",
                    label: "43",
                },
                {
                    value: "44",
                    label: "44",
                },
                {
                    value: "45",
                    label: "45",
                },
                {
                    value: "46",
                    label: "46",
                },
                {
                    value: "47",
                    label: "47",
                },
                {
                    value: "48",
                    label: "48",
                },
                {
                    value: "49",
                    label: "49",
                },
                {
                    value: "50",
                    label: "50",
                },
                {
                    value: "51",
                    label: "52",
                },
                {
                    value: "53",
                    label: "53",
                },
                {
                    value: "54",
                    label: "54",
                },
                {
                    value: "55",
                    label: "55",
                },
                {
                    value: "56",
                    label: "56",
                },
                {
                    value: "57",
                    label: "57",
                },
                {
                    value: "58",
                    label: "58",
                },
                {
                    value: "59",
                    label: "59",
                },
            ],
            //data for react-select ampm

            ampm: [
                {
                    value: "AM",
                    label: "AM",
                },
                {
                    value: "PM",
                    label: "PM",
                },
            ],
            hourvalue: "23",
            minutevalue: "59",
            ampmvalue: "AM",
            selectedTimeZone: "",
            interviewExpiryDate: new Date(
                new Date().setDate(new Date().getDate() + 7)
            ),
            extensiondate: new Date(new Date().setDate(new Date().getDate() + 14)),
            timeZones: [],
            reviewers: [],
            selectedQuestionSet: {},
            selectedInterviewReviewers: [],
            allJobs: [],
            addJobTitleModal: false,
            selectedIndustryIdForJobTitle: -1,
            selectedQuestionSetIdForJobTitle: "",
            questionSetsForJobTitles: [],
            addSkillModal: false,
            parentSkills: [],
            selectedIndustryIdForSkill: "",
            selectedParentSkill: "",
            selectedParentSkillId: "",
            identityType: "",
            // new-design fields
            step: 1,
            addJobBasicModal: false,
            advanceSearchModal: false,
            isSaving: false,
            disclaimerForAIJobDesciprtion: "",
            IsQuestionListLoad: 0,
            questions: [],
            IsQuestionsetSaving: 0,
            NewQuestionSetId: 0,
            NewQuestionSetFinished: 0,
            IsQuestionLoading: 0,
            IsJobTitlesLoading: 0,
            JobTitlesList: [],
            IsGPTJobTitleCreated: 0,
            AIJobDescription: "",
            IsJobDescriptionLoading: 0,
            disclaimerForAISkills: "",
            isJobTitlesRequired: 0,
            isSkillSetLoading: 0,

            defaultQuestionSetSelected: false,

            interviewLevel: "FirstInterview",
            otherInterviewLevel: "",
            interviewTag: "",
            firstInterviewAttampeted: 0,
            secondInterviewAttampeted: 0,
            finalInterviewAttampeted: 0,
            isDefaultInterviewLevel: 1,
            interviewtags: [],
            inputValue: '',
            RequiredEducation: "",
            isIndeedPublishEnable: false,
            isJooblePublishEnable: false,
        };
        this.ref = React.createRef();
        this.reactTags = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        /** Temporaray Fix: Job Title should not have special characters */
        this.jobTitleChangeHandler = this.jobTitleChangeHandler.bind(this);
        this.selectInterviewLevel = this.selectInterviewLevel.bind(this);
    }

    selectInterviewLevel(event) {
        const target = event.target;
        const name = target.name;

        if (name === "InterviewLevel") {
            this.setState({
                interviewLevel: target.value,
            });
            if (target.value === "FirstInterview") {
                this.setState({
                    isDefaultInterviewLevel: 1,
                });
            }
            else if (target.value === "SecondInterview") {
                this.setState({
                    isDefaultInterviewLevel: 2,
                });
            }
            else if (target.value === "FinalInterview") {
                this.setState({
                    isDefaultInterviewLevel: 3,
                });
            }
            else if (target.value === "OtherInterview") {
                this.setState({
                    isDefaultInterviewLevel: 4,
                });
            }

        } else {
            console.log("InterviewLevel name : " + name);
            console.log("InterviewLevel value : " + target.value);
        }
    }

    handleTagchangeChange = (e) => {
        var inputtext = e.target.value;
        if (inputtext.includes(',')) {
            inputtext = inputtext.replace(',', ''); // Remove commas
        }
        this.setState({ interviewTag: inputtext });
    };

    handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.interviewTag.trim() !== '') {
            this.addTag();
        }
    };

    handleInputBlur = () => {
        if (this.state.interviewTag.trim() !== '') {
            this.addTag();
        }
    };

    handleTagRemove = (tagToRemove) => {
        this.setState((prevState) => ({
            interviewtags: prevState.interviewtags.filter((tag) => tag !== tagToRemove)
        }));
    };

    addTag = () => {
        if (this.state.interviewtags.length < 3) {
            let tagsList = [];
            tagsList = this.state.interviewtags;
            var tagValue = this.state.interviewTag.trim();
            var isTagExist = 0;
            for (var i = 0; i < tagsList.length; i++) {
                if (tagsList[i] === tagValue) {
                    this.setState((prevState) => ({
                        interviewTag: ''
                    }));
                    isTagExist = 1;
                    break;
                }
            }
            if (isTagExist === 0) {
                this.setState((prevState) => ({
                    interviewtags: [...prevState.interviewtags, prevState.interviewTag.trim()],
                    interviewTag: ''
                }));
            }
        }
        else {
            this.setState((prevState) => ({
                interviewTag: ''
            }));
            displayWarningMessage(
                "Maximum 3 tags can add for an interview!",
                "top-center"
            );
        }
    };


    componentDidMount() {
        document.title = "Tenant App - Jobs";
        if (verifyTokenOnLocal()) {
            this.getTenantSubscriptionid();
            this.getIntroVideos();
            this.getJobVisbilityLookup();
            this.getOutroVideos();
            this.getTenantUsers();
            this.getTenantUserGroups();
            this.getCountries();
            this.getIndustrieslookup();
            this.getSalaryFrequencylookup();
            this.getAllBonuses();
            this.getJobTypesLookup();
            this.getSalaryTypeLookup();
            this.getJobStatusLookup();
            this.IsTcBPOTenant();
            this.getWorkspaceNatureTypes();
            this.getTimeZones();
            this.getJobs();
            this.getQuestionSetsForJobTitles();
            this.getTenantGeneralInfo();
            this.getUserGroupsMembers();
            this.getToggleSettings();
        }
    }

    //Is this tenant TCBPO
    IsTcBPOTenant() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let tenantId = parseJwt(bearer).tenantid;
            let url =
                process.env.REACT_APP_IDENTITY_API +
                "/Account/IsTCBpoTenant?tenantId=" +
                tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            }).then((response) => {
                this.setState({
                    isTC: response.data,
                });
            });
        }
    }
    /** Get the active user list of this tenant */
    getTenantUsers() {
        var url = null;
        var tenantId = null;
        var subId = null;
        var tenantEmail = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            subId = parseJwt(bearer).sub;
            url =
                process.env.REACT_APP_IDENTITY_API +
                "/Account/GetUsername?userId=" +
                subId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    tenantEmail = response.data;
                })
                .then(() => {
                    url =
                        process.env.REACT_APP_IDENTITY_API +
                        "/Account/GetTenantActiveUsers?tenantId=" +
                        tenantId;

                    axios(url, {
                        method: "GET",
                        headers: {
                            Authorization: bearer,
                            "Content-Type": "application/json",
                        },
                    })
                        .then((response) => {
                            let i = 0;
                            let temparrforusersdata = [];
                            this.setState({
                                allUsersList: response.data,
                            });
                            for (i = 0; i < response.data.length; i++) {
                                if (response.data[i].email == tenantEmail) {
                                    this.setState({
                                        newjobManagerEmail: tenantEmail,
                                        jobManagerEmail: tenantEmail,
                                        jobManagerFirstName: response.data[i].firstName,
                                        jobManagerLastName: response.data[i].lastName,
                                    });
                                } else {
                                    temparrforusersdata.push(response.data[i]);
                                }
                            }
                            this.setState({
                                tenantUsersList: temparrforusersdata,
                                reviewers: response.data,
                            });
                        })
                        .catch((err) => {
                            console.error("Unable to load data of dropdown list == >" + err);
                            displayErrorMessage(
                                "Error while Fetching load data of dropdown list!",
                                "top-center"
                            );
                        });
                })
                .catch((err) => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage(
                        "Error while Fetching email of tenant!",
                        "top-center"
                    );
                });
        }
    }

    /** Get list of videos */
    getIntroVideos() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url =
                process.env.REACT_APP_CORE_API + "/api/Tenant/" + tenantId + "/Intro";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        IntroVideos: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching intro outro videos!",
                        "top-center"
                    );
                });
        }
    }

    getTenantGeneralInfo() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantGeneral/" + tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        selectedTimeZone: response.data.timeZone,
                        identityType: response.data.identityType,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching intro outro videos!",
                        "top-center"
                    );
                });
        }
    }

    getOutroVideos() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url =
                process.env.REACT_APP_CORE_API + "/api/Tenant/" + tenantId + "/Outro";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        OutroVideos: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching intro outro videos!",
                        "top-center"
                    );
                });
        }
    }

    getTenantUserGroups() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url =
                process.env.REACT_APP_IDENTITY_API +
                "/TenantUserGroup/GetTenantUserGroups?tenantId=" +
                tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        tenantUserGroups: response.data,
                    });

                    if (response.data.length > 0) {
                        this.setState({
                            usergroupid: response.data[0].userGroupId,
                        });
                    }
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant User Groups!",
                        "top-center"
                    );
                });
        }
    }

    getUserGroupsMembers() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url =
                process.env.REACT_APP_IDENTITY_API +
                "/TenantUserGroup/GetTenantUserGroupsMembers?tenantId=" +
                tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log(" Members response : ");
                    console.log(response);
                    this.setState({
                        userGroupsMembers: response.data,
                    });

                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant User Groups Members!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get All Question Sets  for job titles*/
    getQuestionSetsForJobTitles() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.length != undefined && response.data.length == 0) {
                        this.setState({
                            questionSetsForJobTitles: [],
                        });
                    } else {
                        this.setState({
                            questionSetsForJobTitles: response.data,
                            // selectedQuestionSetIdForJobTitle: response.data[0].questionSetId,
                        });
                        if (this.state.NewQuestionSetId > 0) {
                            this.setState({ selectedQuestionSetIdForJobTitle: this.state.NewQuestionSetId });
                        }
                        else {
                            this.setState({ NewQuestionSetId: 0 });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while fetching  question sets for job titles!",
                        "top-center"
                    );
                });
        }
    }
    preventScroll = (event) => {
        event.preventDefault();
        // Optionally, you can remove focus from the input field to prevent further scrolling
        event.target.blur();
    }

    /**
     * Get Question Sets
     * @param {any} event
     */

    getQuestionSets(jobTitleDefaultQuestionSet) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet";

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (jobTitleDefaultQuestionSet != null) {
                        result.forEach((item, index) => {
                            if (
                                jobTitleDefaultQuestionSet.questionSetDTO.questionSetId ===
                                item.questionSetId
                            ) {
                                result[index].isChecked = true;
                                this.setState({ selectedQuestionSet: result[index], defaultQuestionSetSelected: result[index].isChecked });

                            } else {
                                result[index].isChecked = false;
                            }
                        });
                        this.setState({
                            questionSets: result,
                        });
                    } else {
                        result.forEach((item, index) => {
                            result[index].isChecked = false;
                        });
                        this.setState({
                            questionSets: result,
                        });
                    }
                    if (result.length < 1) {
                        displayWarningMessage(
                            "Please create question set first for automated interview flow",
                            "top-center"
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching Question sets!",
                        "top-center"
                    );
                });
        }
    }

    getCountries(country = null) {
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let url =
                process.env.REACT_APP_CORE_API +
                "/api/TenantCountryLookup?tenantid=" +
                tenantId;

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        this.setState({
                            countries: result,
                        });

                        this.setState({
                            country: result[0].locationName
                        });

                        this.setState({ tempCountryId: result[0].locationId });
                    }
                })
                .then(() => {
                    this.getStates(this.state.tempCountryId);
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Fetching Countries!", "top-center");
                });
        }
    }

    getStates(countryId, dropChange = false) {
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            let url =
                process.env.REACT_APP_CORE_API + "/api/StateLookup/" + countryId;

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        this.setState({
                            states: result,
                            state: result[0].locationName
                        });
                        if (this.state.states.length > 0)
                            this.getCities(this.state.states[0].locationId);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Fetching states!", "top-center");
                });
        }
    }

    getCities(stateId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/CitiesLookup/" + stateId;

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        this.setState({
                            cities: result,
                            location: result[0].locationName,
                            city: result[0].locationName
                        });

                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Fetching cities!", "top-center");
                });
        }
    }

    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let selectedIndustryId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data != null) {
                        const sorted = [...response.data].sort((a, b) =>
                            a["title"] != undefined
                                ? a["title"].toLowerCase() > b["title"].toLowerCase()
                                    ? 1
                                    : -1
                                : ""
                        );
                        let temp = [];
                        sorted.forEach((item, index) => {
                            temp.push({
                                label: item.title,
                                value: index,
                                title: item.title,
                                defaultIndustryId: item.defaultIndustryId,
                            });
                        });
                        this.setState({
                            // selectedIndustry: sorted[0].title,
                            // selectedIndustryId: sorted[0].defaultIndustryId,
                            industries: temp,
                        });
                        selectedIndustryId = sorted[0].defaultIndustryId;
                    } else console.error("Unable to get industries");
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Industy lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get All Skills
     * @param {any} selectedIndustry
     */

    getAllSkills(selectedIndustry) {
        let bearer = validateAndGetFormatedToken();
        let suggestions = [];
        if (bearer != "" || bearer != null) {
            let url =
                process.env.REACT_APP_CORE_API +
                "/api/TenantSkills";

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        result.forEach((skill) => {
                            suggestions.push({ id: skill.defaultSkillId, name: skill.title });
                        });
                        this.setState({
                            allSkills: result,
                            suggestions: suggestions,
                        });
                    } else {
                        this.setState({
                            allSkills: [],
                        });
                    }
                })
                .catch((err) => {
                    console.log("err from skills", err);
                    this.setState({
                        allSkills: [],
                    });

                    displayErrorMessage("Error while Fetching All Skills!", "top-center");
                });
        }
    }

    /**
     * Get All Job Title
     * @param {any} selectedIndustry
     */

    getAllJobTitles(selectedJobTitleId) {
        this.setState({
            isJobTitlesRequired: 0,
        });
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url =
                process.env.REACT_APP_CORE_API + "/api/JobTitles/" + selectedJobTitleId;

            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.length > 0) {
                        const sorted = [...result].sort((a, b) =>
                            a["title"] < b["title"] ? 1 : -1
                        );
                        this.setState({
                            jobTitles: sorted,
                            selectedJobTitleId: sorted[0].jobTitleId,
                        });
                    } else {
                        this.setState({
                            jobTitles: [],
                            jobTitleDefaultTemplate: null,
                            hideDefaultQuestionSet: false,
                        });
                        if (selectedJobTitleId > 0) {
                            this.setState({
                                isJobTitlesRequired: 1,
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.setState({
                        jobTitles: [],
                        jobTitleDefaultTemplate: null,
                        hideDefaultQuestionSet: false,
                    });

                    displayErrorMessage(
                        "Error while Fetching All Job Titles!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * WorkspaceNatureTypes from look up*/
    getWorkspaceNatureTypes() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/WorkSpaceNatureTypesLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data != null) {
                        this.setState({
                            selectedWorkspaceNatureType: response.data[0],
                            workspaceNatureTypes: response.data,
                        });
                    } else console.error("Unale to get Web Space Nature");
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Web Space Nature lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Salary Frequency from look up*/
    getSalaryFrequencylookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/SalaryFrequencyLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log("response from frequency", response.data);
                    this.setState({
                        salaryFrequencies: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  salary frequency lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get job types look up*/
    getJobTypesLookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobTypesLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    //var obj = response.data;
                    //Object.keys(obj).forEach(function (key)
                    //{
                    //    console.log(key + " " + obj[key]);
                    //});
                    this.setState({
                        jobTypes: response.data,
                        selectedJobType: Object.values(response.data)[0],
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Job Type lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Salary Frequency from look up*/
    getSalaryTypeLookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/SalaryTypesLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    var obj = response.data;
                    Object.keys(obj).forEach(function (key) {
                        console.log(key + " " + obj[key]);
                    });

                    this.setState({
                        salaryTypes: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  salary frequency lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get Job visibility lookup*/
    getJobVisbilityLookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobVisibilityLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        jobVisibilities: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  job visibility lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get All Bonuses from Db*/
    getAllBonuses() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantBonus";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        bonuses: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Fetching  Bonuses!", "top-center");
                });
        }
    }

    /**
     * Job statuses*/
    getJobStatusLookup() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobStatusLookup";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    this.setState({
                        jobStatuses: response.data,
                    });
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Job Status lookup!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get the tenant subscription id
     */
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url =
                process.env.REACT_APP_IDENTITY_API +
                "/TenantSubscription/GetTenantSubscriptions?tenantId=" +
                tenantId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        this.setState({
                            tenantSubscriptionId: response.data[0].tenantSubscriptionId,
                        });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "JobPublishing");
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant Subscription Id",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get the tenant subscriotion feature key value
     * @param {number} tenantSubscriptionId
     * @param {string} featureKey
     */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url =
                process.env.REACT_APP_CORE_API +
                "/api/TenantSubscriptionData/" +
                tenantSubscriptionId +
                "/" +
                featureKey;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.featureKey == "JobPublishing") {
                        this.setState({
                            tenantSubscriptionDataPermissionJobPublishing: response.data,
                        });
                    }
                    if (response.data.featureKey == "Jobs") {
                        let tenantSubscriptionData = response.data;
                        let jobsCount = parseInt(tenantSubscriptionData.value);
                        if (jobsCount == 0) {
                            window.location.href = "/jobs";
                        }
                    }
                })

                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while Fetching  Tenant Subscription Data!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Get Default question set by job title id
     * */
    getDefaultTemplateByJobTitleId(jobTitleId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let jobTitleDefaultQuestionSet = null;
        if (bearer != "" || bearer != null) {
            url =
                process.env.REACT_APP_CORE_API +
                "/api/JobTitleDefaultTemplates/" +
                jobTitleId;

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log("job title default templates ", response);
                    this.setState({
                        jobTitleDefaultTemplate: response.data,
                        hideDefaultQuestionSet: true,
                    });
                    jobTitleDefaultQuestionSet = response.data;
                })
                .then(() => {
                    this.setState({ selectedQuestionSetId: jobTitleDefaultQuestionSet.questionSetDTO.questionSetId })
                    this.getQuestionSets(jobTitleDefaultQuestionSet);
                })

                .catch((err) => {
                    if (err.response.data == "No Data Found") {
                        this.getQuestionSets(null);
                        this.setState({
                            hideDefaultQuestionSet: true,
                        });
                    } else {
                        console.log(err);
                        displayErrorMessage(
                            "Error while Fetching job title default template!",
                            "top-center"
                        );
                    }
                });
        }
    }

    GetJobDescription(JobTitle) {
        // console.log(" JobTitle =:= " + JobTitle + "  ||| " + this.state.selectedJobTitle)
        if (JobTitle === "") {
            return;
        }

        this.setState({ IsJobDescriptionLoading: 1 });

        this.GetJobDescriptionGPT(JobTitle);
    }
    /**
   * Get Job Description by job title from chat GPT
   * */
    GetJobDescriptionGPT(JobTitle) {
        this.setState({ description: "", disclaimerForAIJobDesciprtion: "" });

        let JobDescriptionRequest = {
            Query: JobTitle,
        };
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(JobDescriptionRequest);
            let url = process.env.REACT_APP_CORE_API + "/api/JobDescription/";
            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions)

                .then((response) => response.json())
                .then((result) => {
                    if (result.description !== null) {
                        this.setState({ description: result.description, AIJobDescription: result.description, IsJobDescriptionLoading: 0, disclaimerForAIJobDesciprtion: "*You are responsible for your job post. Review to ensure it has all required information." });
                    }
                    else {
                        this.setState({ IsJobDescriptionLoading: 0, disclaimerForAIJobDesciprtion: "" });
                    }
                })
                .catch((error) => {
                    console.log("error during GetJobDescription", error);
                    this.setState({ IsJobDescriptionLoading: 0, disclaimerForAIJobDesciprtion: "" });
                });
        }
    }

    /**
* Get Skills By Job Title from chat GPT
* */
    GetSkillsByJobTitle(JobTitle) {
        if (JobTitle === "") {
            return;
        }
        this.setState({ isSkillSetLoading: 1 });
        let JobDescriptionRequest = {
            Query: JobTitle,
        };
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(JobDescriptionRequest);

            let url = process.env.REACT_APP_CORE_API + "/api/SkillChatGPT/";

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.skills != null) {
                        let tagsList = [];
                        let updatedtagsList = [];
                        tagsList = this.state.tags;
                        var countSkills = 0;
                        tagsList.forEach((item, index) => {
                            if (item.id > 0 && countSkills < 8) {
                                updatedtagsList.push({ id: item.id, name: item.name });
                                countSkills++;
                            }
                        });
                        this.setState({ tags: updatedtagsList });

                        let skillList = [];
                        var count = 0;
                        result.skills.forEach((item, index) => {
                            if (count > -8) {
                                count = count - 1;
                                skillList.push({ id: count, name: item });
                            }
                        });

                        skillList.forEach((item, index) => {
                            const tags = [].concat(this.state.tags, item);
                            const skillitem = this.state.suggestions.filter((x) => x.name == item.name);
                            if (skillitem != null) {
                                const suggestions = this.state.suggestions.filter((x) => x.name !== item.name);
                                this.setState({ tags: tags, suggestions: suggestions });
                            }
                            else {
                                this.setState({ tags: tags });
                            }
                        });
                        this.setState({ isSkillSetLoading: 0, disclaimerForAISkills: "*You are responsible for your job post. Review to ensure it has all required information." });
                    }
                    else {
                        console.log("skills is null");
                        this.setState({ isSkillSetLoading: 0 });
                    }
                })
                .catch((error) => {
                    console.log("error during GetSkillsByJobTitle", error);
                    this.setState({ isSkillSetLoading: 0 });
                });
        }
    }

    /**
* Get Skills By Job Title from chat GPT
* */
    GetQuestionsByJobTitle(JobTitle) {
        console.log("GetQuestionsByJobTitle : " + JobTitle);
        if (JobTitle === "") {
            return;
        }
        let QuestionsRequest = {
            Query: JobTitle,
        };
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(QuestionsRequest);

            let url = process.env.REACT_APP_CORE_API + "/api/QuestionsChatGPT/";

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result != null) {
                        this.setState({ questions: result, IsQuestionLoading: 0 });

                    }
                    else {
                        console.log("Questions is null");
                    }
                })
                .catch((error) => {
                    console.log("error during GetQuestionsByJobTitle", error);
                });
        }
    }

    /**Get Time Zones from backend */
    getTimeZones() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/TimeZoneInformation";
            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    this.setState({
                        timeZones: result,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while fetching Time zones!", "top-center");
                });
        }
    }

    /** Get all jobs of tenant */
    getJobs() {
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/JobDetails";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.data.length !== undefined && response.data.length === 0) {
                        this.setState({
                            allJobs: [],
                        });
                    } else {
                        this.setState({
                            allJobs: response.data,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while fetching jobs!", "top-center");
                });
        }
    }

    getToggleSettings() {
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/ToggleSettings";

            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    console.log("ToggleSettings : ");
                    console.log(response);
                    this.setState({
                        isIndeedPublishEnable: response.data.isIndeedPublishEnable,
                        isJooblePublishEnable: response.data.isJooblePublishEnable,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while fetching ToggleSettings!", "top-center");
                });
        }
    }

    /**
     * Job title should not have special character
     * @param {any} event
     */
    jobTitleChangeHandler(event) {
        this.setState({ title: event.target.value.replace(/[^\w\s]/gi, "") });
    }

    //For selecting days and setting the state

    handleChange1 = (selectedOption) => {
        this.setState({ selecteddays: selectedOption.value });
    };

    //For selecting hour and setting the state

    handleHour = (selectedOption) => {
        this.setState({ hourvalue: selectedOption.value });
    };

    //For selecting minutes and setting the state

    handleMinute = (selectedOption) => {
        this.setState({ minutevalue: selectedOption.value });
    };

    //For selecting ampm and setting the state

    handleAMPM = (selectedOption) => {
        this.setState({ ampmvalue: selectedOption.value });
    };

    //For selecting city and setting the state

    handleTimeZone = (selectedOption) => {
        this.setState({ selectedTimeZone: selectedOption.target.value });
    };

    handleIndustry = (selectedOption) => {
        this.setState({
            selectedIndustry: this.state.industries[selectedOption.value].title,
            newSelectedIndustry: this.state.industries[selectedOption.value],
            selectedIndustryIdForJobTitle: this.state.industries[selectedOption.value].defaultIndustryId,
            selectedIndustryId:
                this.state.industries[selectedOption.value].defaultIndustryId,
            selectedJobTitle: "",
            IsGPTJobTitleCreated: 0,
        });

        //only reset fields when new industry gets selected, includes job title and associated question sets
        if (
            this.state.selectedIndustry !==
            this.state.industries[selectedOption.value].title
        ) {
            this.setState({
                jobTitles: [],
                questionSets: [],
                selectedQuestionSetId: -1,
                selectedQuestionSet: null,
            });
        }

        this.getAllJobTitles(
            this.state.industries[selectedOption.value].defaultIndustryId
        );
        this.getAllSkills(
            this.state.industries[selectedOption.value].defaultIndustryId
        );
    };
    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        let value;

        if (name === "jobIsActive") {
            value = target.type === "radio" ? (target.value === "true") : target.value;
            this.setState({
                [name]: value,
            });
        } else {
            value = target.type === "checkbox" ? target.checked : target.value;
            this.setState({
                [name]: value,
            });

        }

        if (name === 'jobTitle') {

            if (/^\s*$/.test(target.value)) {
                this.setState({
                    jobTitle: '',
                });
            } else {
                this.setState({
                    jobTitle: target.value,
                });
            }

        }


        //if jobTitle gets changed, we need to reset question sets
        //if (name === "jobTitle") {
        //    if (value !== this.state.jobTitle) {
        //        this.setState({
        //            questionSets: [],
        //            selectedQuestionSetId: -1,
        //            selectedQuestionSet: null,
        //        });
        //    }
        //}
    }
    createNewJobTitlesGPT() {
        if (this.state.selectedIndustryId > 0) {
            if (this.state.selectedIndustry.trim() != '') {
                this.setState({
                    IsJobTitlesLoading: 1,
                });
                this.GetJobTitleByIndustry(this.state.selectedIndustry.trim());
            }
            else {
                displayErrorMessage("Please select an Industry!", "top-center");
            }
        }
        else {
            displayErrorMessage("Please select an Industry!", "top-center");
        }
    }
    /**
* Get Skills By Job Title from chat GPT
* */
    GetJobTitleByIndustry(IndustryTitle) {
        console.log("GetJobTitleByIndustry : " + IndustryTitle);
        if (IndustryTitle === "") {
            return;
        }
        let JobTitleRequest = {
            Query: IndustryTitle,
            IndustryId: this.state.selectedIndustryId,
        };
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            var model = JSON.stringify(JobTitleRequest);

            let url = process.env.REACT_APP_CORE_API + "/api/JobTitleGPT/";

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result != null) {
                        console.log("Job Titles list : ");
                        console.log(result);
                        this.getAllJobTitles(this.state.selectedIndustryId);
                        this.setState({ JobTitlesList: result, IsJobTitlesLoading: 0, IsGPTJobTitleCreated: 1 });
                    }
                    else {
                        console.log("Job Titles is null");
                    }
                })
                .catch((error) => {
                    console.log("error during GetJobTitleByIndustry", error);
                });
        }
    }

    /**
* create New Question Set from chat GPT
* */
    createNewQuestionSet() {
        if (this.state.jobTitle.trim() !== '') {
            this.setState({
                IsQuestionListLoad: 1,
                IsQuestionLoading: 1,
            });
            this.GetQuestionsByJobTitle(this.state.jobTitle);
        }
        else {
            this.setState({
                IsQuestionListLoad: 0,
            });
            displayErrorMessage("Please enter Job Title!", "top-center");
        }
    }
    /**
     * React Tags delete function
     * @param {any} i
     */
    onDelete(i) {
        if (i === -1) return false;
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        const removedTag = this.state.tags[i];
        if (removedTag.id > 0) {
            const suggestions = [].concat(this.state.suggestions, removedTag);
            this.setState({ tags, suggestions });
        }
        else {
            this.setState({ tags });
        }
    }

    /**
     * React Tags add function
     * @param {any} i
     */
    onAddition(tag) {
        if (this.state.tags.length >= 20) {
            toast.error('Maximum 20 skills can be added!', { position: 'top-center' });
            return;
        }
        let tagsList = [];
        let suggestionsList = [];
        var isNotExist = true;
        tagsList = this.state.tags;
        suggestionsList = this.state.suggestions;
        tagsList.forEach((item, index) => {
            if (item.name.toLowerCase().trim() === tag.name.toLowerCase().trim()) {
                suggestionsList.forEach((suggestion, index) => {
                    if (item.name.toLowerCase().trim() === suggestion.name.toLowerCase().trim()) {
                        isNotExist = false;
                    }
                });
            }
        });
        if (isNotExist) {
            const tags = [].concat(this.state.tags, tag);
            const suggestions = this.state.suggestions.filter((x) => x.id !== tag.id);
            this.setState({ tags: tags, suggestions: suggestions });
        }
    }


    /**
     *
     * @param {any} tenantSubscriptionId
     * @param {any} featureKey
     * @param {any} operationType
     */
    subscriptionDataDecrement(tenantSubscriptionId, featureKey, operationType) {
        let tenantSubscriptionModel = {
            tenantSubscriptionId: tenantSubscriptionId,
            featureKey: featureKey,
            operationType: operationType,
        };
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData";
            axios
                .put(url, tenantSubscriptionModel, {
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    console.log(
                        "response from decrementing TenantSubscriptionData",
                        response
                    );
                })
                .catch((error) => {
                    console.log(
                        "error during decrementing TenantSubscriptionData",
                        error
                    );
                    displayErrorMessage(
                        "Error During decrementing TenantSubscriptionData!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * Save job event*/
    handleSubmit(event) {
        let jobId = -1;
        let count = 0;
        let bearer = validateAndGetFormatedToken();

        var createdBy = "";

        if (this.state.description == "") {
            displayWarningMessage("Please fill description field!", "top-center");

            return;
        }

        let inputModel = {};

        inputModel.title = this.state.selectedJobTitle;

        if (inputModel.title == "") {
            displayWarningMessage("Please fill title field!", "top-center");

            return;
        }
        inputModel.description = this.state.description;
        //version 2 updates
        if (this.state.country === "United Arab Emirates") {
            inputModel.location = this.state.state;
        } else {
            inputModel.location = this.state.city;
        }
        var tagsCommaSeperated = null;
        if (this.state.tags.length > 0) {
            tagsCommaSeperated = this.state.tags
                .map(function (val) {
                    return val.name;
                })
                .join(",");
        }
        //else {
        //    displayWarningMessage('Please Add Skill Tags!', "top-center");

        //    return;
        //}

        inputModel.country = this.state.country;
        inputModel.state = this.state.state;
        inputModel.industry = this.state.selectedIndustry;
        inputModel.workExperience = this.state.workExperience;
        inputModel.postalCode = this.state.postalCode;
        inputModel.jobType = this.state.selectedJobType;
        inputModel.status = this.state.status;
        inputModel.isIndeed = this.state.isIndeed;
        inputModel.isJooble = this.state.isJooble;
        if (this.state.isIndeed == true && this.state.RequiredEducation === "") {
            displayWarningMessage("Please fill education field!", "top-center");
            return;
        }
        inputModel.minQualification = this.state.RequiredEducation;
        inputModel.jobManager = this.state.jobManager ?? this.state.jobManagerEmail;
        inputModel.jobVisibilityLevel = this.state.jobVisibilityLevel;
        if (this.state.jobVisibilityLevel === "group") {
            inputModel.isJooble = this.state.isJooble;

            if (this.state.selectedGroupId === -1) {
                displayWarningMessage("Please select group!", "top-center");
                return;
            }
            else {
                inputModel.usergroupid = this.state.usergroupid;
            }
        }
        inputModel.jobSource = this.state.jobSource;
        inputModel.introVideoUrl = this.state.introVideoUrl;
        inputModel.outroVideoUrl = this.state.outroVideoUrl;
        inputModel.visibleToAll = false;
        inputModel.vacantPosition = this.state.vacantPosition;
        inputModel.isNegotiableSalary =
            this.state.salarynegotiable === "yes" ? true : false;
        inputModel.salaryType = this.state.salaryType;
        inputModel.jobBonuses = this.state.selectedBonuses;
        inputModel.salaryFrequencyType = this.state.salaryFrequency;
        inputModel.currencyType = this.state.currencyType;
        inputModel.skills = tagsCommaSeperated;
        inputModel.WorkspaceNatureType = this.state.selectedWorkspaceNatureType;
        inputModel.IsForFemaleOnly =
            this.state.forFemaleOnly === "yes" ? true : false;
        inputModel.IsAutoInterviewSchedule =
            this.state.isAutoInterviewSchedule === "yes" ? true : false;
        if (this.state.isTC === true) {
            if (this.state.salaryType == "-1") {
                displayWarningMessage("Please select valid salary type", "top-center");
                return;
            }
            if (this.state.salaryType == "net") {
                if (this.state.minSalary_STD == "") {
                    displayWarningMessage(
                        "Please enter valid salary range",
                        "top-center"
                    );
                    return;
                }
                if (parseInt(this.state.maxSalary_STD) < 0) {
                    displayWarningMessage(
                        "Please enter valid salary range",
                        "top-center"
                    );
                    return;
                }
                if (parseInt(this.state.minSalary_STD) < 0) {
                    displayWarningMessage(
                        "Please enter valid salary range",
                        "top-center"
                    );
                    return;
                }
                if (parseInt(this.state.maxSalary_STD) < parseInt(this.state.minSalary_STD)) {
                    displayWarningMessage(
                        "Maximum salary should be greater than or equal to minimum salary",
                        "top-center"
                    );
                    return;
                }

                inputModel.minSalary = this.state.minSalary_STD;
                inputModel.maxSalary = this.state.maxSalary_STD;
            }
            if (this.state.salaryType == "gross") {
                if (this.state.salary == "" || parseInt(this.state.salary) < 0) {
                    displayWarningMessage(
                        "Please enter valid salary range",
                        "top-center"
                    );
                    return;
                }
                inputModel.salary = this.state.salary;
            }
        }
        else {
            if (this.state.minSalary_STD == "") {
                displayWarningMessage("Please enter valid salary range", "top-center");
                return;
            }
            if (parseInt(this.state.maxSalary_STD) < 0) {
                displayWarningMessage("Please enter valid salary range", "top-center");
                return;
            }
            if (parseInt(this.state.minSalary_STD) < 0) {
                displayWarningMessage("Please enter valid salary range", "top-center");
                return;
            }
            if (
                parseInt(this.state.maxSalary_STD) < parseInt(this.state.minSalary_STD)
            ) {
                displayWarningMessage(
                    "Maximum salary should be greater than or equal to minimum salary",
                    "top-center"
                );
                return;
            }
            inputModel.salaryType = "net";
            inputModel.salary = this.state.salary;
            inputModel.minSalary = this.state.minSalary_STD;
            inputModel.maxSalary = this.state.maxSalary_STD;
        }

        this.state.allJobs.forEach((item, index) => {
            if (item.isActive && item.title === this.state.selectedJobTitle && item.location === this.state.location && item.industry === this.state.selectedIndustry) {
                if (item.location === inputModel.location) count++;
            }
        });
        if (count > 0) {
            displayErrorMessage(
                "Job already exist with same location!",
                "top-center"
            );
            return;
        }

        if (this.state.workExperience < 0) {
            displayWarningMessage("Please add valid work experience", "top-center");
            return;
        }
        if (this.state.vacantPosition < 0) {
            displayWarningMessage("Vacant position at least 1", "top-center");
            return;
        }
        if (this.state.vacantPosition > 1000) {
            displayWarningMessage("Vacant positions should not more than 1000", "top-center");
            return;
        }
        if (
            this.state.selectedJobType === "" ||
            this.state.selectedJobType === "-1" ||
            this.state.selectedJobType === null ||
            this.state.selectedJobType === undefined
        ) {
            displayWarningMessage("Please select a job type", "top-center");
            return;
        }
        if (this.state.status == "-1" || this.state.status == null) {
            displayWarningMessage("Please select a job status", "top-center");
            return;
        }

        if (this.state.jobManager == null) {
            this.state.jobManager = this.state.jobManagerEmail;
        }
        if (this.state.selectedQuestionSetId === -1) {
            displayWarningMessage("Default question set not selected", "top-center");
            return;
        }
        if (inputModel.IsAutoInterviewSchedule) {
            if (this.state.jobVisibilityLevel != "group") {
                if (this.state.selectedInterviewReviewers.length <= 0) {
                    displayWarningMessage("Please add Interview Reviewers!", "top-center");
                    return;
                }
            }
            if (
                new Date(this.state.extensiondate) <
                new Date(this.state.interviewExpiryDate)
            ) {
                displayWarningMessage(
                    "Extension date should be greater than expiry date.",
                    "top-center"
                );
                return;
            }
        }

        inputModel.defaultQuestionSet = this.state.selectedQuestionSetId;
        //ToDo: Fix the values (hard)
        inputModel.isActive = this.state.jobIsActive;

        //Created By
        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });
            var subId = parseJwt(bearer).sub;
            var url =
                process.env.REACT_APP_IDENTITY_API +
                "/Account/GetUsername?userId=" +
                subId;
            axios(url, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    createdBy = response.data;
                })
                .then(() => {
                    inputModel.createdBy = createdBy;

                    //Created Date
                    var d = new Date();
                    var createdDate =
                        d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
                    inputModel.createdDate = createdDate;

                    if (this.state.jobVisibilityLevel === "everyone") {
                        inputModel.visibleToAll = true;
                    }
                    else {
                        inputModel.visibleToAll = false;
                    }

                    if (bearer !== "" || bearer != null) {
                        var model = JSON.stringify(inputModel);

                        let url = process.env.REACT_APP_CORE_API + "/api/jobs/";

                        const requestOptions = {
                            method: "POST",
                            headers: {
                                Authorization: bearer,
                                "Content-Type": "application/json",
                            },
                            body: model,
                        };

                        fetch(url, requestOptions)
                            .then((response) => response.json())
                            .then((result) => {
                                jobId = result;
                                if (result != null && result !== undefined) {
                                    //Create array of objects
                                    let list = [];

                                    let phoneobj = {
                                        jobId: result,
                                        questionnarieId: 2, //What is your phone?
                                        constraintType: this.state.phoneqconstraint,
                                    };

                                    list.push(phoneobj);

                                    let cvobj = {
                                        jobId: result,
                                        questionnarieId: 3,
                                        constraintType: this.state.cvqconstraint,
                                    };
                                    list.push(cvobj);

                                    let cnicobj = {
                                        jobId: result,
                                        questionnarieId: 4,
                                        constraintType: this.state.cnicconstraint,
                                    };
                                    list.push(cnicobj);

                                    //Post array of object to JobQuestionnariesController

                                    const options1 = {
                                        method: "POST",
                                        headers: {
                                            Authorization: bearer,
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify(list),
                                    };

                                    fetch(
                                        process.env.REACT_APP_CORE_API + "/api/JobQuestionnaries/",
                                        options1
                                    )
                                        .then((response) => {
                                            response.json();
                                        })
                                        .then(() => {
                                            let tenantSubscriptionModel = {
                                                tenantSubscriptionId: this.state.tenantSubscriptionId,
                                                featureKey: "Jobs",
                                                operationType: "decrement",
                                            };
                                            var url =
                                                process.env.REACT_APP_CORE_API +
                                                "/api/TenantSubscriptionData";
                                            axios
                                                .put(url, tenantSubscriptionModel, {
                                                    headers: {
                                                        Authorization: bearer,
                                                        "Content-Type": "application/json",
                                                    },
                                                })
                                                .then((response) => {
                                                    this.setState({ disableSaveBtn: false });
                                                    console.log(
                                                        "response from decrementing job",
                                                        response
                                                    );
                                                })
                                                .catch((error) => {
                                                    console.log("error during decrementing job", error);
                                                    this.setState({ disableSaveBtn: false });
                                                    displayErrorMessage(
                                                        "Error During decrementing job!",
                                                        "top-center"
                                                    );
                                                });
                                        })
                                        .then(() => {
                                            if (this.state.isAutoInterviewSchedule === "yes") {
                                                this.createInterview(this.state.title, jobId);
                                                this.setState({ SuccessMessage: true });
                                            } else {
                                                this.setState({ SuccessMessage: true });
                                            }
                                        })
                                        .catch((error) => {
                                            console.log("error during creating job", error);
                                            this.setState({ disableSaveBtn: false });

                                            displayErrorMessage(
                                                "Error During Job Creation!",
                                                "top-center"
                                            );
                                        });
                                }
                            })

                            .catch(() => {
                                this.setState({ disableSaveBtn: false });

                                displayErrorMessage("Error During Job Creation!", "top-center");
                            });
                    }
                })
                .catch((error) => {
                    console.log(
                        "error during returning created by while creating job",
                        error
                    );
                    this.setState({ disableSaveBtn: false });

                    displayErrorMessage(
                        "Error During Returning Created By While Creating Job!",
                        "top-center"
                    );
                });
        }
    }

    /**
     * create interview submit button handler
     */
    createInterview(jobName, jobId) {
        if (this.state.jobVisibilityLevel != "group") {
            if (this.state.selectedInterviewReviewers.length <= 0) {
                displayWarningMessage("Please add Interview Reviewers!", "top-center");
                return;
            }
        }
        var taglist = "";
        for (var j = 0; j < this.state.interviewtags.length; j++) {
            if (taglist === "") {
                taglist = this.state.interviewtags[j];
            }
            else {
                taglist = taglist + "," + this.state.interviewtags[j];
            }
        }
        if (this.state.jobVisibilityLevel === "manager") {
            if (this.state.reviewers.length > 0) {
                this.setState({ selectedInterviewReviewers: [] });
                this.setState(prevState => ({
                    selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, this.state.reviewers[0].email]
                }));
            }
            else {
                displayWarningMessage("Please add Interview Reviewers!", "top-center");
                return;
            }
        }
        else if (this.state.jobVisibilityLevel === "group") {
            this.setState({ selectedInterviewReviewers: [] });
            for (var j = 0; j < this.state.userGroupsMembers.length; j++) {
                if (this.state.userGroupsMembers[j].userGroupId === parseInt(this.state.usergroupid)) {
                    for (var i = 0; i < this.state.tenantUsersList.length; i++) {
                        if (this.state.userGroupsMembers[j].username === this.state.tenantUsersList[i].email) {
                            this.setState(prevState => ({
                                selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, this.state.tenantUsersList[i].email]
                            }));
                        }
                    }
                }
            }

        }
        let interviewobject = {
            interview: {
                jobId: jobId,
                jobName: jobName,
                interviewType: "oneway",
                timeZoneName: this.state.selectedTimeZone,
                openInterviewDeadLineType: "Absolute",
                relitiveDeadLineNoOfDays: null,
                expiryDate: this.state.interviewExpiryDate,
                expiryTime: this.state.hourvalue + ":" + this.state.minutevalue,
                extensiondate: this.state.extensiondate,
                IsAutoScheduled: true,
                interviewLevel: this.state.interviewLevel === "OtherInterview" ? this.state.otherInterviewLevel : this.state.interviewLevel,
                tagsCSV: taglist,
            },
            questions: this.state.selectedQuestionSet.questions,

            ReviewerUsernames: this.state.selectedInterviewReviewers,
        };
        console.log(
            "this.state.selectedQuestionSet.questions",
            this.state.selectedQuestionSet
        );
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            this.setState({ disableSaveBtn: true });
            var model = JSON.stringify(interviewobject);

            let url = process.env.REACT_APP_CORE_API + "/api/autointerview";

            axios(url, {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                data: model,
            })
                .then((response) => {
                    displaySuccessMessage("Automated interview Created!", "top-center");

                    this.subscriptionDataDecrement(
                        this.state.tenantSubscriptionId,
                        "OneWayClose",
                        "decrement"
                    );
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage(
                        "Error while creating automated interview!",
                        "top-center"
                    );
                });
        }
    }

    /** Save Job Title*/

    saveJobTitle() {
        let bearer = validateAndGetFormatedToken();
        let tenantId = null;
        let count = 0;
        if (this.state.isSaving) {
            return;
        }

        this.setState({ isSaving: true });
        if (this.state.jobTitle.trim() === "") {
            displayErrorMessage("Please add job title!", "top-center");
            this.setState({ isSaving: false });

            return;
        }
        if (this.state.jobTitle.trim().length > 100) {
            displayErrorMessage("Job title cannot exceed 100 characters!", "top-center");
            this.setState({ isSaving: false });
            return;
        }
        if (this.state.selectedIndustryIdForJobTitle == -1) {
            displayErrorMessage("Please select industry!", "top-center");
            this.setState({ isSaving: false });
            return;
        }
        if (this.state.selectedQuestionSetIdForJobTitle === '') {
            displayErrorMessage("Please select question set!", "top-center");
            this.setState({ isSaving: false });

            return;
        }

        if (this.state.selectedQuestionSetIdForJobTitle == -1) {
            displayErrorMessage("Please select question set!", "top-center");
            this.setState({ isSaving: false });

            return;
        }
        this.state.jobTitles.forEach((item, index) => {
            if ((item.title.trim().toLowerCase() === this.state.jobTitle.trim().toLowerCase()) && (this.state.selectedIndustryIdForJobTitle === item.defaultIndustryId)) {
                count++;
            }
        });
        if (count > 0) {
            displayErrorMessage("Job title already exist!", "top-center");
            this.setState({ isSaving: false });

            return;
        }
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let data = {
                Title: this.state.jobTitle.trim(),
                ShortDescription: this.state.jobTitle.trim(),
                IsDefault: false,
                TenantId: tenantId,
                IsActive: true,
                DefaultIndustryId: this.state.selectedIndustryIdForJobTitle,
            };
            let url = process.env.REACT_APP_CORE_API + "/api/JobTitles";

            var model = JSON.stringify(data);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result != null && result !== undefined) {
                        let data1 = {
                            selectedJobTitle: result,
                            JobTitleId: result,
                            QuestionSetId: this.state.selectedQuestionSetIdForJobTitle,

                            IsActive: true,
                        };
                        let url =
                            process.env.REACT_APP_CORE_API + "/api/JobTitleDefaultTemplates";

                        var model1 = JSON.stringify(data1);

                        const requestOptions = {
                            method: "POST",
                            headers: {
                                Authorization: bearer,
                                "Content-Type": "application/json",
                            },
                            body: model1,
                        };
                        fetch(url, requestOptions)
                            .then(() => {
                                displaySuccessMessage(
                                    "Saved Successfully!",
                                    "top-center"
                                );
                                this.setState({
                                    jobTitle: "",
                                    addJobTitleModal: false,
                                    selectedQuestionSetIdForJobTitle: "",
                                });
                                this.getAllJobTitles(this.state.selectedIndustryId);
                            })
                            .catch((err) => {
                                console.log(err);
                                displayErrorMessage(
                                    "Error while Saving Job Title Default Template!",
                                    "top-center"
                                );
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Saving Job Title!", "top-center");
                })
                .finally(() => {
                    this.setState({ isSaving: false, addJobTitleModal: false });
                    document.body.classList.remove('modal-open');
                });
        }

    }


    /** Save Question Set*/

    saveQuestionSet() {
        console.log("saveQuestionSet");
        let bearer = validateAndGetFormatedToken();
        let tenantId = null;
        let count = 0;
        if (this.state.IsQuestionsetSaving === 1) {
            return;
        }
        this.setState({ IsQuestionsetSaving: 1 });
        this.state.questions.forEach((item, index) => {
            if (item.ischecked === true) {
                count++;
            }
        });
        if (count === 0) {
            displayErrorMessage("At least one question should be selected!", "top-center");
            this.setState({ IsQuestionsetSaving: 0 });
            return;
        }
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            var subId = parseJwt(bearer).sub;

            let data = {
                JobTitle: this.state.jobTitle,
                TenantId: tenantId,
                QuestionsList: this.state.questions,
                createdBy: subId
            };
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSetGPT";

            var model = JSON.stringify(data);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result != null) {
                        if (this.state.IsQuestionListLoad > 0) {
                            this.setState({ NewQuestionSetId: result.questionSetId });
                            this.getQuestionSetsForJobTitles();
                            this.setState({
                                IsQuestionListLoad: 0,
                                questions: [],
                                IsQuestionsetSaving: 0,
                                NewQuestionSetFinished: 0,
                            });
                        }
                    }
                    else {
                        console.log("Questions is null");
                    }

                    this.setState({ IsQuestionsetSaving: 0 });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ IsQuestionsetSaving: 0 });
                    displayErrorMessage("Error while Questions!", "top-center");
                })
                .finally(() => {
                    this.setState({ IsQuestionsetSaving: 0 });
                });
        }

    }

    newQuestionSetCancel() {
        this.setState({
            IsQuestionListLoad: 0,
            questions: [],
            IsQuestionsetSaving: 0,
            NewQuestionSetId: 0,
            NewQuestionSetFinished: 0,
        });
    }

    /** Save child Skill*/

    saveSkill(event) {
        const skillExists = this.state.allSkills.some(skill => skill.title === this.state.skill);
        if (skillExists) {
            displayWarningMessage("This skill already exists!", "top-center");
            return
        }


        if (this.state.skill.trim() === "" || this.state.skill === undefined) {
            displayWarningMessage("Please enter skill!", "top-center");
            return;
        }

        if (this.state.skill.length > 30) {
            displayWarningMessage(
                "Skill maximum lenght 30 charachters!",
                "top-center"
            );
            return;
        }

        if (this.state.skill.includes(",")) {
            displayWarningMessage(
                "Comma (,) is not allowed in Skills!",
                "top-center"
            );
            return;
        }

        let bearer = validateAndGetFormatedToken();
        var tenantId = null;

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let data = {
                Title: this.state.skill,
                ShortDesc: "",
                IsDefault: false,
                TenantId: tenantId,
                IsActive: true,
                //DefaultIndustryId: -1, // this.state.selectedIndustryIdForSkill,
                //ParentId: -1, //this.state.selectedParentSkillId,
                LevelId: 1,
            };
            let url = process.env.REACT_APP_CORE_API + "/api/ChildSkills";

            var model = JSON.stringify(data);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };
            fetch(url, requestOptions)
                .then(() => {
                    displaySuccessMessage("Skill Saved Successfully!", "top-center");
                    this.setState({
                        skill: "",
                    }, () => {
                        this.setState({
                            isSaveDisabled: false,
                            addSkillModal: false,
                        });
                    });
                    this.getAllSkills(this.state.selectedIndustryId);
                })
                .catch((err) => {
                    console.log(err);
                    displayErrorMessage("Error while Saving Child Skill!", "top-center");
                });
        }
        document.body.classList.remove('modal-open');

    }


    handleNextStep = () => {
        const { step, selectedIndustry, selectedJobTitle, description, selectedQuestionSet, minSalary_STD, wordCount,
            maxSalary_STD, vacantPosition, selectedInterviewReviewers, isAutoInterviewSchedule, salaryType, workExperience, country, state, city } = this.state;
        if (step === 1) {


            if (!selectedIndustry) {
                toast.error('Please select an Industry', { position: 'top-center' });
                return;
            }
            if (!selectedJobTitle) {
                toast.error('Please select a Job Title', { position: 'top-center' });
                return;
            }
            if (!vacantPosition) {
                toast.error('Please enter a valid number', { position: 'top-center' });
                return;
            }
            if (vacantPosition <= 0) {
                toast.error("Please enter a valid number", "top-center");
                return;
            }
            if (vacantPosition % 1 !== 0) {
                toast.error("Please enter a valid number", "top-center");
                return;
            }
        }
        if (step === 2) {

            let existingActiveJob = 0;
            this.state.allJobs.forEach((item, index) => {
                if (item.isActive && item.title === this.state.selectedJobTitle && item.location === this.state.location && item.industry === this.state.selectedIndustry) {
                    existingActiveJob++;
                }
            });
            if (existingActiveJob) {
                displayErrorMessage(
                    "Job already exist with same location!",
                    "top-center"
                );
                return;
            }

            if (!country) {
                toast.error('Please select job country', { position: 'top-center' });
                return;
            }
            if (!state) {
                toast.error('Please select job state', { position: 'top-center' });
                return;
            }
            if (!city) {
                toast.error('Please select job city', { position: 'top-center' });
                return;
            }
        }
        if (step === 3) {
            if (!description) {
                toast.error('Please provide a job description', { position: 'top-center' });
                return;
            }
            if (wordCount > 15000) {
                toast.error('Maximum 15000 words are allowed for job description', { position: 'top-center' });
                return
            }
        }
        if (step === 4) {
            if (!selectedQuestionSet) {
                toast.error('Please select a default question set', { position: 'top-center' });
                return;
            }
        }
        if (step === 5) {
            if (this.state.tags.length === 0) {
                toast.error('Please select a skill', { position: 'top-center' });
                return;
            }
        }
        if (step === 6) {
            const extensionDate = new Date(this.state.extensiondate);
            extensionDate.setHours(0, 0, 0, 0);

            const interviewExpiryDate = new Date(this.state.interviewExpiryDate);
            interviewExpiryDate.setHours(0, 0, 0, 0);

            if (this.state.jobVisibilityLevel === "group" && this.state.selectedGroupId === -1) {
                toast.error("Please select group!", "top-center");
                return;
            }

            if (isAutoInterviewSchedule === "yes") {
                if (this.state.interviewLevel === "OtherInterview") {
                    if (this.state.otherInterviewLevel === "") {
                        toast.error('Please add interview level!', "top-center");
                        return;
                    }
                }
                if (this.state.interviewtags.length < 1) {
                    toast.error('Please add interview tag!', "top-center");
                    return;
                }
                if (this.state.jobVisibilityLevel != "group") {
                    if (selectedInterviewReviewers.length <= 0) {
                        toast.error("Please add Interview Reviewers!", "top-center");
                        return;
                    }
                }
                if (this.state.selectedInterviewReviewers.length <= 0) {
                    displayWarningMessage("Please add Interview Reviewers!", "top-center");
                    return;
                }

                if (extensionDate <= interviewExpiryDate) {
                    displayWarningMessage(
                        "Extension date should be greater than expiry date",
                        "top-center"
                    );
                    return;
                }
            }
        }
        if (step === 8) {
            if (!workExperience) {
                toast.error('Please select work Experience', { position: 'top-center' });
                return;
            }
        }
        if (step === 7) {

            if (salaryType == "gross") {
                if (this.state.salary == "" || parseInt(this.state.salary) < 0) {
                    displayWarningMessage(
                        "Please enter gross salary",
                        "top-center"
                    );
                    return;
                }
            }
            else {
                if (!minSalary_STD && !maxSalary_STD) {
                    toast.error('Please select salary amount', { position: 'top-center' });
                    return;
                }
                if (!minSalary_STD && maxSalary_STD) {
                    toast.error('Please select Min salary amount', { position: 'top-center' });
                    return;
                }
                if (minSalary_STD && !maxSalary_STD) {
                    toast.error('Please select Max salary amount', { position: 'top-center' });
                    return;
                }
                if (minSalary_STD < 0) {
                    toast.error("Please enter valid salary amount", "top-center");
                    return;
                }
                if (maxSalary_STD < 0) {
                    toast.error("Please enter valid salary amount", "top-center");
                    return;
                }
                if (
                    parseInt(maxSalary_STD) < parseInt(minSalary_STD)
                ) {
                    displayWarningMessage(
                        "Maximum salary should be greater than or equal to minimum salary",
                        "top-center"
                    );
                    return;
                }
            }
        }
        window.scrollTo(0, 0);
        this.setState((prevState) => ({ step: prevState.step + 1 }));
    };

    handlePreviousStep = () => {
        window.scrollTo(0, 0);

        this.setState((prevState) => ({ step: prevState.step - 1 }));
    };
    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        const wordcount = this.countCharacters(data); // Use a separate function to count characters
        console.log(data);
        console.log("length", wordcount);

        this.setState({ description: data });
        this.setState({ wordCount: wordcount });
    };

    countCharacters = (text) => {
        // Remove HTML tags and count characters
        const cleanText = text.replace(/<[^>]*>/g, '');
        return cleanText.length;
    };

    renderOption(key, label) {
        return (
            <option key={key} value="">
                {label}
            </option>
        );
    }

    render() {
        const onRowSelectforBonus = (row, isSelected) => {
            row.checked = isSelected.target.checked;
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedBonuses;
                selectedsets.push(row);

                this.setState({ selectedBonuses: selectedsets });
            } else {
                let selectedsets = this.state.selectedBonuses;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.tenantBonusId === selectedsets[i].tenantBonusId) {
                        selectedsets.splice(i, 1);

                        this.setState({ selectedBonuses: selectedsets });
                        return;
                    }
                }
            }
        };

        const onRowSelectforquestions = (row, isSelected) => {
            if (isSelected.target.checked) {
                let questionSets = this.state.questionSets;
                let selectedQuestionSet = null;
                for (let i = 0; i < questionSets.length; i++) {
                    if (questionSets[i].questionSetId === row.questionSetId) {

                        if (this.state.defaultQuestionSetSelected === true) {

                            displayWarningMessage(
                                "Please select only 1 question set with job",
                                "top-center"
                            );
                        }
                        else {
                            selectedQuestionSet = questionSets[i];
                            this.setState({ defaultQuestionSetSelected: true, selectedQuestionSetId: row.questionSetId, selectedQuestionSet: selectedQuestionSet });
                            questionSets[i].isChecked = true;
                        }
                    }
                }
            } else {
                let questionSets = this.state.questionSets;
                this.setState({ defaultQuestionSetSelected: false });
                for (let i = 0; i < questionSets.length; i++) {
                    if (questionSets[i].questionSetId === row.questionSetId) {
                        questionSets[i].isChecked = false;
                    }
                }
                this.setState({
                    questionSets: questionSets,
                    selectedQuestionSetId: -1,
                    selectedQuestionSet: null,
                });
            }
        };

        const onSelectquestions = (row, isSelected) => {
            console.log(row.questionId + " : ischecked");
            console.log(isSelected.target.checked);
            if (isSelected.target.checked) {
                let questionList = this.state.questions;
                for (let i = 0; i < questionList.length; i++) {
                    if (questionList[i].questionId === row.questionId) {
                        questionList[i].ischecked = true;
                    }
                }
                this.setState({
                    questions: questionList,
                });
            } else {
                let questionList = this.state.questions;
                for (let i = 0; i < questionList.length; i++) {
                    if (questionList[i].questionId === row.questionId) {
                        questionList[i].ischecked = false;
                    }
                }
                this.setState({
                    questions: questionList,
                });
            }
        };

        //For Selecting Interview reviewers

        const onRowSelectForInterviewReviewers = (row, isSelected) => {
            if (isSelected.target.checked) {
                this.setState(prevState => ({
                    selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, row.email]
                }));
            } else {
                this.setState(prevState => ({
                    selectedInterviewReviewers: prevState.selectedInterviewReviewers.filter(
                        email => email !== row.email
                    )
                }));
            }
        };



        return (
            <>
                {/* <main id="content" role="main" className="main"> */}
                {/* <!-- Content --> */}
                {/* <div className="content container-fluid"> */}

                {this.state.SuccessMessage ? (
                    <>
                        {/* <!-- Message Body --> */}

                        <div
                            id="validationFormSuccessMessage"
                            className="js-success-message  align-items-center "

                        >
                            <div class="card border-0 jc-radius mb-3">
                                <div class="row justify-content-center align-items-center g-0">
                                    <div class="col-md-12">
                                        <div class="card-body text-center p-success" style={{ height: "100vh" }}>
                                            <div>
                                                <img
                                                    class="success-img"
                                                    src={JobCongImg}
                                                    alt=""
                                                />
                                                <h1
                                                    class="f-size-24 fw-bold m-2"
                                                    style={{ color: "#482D7D;" }}
                                                >
                                                    Congratulations
                                                </h1>
                                                <p>
                                                    Your job has been created successfully.
                                                    <br />
                                                    Check the brief information in job listing
                                                    panel.
                                                </p>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        this.props.history.push("/jobs");
                                                    }}
                                                    class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color mob-b-d-size text-center"
                                                    type="button"
                                                >
                                                    Your Jobs
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Message Body --> */}
                    </>
                ) : (
                    <>
                        {/* <!--- New Design Started ---> */}
                        <main
                            id="content"
                            role="main"
                            className={`main ${this.props.isCollapsed ? "swift-p-default" : ""
                                }`}
                        // className="main"
                        >
                            <section id="main-right">
                                <div class="container-fluid">
                                    {/* <!--- Step-1 Add Job Basics ---> */}
                                    {this.state.step === 1 && (
                                        <div class="row align-items-center">

                                            {/* <!-- Swift Details --> */}
                                            {/* <IntroSidebar title="" description="" /> */}
                                            {/* <!-- Swift Form --> */}
                                            <div class="col-md-12 col-lg-12">
                                                <div class="swift-form-job">
                                                    {/* <!-- Progress Bar --> */}
                                                    <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                        <div class="progress" style={{ height: "10px;" }}>
                                                            <div
                                                                class="progress-bar pbar-color"
                                                                role="progressbar"
                                                                style={{ width: "10%" }}
                                                                aria-valuenow="20"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                    </div>


                                                    {/* <!-- Ends here-->
                                                        <!-- Job Card With Image --> */}
                                                    <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="job-card-img jc-pad">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="card-title jc-title mb-0">
                                                                            Add Job Basics
                                                                        </h5>
                                                                        <img
                                                                            src={JobBasicImg}
                                                                            alt=""
                                                                            class="img-fluid"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">

                                                                    <>
                                                                        <label
                                                                            for="selectIndustry"
                                                                            class="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Select Industry
                                                                            <span class="asterisk"> *</span>
                                                                        </label>
                                                                        <Select
                                                                            id="industries"
                                                                            name="industries"
                                                                            value={this.state.industries.find(item => item.title === this.state.selectedIndustry)}
                                                                            options={this.state.industries}
                                                                            onChange={this.handleIndustry}
                                                                            className="marg-b-10"
                                                                        />
                                                                        {/*{this.state.isJobTitlesRequired > 0 && (*/}
                                                                        {/*    <div className="col-md-12">*/}
                                                                        {/*        <button*/}
                                                                        {/*            type="button"*/}
                                                                        {/*            className="btn btn-primary d-flex align-items-center"*/}
                                                                        {/*            data-bs-dismiss="modal"*/}
                                                                        {/*            aria-label="Close"*/}
                                                                        {/*            onClick={() => {*/}
                                                                        {/*                this.createNewJobTitlesGPT();*/}
                                                                        {/*            }}*/}
                                                                        {/*        >*/}
                                                                        {/*            <span className="material-symbols-outlined">*/}
                                                                        {/*                lightbulb*/}
                                                                        {/*            </span>*/}
                                                                        {/*            <span className="ms-2">Help me create New Job Titles</span>*/}
                                                                        {/*        </button>*/}
                                                                        {/*    </div>*/}

                                                                        {/*)}*/}

                                                                        <label
                                                                            for="jobTitle"
                                                                            class="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Job Title<span class="asterisk"> *</span>
                                                                            {(this.state.jobTitles.length > 0 && this.state.IsGPTJobTitleCreated === 1) ? (
                                                                                <span style={{ marginLeft: "15px" }}><label style={{ color: "#e74c3c" }}> New Job Titles created and added in below drop down please select!  </label> </span>
                                                                            ) : (<span></span>)}
                                                                        </label>
                                                                        {/* <input type="text" class="marg-b-10 form-control swift-shadow h-swift-44" name="jobTitle" placeholder="Select" value="" /> */}

                                                                        <div class="row mb-2">
                                                                            <div className="col-md-9">
                                                                                {console.log(this.state.jobTitles)}
                                                                                {/* <!-- Select --> */}
                                                                                {/* <label for="numberOfPeopleHireForThisJob" class="form-label f-size-14 fw-bold">Job Title<span class="asterisk"> *</span></label><br /> */}
                                                                                {this.state.jobTitles.length > 0 ? (
                                                                                    <Typeahead
                                                                                        id="jobtitle"
                                                                                        labelKey="title"
                                                                                        options={this.state.jobTitles}
                                                                                        defaultSelected={this.state.jobTitles.filter(item => item.title === this.state.selectedJobTitle)}
                                                                                        placeholder="Choose a Job Title..."
                                                                                        paginationText="Load More..."
                                                                                        onChange={(value) => {
                                                                                            this.setState({
                                                                                                selectedJobTitle:
                                                                                                    value.length > 0
                                                                                                        ? value[0].title
                                                                                                        : "",
                                                                                            });
                                                                                            this.setState({
                                                                                                selectedQuestionSetId: -1,
                                                                                            });

                                                                                            value.length > 0
                                                                                                ? this.getDefaultTemplateByJobTitleId(
                                                                                                    value[0].jobTitleId
                                                                                                )
                                                                                                : this.setState({
                                                                                                    jobTitleDefaultTemplate:
                                                                                                        null,
                                                                                                    hideDefaultQuestionSet: true,
                                                                                                });
                                                                                        }}
                                                                                        ref={this.ref}
                                                                                    />
                                                                                ) : (

                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="No job title found, to add job title click on add button"
                                                                                        readOnly
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            <button
                                                                                className="col-md-3 btn btn-outline-primary d-none d-md-block"
                                                                                onClick={() => {

                                                                                    this.setState({
                                                                                        addJobTitleModal: true,
                                                                                        NewQuestionSetId: 0,
                                                                                    });
                                                                                    document.body.classList.add('modal-open');

                                                                                }}
                                                                                data-tip
                                                                                data-for="otherJobTitle"
                                                                                id="otherJobTitle"
                                                                                style={{ width: '23.9%' }}
                                                                            >
                                                                                <i
                                                                                    className="bi bi-plus-circle"
                                                                                    style={{ cursor: "pointer" }}
                                                                                ></i>{" "}
                                                                                Add More
                                                                                <ReactTooltip
                                                                                    id="otherJobTitle"
                                                                                    place="top"
                                                                                    type="dark"
                                                                                    effect="float"
                                                                                >
                                                                                    <span>
                                                                                        {" "}
                                                                                        Add job title if not found in the
                                                                                        list{" "}
                                                                                    </span>
                                                                                </ReactTooltip>
                                                                            </button>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col d-flex justify-content-end">
                                                                                <button
                                                                                    className=" d-md-none d-block btn btn-outline-primary"
                                                                                    onClick={() => {

                                                                                        this.setState({
                                                                                            addJobTitleModal: true,
                                                                                            NewQuestionSetId: 0,
                                                                                        });
                                                                                        document.body.classList.add('modal-open');

                                                                                    }}
                                                                                    data-tip
                                                                                    data-for="otherJobTitle"
                                                                                    id="otherJobTitle"
                                                                                >
                                                                                    <i
                                                                                        className="bi bi-plus-circle"
                                                                                        style={{ cursor: "pointer" }}
                                                                                    ></i>{" "}
                                                                                    Add More
                                                                                    <ReactTooltip
                                                                                        id="otherJobTitle"
                                                                                        place="top"
                                                                                        type="dark"
                                                                                        effect="float"
                                                                                    >
                                                                                        <span>
                                                                                            {" "}
                                                                                            Add job title if not found in the
                                                                                            list{" "}
                                                                                        </span>
                                                                                    </ReactTooltip>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <label
                                                                            for="numberOfPeopleHireForThisJob"
                                                                            class="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Number of people hire for this job
                                                                            <span class="asterisk"> *</span>
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            onChange={this.handleInputChange}
                                                                            min="0"
                                                                            name="vacantPosition"
                                                                            id="vacantPosition"
                                                                            value={this.state.vacantPosition}
                                                                            aria-label="Your department"
                                                                        />
                                                                    </>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">
                                                        <div class="row">
                                                            <div class="col-md-12 button-right">
                                                                <div class="d-md-flex justify-content-between">
                                                                    <button
                                                                        onClick={this.handleNextStep}
                                                                        id="createJobBtn"
                                                                        class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right "
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Continue{" "}
                                                                            <i class="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <AddJobBasic />  */}
                                            {/* <AddJobDetail /> */}
                                            {/* <AddJobDescription /> */}
                                        </div>
                                    )}
                                    {/* <!--- End Add Job Basics ---> */}

                                    {/* <!---Step-2 Add Job Basics ---> */}
                                    {this.state.step === 2 && (
                                        <div class="row align-items-center">
                                            {/* <!-- Swift Details --> */}
                                            {/* <IntroSidebar title="" description="" /> */}
                                            {/* <!-- Swift Form --> */}
                                            <div class="col-md-12 col-lg-12">
                                                <div class="swift-form-job">
                                                    {/* <!-- Progress Bar --> */}
                                                    <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                        <div class="progress" style={{ height: "10px;" }}>
                                                            <div
                                                                class="progress-bar pbar-color"
                                                                role="progressbar"
                                                                style={{ width: "20%" }}
                                                                aria-valuenow="20"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- End progress bar here --> */}
                                                    <div class="card jc-radius border-0 mb-3 d-none">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body notification-bar">
                                                                    <h5 class="job-card-title">
                                                                        Job post will be in{" "}
                                                                        <span class="black fw-bold">English</span>{" "}
                                                                        in{" "}
                                                                        <span class="black fw-bold">
                                                                            {" "}
                                                                            United States{" "}
                                                                            <i
                                                                                class="gray fa fa-pencil"
                                                                                id="toggle-password"
                                                                            ></i>
                                                                        </span>
                                                                    </h5>
                                                                    <p class="card-text"></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Job Card With Image --> */}

                                                    <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="job-card-img jc-pad">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="card-title jc-title mb-0">
                                                                            Location & Job Type
                                                                        </h5>
                                                                        <img
                                                                            src={JobDetailsImg}
                                                                            alt=""
                                                                            class="img-fluid"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">


                                                                    <form id="edit-job">
                                                                        <div class="row">
                                                                            <div class="col-md-6 swft-form-input">
                                                                                <label
                                                                                    for="country"
                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                >
                                                                                    Country<span class="asterisk">* </span>
                                                                                </label>
                                                                                <select
                                                                                    className="marg-b-10 form-select form-control"
                                                                                    id="country"
                                                                                    name="country"
                                                                                    // value={this.state.state}
                                                                                    value={this.state.countries?.findIndex(item => item.locationName === this.state.country) || ""}
                                                                                    onChange={(e) => {
                                                                                        const selectedCountry = this.state.countries[e.target.value];
                                                                                        this.setState({ country: selectedCountry ? selectedCountry.locationName : '' }, () => {
                                                                                            if (selectedCountry) {
                                                                                                this.getStates(selectedCountry.locationId);
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    {this.state.countries !== [] &&
                                                                                        this.state.countries.map((country, index) => (
                                                                                            <option key={index} value={index} selected={country.locationName === this.state.country}>
                                                                                                {country.locationName}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>

                                                                            </div>

                                                                            <div class="col-md-6 swft-form-input">
                                                                                <label
                                                                                    for="firstName"
                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                >
                                                                                    State<span class="asterisk">* </span>
                                                                                </label>
                                                                                <select
                                                                                    className="marg-b-10 form-select form-control"
                                                                                    id="state"
                                                                                    name="state"
                                                                                    // value={this.state.state}
                                                                                    value={this.state.states?.findIndex(item => item.locationName === this.state.state) || ""}
                                                                                    onChange={(e) => {
                                                                                        const selectedState = this.state.states[e.target.value];
                                                                                        this.setState({ state: selectedState ? selectedState.locationName : '' }, () => {
                                                                                            if (selectedState) {
                                                                                                this.getCities(selectedState.locationId);
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    {this.state.states !== [] &&
                                                                                        this.state.states.map((state, index) => (
                                                                                            <option key={index} value={index} selected={state.locationName === this.state.state}>
                                                                                                {state.locationName}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                        {/* City dropdown start here */}
                                                                        <div class="row">
                                                                            {console.log("check", this.state.cities?.findIndex(item => item.locationName === this.state.city), this.state.city, this.state.state, this.state.country, "yesss")}
                                                                            <div class="swft-form-input col-md-6">
                                                                                <label
                                                                                    for="city"
                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                >
                                                                                    City<span class="asterisk"> *</span>
                                                                                </label>
                                                                                <select
                                                                                    className="marg-b-10 form-select form-control"
                                                                                    id="city"
                                                                                    name="city"
                                                                                    value={this.state.cities?.findIndex(item => item.locationName === this.state.city) || ""}
                                                                                    onChange={(e) => {
                                                                                        const selectedCity = this.state.cities[e.target.value];
                                                                                        this.setState({ city: selectedCity ? selectedCity.locationName : '', location: selectedCity.locationName });
                                                                                    }}
                                                                                >
                                                                                    {this.state.cities.map((city, index) => (
                                                                                        <option
                                                                                            key={index}
                                                                                            value={index}
                                                                                            selected={city.locationName === this.state.city}
                                                                                        >
                                                                                            {city.locationName}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>

                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <label
                                                                                    for="lastName"
                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                >
                                                                                    Zip Postal
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    onChange={this.handleInputChange}
                                                                                    name="postalCode"
                                                                                    id="postalCode"
                                                                                    placeholder="Zip/Postal Code"
                                                                                    aria-label="Your department"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">
                                                                    <label
                                                                        for="jobTitle"
                                                                        class="form-label f-size-14 fw-bold"
                                                                    >
                                                                        What type of job is it
                                                                        <span class="asterisk"> *</span>
                                                                    </label>
                                                                    <form
                                                                        id="add-job-detials"
                                                                        action="#"
                                                                        class="pad-20-b"
                                                                    >
                                                                        <div className="row">
                                                                            {/* <!-- Form Inputs as Checkbox--> */}
                                                                            {Object.keys(this.state.jobTypes).map(
                                                                                (key) => (
                                                                                    <div className="swft-form-input col-md-2">
                                                                                        <div class="form-check mb-2">
                                                                                            <div class="swift-wrapper" key={key}>
                                                                                                <input
                                                                                                    onChange={this.handleInputChange}
                                                                                                    class="form-check-input"
                                                                                                    type="radio"
                                                                                                    id={key}
                                                                                                    name="selectedJobType"
                                                                                                    value={this.state.jobTypes[key]}
                                                                                                    checked={this.state.selectedJobType === this.state.jobTypes[key]}

                                                                                                />
                                                                                                <label
                                                                                                    class="form-check-label"
                                                                                                    for="partTime"
                                                                                                >
                                                                                                    {key}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/*        <option key={key} value={this.state.jobTypes[key]}>{key} </option>*/}
                                                                                    </div>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-md-flex justify-content-between">
                                                                    <button
                                                                        onClick={this.handlePreviousStep}
                                                                        class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            <i class="bi bi-arrow-left mr-1"></i>Back
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        onClick={this.handleNextStep}
                                                                        id="createJobBtn"
                                                                        class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Continue{" "}
                                                                            <i class="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <!--- End Add Job Basics ---> */}

                                    {/* <!---Step-3 Add Job Description---> */}
                                    {this.state.step === 3 && (
                                        <div class="row">
                                            {/* <!-- Swift Details --> */}
                                            {/* <IntroSidebar title="" description="" /> */}
                                            {/* <!-- Swift Form --> */}
                                            <div class="col-md-12 col-lg-12  align-items-center">
                                                <div class="swift-form-job">
                                                    {/* <!-- Progress Bar --> */}
                                                    <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                        <div class="progress" style={{ height: "10px;" }}>
                                                            <div
                                                                class="progress-bar pbar-color"
                                                                role="progressbar"
                                                                style={{ width: "30%" }}
                                                                aria-valuenow="20"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                    </div>

                                                    {/* <!-- Job Card With Image --> */}

                                                    <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="job-card-img jc-pad">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="card-title jc-title mb-0">
                                                                            Describe The Job
                                                                        </h5>
                                                                        <img
                                                                            src={JobDescriptionImg}
                                                                            alt=""
                                                                            class="img-fluid"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">
                                                                    <label
                                                                        for="jobDescription"
                                                                        class="form-label f-size-14 fw-bold pt-2"
                                                                    >
                                                                        Job description
                                                                        <span class="asterisk"> *</span>
                                                                    </label>
                                                                    <form id="create-job">
                                                                        <div className="col-md-12 mb-2">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-flex align-items-center"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                onClick={() => {
                                                                                    this.GetJobDescription(this.state.selectedJobTitle);
                                                                                }}
                                                                            >
                                                                                <span className="material-symbols-outlined">
                                                                                    lightbulb
                                                                                </span>
                                                                                <span className="ms-2">Help me create Job Description</span>
                                                                            </button>
                                                                        </div>
                                                                        {this.state.IsJobDescriptionLoading > 0 && (
                                                                            <div class="overlay">
                                                                                <div className="overlay-content">
                                                                                    <Oval
                                                                                        visible={true}
                                                                                        height="80"
                                                                                        width="80"
                                                                                        color="#4fa94d"
                                                                                        ariaLabel="oval-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <CKEditor
                                                                            name="jobDescription"
                                                                            editor={ClassicEditor}
                                                                            data={this.state.description}
                                                                            config={{
                                                                                toolbar: [
                                                                                    'heading',
                                                                                    '|',
                                                                                    'bold',
                                                                                    'italic',
                                                                                    'bulletedlist',
                                                                                    'numberedlist',
                                                                                    '|',
                                                                                    'undo',
                                                                                    'redo',
                                                                                ],
                                                                            }}
                                                                            onReady={(editor) => { }}
                                                                            onChange={this.handleEditorChange}
                                                                            onBlur={(event, editor) => { }}
                                                                            onFocus={(event, editor) => { }}
                                                                        />
                                                                    </form>
                                                                    <i>
                                                                        <label class="text-left font-italic" style={{ color: "#e74c3c" }}>
                                                                            {this.state.disclaimerForAIJobDesciprtion}
                                                                        </label>
                                                                    </i>
                                                                    <p className="text-right">{this.state.wordCount}/{this.state.maxWords}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-md-flex justify-content-between">
                                                                    <button
                                                                        onClick={this.handlePreviousStep}
                                                                        class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            <i class="bi bi-arrow-left mr-1"></i>Back
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        onClick={this.handleNextStep}
                                                                        class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Continue{" "}
                                                                            <i class="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <!---Step-3 End Add Job Description---> */}

                                    {/* <!--- Step-4 Questions set ---> */}
                                    <>
                                        {this.state.step === 4 && (
                                            <div class="row">
                                                {/* <!-- Swift Details --> */}
                                                {/* <IntroSidebar title="" description="" /> */}
                                                {/* <!-- Swift Form --> */}
                                                <div class="col-md-12 col-lg-12  align-items-center">
                                                    <div class="swift-form-job">
                                                        {/* <!-- Progress Bar --> */}
                                                        <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                            <div class="progress" style={{ height: "10px;" }}>
                                                                <div
                                                                    class="progress-bar pbar-color"
                                                                    role="progressbar"
                                                                    style={{ width: "40%" }}
                                                                    aria-valuenow="20"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- Job Card With Image --> */}

                                                        <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="job-card-img jc-pad">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="card-title jc-title mb-0">
                                                                                Question Set
                                                                            </h5>
                                                                            <img
                                                                                src={JobDescriptionImg}
                                                                                alt=""
                                                                                class="img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card border-0 jc-radius mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="card-body jc-t-pad swift-list-group-box">


                                                                        {this.state.questionSets != [] &&
                                                                            <>
                                                                                <div className="row">
                                                                                    <div className="col-6">
                                                                                        <label
                                                                                            for="jobDescription"
                                                                                            class="form-label f-size-14 fw-bold pt-2"
                                                                                        >
                                                                                            Default Question set
                                                                                            <span class="asterisk"> *</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-6">


                                                                                        {this.state.hideDefaultQuestionSet ? <i style={{ float: 'right', cursor: 'pointer' }} className="bi bi-x-square" onClick={() => { this.setState({ hideDefaultQuestionSet: false }) }}></i> : <i style={{ float: 'right', cursor: 'pointer' }} onClick={() => { this.setState({ hideDefaultQuestionSet: true }) }} className="bi bi-arrow-down-square pe-auto"></i>}
                                                                                    </div>
                                                                                    {(this.state.hideDefaultQuestionSet) && <>
                                                                                        <div className="swift-list-group-items ">
                                                                                            <div className="">


                                                                                                <div className="flex-grow-1 ms-3">
                                                                                                    <div className="row align-items-center">

                                                                                                        <ul className="list-group">

                                                                                                            {this.state.questionSets.length > 0 ? this.state.questionSets.map((questionSet, index) => {
                                                                                                                return <li key={index} className="list-group-item">




                                                                                                                    {/*<!-- Stats --> */}



                                                                                                                    <div className="row-container">
                                                                                                                        <div className="fs-6 fw-bold question-text text-break">{questionSet.questionSetName}</div>
                                                                                                                        <div className="switch-container">
                                                                                                                            <div className="form-switch">
                                                                                                                                <input
                                                                                                                                    className="form-check-input"
                                                                                                                                    type="checkbox"
                                                                                                                                    id={questionSet.questionSetId}
                                                                                                                                    value={questionSet}
                                                                                                                                    onChange={(e) => onRowSelectforquestions(questionSet, e, index)}
                                                                                                                                    checked={this.state.questionSets[index].isChecked}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                    {/*<!-- End Stats --> */}








                                                                                                                </li>


                                                                                                            })
                                                                                                                :
                                                                                                                <div className="col">
                                                                                                                    <h4 className="mb-1 text-info">Please select job title</h4>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </ul>

                                                                                                    </div>
                                                                                                    {/* <!-- End Row --> */}
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>

                                                                                    </>}
                                                                                </div>
                                                                            </>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="d-md-flex justify-content-between">
                                                                        <button
                                                                            onClick={this.handlePreviousStep}
                                                                            class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                <i class="bi bi-arrow-left mr-1"></i>Back
                                                                            </span>
                                                                        </button>
                                                                        <button
                                                                            onClick={this.handleNextStep}
                                                                            class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                Continue{" "}
                                                                                <i class="bi bi-arrow-right ml-1"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>

                                    {/* <!---Step-4 end Questions set ---> */}

                                    {/* <!---Step-5 Add Skills---> */}
                                    {this.state.step === 5 && (
                                        <div class="row">
                                            {/* <!-- Swift Details --> */}
                                            {/* <IntroSidebar title="" description="" /> */}
                                            {/* <!-- Swift Form --> */}
                                            <div class="col-md-12 col-lg-12  align-items-center">
                                                <div class="swift-form-job">
                                                    {/* <!-- Progress Bar --> */}
                                                    <div class="swift-bar m-auto mb-swift mt-2 mb-2">
                                                        <div class="progress" style={{ height: "10px;" }}>
                                                            <div
                                                                class="progress-bar pbar-color"
                                                                role="progressbar"
                                                                style={{ width: "50%" }}
                                                                aria-valuenow="20"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- End progress bar here --> */}


                                                    {/* <!-- Job Card With Image --> */}

                                                    <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="job-card-img jc-pad">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="card-title jc-title mb-0">
                                                                            Add Skills
                                                                        </h5>
                                                                        <img
                                                                            src={JobSkillImg}
                                                                            alt=""
                                                                            class="img-fluid marg-reverse"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">
                                                                    {/* Old skill start */}
                                                                    <div className="row">
                                                                        <label
                                                                            for="skills"
                                                                            class="form-label marg-b-0 f-size-14 fw-bold"
                                                                        >
                                                                            Skills <span class="asterisk"> *</span>
                                                                        </label>
                                                                        <div className="col-md-12 mb-2">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary d-flex align-items-center"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                onClick={() => {
                                                                                    this.GetSkillsByJobTitle(this.state.selectedJobTitle);
                                                                                }}
                                                                            >
                                                                                <span className="material-symbols-outlined">
                                                                                    lightbulb
                                                                                </span>
                                                                                <span className="ms-2">Help me create skills</span>
                                                                            </button>
                                                                        </div>
                                                                        {this.state.isSkillSetLoading > 0 && (
                                                                            <div class="overlay">
                                                                                <div className="overlay-content">
                                                                                    <Oval
                                                                                        visible={true}
                                                                                        height="80"
                                                                                        width="80"
                                                                                        color="#4fa94d"
                                                                                        ariaLabel="oval-loading"
                                                                                        wrapperStyle={{}}
                                                                                        wrapperClass=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <small
                                                                            id="addSkillsHelp"
                                                                            class="form-text swift-muted-text mb-2 mt-0"
                                                                        >
                                                                            Show your top skills - add up to 20 skills you want to be known for
                                                                        </small>
                                                                        {/* <label for="skillTag" className="col-md-3 col-form-label form-label"><span>Skills</span></label> */}

                                                                        <div className="col-md-9" style={{ zIndex: "1" }}>
                                                                            <ReactTags
                                                                                name="skillTag"
                                                                                ref={this.reactTags}
                                                                                tags={this.state.tags}
                                                                                suggestions={this.state.suggestions}
                                                                                onDelete={this.onDelete.bind(this)}
                                                                                onAddition={this.onAddition.bind(this)}
                                                                                placeholderText="Add skill"
                                                                                minQueryLength={1}
                                                                            />
                                                                        </div>
                                                                        <button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    addSkillModal: true,
                                                                                });
                                                                                if (this.state.selectedIndustry !== "") {
                                                                                    this.setState({
                                                                                        skillIndustrySelected: this.state.selectedIndustry,
                                                                                    });
                                                                                    this.setState({
                                                                                        selectedIndustryIdForSkill: this.state.selectedIndustryId,
                                                                                    });

                                                                                }
                                                                                document.body.classList.add('modal-open');
                                                                            }}
                                                                            className="col-md-3 btn btn-outline-primary btn-block custom-button"
                                                                            data-tip
                                                                            data-for="otherSkill"
                                                                            id="otherSkill"
                                                                        >
                                                                            <i
                                                                                className="bi bi-plus-circle"
                                                                                style={{ cursor: "pointer" }}
                                                                            ></i>{" "}
                                                                            Add Skill
                                                                            <ReactTooltip
                                                                                id="otherSkill"
                                                                                place="top"
                                                                                type="dark"
                                                                                effect="float"
                                                                            >
                                                                                <span> Add skill if not found in the list </span>
                                                                            </ReactTooltip>
                                                                        </button>
                                                                    </div>
                                                                    <i>
                                                                        <label class="text-left font-italic" style={{ color: "#e74c3c" }}>
                                                                            {this.state.disclaimerForAISkills}
                                                                        </label>
                                                                    </i>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-md-flex justify-content-between">
                                                                    <button
                                                                        onClick={this.handlePreviousStep}
                                                                        class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            <i class="bi bi-arrow-left mr-1"></i>Back
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        onClick={this.handleNextStep}
                                                                        id="createJobBtn"
                                                                        class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Continue{" "}
                                                                            <i class="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <!---Step-5 End Add Skills---> */}

                                    {/* <!---Step-6 Conduct interview---> */}
                                    {this.state.step === 6 && (
                                        <>
                                            <div class="row">
                                                {/* <!-- Swift Details --> */}
                                                {/* <IntroSidebar title="" description="" /> */}
                                                {/* <!-- Swift Form --> */}
                                                <div class="col-md-12 col-lg-12  align-items-center">
                                                    <div class="swift-form-job conductInterviewCard">
                                                        {/* <!-- Progress Bar --> */}
                                                        <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                            <div class="progress" style={{ height: "10px;" }}>
                                                                <div
                                                                    class="progress-bar pbar-color"
                                                                    role="progressbar"
                                                                    style={{ width: "60%" }}
                                                                    aria-valuenow="20"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- Job Card With Image --> */}

                                                        <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="job-card-img jc-pad">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="card-title jc-title mb-0">
                                                                                Conduct Interview & Permissions
                                                                            </h5>
                                                                            <img
                                                                                src={JobDescriptionImg}
                                                                                alt=""
                                                                                class="img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card border-0 jc-radius mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="card-body jc-t-pad">
                                                                        <label
                                                                            for="jobDescription"
                                                                            class="form-label f-size-14 fw-bold pt-2"
                                                                        >
                                                                            Conduct Automatic Interview
                                                                            <span class="asterisk"> *</span>
                                                                        </label>
                                                                        <div className="swift-custom-wrapper">

                                                                            <div id="isAutoInterviewSchedule" className="mb-4">

                                                                                <div className="col-md-12">
                                                                                    <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                                                        <input class="form-check-input-en" type="radio" value="yes" name="isAutoInterviewSchedule" checked={this.state.isAutoInterviewSchedule === "yes"} /> <span className="form-check-label mx-2">Yes</span>
                                                                                        <input class="form-check-input-en" type="radio" value="no" name="isAutoInterviewSchedule" checked={this.state.isAutoInterviewSchedule === "no"} /> <span className="form-check-label mx-2">No</span>
                                                                                    </div>
                                                                                    {/* <!-- End Row --> */}
                                                                                </div>

                                                                            </div>

                                                                            {this.state.isAutoInterviewSchedule === "yes" && <>
                                                                                <div className="row mb-4">

                                                                                    <div className="col-md-6">

                                                                                        <label for="expiryDate" class="form-label f-size-14 fw-bold">Expiry date</label>
                                                                                        <div className="row">
                                                                                            <div className="mb-2 mb-sm-0">
                                                                                                {/* <DatePicker
                                                                                                    selected={this.state.interviewExpiryDate}
                                                                                                    onChange={(date) => {
                                                                                                        this.setState({ interviewExpiryDate: date });
                                                                                                    }}
                                                                                                    minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}
                                                                                                    locale={navigator.language}
                                                                                                    inline
                                                                                                    dateFormat="yyyy-dd-MM"
                                                                                                    placeholderText="Select Date"
                                                                        /> */}


                                                                                                <DatePicker selected={this.state.interviewExpiryDate}
                                                                                                    inline
                                                                                                    onChange={(date) => {
                                                                                                        // Validation: Prevent selecting dates earlier than the current date
                                                                                                        const currentDate = new Date();
                                                                                                        if (date < currentDate) {
                                                                                                            // If selected date is before current date, don't update state
                                                                                                            return;
                                                                                                        }
                                                                                                        this.setState({ interviewExpiryDate: date });
                                                                                                    }} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>


                                                                                    <div className="col-md-6">

                                                                                        <label for="expiryDate" class="form-label f-size-14 fw-bold">Extension date <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} id="extentionDate" data-tip data-for='extentionDate'></i>
                                                                                            <ReactTooltip id="extentionDate" place="top" type="dark" effect="float" >
                                                                                                <span> Candidate can extend his/her interview to extended date </span>
                                                                                            </ReactTooltip>
                                                                                        </label>
                                                                                        <div className="row">
                                                                                            <div className="mb-2 mb-sm-0">
                                                                                                {/* <DatePicker
                                                                                                    selected={this.state.extensiondate}
                                                                                                    value={this.state.extensiondate}
                                                                                                    onChange={(date) => {
                                                                                                        this.setState({ extensiondate: date });
                                                                                                    }}
                                                                                                    minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}
                                                                                                    locale={navigator.language}
                                                                                                    inline
                                                                                                    dateFormat="yyyy-dd-MM"
                                                                                                    placeholderText="Select Date"
                                                                        /> */}

                                                                                                <DatePicker selected={this.state.extensiondate}
                                                                                                    inline
                                                                                                    onChange={(date) => {
                                                                                                        this.setState({ extensiondate: date });
                                                                                                    }} />


                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="row mb-4">
                                                                                    <label className="col-md-3 col-form-label form-label">Time (24 Hour Format)</label>
                                                                                    <div className="col-md-9">
                                                                                        <div className="row">
                                                                                            <div className="col-md-3 mb-2 mb-sm-0">

                                                                                                <Select
                                                                                                    id="hours"
                                                                                                    name="hours"
                                                                                                    defaultValue={this.state.hours[0]}

                                                                                                    value={this.state.hours.find(item => item.value === this.state.hourvalue)}
                                                                                                    options={this.state.hours}
                                                                                                    onChange={this.handleHour}
                                                                                                />
                                                                                                {/* <!-- End Col --> */}

                                                                                            </div>

                                                                                            <div className="col-md-3 mb-2 mb-sm-0">
                                                                                                <Select
                                                                                                    id="minutes"
                                                                                                    name="minutes"
                                                                                                    defaultValue={this.state.minutes[0]}
                                                                                                    value={this.state.minutes.find(item => item.value === this.state.minutevalue)}
                                                                                                    options={this.state.minutes}
                                                                                                    onChange={this.handleMinute}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row mb-4">
                                                                                    <label className="col-md-3 col-form-label form-label">Time zone</label>
                                                                                    <div className="col-md-5">
                                                                                        <div className="row">
                                                                                            <div className="tom-select-custom">
                                                                                                <select className="js-select form-select"
                                                                                                    name="timezone" type="text" value={this.state.timeZones.find(item => item.value === this.state.selectedTimeZone)} placeholder="TimeZone" onChange={this.handleTimeZone}>
                                                                                                    {
                                                                                                        this.state.timeZones.map(timeZone =>
                                                                                                        (
                                                                                                            <option key={timeZone.Id} selected={timeZone.Id == this.state.selectedTimeZone && "selected"} value={timeZone.Id}>{timeZone.DisplayName} </option>
                                                                                                        ))
                                                                                                    }

                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mb-1">
                                                                                    <div className="card card-centered mb-3 mb-lg-5 card-table-box">
                                                                                        <div className="card-header p-relative mb-lg-5 mb-4 w-100">
                                                                                            <div className="mb-4 w-100">
                                                                                                <h4 className="card-title">Interview Level</h4>
                                                                                                <div className="row">
                                                                                                    <div className="swft-form-input col-md-2">
                                                                                                        <div class="form-check mb-2">
                                                                                                            <div class="swift-wrapper" key="FirstInterview">
                                                                                                                <input
                                                                                                                    onChange={this.selectInterviewLevel}
                                                                                                                    class="form-check-input"
                                                                                                                    type="radio"
                                                                                                                    id="FirstInterview"
                                                                                                                    name="InterviewLevel"
                                                                                                                    value="FirstInterview"
                                                                                                                    checked={this.state.isDefaultInterviewLevel === 1}
                                                                                                                />
                                                                                                                <label
                                                                                                                    class="form-check-label"
                                                                                                                    for="partTime"
                                                                                                                >
                                                                                                                    First
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="swft-form-input col-md-2">
                                                                                                        <div class="form-check mb-2">
                                                                                                            <div class="swift-wrapper" key="SecondInterview">
                                                                                                                <input
                                                                                                                    onChange={this.selectInterviewLevel}
                                                                                                                    class="form-check-input"
                                                                                                                    type="radio"
                                                                                                                    id="SecondInterview"
                                                                                                                    name="InterviewLevel"
                                                                                                                    value="SecondInterview"
                                                                                                                    checked={this.state.isDefaultInterviewLevel === 2}
                                                                                                                />
                                                                                                                <label
                                                                                                                    class="form-check-label"
                                                                                                                    for="partTime"
                                                                                                                >
                                                                                                                    Second
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="swft-form-input col-md-2">
                                                                                                        <div class="form-check mb-2">
                                                                                                            <div class="swift-wrapper" key="FinalInterview">
                                                                                                                <input
                                                                                                                    onChange={this.selectInterviewLevel}
                                                                                                                    class="form-check-input"
                                                                                                                    type="radio"
                                                                                                                    id="FinalInterview"
                                                                                                                    name="InterviewLevel"
                                                                                                                    value="FinalInterview"
                                                                                                                    checked={this.state.isDefaultInterviewLevel === 3}
                                                                                                                />
                                                                                                                <label
                                                                                                                    class="form-check-label"
                                                                                                                    for="partTime"
                                                                                                                >
                                                                                                                    Final
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="swft-form-input col-md-2">
                                                                                                        <div class="form-check mb-2">
                                                                                                            <div class="swift-wrapper" key="OtherInterview">
                                                                                                                <input
                                                                                                                    onChange={this.selectInterviewLevel}
                                                                                                                    class="form-check-input"
                                                                                                                    type="radio"
                                                                                                                    id="OtherInterview"
                                                                                                                    name="InterviewLevel"
                                                                                                                    value="OtherInterview"
                                                                                                                    checked={this.state.isDefaultInterviewLevel === 4}
                                                                                                                />
                                                                                                                <label
                                                                                                                    class="form-check-label"
                                                                                                                    for="partTime"
                                                                                                                >
                                                                                                                    Other
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {this.state.interviewLevel == "OtherInterview" &&
                                                                                                    <div className="col mt-3 mb-4 w-75">

                                                                                                        <input type="text" className="form-control" placeholder="Interview Level *"
                                                                                                            id="OtherInterviewLevel"
                                                                                                            maxLength="20"
                                                                                                            value={this.state.otherInterviewLevel}
                                                                                                            onChange={(e) => { this.setState({ otherInterviewLevel: e.target.value }) }} />
                                                                                                    </div>
                                                                                                }

                                                                                                <div className="col mb-4">
                                                                                                    <div className="col-md-9 mb-2" style={{ zIndex: "1" }}>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            value={this.state.interviewTag}
                                                                                                            onChange={this.handleTagchangeChange}
                                                                                                            onKeyDown={this.handleInputKeyDown}
                                                                                                            onBlur={this.handleInputBlur}
                                                                                                            placeholder="Interview Tag *"
                                                                                                            maxLength="30"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        {this.state.interviewtags.map((tag, index) => (
                                                                                                            <div key={index} className="tag float-start p-2 bg-secondary text-white rounded h4" style={{ marginRight: '5px' }}>
                                                                                                                {tag}
                                                                                                                <span onClick={() => this.handleTagRemove(tag)} style={{ cursor: 'pointer' }}><i class="bi bi-x"></i></span>

                                                                                                            </div>
                                                                                                        ))}

                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row mb-4">

                                                                                    <form id="edit-job">
                                                                                        <div class="swft-form-input">
                                                                                            <label
                                                                                                for="country"
                                                                                                class="form-label f-weight-700 f-size-14"
                                                                                            >
                                                                                                Visibility<span class="asterisk"> *</span>
                                                                                            </label>
                                                                                            <select
                                                                                                class="marg-b-10 form-select form-control"
                                                                                                id="visibility"
                                                                                                name="jobVisibilityLevel"
                                                                                                onChange={(e) => {
                                                                                                    this.handleInputChange(e);
                                                                                                    if (e.target.value === "everyone") {
                                                                                                        this.setState({
                                                                                                            reviewers: this.state.allUsersList,
                                                                                                            selectedInterviewReviewers: [],
                                                                                                        });
                                                                                                    }
                                                                                                    let tempReviewer = [];
                                                                                                    if (e.target.value === "manager") {
                                                                                                        this.state.allUsersList.length > 0 &&
                                                                                                            this.state.allUsersList.forEach((user) => {
                                                                                                                if (
                                                                                                                    user.email === this.state.jobManagerEmail
                                                                                                                ) {
                                                                                                                    console.log(
                                                                                                                        "in manager",
                                                                                                                        this.state.jobManagerEmail
                                                                                                                    );
                                                                                                                    this.setState({
                                                                                                                        selectedInterviewReviewers: []
                                                                                                                    });
                                                                                                                    this.setState(prevState => ({
                                                                                                                        selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, user.email]
                                                                                                                    }));
                                                                                                                    tempReviewer.push(user);
                                                                                                                    this.setState({
                                                                                                                        reviewers: tempReviewer,
                                                                                                                    });

                                                                                                                }
                                                                                                            });
                                                                                                    }

                                                                                                }}
                                                                                            >
                                                                                                {Object.keys(this.state.jobVisibilities).map(
                                                                                                    (jobVisibility, i) => (
                                                                                                        <>
                                                                                                            <option
                                                                                                                key={i}
                                                                                                                value={
                                                                                                                    this.state.jobVisibilities[jobVisibility]
                                                                                                                }
                                                                                                                selected={
                                                                                                                    this.state.jobVisibilities[
                                                                                                                    jobVisibility
                                                                                                                    ] == this.state.jobVisibilityLevel
                                                                                                                }
                                                                                                            >
                                                                                                                {jobVisibility}{" "}
                                                                                                            </option>
                                                                                                        </>
                                                                                                    )
                                                                                                )}
                                                                                                {/* <option value="Select" selected disabled hidden>Select</option>
                                             <option value="Select">On</option>
                                             <option value="Select">Off</option> */}
                                                                                            </select>
                                                                                        </div>
                                                                                    </form>
                                                                                    {this.state.jobVisibilityLevel === "manager" &&
                                                                                        <form id="edit-job">
                                                                                            <div class="swft-form-input">
                                                                                                <label
                                                                                                    for="country"
                                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                                >
                                                                                                    Job Manager<span class="asterisk"> *</span>
                                                                                                </label>
                                                                                                <select
                                                                                                    class="marg-b-10 form-select form-control"
                                                                                                    id="job-manager"
                                                                                                    name="jobManager"

                                                                                                    /*name="jobVisibility"*/
                                                                                                    onChange={(e) => {
                                                                                                        this.handleInputChange(e);
                                                                                                        let tempReviewer = [];

                                                                                                        if (e.target.value != -1) {
                                                                                                            //this.setState({
                                                                                                            //    jobManagerEmail: e.target.value
                                                                                                            //})
                                                                                                            if (
                                                                                                                this.state.jobVisibilityLevel === "manager"
                                                                                                            ) {
                                                                                                                this.state.allUsersList.length > 0 &&
                                                                                                                    this.state.allUsersList.forEach((user) => {
                                                                                                                        if (user.email == e.target.value) {
                                                                                                                            tempReviewer.push(user);
                                                                                                                            this.setState({
                                                                                                                                reviewers: tempReviewer,
                                                                                                                            });
                                                                                                                        }
                                                                                                                    });
                                                                                                            }
                                                                                                        }
                                                                                                        //console.log(" new email of jobManagerEmail", this.state.jobManagerEmail)

                                                                                                    }}
                                                                                                >

                                                                                                    {this.state.jobManagerEmail == null ? (
                                                                                                        <option value="-1">Select Job Manager </option>
                                                                                                    ) : (
                                                                                                        <option value={this.state.jobManagerEmail}>
                                                                                                            {this.state.jobManagerFirstName}{" "}
                                                                                                            {this.state.jobManagerLastName}{" "}
                                                                                                        </option>
                                                                                                    )}

                                                                                                    {this.state.tenantUsersList.map((user, index) => (
                                                                                                        <option key={user.userId} value={user.email} selected={user.email === this.state.jobManager}>
                                                                                                            {user.firstName} {user.lastName}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                    {/* <option value="Select" selected disabled hidden>Enter Name</option> */}
                                                                                                </select>

                                                                                            </div>
                                                                                        </form>
                                                                                    }
                                                                                    {this.state.jobVisibilityLevel === "group" &&
                                                                                        <form id="edit-job">
                                                                                            <div class="swft-form-input">
                                                                                                <label
                                                                                                    for="country"
                                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                                >
                                                                                                    Groups<span class="asterisk"> *</span>
                                                                                                </label>
                                                                                                <select className="form-select"
                                                                                                    name="usergroupid" type="text" placeholder="Group" onChange={(e) => {
                                                                                                        this.handleInputChange(e);
                                                                                                        if (e.target.value !== -1) {
                                                                                                            let tempReviewer = [];
                                                                                                            this.setState({
                                                                                                                selectedInterviewReviewers: []
                                                                                                            });

                                                                                                            this.state.userGroupsMembers.length > 0 && this.state.userGroupsMembers.forEach((group) => {
                                                                                                                if (group.userGroupId === parseInt(e.target.value)) {
                                                                                                                    tempReviewer.push({ userId: group.applicationUserId, email: group.username });
                                                                                                                    this.setState({ selectedGroupId: group.userGroupId });
                                                                                                                    this.setState(prevState => ({
                                                                                                                        selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, group.username]
                                                                                                                    }));
                                                                                                                }
                                                                                                            })

                                                                                                            this.setState({ reviewers: tempReviewer });
                                                                                                        }

                                                                                                    }}>

                                                                                                    <option key="-1" value="-1">
                                                                                                        Select
                                                                                                    </option>
                                                                                                    {

                                                                                                        this.state.tenantUserGroups.map(tenantUserGroup =>
                                                                                                        (
                                                                                                            <option key={tenantUserGroup.userGroupId} value={tenantUserGroup.userGroupId}>{tenantUserGroup.name} </option>
                                                                                                        ))
                                                                                                    }

                                                                                                </select>
                                                                                            </div>
                                                                                        </form>
                                                                                    }
                                                                                </div>

                                                                                <div className="row mb-4">
                                                                                    <div className="table-responsive datatable-custom w-100">
                                                                                        <label
                                                                                            for="jobDescription"
                                                                                            class="form-label f-size-14 fw-bold pt-2"
                                                                                            data-tip
                                                                                            data-for="reviewer-id"
                                                                                            id="reviewer-id"
                                                                                        >
                                                                                            Reviewer
                                                                                            <span class="asterisk"> *</span>
                                                                                            <ReactTooltip
                                                                                                id="reviewer-id"
                                                                                                place="top"
                                                                                                type="dark"
                                                                                                effect="float"
                                                                                                className="mytooltip"
                                                                                            >Selected reviewer will be assessing the interview</ReactTooltip>
                                                                                        </label>
                                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                            <thead className="thead-light">
                                                                                                <tr>

                                                                                                    <th
                                                                                                    >
                                                                                                        Select
                                                                                                    </th>
                                                                                                    <th
                                                                                                    >
                                                                                                        Reviewer Email
                                                                                                    </th>




                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    this.state.reviewers.length > 0 && this.state.reviewers.map((interviewreviewer, index) => {
                                                                                                        return (

                                                                                                            <tr key={index}>

                                                                                                                <td >
                                                                                                                    <input type="checkbox"
                                                                                                                        className="form-checkbox cursor-pointer"
                                                                                                                        name="questions"
                                                                                                                        id={index}
                                                                                                                        checked={this.state.selectedInterviewReviewers.some(selectedReviewer => selectedReviewer === interviewreviewer.email)}

                                                                                                                        onChange={e => {
                                                                                                                            onRowSelectForInterviewReviewers(interviewreviewer, e);

                                                                                                                        }
                                                                                                                        }

                                                                                                                    />

                                                                                                                </td>

                                                                                                                <td >
                                                                                                                    {interviewreviewer.email}

                                                                                                                </td>



                                                                                                            </tr>
                                                                                                        )

                                                                                                    })
                                                                                                }

                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            }
                                                                            {this.state.isAutoInterviewSchedule === "no" &&
                                                                                <div className="col-md-12 mt-3">
                                                                                    <div className="row g-0">

                                                                                        <form id="edit-job">
                                                                                            <div class="swft-form-input">
                                                                                                <label
                                                                                                    for="country"
                                                                                                    class="form-label f-weight-700 f-size-14"
                                                                                                >
                                                                                                    Visibility<span class="asterisk"> *</span>
                                                                                                </label>
                                                                                                <select
                                                                                                    class="marg-b-10 form-select form-control"
                                                                                                    id="visibility"
                                                                                                    name="jobVisibilityLevel"
                                                                                                    onChange={(e) => {
                                                                                                        this.handleInputChange(e);
                                                                                                        if (e.target.value === "everyone") {
                                                                                                            this.setState({
                                                                                                                reviewers: this.state.allUsersList,
                                                                                                                selectedInterviewReviewers: [],
                                                                                                            });
                                                                                                        }
                                                                                                        let tempReviewer = [];
                                                                                                        if (e.target.value === "manager") {
                                                                                                            this.state.allUsersList.length > 0 &&
                                                                                                                this.state.allUsersList.forEach((user) => {
                                                                                                                    if (
                                                                                                                        user.email === this.state.jobManagerEmail
                                                                                                                    ) {
                                                                                                                        console.log(
                                                                                                                            "in manager",
                                                                                                                            this.state.jobManagerEmail
                                                                                                                        );
                                                                                                                        this.setState({
                                                                                                                            selectedInterviewReviewers: []
                                                                                                                        });
                                                                                                                        this.setState(prevState => ({
                                                                                                                            selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, user.email]
                                                                                                                        }));
                                                                                                                        tempReviewer.push(user);
                                                                                                                        this.setState({
                                                                                                                            reviewers: tempReviewer,
                                                                                                                        });

                                                                                                                    }
                                                                                                                });
                                                                                                        }

                                                                                                    }}
                                                                                                >
                                                                                                    {Object.keys(this.state.jobVisibilities).map(
                                                                                                        (jobVisibility, i) => (
                                                                                                            <>
                                                                                                                <option
                                                                                                                    key={i}
                                                                                                                    value={
                                                                                                                        this.state.jobVisibilities[jobVisibility]
                                                                                                                    }
                                                                                                                    selected={
                                                                                                                        this.state.jobVisibilities[
                                                                                                                        jobVisibility
                                                                                                                        ] == this.state.jobVisibilityLevel
                                                                                                                    }
                                                                                                                >
                                                                                                                    {jobVisibility}{" "}
                                                                                                                </option>
                                                                                                            </>
                                                                                                        )
                                                                                                    )}
                                                                                                    {/* <option value="Select" selected disabled hidden>Select</option>
                                             <option value="Select">On</option>
                                             <option value="Select">Off</option> */}
                                                                                                </select>
                                                                                            </div>
                                                                                        </form>
                                                                                        {this.state.jobVisibilityLevel === "manager" &&
                                                                                            <form id="edit-job">
                                                                                                <div class="swft-form-input">
                                                                                                    <label
                                                                                                        for="country"
                                                                                                        class="form-label f-weight-700 f-size-14"
                                                                                                    >
                                                                                                        Job Manager<span class="asterisk"> *</span>
                                                                                                    </label>
                                                                                                    <select
                                                                                                        class="marg-b-10 form-select form-control"
                                                                                                        id="job-manager"
                                                                                                        name="jobManager"

                                                                                                        /*name="jobVisibility"*/
                                                                                                        onChange={(e) => {
                                                                                                            this.handleInputChange(e);
                                                                                                            let tempReviewer = [];

                                                                                                            if (e.target.value != -1) {
                                                                                                                //this.setState({
                                                                                                                //    jobManagerEmail: e.target.value
                                                                                                                //})
                                                                                                                if (
                                                                                                                    this.state.jobVisibilityLevel === "manager"
                                                                                                                ) {
                                                                                                                    this.state.allUsersList.length > 0 &&
                                                                                                                        this.state.allUsersList.forEach((user) => {
                                                                                                                            if (user.email == e.target.value) {
                                                                                                                                tempReviewer.push(user);
                                                                                                                                this.setState({
                                                                                                                                    reviewers: tempReviewer,
                                                                                                                                });
                                                                                                                            }
                                                                                                                        });
                                                                                                                }
                                                                                                            }
                                                                                                            //console.log(" new email of jobManagerEmail", this.state.jobManagerEmail)

                                                                                                        }}
                                                                                                    >

                                                                                                        {this.state.jobManagerEmail == null ? (
                                                                                                            <option value="-1">Select Job Manager </option>
                                                                                                        ) : (
                                                                                                            <option value={this.state.jobManagerEmail}>
                                                                                                                {this.state.jobManagerFirstName}{" "}
                                                                                                                {this.state.jobManagerLastName}{" "}
                                                                                                            </option>
                                                                                                        )}

                                                                                                        {this.state.tenantUsersList.map((user, index) => (
                                                                                                            <option key={user.userId} value={user.email} selected={user.email === this.state.jobManager}>
                                                                                                                {user.firstName} {user.lastName}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                        {/* <option value="Select" selected disabled hidden>Enter Name</option> */}
                                                                                                    </select>

                                                                                                </div>
                                                                                            </form>
                                                                                        }
                                                                                        {this.state.jobVisibilityLevel === "group" &&
                                                                                            <form id="edit-job">
                                                                                                <div class="swft-form-input">
                                                                                                    <label
                                                                                                        for="country"
                                                                                                        class="form-label f-weight-700 f-size-14"
                                                                                                    >
                                                                                                        Groups<span class="asterisk"> *</span>
                                                                                                    </label>
                                                                                                    <select className="form-select"
                                                                                                        name="usergroupid" type="text" placeholder="Group" onChange={(e) => {
                                                                                                            this.handleInputChange(e);
                                                                                                            if (e.target.value !== -1) {
                                                                                                                let tempReviewer = [];
                                                                                                                this.setState({
                                                                                                                    selectedInterviewReviewers: []
                                                                                                                });

                                                                                                                this.state.userGroupsMembers.length > 0 && this.state.userGroupsMembers.forEach((group) => {
                                                                                                                    if (group.userGroupId === parseInt(e.target.value)) {
                                                                                                                        tempReviewer.push({ userId: group.applicationUserId, email: group.username });
                                                                                                                        this.setState({ selectedGroupId: group.userGroupId });
                                                                                                                        this.setState(prevState => ({
                                                                                                                            selectedInterviewReviewers: [...prevState.selectedInterviewReviewers, group.username]
                                                                                                                        }));
                                                                                                                    }
                                                                                                                })

                                                                                                                this.setState({ reviewers: tempReviewer });
                                                                                                            }

                                                                                                        }}>

                                                                                                        <option key="-1" value="-1">
                                                                                                            Select
                                                                                                        </option>
                                                                                                        {

                                                                                                            this.state.tenantUserGroups.map(tenantUserGroup =>
                                                                                                            (
                                                                                                                <option key={tenantUserGroup.userGroupId} value={tenantUserGroup.userGroupId}>{tenantUserGroup.name} </option>
                                                                                                            ))
                                                                                                        }

                                                                                                    </select>
                                                                                                </div>
                                                                                            </form>
                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="d-md-flex justify-content-between">
                                                                        <button
                                                                            onClick={this.handlePreviousStep}
                                                                            class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                <i class="bi bi-arrow-left mr-1"></i>Back
                                                                            </span>
                                                                        </button>
                                                                        <button
                                                                            onClick={this.handleNextStep}
                                                                            class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                Continue{" "}
                                                                                <i class="bi bi-arrow-right ml-1"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {/* <!---Step-6 end Conduct interview---> */}


                                    {/* <!---Step-7 Start Salary ---> */}
                                    {this.state.step === 7 && (
                                        <>

                                            <div class="row">
                                                {/* <!-- Swift Details --> */}
                                                {/* <IntroSidebar title="" description="" /> */}
                                                {/* <!-- Swift Form --> */}
                                                <div class="col-md-12 col-lg-12  align-items-center">
                                                    <div class="swift-form-job conductInterviewCard">
                                                        {/* <!-- Progress Bar --> */}
                                                        <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                            <div class="progress" style={{ height: "10px;" }}>
                                                                <div
                                                                    class="progress-bar pbar-color"
                                                                    role="progressbar"
                                                                    style={{ width: "70%" }}
                                                                    aria-valuenow="20"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- Job Card With Image --> */}

                                                        <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="job-card-img jc-pad">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="card-title jc-title mb-0">
                                                                                Salary
                                                                            </h5>
                                                                            <img
                                                                                src={JobDescriptionImg}
                                                                                alt=""
                                                                                class="img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card border-0 jc-radius mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="card-body jc-t-pad">
                                                                        <div className="">
                                                                            {/* <!-- Form --> */}
                                                                            <form>

                                                                                <div id="accountType" className="mb-4">
                                                                                    <label className="form-label f-size-14 fw-bold">Salary Negotiable <span class="asterisk"> *</span> </label>

                                                                                    <div className="col-lg form-check" onChange={this.handleInputChange}>

                                                                                        <input className="form-check-input-en" type="radio" value="yes" name="salarynegotiable" checked={this.state.salarynegotiable === "yes"} /> <span className="form-check-label mx-2">Yes</span>
                                                                                        <input className="form-check-input-en" type="radio" value="no" name="salarynegotiable" checked={this.state.salarynegotiable === "no"} /> <span className="form-check-label mx-2">No</span>
                                                                                    </div>
                                                                                    {/* <!-- End Row --> */}

                                                                                </div>



                                                                            </form>

                                                                            {this.state.isTC == true &&

                                                                                <div>
                                                                                    <div className="mb-4">
                                                                                        <label for="jobtitle" className="form-label f-size-14 fw-bold">Salary Type  <span className="asterisk">*</span></label>

                                                                                        <div className="col-md-12">
                                                                                            <select className="form-select"
                                                                                                name="salaryType"
                                                                                                type="text"
                                                                                                placeholder="salary Type"
                                                                                                onChange={this.handleInputChange}
                                                                                                value={this.state.salaryType}

                                                                                            >

                                                                                                <option value="-1">Select Salary Type </option>
                                                                                                {
                                                                                                    Object.keys(this.state.salaryTypes).map((key) => (
                                                                                                        <option key={key} value={this.state.salaryTypes[key]}>{key} </option>

                                                                                                    ))
                                                                                                }

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                    {this.state.salaryType == "net" && <div className=" mb-4">
                                                                                        <label for="salary" className="form-label f-size-14 fw-bold"><span>Salary Amount</span> <span className="asterisk">*</span></label>
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <input type="number" className="form-control" onChange={this.handleInputChange} name="minSalary_STD" max="8" value={this.state.minSalary_STD} id="salary" placeholder="Min" aria-label="Your department" />
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <input type="number" className="form-control" onChange={this.handleInputChange} name="maxSalary_STD" max="8" value={this.state.maxSalary_STD} id="salary" placeholder="Max" aria-label="Your department" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>}
                                                                                    {this.state.salaryType == "gross" && <div className="mb-4">
                                                                                        <label for="salary" className="form-label f-size-14 fw-bold"><span>Salary Amount </span> <span className="form-label-secondary text-danger">*</span></label>

                                                                                        <input type="number" className="form-control" value={this.state.salary} onChange={this.handleInputChange} name="salary" id="salary" placeholder="Salary" aria-label="Your department" />

                                                                                    </div>}
                                                                                </div>
                                                                            }

                                                                            {this.state.isTC == false &&

                                                                                <div className="mb-4">
                                                                                    <label for="salary" className="form-label f-size-14 fw-bold"><span>Salary Amount</span>  <span className="asterisk">*</span></label>
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <input type="number" className="form-control" onWheel={this.preventScroll} value={this.state.minSalary_STD} onChange={this.handleInputChange} name="minSalary_STD" id="salary" placeholder="Min" aria-label="Your department" />
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <input type="number" className="form-control" onWheel={this.preventScroll} onChange={this.handleInputChange} value={this.state.maxSalary_STD} name="maxSalary_STD" id="salary" placeholder="Max" aria-label="Your department" />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            }


                                                                            <div className="mb-4">
                                                                                <label for="currencyType" className="form-label f-size-14 fw-bold">Currency</label>

                                                                                <div className="col-md-12">
                                                                                    <select className="form-select"
                                                                                        name="currencyType" type="text" value={this.state.currencyType} placeholder="Curency" onChange={this.handleInputChange}>

                                                                                        <option value="USD">USD</option>
                                                                                        <option value="PKR" selected>PKR</option>
                                                                                        <option value="CAD">CAD</option>

                                                                                    </select>
                                                                                </div>
                                                                            </div>


                                                                            <div className="mb-4">
                                                                                <label for="salaryFrequency" className="form-label f-size-14 fw-bold">Frequency</label>

                                                                                <div className="col-md-12">
                                                                                    <select className="form-select"
                                                                                        name="salaryFrequency" type="text" placeholder="Salary Frequency" value={this.state.salaryFrequency} onChange={this.handleInputChange}>

                                                                                        {
                                                                                            Object.keys(this.state.salaryFrequencies).map((salaryFrequency, i) => (
                                                                                                <option key={i} value={this.state.salaryFrequencies[salaryFrequency]}>{salaryFrequency} </option>

                                                                                            ))
                                                                                        }

                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            {this.state.bonuses.length > 0 ?
                                                                                <div className="mb-4">
                                                                                    <label for="Bonuses" className="form-label f-size-14 fw-bold" id="locationLabel-ts-label">Bonuses </label>

                                                                                    <div className="table-responsive datatable-custom w-100">
                                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table">
                                                                                            <thead className="thead-light">
                                                                                                <tr>
                                                                                                    <th
                                                                                                    >
                                                                                                        Sr no
                                                                                                    </th>
                                                                                                    <th
                                                                                                    >
                                                                                                        Select
                                                                                                    </th>
                                                                                                    <th
                                                                                                    >
                                                                                                        Bonus
                                                                                                    </th>
                                                                                                    <th
                                                                                                    >
                                                                                                        Frequency
                                                                                                    </th>




                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.state.bonuses.length > 0 && this.state.bonuses.map((bonus, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>
                                                                                                            {index + 1}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <input type="checkbox"
                                                                                                                className="form-checkbox cursor-pointer"
                                                                                                                name="bonus"
                                                                                                                id={index}
                                                                                                                checked={this.state.selectedBonuses.some(selectedBonus => selectedBonus.tenantBonusId === bonus.tenantBonusId)}
                                                                                                                onChange={e => {
                                                                                                                    onRowSelectforBonus(bonus, e);

                                                                                                                }
                                                                                                                }

                                                                                                            />
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {bonus.title}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {bonus.frequency}
                                                                                                        </td>

                                                                                                    </tr>

                                                                                                ))}


                                                                                            </tbody>
                                                                                        </table>

                                                                                    </div>

                                                                                </div>
                                                                                : null}

                                                                            {/* <!-- End Form --> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="d-md-flex justify-content-between">
                                                                        <button
                                                                            onClick={this.handlePreviousStep}
                                                                            class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                <i class="bi bi-arrow-left mr-1"></i>Back
                                                                            </span>
                                                                        </button>
                                                                        <button
                                                                            onClick={this.handleNextStep}
                                                                            class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                Continue{" "}
                                                                                <i class="bi bi-arrow-right ml-1"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                    {/* <!---Step-7 End Salary ---> */}


                                    {/* <!---Step-8 Additional Details---> */}
                                    {this.state.step === 8 && (
                                        <div class="row align-items-center">
                                            {/* <!-- Swift Details --> */}
                                            {/* <IntroSidebar title="" description="" /> */}
                                            {/* <!-- Swift Form --> */}
                                            <div class="col-md-12 col-lg-12">
                                                <div class="swift-form-job">
                                                    {/* <!-- Progress Bar --> */}
                                                    <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                        <div class="progress" style={{ height: "10px;" }}>
                                                            <div
                                                                class="progress-bar pbar-color"
                                                                role="progressbar"
                                                                style={{ width: "80%" }}
                                                                aria-valuenow="20"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- End progress bar here --> */}
                                                    <div class="card jc-radius border-0 mb-3 d-none">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body notification-bar">
                                                                    <h5 class="job-card-title">
                                                                        Job post will be in{" "}
                                                                        <span class="black fw-bold">English</span>{" "}
                                                                        in{" "}
                                                                        <span class="black fw-bold">
                                                                            {" "}
                                                                            United States{" "}
                                                                            <i
                                                                                class="gray fa fa-pencil"
                                                                                id="toggle-password"
                                                                            ></i>
                                                                        </span>
                                                                    </h5>
                                                                    <p class="card-text"></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Job Card With Image --> */}

                                                    <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="job-card-img jc-pad">
                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="card-title jc-title mb-0">
                                                                            Additional Details
                                                                        </h5>
                                                                        <img
                                                                            src={SwiftAdditionalDetailsImg}
                                                                            alt=""
                                                                            class="img-fluid"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card border-0 jc-radius mb-3">
                                                        <div class="row g-0">
                                                            <div class="col-md-12">
                                                                <div class="card-body jc-t-pad">
                                                                    <form id="create-job">
                                                                        <label
                                                                            for="workNature"
                                                                            class="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Work Space Nature
                                                                            <span class="asterisk"> *</span>
                                                                        </label>
                                                                        {/* <select class="marg-b-10 form-select form-control swift-shadow h-swift-44" id="workNature" name="workNature">
                                                                            <option value="OnSite" selected disabled hidden>OnSite</option>
                                                                            <option value="OnSite">OnSite</option>
                                                                            <option value="Remote">Remote</option>
                                                                            <option value="Hybrid">Hybrid</option>
                                                                            </select> */}

                                                                        <select
                                                                            name="selectedWorkspaceNatureType"
                                                                            className="marg-b-10 form-select form-control swift-shadow h-swift-44"
                                                                            type="text"
                                                                            placeholder="Job Natures"
                                                                            value={this.state.selectedWorkspaceNatureType}
                                                                            onChange={this.handleInputChange}
                                                                        >
                                                                            {Object.keys(
                                                                                this.state.workspaceNatureTypes
                                                                            ).map((key) => (
                                                                                <option
                                                                                    key={key}
                                                                                    value={
                                                                                        this.state.workspaceNatureTypes[key]
                                                                                    }
                                                                                >
                                                                                    {key}{" "}
                                                                                </option>
                                                                            ))}
                                                                        </select>

                                                                        <label
                                                                            for="workExperience"
                                                                            class="form-label f-size-14 fw-bold"
                                                                        >
                                                                            Work Experience (Years)
                                                                            <span class="asterisk"> *</span>
                                                                        </label>
                                                                        {/* <input type="text" class="marg-b-10 form-control swift-shadow  inter-ff h-swift-44"  name="workExperience" placeholder="05" value="" /> */}
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            onChange={this.handleInputChange}
                                                                            min="0"
                                                                            name="workExperience"
                                                                            id="workExperience"
                                                                            placeholder="Work Experience"
                                                                            aria-label="Your department"
                                                                            value={this.state.workExperience}
                                                                        />
                                                                        <div className="row g-0 mt-2">
                                                                            <div className="col-md-12">
                                                                                <div className="d-inline-block job-req-label mt-2 mb-2 p-2">
                                                                                    <h3>Application Requirements</h3>
                                                                                    <span className="">
                                                                                        What personal info would you like to gather about each applicant?
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col swift-radio-group">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="workExperience"
                                                                                    class="form-label f-size-14 fw-bold"
                                                                                >
                                                                                    Phone Number
                                                                                    <span class="asterisk"> *</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="form-group"
                                                                                onChange={this.handleInputChange}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    value="required"
                                                                                    name="phoneqconstraint"
                                                                                    checked={this.state.phoneqconstraint === "required"}
                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2">
                                                                                    Required
                                                                                </span>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="optional"
                                                                                    name="phoneqconstraint"
                                                                                    checked={this.state.phoneqconstraint === "optional"}

                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2 ml-15">
                                                                                    Optional
                                                                                </span>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="off"
                                                                                    name="phoneqconstraint"
                                                                                    checked={this.state.phoneqconstraint === "off"}
                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2 ml-15" >
                                                                                    Off
                                                                                </span>
                                                                                {/* <div class="form-check no-border form-check-inline">
                                                                                    <input class="form-check-input" type="radio" name="phoneOption" id="required" checked="checked" />
                                                                                    <label class="form-check-label" for="required">Required</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                    <input class="form-check-input" type="radio" name="phoneOption" id="optional" />
                                                                                    <label class="form-check-label" for="optional">Optional</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                    <input class="form-check-input" type="radio" name="phoneOption" id="off" /> 
                                                                                    <label class="form-check-label" for="off">Off</label>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col swift-radio-group">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="workExperience"
                                                                                    class="form-label f-size-14 fw-bold"
                                                                                >
                                                                                    CV/Resume
                                                                                    <span class="asterisk">  * (To get lead information resume always required)</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="form-group"
                                                                                onChange={this.handleInputChange}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    value="required"
                                                                                    name="cvqconstraint"
                                                                                    checked={this.state.cvqconstraint === "required"}
                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2">
                                                                                    Required
                                                                                </span>
                                                                                {/*<input*/}
                                                                                {/*    type="radio"*/}
                                                                                {/*    value="optional"*/}
                                                                                {/*    name="cvqconstraint"*/}
                                                                                {/*    readOnly*/}
                                                                                {/*    checked={this.state.cvqconstraint === "optional"}*/}

                                                                                {/*/>{" "}*/}
                                                                                {/*<span className="form-check-label mx-1 mx-lg-2 ml-15">*/}
                                                                                {/*    Optional*/}
                                                                                {/*</span>*/}
                                                                                {/*<input*/}
                                                                                {/*    type="radio"*/}
                                                                                {/*    value="off"*/}
                                                                                {/*    name="cvqconstraint"*/}
                                                                                {/*    readOnly*/}
                                                                                {/*    checked={this.state.cvqconstraint === "off"}*/}

                                                                                {/*/>{" "}*/}
                                                                                {/*<span className="form-check-label mx-1  mx-lg-2 ml-15">*/}
                                                                                {/*    Off*/}
                                                                                {/*</span>*/}
                                                                                {/* <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="cvOption" id="cvRequired" checked="checked" />
                                                                                <label class="form-check-label" for="cvRequired">Required</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="cvOption" id="cvOptional" />
                                                                                <label class="form-check-label" for="cvOptional">Optional</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="cvOption" id="cvOff" />
                                                                                <label class="form-check-label" for="cvOff">Off</label>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col swift-radio-group">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="workExperience"
                                                                                    class="form-label f-size-14 fw-bold"
                                                                                >
                                                                                    {this.state.identityType}<span class="asterisk"> *</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="form-group"
                                                                                onChange={this.handleInputChange}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    value="required"
                                                                                    name="cnicconstraint"
                                                                                    checked={this.state.cnicconstraint === "required"}
                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2">
                                                                                    Required
                                                                                </span>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="optional"
                                                                                    name="cnicconstraint"
                                                                                    checked={this.state.cnicconstraint === "optional"}

                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2 ml-15">
                                                                                    Optional
                                                                                </span>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="off"
                                                                                    name="cnicconstraint"
                                                                                    checked={this.state.cnicconstraint === "off"}

                                                                                />{" "}
                                                                                <span className="form-check-label mx-1 mx-lg-2 ml-15">
                                                                                    Off
                                                                                </span>
                                                                                {/* <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="ssnOption" id="ssnRequired" checked="checked" />
                                                                                <label class="form-check-label" for="ssnRequired">Required</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="ssnOption" id="ssnOptional" />
                                                                                <label class="form-check-label" for="ssnOptional">Optional</label>
                                                                                </div>
                                                                                <div class="form-check no-border form-check-inline">
                                                                                <input class="form-check-input" type="radio" name="ssnOption" id="ssnOff" />
                                                                                <label class="form-check-label" for="ssnOff">Off</label>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col swift-radio-group">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="workExperience"
                                                                                    class="form-label f-size-14 fw-bold"
                                                                                >
                                                                                    For Female Only<span class="asterisk"> *</span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="form-group"
                                                                                onChange={this.handleInputChange}
                                                                            >
                                                                                <input class="form-check-input-en" type="radio" value="yes" name="forFemaleOnly" checked={this.state.forFemaleOnly === "yes"} /> <span className="form-check-label mx-2">Yes</span>
                                                                                <input class="form-check-input-en" type="radio" value="no" name="forFemaleOnly" checked={this.state.forFemaleOnly === "no"} /> <span className="form-check-label mx-2">No</span>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card border-0 jc-radius jc-t-pad h-swift-92 mb-3">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-md-flex justify-content-between">
                                                                    <button
                                                                        onClick={this.handlePreviousStep}
                                                                        class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            <i class="bi bi-arrow-left mr-1"></i>Back
                                                                        </span>
                                                                    </button>
                                                                    <button
                                                                        onClick={this.handleNextStep}
                                                                        class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                        type="button"
                                                                    >
                                                                        <span>
                                                                            Continue{" "}
                                                                            <i class="bi bi-arrow-right ml-1"></i>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* <!---End Step-8 Additional Details---> */}

                                    {/* <!---Step-9 Job Messaging---> */}
                                    {this.state.step === 9 && (
                                        <>
                                            <div class="row align-items-center">
                                                {/* <!-- Swift Details --> */}
                                                {/* <IntroSidebar title="" description="" /> */}
                                                {/* <!-- Swift Form --> */}
                                                <div class="col-md-12 col-lg-12">
                                                    <div class="swift-form-job">
                                                        {/* <!-- Progress Bar --> */}
                                                        <div class="swift-bar m-auto mb-swift mb-2 mt-2">
                                                            <div class="progress" style={{ height: "10px;" }}>
                                                                <div
                                                                    class="progress-bar pbar-color"
                                                                    role="progressbar"
                                                                    style={{ width: "100%" }}
                                                                    aria-valuenow="20"
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>
                                                            </div>
                                                        </div>

                                                        {/* <!-- Job Card With Image --> */}

                                                        <div class="card h-swift-130 jc-radius border-0 mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="job-card-img jc-pad">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <h5 class="card-title jc-title mb-0">
                                                                                Job Permissions
                                                                            </h5>
                                                                            <img
                                                                                src={JobDescriptionImg}
                                                                                alt=""
                                                                                class="img-fluid"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="card border-0 jc-radius mb-3">
                                                            <div class="row g-0">
                                                                <div class="col-md-12">
                                                                    <div class="card-body jc-t-pad">

                                                                        <div className="card-body">
                                                                            {/* <!-- Form --> */}
                                                                            <form>

                                                                                <div id="accountType" className="row">
                                                                                    <label className="col-md-12 fw-bold  col-form-label form-label">Intro video url </label>

                                                                                    <div className="col-md-12">
                                                                                        <div className="row g-0">
                                                                                            <div className="col-md-7 mb-2 mb-sm-0">
                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <input type="text" className="form-control" value={this.state.introVideoUrl} name="department" id="questionTitle" aria-label="Your department" placeholder="https://" readOnly />
                                                                                                {/* <!-- End Form Radio --> */}
                                                                                            </div>

                                                                                            <div className="col-md-5 mb-2 mb-sm-0">

                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <button type="button" className="btn btn-primary mx-md-3" onClick={() => {
                                                                                                    document.body.classList.add('modal-open');
                                                                                                    this.setState({ AddIntroVideo: true })
                                                                                                }}>Add video</button>
                                                                                                {/* <!-- End Form Radio --> */}

                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <button type="button" className="btn btn-outline-primary mx-2 mx-md-0" onClick={() => { this.setState({ introVideoUrl: "" }) }}>Clear</button>
                                                                                            </div>


                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div id="accountType" className="row">
                                                                                    <label className="col-md-12 col-form-label form-label fw-bold">Outro video url </label>

                                                                                    <div className="col-md-12">
                                                                                        <div className="row g-0">
                                                                                            <div className="col-md-7 mb-2 mb-sm-0">
                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <input type="text" className="form-control" value={this.state.outroVideoUrl} name="department" id="questionTitle" aria-label="Your department" placeholder="https://" readOnly />
                                                                                                {/* <!-- End Form Radio --> */}
                                                                                            </div>
                                                                                            <div className="col-md-5 mb-2 mb-sm-0">
                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <button type="button" className="btn btn-primary mx-md-3" onClick={() => {
                                                                                                    document.body.classList.add('modal-open');

                                                                                                    this.setState({ AddOutroVideo: true })
                                                                                                }}>Add video</button>


                                                                                                {/* <!-- Form Radio --> */}
                                                                                                <button type="button" className="btn btn-outline-primary mx-2 mx-md-0" onClick={() => { this.setState({ outroVideoUrl: "" }) }}>Clear</button>
                                                                                            </div>


                                                                                        </div>
                                                                                        {/* <!-- End Row --> */}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-9 mt-3">
                                                                                    <div className="row g-0">

                                                                                        <form id="edit-job">

                                                                                            <div class="col swift-radio-group">
                                                                                                <div class="form-group">
                                                                                                    <label
                                                                                                        for="workExperience"
                                                                                                        class="form-label f-size-14 fw-bold" style={{ marginRight: "26px" }}
                                                                                                    >
                                                                                                        Required Education
                                                                                                        {this.state.isIndeed === true &&
                                                                                                            <span class="asterisk"> *</span>
                                                                                                        }
                                                                                                    </label>

                                                                                                    <input
                                                                                                        type="text"
                                                                                                        name="RequiredEducation"
                                                                                                        className="form-control"
                                                                                                        value={this.state.RequiredEducation}
                                                                                                        placeholder="Education"
                                                                                                        maxLength="100"
                                                                                                        onChange={this.handleInputChange}
                                                                                                    />
                                                                                                </div>



                                                                                            </div>

                                                                                            <div class="col swift-radio-group">
                                                                                                <div class="form-group">
                                                                                                    <label
                                                                                                        for="workExperience"
                                                                                                        class="form-label f-size-14 fw-bold"
                                                                                                    >
                                                                                                        Select Job Publishing Portals

                                                                                                    </label>
                                                                                                    <br />
                                                                                                    <input type="checkbox"
                                                                                                        className="form-checkbox cursor-pointer me-2"
                                                                                                        name="status"
                                                                                                        id="publishing-status"
                                                                                                        checked={this.state.isStatus}
                                                                                                        onChange={e => {
                                                                                                            if (e.target.checked) {
                                                                                                                this.setState({ isStatus: true, status: "public" });
                                                                                                            }
                                                                                                            else {
                                                                                                                this.setState({ isStatus: false, status: "private" });
                                                                                                            }
                                                                                                        }
                                                                                                        }
                                                                                                    />

                                                                                                    <img src={PortalLogo} data-tip data-for="portalPublish" alt="Publish on portal" className="publish-portal-img me-3" />

                                                                                                    <ReactTooltip id={"portalPublish"} place="top" type="dark" effect="float">
                                                                                                        <span className="d-block fs-5">Publish on portal</span>
                                                                                                    </ReactTooltip>


                                                                                                    {this.state.isIndeedPublishEnable === true &&
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer me-2"
                                                                                                            name="Indeed"
                                                                                                            id="publishing-Indeed"
                                                                                                            checked={this.state.isIndeed}
                                                                                                            onChange={e => {
                                                                                                                if (e.target.checked) {
                                                                                                                    this.setState({ isIndeed: true });
                                                                                                                }
                                                                                                                else {
                                                                                                                    this.setState({ isIndeed: false });
                                                                                                                }
                                                                                                            }
                                                                                                            }
                                                                                                        />
                                                                                                    }


                                                                                                    {this.state.isIndeedPublishEnable === false &&
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer me-2"
                                                                                                            name="Indeed"
                                                                                                            id="publishing-Indeed"
                                                                                                            disabled="true" />
                                                                                                    }



                                                                                                    <img src={IndeedLogo} data-tip data-for="indeedPublish" alt="Publish on Indeed" className="publish-indeed-img me-3" />

                                                                                                    <ReactTooltip id={"indeedPublish"} place="top" type="dark" effect="float">
                                                                                                        <span className="d-block fs-5">Publish on Indeed</span>
                                                                                                    </ReactTooltip>
                                                                                                    {this.state.isJooblePublishEnable === true &&
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer me-2"
                                                                                                            name="Jooble"
                                                                                                            id="publishing-Jooble"
                                                                                                            checked={this.state.isJooble}
                                                                                                            onChange={e => {
                                                                                                                if (e.target.checked) {
                                                                                                                    this.setState({ isJooble: true, status: "public" });
                                                                                                                }
                                                                                                                else {
                                                                                                                    this.setState({ isJooble: false, status: "private" });
                                                                                                                }
                                                                                                            }
                                                                                                            }
                                                                                                        />
                                                                                                    }




                                                                                                    {this.state.isJooblePublishEnable === false &&
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer"
                                                                                                            name="Jooble"
                                                                                                            id="publishing-Jooble"
                                                                                                            disabled="true"
                                                                                                        />
                                                                                                    }

                                                                                                    <img src={JoobleSeeklogo} data-tip data-for="jooblePublish" alt="Publish on Jooble" className="publish-jooble-img me-3" />

                                                                                                    <ReactTooltip id={"jooblePublish"} place="top" type="dark" effect="float">
                                                                                                        <span className="d-block fs-5">Publish on Jooble</span>
                                                                                                    </ReactTooltip>


                                                                                                </div>



                                                                                            </div>


                                                                                            <div class="col swift-radio-group">
                                                                                                <div class="form-group">
                                                                                                    <label
                                                                                                        for="workExperience"
                                                                                                        class="form-label f-size-14 fw-bold"
                                                                                                    >
                                                                                                        Job Status<span class="asterisk"> *</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="form-group">
                                                                                                    <div className="form-check no-border form-check-inline">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="jobIsActive"
                                                                                                            value={true}
                                                                                                            checked={this.state.jobIsActive === true}
                                                                                                            onChange={this.handleInputChange}
                                                                                                        />
                                                                                                        <label className="form-check-label">Active</label>
                                                                                                    </div>
                                                                                                    <div className="form-check no-border form-check-inline">
                                                                                                        <input
                                                                                                            className="form-check-input"
                                                                                                            type="radio"
                                                                                                            name="jobIsActive"
                                                                                                            value={false}
                                                                                                            checked={this.state.jobIsActive === false}
                                                                                                            onChange={this.handleInputChange}
                                                                                                        />
                                                                                                        <label className="form-check-label">Inactive</label>
                                                                                                    </div>

                                                                                                </div>



                                                                                            </div>

                                                                                        </form>

                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                            {/* <!-- End Form --> */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card border-0 jc-radius  jc-t-pad h-swift-92 mb-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="d-md-flex justify-content-between">
                                                                        <button
                                                                            onClick={this.handlePreviousStep}
                                                                            class="border-swift btn-swift-text btn btn-light swift-shadow b-d-size"
                                                                            type="button"
                                                                        >
                                                                            <span>
                                                                                <i class="bi bi-arrow-left mr-1"></i>Back
                                                                            </span>
                                                                        </button>
                                                                        <button

                                                                            id="createJobBtn"
                                                                            class="border-swift btn-swift-text btn btn-primary swift-shadow theme-color b-d-size text-right"
                                                                            type="button"
                                                                            disabled={this.state.disableSaveBtn} onClick={this.handleSubmit}
                                                                        >
                                                                            <span>
                                                                                Save{" "}
                                                                                <i class="bi bi-arrow-right ml-1"></i>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    )}
                                    {/* <!---End Step-9 End job Messaging---> */}
                                </div>
                                {/* End Main Container */}
                            </section>
                        </main>

                        {/* <!-- End Row --> */}
                    </>
                )}

                {/* </div> */}

                {/* </main > */}
                {this.state.AddIntroVideo && (
                    <div
                        className=" d-block modal video-modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog-scrollable set-modal-size   "
                            role="document"
                        // style={{width:'100% !important'}}
                        >
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header">
                                    <h4
                                        className="modal-title whiteColor"
                                        id="accountUpdatePlanModalLabel"
                                    >
                                        Add Video
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            this.setState({ AddIntroVideo: false });
                                            document.body.classList.remove('modal-open');

                                        }}
                                    ></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">
                                    <p class="gray pad-0 marg-0 swfit-base-font mb-4">
                                        Please Select Video
                                    </p>
                                    <div className="row">
                                        {/* <!-- Card --> */}
                                        {this.state.IntroVideos != [] ? (
                                            this.state.IntroVideos.map((introvideo, index) => (
                                                <div className="col-lg-4 col-12 mb-3" key={index}>
                                                    <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                                                        <div className="card-header text-center">
                                                            <p className="card-text"> {introvideo.title}</p>
                                                        </div>

                                                        <div className="card-body d-flex justify-content-center">
                                                            {/* <!-- List Checked --> */}
                                                            <div className="card card-sm col-12">

                                                                {(() => {
                                                                    const videoMimeType = getVideoMimeType(introvideo.videoFileName);
                                                                    const videoJsOptions = {
                                                                        autoplay: false,
                                                                        controls: true,
                                                                        responsive: true,
                                                                        fluid: true,
                                                                        sources: [{
                                                                            src: introvideo.videoFileName,
                                                                            type: videoMimeType
                                                                        }]
                                                                    };

                                                                    return (
                                                                        <HlsVideoPlayer options={videoJsOptions} />
                                                                    );
                                                                })()}

                                                            </div>
                                                            {/* <!-- End List Checked --> */}
                                                        </div>

                                                        <div className="card-footer border-0 text-center">
                                                            <div className="d-grid mb-2">
                                                                <button
                                                                    type="button"
                                                                    className="form-check-select-stretched-btn btn btn-white"
                                                                    onClick={() => {
                                                                        document.body.classList.remove('modal-open');

                                                                        this.setState({
                                                                            AddIntroVideo: false,
                                                                            introVideoUrl: introvideo.videoFileName,
                                                                        })
                                                                    }
                                                                    }
                                                                >
                                                                    Select video
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <h3 className="text-primary">No Videos Found</h3>
                                        )}
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Row --> */}

                                    <div className="d-flex justify-content-between justify-content-sm-end gap-3">
                                        <button
                                            type="button"
                                            className="btn btn-white"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({ AddIntroVideo: false });
                                                document.body.classList.remove('modal-open');

                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                )}

                {this.state.AddOutroVideo && (
                    <div
                        className=" d-block modal video-modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog-scrollable set-modal-size   "
                            role="document"
                        // style={{width:'100% !important'}}
                        >
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header">
                                    <h4
                                        className="modal-title whiteColor"
                                        id="accountUpdatePlanModalLabel"
                                    >
                                        Add Video
                                    </h4>
                                    <button
                                        type="button"
                                        className="btn-close btn-close-white"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            this.setState({ AddOutroVideo: false });
                                            document.body.classList.remove('modal-open');

                                        }}
                                    ></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">
                                    <h1 class="swfit-base-font f-weight-600 f-size-18">
                                        Add Video
                                    </h1>
                                    <p class="gray pad-0 marg-0 swfit-base-font mb-4">
                                        Please Select Video
                                    </p>
                                    <div className="row">
                                        {/* <!-- Card --> */}
                                        {this.state.OutroVideos != [] ? (
                                            this.state.OutroVideos.map((outrovideo, index) => (
                                                <div className="col-lg-4 col-12 mb-3" key={index}>
                                                    <div className="card card-lg form-check form-check-select-stretched h-100 zi-1">
                                                        <div className="card-header text-center">
                                                            <p className="card-text"> {outrovideo.title}</p>
                                                        </div>

                                                        <div className="card-body d-flex justify-content-center">
                                                            {/* <!-- List Checked --> */}
                                                            <div className="card card-sm col-12">
                                                                {(() => {
                                                                    const videoMimeType = getVideoMimeType(outrovideo.videoFileName);
                                                                    const videoJsOptions = {
                                                                        autoplay: false,
                                                                        controls: true,
                                                                        responsive: true,
                                                                        fluid: true,
                                                                        sources: [{
                                                                            src: outrovideo.videoFileName,
                                                                            type: videoMimeType
                                                                        }]
                                                                    };

                                                                    return (
                                                                        <HlsVideoPlayer options={videoJsOptions} />
                                                                    );
                                                                })()}
                                                            </div>
                                                            {/* <!-- End List Checked --> */}
                                                        </div>
                                                        <div className="card-footer border-0 text-center">
                                                            <div className="d-grid mb-2">
                                                                <button
                                                                    type="button"
                                                                    className="form-check-select-stretched-btn btn btn-white"
                                                                    onClick={() => {
                                                                        document.body.classList.remove('modal-open');
                                                                        this.setState({
                                                                            AddOutroVideo: false,
                                                                            outroVideoUrl: outrovideo.videoFileName,
                                                                        });
                                                                    }}
                                                                >
                                                                    Select video
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <h3 className="text-primary">No Videos Found</h3>
                                        )}
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Row --> */}

                                    <div className="d-flex justify-content-between justify-content-sm-end gap-3">
                                        <button
                                            type="button"
                                            className="btn btn-white"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({ AddOutroVideo: false });
                                                document.body.classList.remove('modal-open');

                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                )}
                {this.state.IsJobTitlesLoading > 0 && (
                    <div class="overlay">
                        <div className="overlay-content">
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                )}

                {this.state.addJobTitleModal && (
                    <div
                        className=" d-block modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                        aria-hidden="true"
                    >

                        <div
                            className="modal-dialog modal-dialog-centered modal-xl model-lg-wrapper"
                            role="document"
                        >
                            <div className="modal-content">
                                {/* <!-- Header --> */}

                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div class="modal-body">
                                    <h1 class="swfit-base-font f-weight-600 f-size-18">
                                        {this.state.IsQuestionListLoad === 1 ? "AI Generated Question Pool" : "Add Job Title"}

                                    </h1>
                                    <div className="row">
                                        {this.state.IsQuestionListLoad === 0 ? <>
                                            <div className="swft-form-input mb-2">
                                                <label
                                                    for="jobTitle"
                                                    className="form-label f-weight-700 f-size-14"
                                                >
                                                    Job Title{" "}
                                                    <span class="asterisk"> *</span>
                                                </label>

                                                <div className="col-md-12 mb-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={this.handleInputChange}
                                                        value={this.state.jobTitle}
                                                        name="jobTitle"
                                                        id="jobTitle"
                                                        placeholder="Job Title"
                                                    />
                                                </div>
                                                <div className="col-md-12">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-flex align-items-center"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={() => {
                                                            if (this.state.jobTitle.trim().length > 100) {
                                                                displayErrorMessage("Job title cannot exceed 100 characters!", "top-center");
                                                                this.setState({ isSaving: false });
                                                                return;
                                                            }
                                                            this.createNewQuestionSet();
                                                        }}
                                                    >
                                                        <span className="material-symbols-outlined">
                                                            lightbulb
                                                        </span>
                                                        <span className="ms-2">Help me create a new question set</span>
                                                    </button>
                                                </div>

                                            </div>

                                            <div className="swft-form-input mb-2">
                                                <label
                                                    for="industries"
                                                    className="form-label f-weight-700 f-size-14"
                                                >
                                                    Industries{" "}
                                                    <span class="asterisk"> *</span>
                                                </label>

                                                <div className="col-md-12">
                                                    {/* <!-- Select --> */}
                                                    <div className="tom-select-custom">
                                                        <select
                                                            className="form-select"
                                                            name="industries"
                                                            onChange={(e) => {

                                                                const selectedValue = e.target.value;
                                                                const selectedIndustry = this.state.industries[selectedValue] || {};
                                                                this.setState({
                                                                    selectedIndustryIdForJobTitle: selectedIndustry.defaultIndustryId,
                                                                });

                                                                this.getAllJobTitles(selectedIndustry.defaultIndustryId);

                                                            }}
                                                        >
                                                            {this.state.selectedIndustryIdForJobTitle == -1 &&
                                                                <option key="-1" value="">
                                                                    Select
                                                                </option>}

                                                            {this.state.industries != [] &&
                                                                this.state.industries.map((industry, index) => (
                                                                    <option
                                                                        key={index}
                                                                        selected={
                                                                            industry.title ==
                                                                            this.state.selectedIndustry
                                                                        }
                                                                        value={industry.value}
                                                                    >
                                                                        {industry.label}{" "}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    {/* <!-- End Select --> */}
                                                </div>
                                            </div>

                                            <div className="swft-form-input mb-4">
                                                <label
                                                    for="questionsets"
                                                    className="form-label f-weight-700 f-size-14"
                                                >
                                                    Question Sets{" "}
                                                    <span class="asterisk"> *</span>
                                                </label>

                                                <div className="col-md-12">
                                                    {/* <!-- Select --> */}
                                                    <div className="tom-select-custom">
                                                        <select
                                                            className="form-select"
                                                            name="questionSets"
                                                            onChange={(e) => {
                                                                if (e.target.value === '') {
                                                                    this.setState({ selectedQuestionSetIdForJobTitle: "" })
                                                                } else {
                                                                    this.setState({
                                                                        selectedQuestionSetIdForJobTitle: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <option value={this.state.selectedQuestionSetIdForJobTitle} defaultValue>
                                                                Select
                                                            </option>

                                                            {this.state.questionSetsForJobTitles.length > 0 &&
                                                                this.state.questionSetsForJobTitles.map(
                                                                    (questionset, index) => (
                                                                        <option
                                                                            key={index}
                                                                            value={questionset.questionSetId}
                                                                            selected={this.state.NewQuestionSetId == questionset.questionSetId}
                                                                        >
                                                                            {questionset.questionSetName.length > 50
                                                                                ? questionset.questionSetName.slice(0, 50) + '...' 
                                                                                : questionset.questionSetName
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                    </div>
                                                    {/* <!-- End Select --> */}
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between justify-content-sm-between gap-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mod-b-d-size"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        this.saveJobTitle();
                                                    }}
                                                    disabled={this.state.isSaving}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-white mod-b-d-size"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        this.setState({ addJobTitleModal: false, jobTitle: '' });
                                                        document.body.classList.remove('modal-open');

                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>

                                        </>
                                            :
                                            <>
                                                {this.state.questions.length > 0 && this.state.questions.map((question, index) => {
                                                    return <div className="list-group-item" key={index}>
                                                        <div className="d-flex">


                                                            <div className="flex-grow-1 ms-3">
                                                                <div className="row align-items-center">
                                                                    <div className="col">
                                                                        {/* <h4 className="mb-0">{question.title}</h4>*/}
                                                                        <p className="fs-5 text-body mb-0 ">{question.title}</p>
                                                                    </div>
                                                                    {/* <!-- End Col --> */}

                                                                    <div className="col-auto swape-box">
                                                                        {/* <!-- Form Switch --> */}
                                                                        <div className="form-check form-switch float-end">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id={question.questionId}
                                                                                value={question}
                                                                                onChange={e => onSelectquestions(question, e)}
                                                                                checked={question.ischecked} />
                                                                            {/*<label className="form-check-label" for="connectedAccounts1"></label>*/}
                                                                        </div>

                                                                    </div>



                                                                    {/* <!-- End Col --> */}
                                                                </div>

                                                                {/* <!-- End Row --> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                })
                                                }
                                                {this.state.questions.length > 0 && <>
                                                    <div className="d-flex justify-content-between justify-content-sm-between gap-3 mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary mod-b-d-size"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => {
                                                                this.saveQuestionSet();
                                                            }}
                                                        >
                                                            Save Questions
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-white mod-b-d-size"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Cancel"
                                                            onClick={() => {
                                                                this.newQuestionSetCancel();

                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>

                                                </>}
                                                {this.state.IsQuestionLoading > 0 && <>
                                                    <div class="overlay">
                                                        <div className="overlay-content">
                                                            <Oval
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                color="#4fa94d"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                }
                                            </>
                                        }

                                    </div>
                                    {/* <!-- End Row --> */}

                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                )}

                {this.state.addSkillModal && (
                    <div
                        className=" d-block modal"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-xl model-lg-wrapper"
                            role="document"
                        >
                            <div className="modal-content">
                                {/* <!-- Header --> */}

                                {/* <!-- End Header --> */}


                                {/* <!-- Body --> */}
                                <div class="modal-body">
                                    <h1 class="swfit-base-font f-weight-600 f-size-18">
                                        Create Skill
                                    </h1>


                                    <div className="row mb-4">
                                        <div className="swft-form-input mb-2">
                                            <label
                                                for="skill"
                                                className="form-label f-weight-700 f-size-14"
                                            >
                                                Skill{" "}
                                                <span class="asterisk"> *</span>
                                            </label>

                                            <div className="col-md-12">
                                                <input
                                                    value={this.state.skill}
                                                    type="text"
                                                    className="form-control"
                                                    onChange={this.handleInputChange}
                                                    maxLength="30"
                                                    name="skill"
                                                    id="skill"
                                                    aria-label="Your department"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between justify-content-sm-between gap-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary mod-b-d-size"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.saveSkill();
                                            }}
                                            disabled={this.state.isSaveDisabled}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-white mod-b-d-size"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({ addSkillModal: false });
                                                document.body.classList.remove('modal-open');


                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {/* <!-- End Row --> */}


                            </div>
                            {/* <!-- End Body --> */}
                        </div>
                    </div>

                )}

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: "35%" }}
                />

                {/*tool tips*/}

                <ReactTooltip id="jobSource" place="right" type="dark" effect="solid">
                    <span> Job Source </span>
                </ReactTooltip>
                <ReactTooltip id="permission" place="right" type="dark" effect="solid">
                    <span>
                        {" "}
                        Job permission: who can see the job when it will be created, You can
                        assign to a group or manager only, or everyone.{" "}
                    </span>
                </ReactTooltip>
                <ReactTooltip
                    id="questionnaries"
                    place="right"
                    type="dark"
                    effect="solid"
                >
                    <span>
                        {" "}
                        Questionnaries are the requirments which will be required by
                        candidates after registeration.{" "}
                    </span>
                </ReactTooltip>
                <ReactTooltip
                    id="jobMessaging"
                    place="right"
                    type="dark"
                    effect="solid"
                >
                    <span>
                        {" "}
                        Set video messages to display before start of interview and after
                        ending the interview{" "}
                    </span>
                </ReactTooltip>
                <ReactTooltip id="jobStatus" place="right" type="dark" effect="solid">
                    <span>
                        {" "}
                        Private jobs will be shown on public site, while public can be seen{" "}
                    </span>
                </ReactTooltip>
            </>
        );
    }
}
export default CreateJob;
