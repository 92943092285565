import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import axios from "axios";
import ReactSelect from 'react-select';
import { parseJwt } from '../../modules/TokenParser';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
// import DatePicker from "react-datepicker";
import DatePicker from "../DataPicker/index"
import { ToastContainer } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import SuccessInterview from '../../assets/svg/illustrations/oc-hi-five.svg'
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import readXlsxFile from 'read-excel-file'
import sampleDownloadCSV from '../../assets/sampleexcel/sampletemplate.xlsx'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import ReactTooltip from 'react-tooltip';
import swiftAddapplicant from './../../assets/img/new-design/swift-add-candidates.png';
import swiftAddapplicantTick from './../../assets/img/new-design/add-candidate.svg';

class CreateInterview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedJobId: null,
            isModalOpen: true,
            interviewIdReturned: "",
            OpenInterviewAlert: false,
            date: new Date(),
            OpenInterviewUrl: "",
            addedliveQuestions: [],
            shortlistedApplicants: [],
            liveQuestionTitle: "",
            liveQuestionDescription: "",
            isJobCreated: false,
            applicantFormLabel: "Add Applicant",
            isapplicantNotAdded: true,
            isHostLimit: true,
            jobManager: "",
            //data for react-select days
            days: [
                {
                    value: '1',
                    label: '1'
                },
                {
                    value: '2',
                    label: '2'
                },
                {
                    value: '3',
                    label: '3'
                },
                {
                    value: '4',
                    label: '4'
                },
                {
                    value: '5',
                    label: '5'
                },
                {
                    value: '6',
                    label: '6'
                }, {
                    value: '7',
                    label: '7'
                },
                {
                    value: '8',
                    label: '8'
                }, {
                    value: '9',
                    label: '9'
                },
                {
                    value: '10',
                    label: '10'
                }, {
                    value: '11',
                    label: '11'
                },
                {
                    value: '12',
                    label: '12'
                }, {
                    value: '13',
                    label: '13'
                },
                {
                    value: '14',
                    label: '14'
                }, {
                    value: '15',
                    label: '15'
                },
                {
                    value: '16',
                    label: '16'
                }, {
                    value: '17',
                    label: '17'
                },
                {
                    value: '18',
                    label: '18'
                }, {
                    value: '19',
                    label: '19'
                },
                {
                    value: '20',
                    label: '20'
                }, {
                    value: '21',
                    label: '21'
                },
                {
                    value: '22',
                    label: '22'
                }, {
                    value: '23',
                    label: '23'
                },
                {
                    value: '24',
                    label: '24'
                },
                {
                    value: '25',
                    label: '25'
                },
                {
                    value: '26',
                    label: '26'
                },
                {
                    value: '27',
                    label: '27'
                },
                {
                    value: '28',
                    label: '28'
                },
                {
                    value: '29',
                    label: '29'
                },
                {
                    value: '30',
                    label: '30'
                },
            ],
            //data for react-select hours

            hours: [
                {
                    value: '0',
                    label: '0'
                },
                {
                    value: '1',
                    label: '1'
                },
                {
                    value: '2',
                    label: '2'
                },
                {
                    value: '3',
                    label: '3'
                },
                {
                    value: '4',
                    label: '4'
                },
                {
                    value: '5',
                    label: '5'
                },
                {
                    value: '6',
                    label: '6'
                }, {
                    value: '7',
                    label: '7'
                },
                {
                    value: '8',
                    label: '8'
                }, {
                    value: '9',
                    label: '9'
                },
                {
                    value: '10',
                    label: '10'
                }, {
                    value: '11',
                    label: '11'
                },
                {
                    value: '12',
                    label: '12'
                },
                {
                    value: '13',
                    label: '13'
                },
                {
                    value: '14',
                    label: '14'
                },
                {
                    value: '15',
                    label: '15'
                },
                {
                    value: '16',
                    label: '16'
                },
                {
                    value: '17',
                    label: '17'
                },
                {
                    value: '18',
                    label: '18'
                }, {
                    value: '19',
                    label: '19'
                },
                {
                    value: '20',
                    label: '20'
                }, {
                    value: '21',
                    label: '21'
                },
                {
                    value: '22',
                    label: '22'
                }, {
                    value: '23',
                    label: '23'
                },

            ],
            //data for react-select minutes

            minutes: [
                {
                    value: '00',
                    label: '00'
                },
                {
                    value: '01',
                    label: '01'
                },
                {
                    value: '02',
                    label: '02'
                },
                {
                    value: '03',
                    label: '03'
                },
                {
                    value: '04',
                    label: '04'
                },
                {
                    value: '05',
                    label: '05'
                },
                {
                    value: '06',
                    label: '06'
                }, {
                    value: '07',
                    label: '07'
                },
                {
                    value: '08',
                    label: '08'
                }, {
                    value: '09',
                    label: '09'
                },
                {
                    value: '10',
                    label: '10'
                }, {
                    value: '11',
                    label: '11'
                },
                {
                    value: '12',
                    label: '12'
                }, {
                    value: '13',
                    label: '13'
                },
                {
                    value: '14',
                    label: '14'
                }, {
                    value: '15',
                    label: '15'
                },
                {
                    value: '16',
                    label: '16'
                }, {
                    value: '17',
                    label: '17'
                },
                {
                    value: '18',
                    label: '18'
                }, {
                    value: '19',
                    label: '19'
                },
                {
                    value: '20',
                    label: '20'
                }, {
                    value: '21',
                    label: '21'
                },
                {
                    value: '22',
                    label: '22'
                }, {
                    value: '23',
                    label: '23'
                },
                {
                    value: '24',
                    label: '24'
                },
                {
                    value: '25',
                    label: '25'
                },
                {
                    value: '26',
                    label: '26'
                },
                {
                    value: '27',
                    label: '27'
                },
                {
                    value: '28',
                    label: '28'
                },
                {
                    value: '29',
                    label: '29'
                },
                {
                    value: '30',
                    label: '30'
                },
                {
                    value: '31',
                    label: '31'
                },
                {
                    value: '32',
                    label: '32'
                },
                {
                    value: '33',
                    label: '33'
                },
                {
                    value: '34',
                    label: '34'
                },
                {
                    value: '35',
                    label: '35'
                }, {
                    value: '36',
                    label: '36'
                },
                {
                    value: '37',
                    label: '37'
                }, {
                    value: '38',
                    label: '38'
                },
                {
                    value: '39',
                    label: '39'
                }, {
                    value: '40',
                    label: '40'
                },
                {
                    value: '41',
                    label: '41'
                }, {
                    value: '42',
                    label: '42'
                },
                {
                    value: '43',
                    label: '43'
                }, {
                    value: '44',
                    label: '44'
                },
                {
                    value: '45',
                    label: '45'
                }, {
                    value: '46',
                    label: '46'
                },
                {
                    value: '47',
                    label: '47'
                }, {
                    value: '48',
                    label: '48'
                },
                {
                    value: '49',
                    label: '49'
                }, {
                    value: '50',
                    label: '50'
                },
                {
                    value: '51',
                    label: '52'
                }, {
                    value: '53',
                    label: '53'
                },
                {
                    value: '54',
                    label: '54'
                },
                {
                    value: '55',
                    label: '55'
                },
                {
                    value: '56',
                    label: '56'
                },
                {
                    value: '57',
                    label: '57'
                },
                {
                    value: '58',
                    label: '58'
                },
                {
                    value: '59',
                    label: '59'
                },

            ],
            //data for react-select ampm

            ampm: [
                {
                    value: 'AM',
                    label: 'AM'
                },
                {
                    value: 'PM',
                    label: 'PM'
                },

            ],


            allInterviewReviewers: [],
            hourvalue: "23",
            minutevalue: "59",
            ampmvalue: "AM",
            selectedTimeZone: "",
            selectedjob: "",
            selectedinterview: "",
            scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
            extensiondate: new Date(new Date().setDate(new Date().getDate() + 14)),
            firstname: "",
            lastname: "",
            email: "",
            addedapplicants: [],
            alljobs: [],
            emailTemplateSelected: "",
            messageBodyRadio: "no",
            emailTemplates: [

            ],
            customMessageBody: "",

            //Selected questions for one way interview
            selectedquestionsforonewayChecked: [],
            selectedquestionsforoneway: {
                Title: "",
                Description: "",
                questions: []
            },

            //Selected questions for open one way interview
            selectedquestionsforopenonewayChecked: [],
            selectedquestionsforopenoneway: {
                Title: "",
                Description: "",
                questions: []
            },
            selectedtab: "absolutedeadline",
            openTab: "Absolute",
            expandTableRow: false,
            rowId: "",
            interviewReviewers: [],

            selectedInterviewReviewersClose: [],
            selectedInterviewReviewersOpen: [],
            selectedInterviewReviewersLive: [],
            defaultQuestionSet: -1,
            defaultQuestionSetObject: {},
            isHost: false,
            timeZones: [],
            isloadedgetJobs: true,
            questionsset: [],
            addQuestionModal: false,
            questionDetailsModal: false,
            questionsForQuestionDetailsModal: {},
            questionsForQuestionDetailsModalIndex: -1,
            addapplicantModal: false,
            addReviewerModal: false,
            openInterviewLink: "",
            manualGuideLink: "https://mediatc.blob.core.windows.net/media/Manuals/OpenInterviewStepbyStepGuide.docx",
            addapplicantThroughExcelModal: false,
            addShortlistedapplicants: false,

            interviewStages: [{
                name: "FirstInterview", value: "FirstInterview"
            },
            {
                name: "SecondInterview", value: "SecondInterview"
            },
            {
                name: "ThirdInterview", value: "ThirdInterview"
            }
            ],
            interviewStage: "FirstInterview",
            // scheduleddate: new Date(),
            PhoneNumber: "",
            tenantSubscriptionId: 0,
            tenantSubscriptionDataPermissionThinkTime: {},
            tenantSubscriptionDataPermissionNoOfAttempts: {},
            tenantSubscriptionDataPermissionTimeLimit: {},

            sfc_AllowedOneWayCloseCount: 0,
            sfc_AllowedOneWayOpenCount: 0,
            sfc_AllowedLiveInterviewCount: 0,
            sfc_AllowedJobsCount: 0,
            disableSaveBtn: false,
            jobDefaultQuestionSet: null,
            currentHour: -1,
            currentMinute: -1,
            interviewLevel: "FirstInterview",
            otherInterviewLevel: "",
            interviewTag: "",
            firstInterviewAttampeted: 0,
            secondInterviewAttampeted: 0,
            finalInterviewAttampeted: 0,
            isDefaultInterviewLevel: 1,
            tags: [],
            inputValue: '',
            interviewLevelList: [],
            JobVisibilityLevel: "",
            UserGroupId: 0,
            isActiveInviteExist: false,
        }
        this.createInterview = this.createInterview.bind(this);
        this.refreshShortListedapplicants = this.refreshShortListedapplicants.bind(this);
        this.selectInterviewLevel = this.selectInterviewLevel.bind(this);
    }

    selectInterviewLevel(event) {
        const target = event.target;
        const name = target.name;
        let LevelList = this.state.interviewLevelList;
        for (var i = 0; i < LevelList.length; i++) {
            if (LevelList[i] === target.value) {
                if (LevelList[i] === "FirstInterview") {
                    displayWarningMessage('Already first interview exist, It may cause in lead pipeline!', "top-center");
                    break;
                }
                else if (LevelList[i] === "SecondInterview") {
                    displayWarningMessage('Already second interview exist, It may cause in lead pipeline!', "top-center");
                    break;
                }
                else if (LevelList[i] === "FinalInterview") {
                    displayWarningMessage('Already final interview exist, It may cause in lead pipeline!', "top-center");
                    break;
                }
            }
        }

        if (name === "InterviewLevel") {
            this.setState({
                interviewLevel: target.value,
            });
            if (target.value === "FirstInterview") {
                this.setState({
                    isDefaultInterviewLevel: 1,
                });
            }
            else if (target.value === "SecondInterview") {
                this.setState({
                    isDefaultInterviewLevel: 2,
                });
            }
            else if (target.value === "FinalInterview") {
                this.setState({
                    isDefaultInterviewLevel: 3,
                });
            }
            else if (target.value === "OtherInterview") {
                this.setState({
                    isDefaultInterviewLevel: 4,
                });
            }

        } else {
            console.log("InterviewLevel name : " + name);
            console.log("InterviewLevel value : " + target.value);
        }
    }

    async componentDidMount() {
        document.title = "Tenant App - Interviews";
        this.setState({
            currentHour: new Date().getHours(),
            currentMinute: new Date().getMinutes()

        })
        this.getTenantSubscriptionid();

        if (this.props.match.params.JobId !== -1 && this.props.match.params.JobId !== "" && this.props.match.params.JobId != null && this.props.match.params.JobId != undefined) {
            {
                this.setState({
                    selectedJobId: this.props.match.params.JobId
                });
            }
        }
        else
            console.error("Fail : Setting selectedJob state variable");

        await this.getEmailTemplates();
        await this.getTimeZones();
        await this.getJobs().then(async () => {
            if (this.props.match.params.JobId != -1 && this.state.selectedJobId !== "" && this.state.selectedJobId != null) {
                await this.handleChange(this.state.selectedjob)
            }
        })

        this.getTenantGeneralInfo();
    }




    /**Get Time Zones from backend */
    async getTimeZones() {
        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/TimeZoneInformation";
            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        timeZones: result
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Time zones!', "top-center");

                });
        }
    }

    getTenantGeneralInfo() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantGeneral/" + tenantId

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        selectedTimeZone: response.data.timeZone,
                        selectedTimeZone1: response.data.timeZone,
                    })
                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching intro outro videos!', "top-center");

                });

        }

    }

    /**
     * Get the filtered job that are visibile to this user
     * */
    async getJobs() {

        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/AutoJob";

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    let jobs = [];
                    if (result.length > 0) {

                        // console.log("in for each =>" + selected_job_id);

                        //This loop is for setting the values in specific pattern for React-select to accept
                        result.forEach((job) => {
                            if ((this.state.selectedJobId == job.jobId)) {
                                // this.setState({ selectedjob: { value: job.jobId, label: job.title, defaultQuestionSet: job.defaultQuestionSet } });
                                this.setState({ selectedjob: { value: job.jobId, label: job.title, defaultQuestionSet: job.defaultQuestionSet } });
                                this.setState({ jobManager: job.jobManager })
                            }
                            // jobs.push({ value: job.jobId, label: job.title, defaultQuestionSet: job.defaultQuestionSet })
                            if (job.isForFemaleOnly) {
                                jobs.push({ value: job.jobId, label: job.jobId + " - " + job.title + " (" + job.location + ")" + " - " + "Female", defaultQuestionSet: job.defaultQuestionSet })
                            }
                            else {
                                jobs.push({ value: job.jobId, label: job.jobId + " - " + job.title + " (" + job.location + ")", defaultQuestionSet: job.defaultQuestionSet })

                            }

                        }
                        )
                        this.setState({
                            alljobs: jobs,
                            isloadedgetJobs: false
                        })
                    }
                    else {
                        this.setState({
                            alljobs: [],
                            isloadedgetJobs: false,
                            isJobCreated: true
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Jobs!', "top-center");

                });
        }
    }


    /**
    * create interview submit button handler
    * @param {any} jobId
    */
    async getShortlistedApplicants(jobId) {
        let bearer = validateAndGetFormatedToken();
        console.info("Fired getShortlistedApplicants");

        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/ShortlistedApplicants/" + jobId + "/filteredByInterviewInvites";
            await axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ shortlistedApplicants: response.data });
                })
                .catch(err => {
                    displayErrorMessage('Error while fetching Short Listed applicants!', "top-center");


                });
        }
    }

    async getEmailTemplates() {

        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/EmailTemplates/GetAllTemplates";

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    let templates = [];
                    if (result.length > 0) {

                        result.forEach(x =>
                            templates.push({ value: x.emailTemplateId, label: x.emailTemplateName })
                        )
                        this.setState({
                            emailTemplates: templates
                        })
                    }
                    else {
                        this.setState({
                            emailTemplates: []
                        })
                    }

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Templates!', "top-center");


                });
        }
    }

    // Function to check if the applicant has an active invite based on their email
    async isApplicantActiveInvite(email, interviewType) {
        // Retrieve the bearer token from a function that validates and formats it
        let bearer = validateAndGetFormatedToken();
        // Check if the bearer token is not empty or null
        if (bearer != "" || bearer != null) {
            // Construct the API URL to check the applicant's active invite status
            let url = process.env.REACT_APP_CORE_API + "/api/ApplicantActiveInvite";
            // Create an object to send the job ID and applicant email as payload
            let applicantInvite = {
                jobId: this.state.selectedJobId,
                email: email
            };
            // Convert the object to a JSON string to send as the body of the request
            var model = JSON.stringify(applicantInvite);
            // Make a POST request to the API endpoint with the token and request body
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: model
                }).then(response => response.json())
                .then(result => {
                    // If the result contains any active invites (i.e., result is not empty)
                    if (result.length > 0) {
                        displayWarningMessage('Applicant already has an active interview invite for the current job!', "top-center");
                        return true;
                    }
                    else {
                        if (interviewType === 1) {
                            // If no active invite exists, proceed to add the applicant
                            let applicants = [...this.state.addedapplicants];
                            const applicant = {
                                firstname: this.state.firstname,
                                lastname: this.state.lastname,
                                email: this.state.email,
                                PhoneNumber: this.state.PhoneNumber,
                                interviewStage: "FirstInterview",
                                isHost: false,
                            }
                            let tempAllReviewers = this.state.allInterviewReviewers;
                            let tmpCount = 0;
                            for (let i = 0; i < tempAllReviewers.length; i++) {
                                if (applicant.email == tempAllReviewers[i]) {
                                    tmpCount++;
                                }
                            }
                            if (tmpCount > 0) {
                                displayWarningMessage('applicant Email cannot contain Reviewer Email!', "top-center");
                                return;
                            }
                            tmpCount = 0;
                            for (let i = 0; i < applicants.length; i++) {
                                if (applicant.email == applicants[i].email) {
                                    tmpCount++;
                                }
                            }
                            if (tmpCount > 0) {
                                displayWarningMessage('Duplicate Email Found!', "top-center");
                                return;
                            }
                            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            let result = re.test(String(applicant.email).toLowerCase());
                            if (!result) {
                                displayWarningMessage('Email is not valid', "top-center");
                                return;
                            }
                            applicants.push(applicant);
                            // Update the state with the new applicant list and reset the form fields
                            this.setState({
                                addedapplicants: applicants,
                                firstname: "",
                                lastname: "",
                                email: "",
                                PhoneNumber: "",
                                interviewStage: "FirstInterview",
                                isHost: false,
                                addapplicantModal: false,
                                isapplicantNotAdded: false,
                            });
                        }
                        else if (interviewType === 3) {
                            let applicants = [...this.state.addedapplicants];
                            let applicant = {}
                            
                                applicant = {
                                    firstname: this.state.firstname,
                                    lastname: this.state.lastname,
                                    email: email,
                                    interviewStage: "FirstInterview",
                                    isHost: this.state.isHost,
                                    PhoneNumber: this.state.PhoneNumber
                                }
                            let tempAllReviewers = this.state.allInterviewReviewers;
                            let tmpCount = 0;
                            for (let i = 0; i < tempAllReviewers.length; i++) {
                                if (applicant.email == tempAllReviewers[i] && applicant.isHost == false) {
                                    tmpCount++;
                                }
                            }
                            if (tmpCount > 0) {
                                displayWarningMessage('applicant Email cannot contain Reviewer Email!', "top-center");
                                return;
                            }
                            tmpCount = 0;
                            for (let i = 0; i < applicants.length; i++) {
                                if (applicant.email == applicants[i].email) {
                                    tmpCount++;
                                }
                            }
                            if (tmpCount > 0) {
                                displayWarningMessage('Duplicate Email Found!', "top-center");
                                return;
                            }
                            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            let result = re.test(String(applicant.email).toLowerCase());
                            if (!result) {
                                displayWarningMessage('Email is not valid', "top-center");
                                return;
                            }
                            applicants.push(applicant);
                            this.setState({
                                addedapplicants: applicants,
                                firstname: "",
                                lastname: "",
                                email: "",
                                PhoneNumber: "",
                                interviewStage: "FirstInterview",
                                isHost: false
                            });
                            if (applicant.isHost === false) {
                                this.setState({ isapplicantNotAdded: false });
                            }
                           
                        }
                        this.setState({ addapplicantModal: false });
                        document.body.classList.remove('modal-open');
                    }

                })
                .catch(err => {
                    console.log(err);
                    return false;
                });
        }
    }

   // For selecting jobs and setting the state

    handleChange = async (selectedOption) => {
        this.setState({
            selectedjob: selectedOption,
            selectedinterview: "",
            addedapplicants: [],
            liveQuestionDescription: "",
            liveQuestionTitle: "",
            selectedquestionsforoneway: [],
            selectedquestionsforopenoneway: [],
            addedliveQuestions: []

        });

        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/InterviewReviewers?jobId=" + selectedOption.value;
            await axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(async (response) => {
                    this.setState({
                        interviewReviewers: response.data.users,
                        interviewLevelList: response.data.interviewLevel,
                        JobVisibilityLevel: response.data.jobVisibilityLevel,
                        UserGroupId: response.data.userGroupId,
                    });

                }).then(async () => {
                    if (selectedOption.defaultQuestionSet > 0)
                        await this.getJobDefaultQuestionSet(selectedOption.defaultQuestionSet)
                    else
                        this.getQuestionSets({});
                })
                .catch(err => {
                    console.error("Interview Reviewers" + err);
                    displayErrorMessage('Error while fetching Interview Reviewers!', "top-center");


                });
        }

        this.setState({ selectedJobId: selectedOption.value });

        await this.getShortlistedApplicants(selectedOption.value);

    };

    /**
     * Refresh data source
     * @param {any} jobIdTo join the video meeting, click this link: https://meet.google.com/cyo-vnsk-ddb
Otherwise, to join by phone, dial +1 419-812-2542 and enter this PIN: 651 511 833#
To view more phone numbers, click this link: https://tel.meet/cyo-vnsk-ddb?hs=5
     */
    async refreshShortListedapplicants() {
        console.log("selectedJobId =>  " + this.state.selectedJobId);
        if (this.state.selectedJobId != null && this.state.selectedJobId != '' && this.state.selectedJobId != undefined) {
            await this.getShortlistedApplicants(this.state.selectedJobId);
        }
        else {
            console.log("No job seleted");
        }
    }


    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "TimeLimit");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "NoOfAttempts");

                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "ThinkTime");
                })
                .then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "OneWayClose");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "OneWayOpen");

                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "LiveInterview");

                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");


                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }



    /**
 * Get the tenant subscriotion feature key value 
 * @param {number} tenantSubscriptionId
 * @param {string} featureKey
 */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.featureKey === "TimeLimit") {
                        this.setState({
                            tenantSubscriptionDataPermissionTimeLimit: response.data
                        })
                    }
                    if (response.data.featureKey === "NoOfAttempts") {
                        this.setState({
                            tenantSubscriptionDataPermissionNoOfAttempts: response.data
                        })
                    }
                    if (response.data.featureKey === "ThinkTime") {
                        this.setState({
                            tenantSubscriptionDataPermissionThinkTime: response.data
                        })
                    }
                    if (response.data.featureKey === "OneWayClose") {
                        this.setState({
                            sfc_AllowedOneWayCloseCount: parseInt(response.data.value)
                        })
                    }
                    if (response.data.featureKey === "OneWayOpen") {
                        this.setState({
                            sfc_AllowedOneWayOpenCount: parseInt(response.data.value)
                        })
                    }
                    if (response.data.featureKey === "LiveInterview") {
                        this.setState({
                            sfc_AllowedLiveInterviewCount: parseInt(response.data.value)
                        })
                    }
                    if (response.data.featureKey === "Jobs") {
                        this.setState({
                            sfc_AllowedJobsCount: parseInt(response.data.value)
                        })
                    }


                    console.log("tenantSubscriptionData", response.data)
                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }

    /**
   * 
   * @param {any} tenantSubscriptionId
   * @param {any} featureKey
   * @param {any} operationType
   */
    subscriptionDataDecrement(tenantSubscriptionId, featureKey, operationType) {
        let tenantSubscriptionModel = {
            tenantSubscriptionId: tenantSubscriptionId,
            featureKey: featureKey,
            operationType: operationType
        };
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData";
            axios.put(url, tenantSubscriptionModel, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                console.log("response from decrementing TenantSubscriptionData", response)
            })
                .catch(error => {
                    console.log("error during decrementing TenantSubscriptionData", error);
                    displayErrorMessage('Error During decrementing TenantSubscriptionData!', "top-center");

                })
        }
    }

    //Get all questions set with their respective questions on the base of tenant id and default check job questionset
    getQuestionSets(jobDefaultQuestionSet) {
        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet";

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    result.forEach((item, index) => {

                        item.questions.forEach((item1, index1) => {
                            if (jobDefaultQuestionSet.questionSetId == item.questionSetId) {
                                result[index].questions[index1].isChecked = true;
                                result[index].questions[index1].thinkTime = result[index].questions[index1].thinkTime;
                                result[index].questions[index1].timeLimit = result[index].questions[index1].timeLimit;
                            }
                            else {
                                result[index].questions[index1].thinkTime = result[index].questions[index1].thinkTime;
                                result[index].questions[index1].timeLimit = result[index].questions[index1].timeLimit;
                            }
                        })

                    });
                    console.log("questionsset", result);
                    this.setState({
                        questionsset: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Question Sets!', "top-center");
                });
        }
    }


    /**
     * Get Default Question Set Of A Job*/
    getJobDefaultQuestionSet(questionSetId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/QuestionSet/" + questionSetId;
            let jobDefaultQuestionSet = null;
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    result.questions.forEach((item1, index) => {
                        result.questions[index].thinkTime = result.questions[index].thinkTime;
                        result.questions[index].timeLimit = result.questions[index].timeLimit;
                    })

                    this.setState({
                        jobDefaultQuestionSet: result,
                        selectedquestionsforoneway: result,
                        selectedquestionsforopenoneway: result
                    });
                    jobDefaultQuestionSet = result;
                })
                .then(() => {
                    this.getQuestionSets(jobDefaultQuestionSet);
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Job Default Question Set!', "top-center");

                });
        }
    }


    //For selecting days and setting the state

    handleChange1 = selectedOption => {
        this.setState({ selecteddays: selectedOption.value });
    };

    //For selecting hour and setting the state

    handleHour = selectedOption => {

        this.setState({ hourvalue: selectedOption.value });
    };

    //For selecting minutes and setting the state

    handleMinute = selectedOption => {

        this.setState({ minutevalue: selectedOption.value });
    };

    //For selecting ampm and setting the state

    handleAMPM = selectedOption => {
        this.setState({ ampmvalue: selectedOption.value });
    };

    //For selecting city and setting the state

    handleTimeZone = selectedOption => {
        this.setState({ selectedTimeZone: selectedOption.target.value });
    };

    //CheckBox.CheckedChanged Event

    handleCheckedChanged(e) {
        if (e.target.checked) {
            this.setState({ isHost: true })

        }
        else {
            this.setState({ isHost: false })

        }
    }


    /**
     * create interview submit button handler
     * @param {any} event
     */
    async createInterview(event) {
        var openInterviewLink = null;
        if (this.state.selectedinterview === "") {
            displayWarningMessage('Please select interview type!', "top-center");
            return;
        }

        if (this.state.interviewLevel === "OtherInterview") {
            if (this.state.otherInterviewLevel === "") {
                displayWarningMessage('Please enter interview level!', "top-center");
                return;
            }
        }
        if (this.state.tags.length < 1) {
            displayWarningMessage('Please enter interview tag!', "top-center");
            return;
        }


        if ((this.state.scheduleddate.getMonth() == new Date().getMonth() && this.state.scheduleddate.getFullYear() == new Date().getFullYear()) && this.state.scheduleddate.getDate() == new Date().getDate()) {
            if (this.state.hourvalue < this.state.currentHour) {
                if (this.state.minutevalue < this.state.currentMinute) {
                    console.log("this.state.minutevalue < this.state.currentMinute", this.state.minutevalue, this.state.currentMinute);
                    displayWarningMessage("Please select hours and minutes greater than now !", "top-center");
                    return;
                }
            }
        }
        if (this.state.selectedinterview === "") {
            displayWarningMessage('Please select the Interview type and fill all required fields!', "top-center");


            return;
        }
        if (this.state.selectedinterview === 1 || this.state.selectedinterview === 3) {
            if (this.state.addedapplicants.length <= 0) {
                displayWarningMessage('Please add applicants!', "top-center");

                return;
            }

        }
        if (this.state.selectedinterview === 1) {
            if (this.state.selectedInterviewReviewersClose.length <= 0) {
                displayWarningMessage('Please add Interview Reviewers!', "top-center");


                return;
            }

        }
        if (this.state.selectedinterview === 2) {
            if (this.state.selectedInterviewReviewersOpen.length <= 0) {
                displayWarningMessage('Please add Interview Reviewers!', "top-center");


                return;
            }

        }

        //if (this.state.extensiondate <= this.state.scheduleddate) {
        //    displayWarningMessage('Extension date should be greater than expiry date.', "top-center");
        //    return;
        //}
        const extensionDate = new Date(this.state.extensiondate);
        extensionDate.setHours(0, 0, 0, 0);

        const scheduledDate = new Date(this.state.scheduleddate);
        scheduledDate.setHours(0, 0, 0, 0);

        if (extensionDate <= scheduledDate) {
            displayWarningMessage(
                "Extension date should be greater than due date.",
                "top-center"
            );
            return;
        }

        if (this.state.selectedinterview === 3) {
            if (this.state.selectedInterviewReviewersLive.length <= 0) {
                displayWarningMessage('Please add Interview Reviewers!', "top-center");


                return;
            }
            let addedapplicants = this.state.addedapplicants;
            let i = 0;
            let hostcounter = 0;
            let applicantcounter = 0;
            for (i = 0; i < addedapplicants.length; i++) {
                if (addedapplicants[i].isHost) {
                    hostcounter++;
                }
                if (!addedapplicants[i].isHost) {
                    applicantcounter++;
                }

            }
            if (hostcounter == 0) {
                displayWarningMessage('Please add a Host!', "top-center");


                return;
            }
            if (applicantcounter == 0) {
                displayWarningMessage('Please add a applicant!', "top-center");


                return;
            }

        }

        var taglist = "";
        for (var j = 0; j < this.state.tags.length; j++) {
            if (taglist === "") {
                taglist = this.state.tags[j];
            }
            else {
                taglist = taglist + "," + this.state.tags[j];
            }
        }

        let interviewobject = {
            interview:
            {
                jobId: this.state.selectedjob.value,
                jobName: this.state.selectedjob.label,
                interviewType: this.state.selectedinterview === 1 && "oneway" || this.state.selectedinterview === 2 && "onewayopen" || this.state.selectedinterview === 3 && "live",
                timeZoneName: this.state.selectedTimeZone,
                openInterviewDeadLineType: this.state.openTab,
                relitiveDeadLineNoOfDays: this.state.openTab === "Relative" ? this.state.selecteddays : null,
                expiryDate: this.state.scheduleddate,
                expiryTime: this.state.hourvalue + ":" + this.state.minutevalue,
                extensiondate: this.state.extensiondate,
                InterviewLevel: this.state.interviewLevel === "OtherInterview" ? this.state.otherInterviewLevel : this.state.interviewLevel,
                TagsCSV: taglist,

            },
            questions: [],

            invites: [],
            CustomMessageBody: this.state.customMessageBody,
            ReviewerUsernames: []
        }
        if (this.state.messageBodyRadio === "yes") {
            if (this.state.emailTemplateSelected === "") {
                displayWarningMessage('Please select the Template!', "top-center");

                return;
            }
            interviewobject.interview.emailTemplateId = this.state.emailTemplateSelected.toString();

        }
        else {
            interviewobject.interview.emailTemplateId = "1";

        }

        if (this.state.selectedinterview === 1) {
            let check = 0;
            if (this.state.selectedquestionsforoneway.questions.length <= 0) {
                displayWarningMessage('Please select the questions first!', "top-center");


                return;
            }
            this.state.selectedquestionsforoneway.questions.forEach(
                x => {
                    if (x.thinkTime <= -1) {
                        //think time can be zero
                        displayWarningMessage('Think time can not be negitive!', "top-center");
                        check++;
                    }
                    else if (x.numberOfAttempts <= -1) {
                        displayWarningMessage('Number of attempts can not be negitive!', "top-center");
                        check++;
                    }
                    //else if (x.marks <= 0) {
                    //    displayWarningMessage('Marks Cannot be less than or equal to 0!', "top-center");
                    //    check++;
                    //}
                    else if (x.timeLimit < 30) {
                        displayWarningMessage('Time limit can not be less than 30!', "top-center");
                        check++;
                    }
                    else if (x.thinkTime % 1 !== 0) {
                        displayWarningMessage('Think Time can not be float!', "top-center");
                        check++;
                    }
                    else if (x.numberOfAttempts % 1 !== 0) {
                        displayWarningMessage('Number of attempts has invalid format', "top-center");
                        check++;
                    }
                    //else if (x.marks % 1 !== 0) {
                    //    displayWarningMessage('Marks Cannot be float!', "top-center");
                    //    check++;
                    //}
                    else if (x.timeLimit % 1 !== 0) {
                        displayWarningMessage('Time limit has invalid format', "top-center");
                        check++;
                    }
                    else {
                        interviewobject.questions.push({
                            numberOfAttempts: x.numberOfAttempts,
                            thinkTime: x.thinkTime,
                            //marks: x.marks,
                            questionId: x.questionId,
                            timeLimit: x.timeLimit,
                            PhoneNumber: x.PhoneNumber,
                            interviewQuestionId: 0,
                            interviewId: 0,
                        })
                    }
                }

            )
            if (check > 0) {
                return;
            }


            interviewobject.ReviewerUsernames = this.state.selectedInterviewReviewersClose;
        }
        if (this.state.selectedinterview === 2) {
            let check = 0;
            if (this.state.selectedquestionsforopenoneway.questions.length <= 0) {
                displayWarningMessage('Please select the questions first!', "top-center");


                return;
            }
            this.state.selectedquestionsforopenoneway.questions.forEach(
                x => {
                    if (x.thinkTime <= -1) {
                        //think time can be zero
                        displayWarningMessage('Think time can not be negitive!', "top-center");
                        check++;
                    }
                    else if (x.numberOfAttempts <= -1) {
                        displayWarningMessage('Number of attempts can not be negitive!', "top-center");
                        check++;
                    }
                    //else if (x.marks <= 0) {
                    //    displayWarningMessage('Marks Cannot be less than or equal to 0!', "top-center");
                    //    check++;
                    //}
                    else if (x.timeLimit < 30) {
                        displayWarningMessage('Time Limit Cannot be less than 30!', "top-center");
                        check++;
                    }
                    else if (x.thinkTime % 1 !== 0) {
                        displayWarningMessage('Think Time Cannot be float!', "top-center");
                        check++;
                    }
                    else if (x.numberOfAttempts % 1 !== 0) {
                        displayWarningMessage('Number of attempts Cannot be float!', "top-center");
                        check++;
                    }
                    //else if (x.marks % 1 !== 0) {
                    //    displayWarningMessage('Marks Cannot be float!', "top-center");
                    //    check++;
                    //}
                    else if (x.timeLimit % 1 !== 0) {
                        displayWarningMessage('Time Limit Cannot be float!', "top-center");
                        check++;
                    }
                    else {
                        interviewobject.questions.push(
                            {
                                numberOfAttempts: x.numberOfAttempts,
                                thinkTime: x.thinkTime,
                                //marks: x.marks,
                                questionId: x.questionId,
                                timeLimit: x.timeLimit,

                                interviewQuestionId: 0,
                                interviewId: 0
                            })
                    }
                }
            )
            if (check > 0) {
                return;
            }

            interviewobject.ReviewerUsernames = this.state.selectedInterviewReviewersOpen;

        }
        if (this.state.selectedinterview === 3) {
            interviewobject.ReviewerUsernames = this.state.selectedInterviewReviewersLive;
            if (this.state.addedliveQuestions.length < 0) {
                displayWarningMessage('Please add atleast 1 question!', "top-center");


                return;
            }

        }

        let dat = null;
        if (this.state.openTab === "Relative") {
            dat = null;
        }
        else {
            dat = this.state.scheduleddate;
        }
        console.log("this.state.addedapplicants", this.state.addedapplicants)
        this.state.addedapplicants.forEach(
            x => interviewobject.invites.push({

                firstName: x.firstname,
                lastName: x.lastname,
                candidateEmail: x.email,
                isHost: x.isHost,
                PhoneNumber: x.PhoneNumber,
                inviteRequestedDate: dat,
                interviewStage: x.interviewStage,
            })
        )



        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });
            interviewobject.interview.createdBy = parseJwt(bearer).sub;
            interviewobject.invites.createdBy = parseJwt(bearer).sub;

            var model = JSON.stringify(interviewobject);

            let url = process.env.REACT_APP_CORE_API + "/api/interview";

            await axios(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    data: model
                }).then(async (response) => {

                    this.setState({ interviewIdReturned: response.data.interviewId })
                })
                .then(async () => {
                    displaySuccessMessage('Interview Created!', "top-center");

                    if (interviewobject.interview.interviewType === "live") {
                        this.subscriptionDataDecrement(this.state.tenantSubscriptionId, "LiveInterview", "decrement");

                        let tempLiveInterviewQuestions = { interviewId: this.state.interviewIdReturned, LiveQuestions: this.state.addedliveQuestions };
                        var model = JSON.stringify(tempLiveInterviewQuestions);

                        let url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewQuestionManagement";

                        await axios(url,
                            {
                                method: 'POST',
                                headers:
                                {
                                    'Authorization': bearer,
                                    'Content-Type': 'application/json'
                                },
                                data: model
                            }).then(async (response) => {
                                console.log("Live interview questions saved");
                            }).catch(err => {
                                console.log(err);
                                displayErrorMessage('Error while saving live interview!', "top-center");
                            })
                    }
                    if (interviewobject.interview.interviewType === "onewayopen") {
                        this.subscriptionDataDecrement(this.state.tenantSubscriptionId, "OneWayOpen", "decrement");
                        openInterviewLink = process.env.REACT_APP_RESOURCE_MANAGEMENT + "/OpenInterview/Index?jobId=" + this.state.selectedjob.value + "&interviewId=" + this.state.interviewIdReturned;
                        this.setState({ OpenInterviewAlert: true, OpenInterviewUrl: openInterviewLink })
                        this.setState({
                            hourvalue: "",
                            minutevalue: "",
                            ampmvalue: "",
                            selectedinterview: "",
                            selectedjob: "",
                            scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
                            addedapplicants: [],
                            selectedquestionsforoneway: { questions: [] },
                            defaultQuestionSetObject: {},
                            interviewStage: "FirstInterview",
                            interviewLevel: "FirstInterview",
                            isDefaultInterviewLevel: 1,
                            defaultQuestionSet: -1,
                            selectedquestionsforopenoneway: { questions: [] }
                        });



                    }
                    else {
                        this.subscriptionDataDecrement(this.state.tenantSubscriptionId, "OneWayClose", "decrement");

                        this.setState({
                            hourvalue: "",
                            minutevalue: "",
                            ampmvalue: "",
                            selectedinterview: "",
                            selectedjob: "",
                            scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
                            addedapplicants: [],
                            defaultQuestionSetObject: {},
                            defaultQuestionSet: -1,
                            selectedquestionsforoneway: { questions: [] },
                            selectedquestionsforopenoneway: { questions: [] },
                            addedliveQuestions: [],
                            interviewStage: "FirstInterview",
                            interviewLevel: "FirstInterview",
                            isDefaultInterviewLevel: 1,
                        })

                    }

                    this.setState({
                        disableSaveBtn: false,
                        successInerview: true,
                        openInterviewLink: openInterviewLink
                    })

                })
                .catch(error => {
                    console.log(error);
                    this.setState({ disableSaveBtn: false });
                    displayErrorMessage(error.response.data, "top-center");

                });
        }


    }



    /*
    *Copy text invitation link
    * */
    invitationLinkCopy() {
        /* Get the text field */
        var copyText = document.getElementById("invitationlink");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        displaySuccessMessage('Link Copied :' + copyText.value, "top-center");

    }
    /*
   *Copy Manual Guide link
   * */
    manualLinkCopy() {
        /* Get the text field */
        var copyText = document.getElementById("manuallink");

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        displaySuccessMessage('Link Copied :' + copyText.value, "top-center");

    }
    handleDelete = (index) => {
        // Create a copy of the current state
        const updatedQuestions = [...this.state.selectedquestionsforopenoneway.questions];

        // Remove the selected question
        updatedQuestions.splice(index, 1);

        // Update the state with the modified questions array
        this.setState({
            selectedquestionsforopenoneway: {
                ...this.state.selectedquestionsforopenoneway,
                questions: updatedQuestions,
            },
        });
    };
    handleDeleteClick = (index) => {
        const updatedQuestions = [...this.state.selectedquestionsforoneway.questions];
        // const q_id = this.state.selectedquestionsforoneway.questions[index].questionId;
        // console.log(this.state.questionsset, "fff")
        // for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
        //     if (row.questionId == this.state.questionsset[i].questions[j].questionId) {
        //         questionssetprototype[i].questions[j].isChecked = true;
        //         break;
        //     }
        // }
        updatedQuestions.splice(index, 1);

        this.setState({
            selectedquestionsforoneway: {
                ...this.state.selectedquestionsforoneway,
                questions: updatedQuestions,
            }
        });
    }
    handleDeleteClick1 = (index) => {
        const updatedQuestions = [...this.state.selectedquestionsforopenoneway.questions];
        updatedQuestions.splice(index, 1);

        this.setState({
            selectedquestionsforopenoneway: {
                ...this.state.selectedquestionsforopenoneway,
                questions: updatedQuestions,
            }
        });
    }
    onChange = (date) => {
        // Update state when the date is changed
        this.setState({ date });
    };
    handleInputChange = (e) => {
        var inputtext = e.target.value;
        if (inputtext.includes(',')) {
            inputtext = inputtext.replace(',', ''); // Remove commas
        }
        this.setState({ interviewTag: inputtext });
    };

    handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.interviewTag.trim() !== '') {
            this.addTag();
        }
    };

    handleInputBlur = () => {
        if (this.state.interviewTag.trim() !== '') {
            this.addTag();
        }
    };

    handleTagRemove = (tagToRemove) => {
        this.setState((prevState) => ({
            tags: prevState.tags.filter((tag) => tag !== tagToRemove)
        }));
    };

    addTag = () => {
        if (this.state.tags.length < 3) {
            let tagsList = [];
            tagsList = this.state.tags;
            var tagValue = this.state.interviewTag.trim();
            var isTagExist = 0;
            for (var i = 0; i < tagsList.length; i++) {
                if (tagsList[i] === tagValue) {
                    this.setState({
                        interviewTag: ''
                    });
                    isTagExist = 1;
                    break;
                }
            }
            if (isTagExist === 0) {
                this.setState((prevState) => ({
                    tags: [...prevState.tags, prevState.interviewTag.trim()],
                    interviewTag: ''
                }));
            }
        }
        else {
            this.setState((prevState) => ({
                interviewTag: ''
            }));
            displayWarningMessage(
                "Maximum 3 tags can add for an interview!",
                "top-center"
            );
        }
    };

    async isExistActiveInvite(email) {
        let bearer = validateAndGetFormatedToken();
        if (bearer) {
            let url = `${process.env.REACT_APP_CORE_API}/api/ApplicantActiveInvite`;
            let applicantInvite = {
                jobId: this.state.selectedJobId,
                email: email,
            };
            let model = JSON.stringify(applicantInvite);
            try {
                let response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Authorization": bearer,
                        "Content-Type": "application/json",
                    },
                    body: model,
                });
                // Wait for the response and convert to JSON
                let result = await response.json();
                // Return true if active invite exists, else return false
                return result.length > 0;
            } catch (error) {
                console.log("Error:", error);
                return false; // On error, assume no active invite
            }
        } else {
            // Return false if bearer token is invalid
            return false;
        }
    }

    // Function to bind Excel data to the list of applicants
    async bindExcelData(rows) {
        // Clone the existing list of added applicants from state
        let applicants = [...this.state.addedapplicants];
        // Variables to store lists of duplicate emails and emails with active invites
        let duplicateEmailList = "";
        let hasActiveInviteEmailList = "";
        let isValidData = true; // Flag to track whether the data is valid
        // Regular expressions for email and phone number validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?\d{10,16}$/; // Example: A simple regex for 10-digit phone numbers
        // Iterate over each row starting from the second row (assuming the first row is headers)
        for (let row of rows.slice(1)) {
            // Extract first name, last name, email, and phone number from each row
            const firstname = row[0] || ""; // First Name in Column A
            const lastname = row[1] || "";  // Last Name in Column B
            const email = row[2] || "";     // Email in Column C
            const phoneNumber = row[3] || ""; // Phone Number in Column D

            // Check for empty fields, invalid email format, or invalid phone number format
            if (!firstname || !lastname || !email || !emailRegex.test(email) || !phoneNumber || !phoneRegex.test(phoneNumber)) {
                isValidData = false; // Set invalid data flag
                break; // Exit loop if validation fails for this row
            }
            // Check if the email is already in the applicants list
            const isDuplicate = applicants.some(applicant => applicant.email === email);
            if (!isDuplicate && email) {
                const hasActiveInvite = await this.isExistActiveInvite(email); // Check for active invite
                // If there's no active invite, add the applicant to the list
                if (!hasActiveInvite) {
                    const applicant = {
                        firstname: row[0] || "", // First Name in Column A
                        lastname: row[1] || "",  // Last Name in Column B
                        email: email,            // Email in Column C
                        PhoneNumber: row[3] || "", // Phone Number in Column D
                        
                        interviewStage: "FirstInterview",
                        isHost: false,
                    };
                    applicants.push(applicant); // Add new applicant
                }
                else {
                    // If the applicant has an active invite, add their email to the list
                    if (hasActiveInviteEmailList === "") {
                        hasActiveInviteEmailList = email;
                    }
                    else {
                        hasActiveInviteEmailList += ", " + email;
                    }
                }
            }
            else {
                // If the email is a duplicate, add it to the duplicate email list
                if (duplicateEmailList === "") {
                    duplicateEmailList = email;
                }
                else {
                    duplicateEmailList += ", " + email;
                }
            }
        }
        // After processing all rows, check if the data is valid
        if (isValidData) {
            // Update the state with the new list of applicants
            this.setState({
                addedapplicants: applicants,
                isapplicantNotAdded: false,
            });
            // Show appropriate success or warning messages based on the duplicate and active invite lists
            if (duplicateEmailList === "" && hasActiveInviteEmailList === "") {
                displaySuccessMessage('Applicant(s)  successfully added', 'top-center');
            }
            else if (duplicateEmailList !== "" && hasActiveInviteEmailList === "") {
                displayWarningMessage('Applicant(s) already added in the interview applicant list. ' + duplicateEmailList, 'top-center');
            }
            else if (duplicateEmailList === "" && hasActiveInviteEmailList !== "") {
                displayWarningMessage('Applicant already has an active interview invite for the current job. ' + hasActiveInviteEmailList, 'top-center');
            }
            else if (duplicateEmailList !== "" && hasActiveInviteEmailList !== "") {
                let message = "Applicant(s) already have an active interview invite. " + hasActiveInviteEmailList + " Applicant(s) already added in the interview invite list. " + duplicateEmailList;
                displayWarningMessage(message, 'top-center');
            }
        }
        else {
            // If data is invalid, show a failure message
            displayWarningMessage("File upload fail, file format and data should be as per the sample excel sheet", 'top-center');
        }
    }

    // For binding shortlisted applicants
    async bindShortlistedApplicants(row, isSelected, email) {
        let errCounter = 0;  // Counter to track validation errors
        let applicants = [...this.state.addedapplicants];  // Get the current list of added applicants
        const hasActiveInvite = await this.isExistActiveInvite(email);  // Check if the applicant already has an active interview invite

        // If the applicant does not have an active invite
        if (!hasActiveInvite) {
            // Create an applicant object with first name, last name, and email from the row
            const applicant = {
                firstname: row.firstName,
                lastname: row.lastName,
                email: row.email
            }

            let tempAllReviewers = this.state.allInterviewReviewers;
            let tmpCount = 0;
            for (let j = 0; j < tempAllReviewers.length; j++) {
                if (applicant.email == tempAllReviewers[j]) {
                    tmpCount++;
                }

            }
            if (tmpCount > 0) {
                errCounter = 1;
            }
            tmpCount = 0;
            for (let j = 0; j < applicants.length; j++) {
                if (applicant.email == applicants[j].email) {
                    tmpCount++;
                }

            }
            if (tmpCount > 0) {
                errCounter = 2;
            }
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let result = re.test(String(applicant.email).toLowerCase());
            if (!result) {
                errCounter = 3;
            }
            if (applicant.firstname === "" || applicant.lastname === "") {
                errCounter = 4;

            }

            if (errCounter == 0) {
                applicants.push(applicant);
                displaySuccessMessage('applicant Selected Successfully', "top-center");
            }
            else {
                if (errCounter == 1) {
                    displayWarningMessage('applicant Email cannot contain Reviewer Email!' + applicant.email, "top-center");
                    return;

                }
                if (errCounter == 2) {
                    displayWarningMessage('Duplicate Email Found!' + applicant.email, "top-center");
                    return;

                }
                if (errCounter == 3) {
                    displayWarningMessage('Email is not valid' + applicant.email, "top-center");
                    return;

                }
                if (errCounter == 4) {
                    displayWarningMessage('First name, last name not found' + applicant.email, "top-center");
                    return;
                }


            }
            let shortlistedApplicants = this.state.shortlistedApplicants;

            for (let j = 0; j < shortlistedApplicants.length; j++) {
                if (email == shortlistedApplicants[j].email) {
                    shortlistedApplicants[j].isChecked = true;
                    break;
                }

            }

            this.setState({
                addedapplicants: applicants,
                firstname: "",
                lastname: "",
                email: "",
                shortlistedApplicants: shortlistedApplicants
            });
        }
        else {
            // If the applicant already has an active invite, display a warning message
            displayWarningMessage('Applicant already have an active interview invite ' , 'top-center');
            return false;
        }
        return true;  // Return true indicating the applicant was successfully bound
    }

    render() {

        const isSelectedInterviewThree = this.state.selectedinterview === 3;

        const mergeQuestions = (questionsDefault, questionsChecked) => {
            let mergedQuestionsMap = new Map();

            questionsDefault.forEach(question => {
                mergedQuestionsMap.set(question.questionId, question
                )
            })
            questionsChecked.forEach(question => {
                if (mergedQuestionsMap.has(question.questionId)) {
                    const existingQuestion = mergedQuestionsMap.get(question.questionId)
                    mergedQuestionsMap.set(question.questionId, { ...existingQuestion, question })
                } else {
                    mergedQuestionsMap.set(question.questionId, question)
                }
            })

            // Convert the map back to an array
            return Array.from(mergedQuestionsMap.values());
        }
        //For Selecting questionsforonewayclose
        const onRowSelectforquestionsforonewaySubmit = () => {
            if (this.state.selectedquestionsforonewayChecked.length === 0) {
                displayWarningMessage(
                    "Please select a question.",
                    "top-center"
                );
                return;
            }
            let selectedsets = this.state.selectedquestionsforoneway
            let selectedquests = selectedsets.questions;

            

            const mergedQuestionsList = mergeQuestions(selectedquests, this.state.selectedquestionsforonewayChecked)
            selectedsets.questions = mergedQuestionsList;

            this.setState({ selectedquestionsforoneway: selectedsets });
            this.setState({
                questionDetailsModal: false,
            });
            displaySuccessMessage('Question Added!', "top-center");
        }
        const onRowSelectforquestionsforopenonewaySubmit = () => {
            if (this.state.selectedquestionsforopenonewayChecked.length === 0) {
                displayWarningMessage(
                    "Please select a question.",
                    "top-center"
                );
                return;
            }

            let selectedsets = this.state.selectedquestionsforopenoneway;
            let selectedquests = selectedsets.questions;
           
           
            const mergedArray = mergeQuestions(selectedquests, this.state.selectedquestionsforopenonewayChecked);        
            selectedsets.questions = mergedArray;
            this.setState({ selectedquestionsforopenoneway: selectedsets });
            this.setState({
                questionDetailsModal: false,
            });
            displaySuccessMessage('Question Added!', "top-center");
        }

        const onRowSelectforquestionsforoneway = (row, isSelected, questionSetId) => {
            if (isSelected.target.checked) {
                let selectedquests = this.state.selectedquestionsforonewayChecked;

                for (let i = 0; i < selectedquests.length; i++) {
                    if (row.questionId === selectedquests[i].questionId) {
                        //displayWarningMessage('Question already in the list!', "top-center");

                        return;
                    }
                }
                selectedquests.push(row);

                this.setState({ selectedquestionsforonewayChecked: selectedquests });
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            if (row.questionId == this.state.questionsset[i].questions[j].questionId) {
                                questionssetprototype[i].questions[j].isChecked = true;
                                break;
                            }
                        }


                    }
                }

                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: row.questionId === item.questionId ? true : item.isChecked })) } })


            }
            else {
                let selectedquests = this.state.selectedquestionsforonewayChecked;

                for (let i = 0; i < selectedquests.length; i++) {
                    if (row.questionId === selectedquests[i].questionId) {

                        selectedquests.splice(i, 1);

                        this.setState({ selectedquestionsforonewayChecked: selectedquests });
                        // return;
                    }
                }

                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            if (row.questionId == this.state.questionsset[i].questions[j].questionId) {
                                questionssetprototype[i].questions[j].isChecked = false;
                                break;
                            }
                        }
                    }
                }
                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: row.questionId === item.questionId ? false : item.isChecked })) } })

            }
        };

        const onSelectAllforquestionsforoneway = (isSelected, questionSetId) => {
            if (isSelected.target.checked) {
                let selectedquests = this.state.selectedquestionsforonewayChecked;
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            var alreadySelected = 0;
                            for (let k = 0; k < selectedquests.length; k++) {
                                if (selectedquests[k].questionId == questionssetprototype[i].questions[j].questionId) {
                                    alreadySelected = 1;
                                }
                            }

                            if (alreadySelected == 0) {
                                selectedquests.push(questionssetprototype[i].questions[j]);
                                questionssetprototype[i].questions[j].isChecked = true;
                            }
                        }
                    }
                }
                this.setState({ selectedquestionsforonewayChecked: selectedquests, questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: true })) } })

            }
            else {
                let selectedquests = this.state.selectedquestionsforonewayChecked;

                for (let i = 0; i < selectedquests.length; i++) {
                    selectedquests.splice(i, 1);
                }
                this.setState({ selectedquestionsforonewayChecked: [] });
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            questionssetprototype[i].questions[j].isChecked = false;

                        }
                    }
                }
                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: false })) } })


            }

        };

        //For Selecting questionsforopenoneway


        const onRowSelectforquestionsforopenoneway = (row, isSelected, questionSetId) => {

            if (isSelected.target.checked) {
                let selectedquests = this.state.selectedquestionsforopenonewayChecked;


                for (let i = 0; i < selectedquests.length; i++) {
                    if (row.questionId === selectedquests[i].questionId) {
                        //displayWarningMessage('Question already in the list!', "top-center");


                        return;
                    }
                }
                selectedquests.push(row);

                this.setState({ selectedquestionsforopenonewayChecked: selectedquests });
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            if (row.questionId == this.state.questionsset[i].questions[j].questionId) {
                                questionssetprototype[i].questions[j].isChecked = true;
                                break;
                            }
                        }
                    }
                }
                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: row.questionId === item.questionId ? true : item.isChecked })) } })

            }
            else {
                let selectedquests = this.state.selectedquestionsforopenonewayChecked;


                for (let i = 0; i < selectedquests.length; i++) {
                    if (row.questionId === selectedquests[i].questionId) {

                        selectedquests.splice(i, 1);

                        this.setState({ selectedquestionsforopenonewayChecked: selectedquests })
                        // return;
                    }
                }
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            if (row.questionId == this.state.questionsset[i].questions[j].questionId) {
                                questionssetprototype[i].questions[j].isChecked = false;
                                break;
                            }
                        }
                    }
                }
                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: row.questionId === item.questionId ? false : item.isChecked })) } })


            }
        };

        const onSelectAllforquestionsforopenoneway = (isSelected, questionSetId) => {
            if (isSelected.target.checked) {
                let selectedquests = this.state.selectedquestionsforopenonewayChecked;
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            var alreadySelected = 0;
                            for (let k = 0; k < selectedquests.length; k++) {
                                if (selectedquests[k].questionId == questionssetprototype[i].questions[j].questionId) {
                                    alreadySelected = 1;
                                }
                            }

                            if (alreadySelected == 0) {
                                selectedquests.push(questionssetprototype[i].questions[j]);
                                questionssetprototype[i].questions[j].isChecked = true;
                            }
                        }
                    }
                }

                this.setState({ selectedquestionsforopenonewayChecked: selectedquests, questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: true })) } })

            }
            else {
                let selectedquests = this.state.selectedquestionsforopenonewayChecked;
                for (let i = 0; i < selectedquests.length; i++) {

                    selectedquests.splice(i, 1);


                }
                this.setState({ selectedquestionsforopenonewayChecked: [] });
                let questionssetprototype = this.state.questionsset;
                for (let i = 0; i < this.state.questionsset.length; i++) {
                    if (this.state.questionsset[i].questionSetId == questionSetId) {
                        for (let j = 0; j < this.state.questionsset[i].questions.length; j++) {
                            questionssetprototype[i].questions[j].isChecked = false;

                        }
                    }
                }
                this.setState({ questionsset: questionssetprototype, questionsForQuestionDetailsModal: { ...this.state.questionsForQuestionDetailsModal, questions: this.state.questionsForQuestionDetailsModal.questions.map(item => ({ ...item, isChecked: false })) } })


            }

        }

        //For Selecting Interview reviewers

        const onRowSelectforinterviewreviewersonewayclose = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedInterviewReviewersClose;
                selectedsets.push(row);

                this.setState({ selectedInterviewReviewersClose: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedInterviewReviewersClose;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.reviewerId === selectedsets[i].reviewerId) {


                        selectedsets.splice(i, 1);

                        this.setState({ selectedInterviewReviewersClose: selectedsets })
                        return;
                    }
                }

            }

        }

        const onRowSelectforinterviewreviewersonewayopen = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedInterviewReviewersOpen;
                selectedsets.push(row);

                this.setState({ selectedInterviewReviewersOpen: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedInterviewReviewersOpen;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.reviewerId === selectedsets[i].reviewerId) {
                        selectedsets.splice(i, 1);
                        this.setState({ selectedInterviewReviewersOpen: selectedsets })
                        return;
                    }
                }

            }


        }
        const onRowSelectforinterviewreviewerslive = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedInterviewReviewersLive;
                selectedsets.push(row);
                this.setState({ selectedInterviewReviewersLive: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedInterviewReviewersLive;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.reviewerId === selectedsets[i].reviewerId) {


                        selectedsets.splice(i, 1);

                        this.setState({ selectedInterviewReviewersLive: selectedsets })
                        return;
                    }
                }

            }

        }

        //For Selecting Shortlisted Applicants
        const onRowSelectForShortlistedApplicants = async (row, isSelected, email) => {
            // Check if the checkbox for the row is selected
            if (isSelected.target.checked) {
                // Copy the existing applicants list from the state
                let applicants = [...this.state.addedapplicants];
                // Check if the email is already in the applicants list
                const isDuplicate = applicants.some(applicant => applicant.email === email);
                // If the email is not a duplicate and email is not empty
                if (!isDuplicate && email) {
                    var isDataBind = await this.bindShortlistedApplicants(row, isSelected, email);
                    // If data binding fails, uncheck the checkbox
                    if (!isDataBind) {
                        isSelected.target.checked = false;
                    }
                }
                else { // If the email is already in the list or is empty
                    isSelected.target.checked = false;
                    displayWarningMessage('Applicant(s) already added in the interview applicant list. ' + email, 'top-center');
                }
            }
        }


        const excelReader = () => {
            const fileInput = document.getElementById('excelReader');
            const [file] = fileInput.files;

            // Check if a file is selected
            if (!file) {
                // Display a message to choose a file
                displayWarningMessage('Please choose a file!', 'top-center');
                return;
            }
            // Read the selected Excel file using the readXlsxFile library
            readXlsxFile(file).then((rows) => {
                // If the file contains more than one row (i.e., it's not empty)
                if (rows.length > 1) {
                    // Bind the Excel data to your component by passing the rows to a function
                    this.bindExcelData(rows);
                }
                else {
                    // If the file is empty or has only one row, display a warning message
                    displayWarningMessage('Unable to add applicant(s), uploaded file was empty', 'top-center');
                }
            });
        
            this.setState({
                addapplicantThroughExcelModal: false,
            });
            document.body.classList.remove('modal-open');

        };

        return (
            <>
                <main id="content" role="main"
                    // className="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                >
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">

                                    <h1 className="page-header-title">Create interview</h1>
                                </div>
                                {/* <!-- End Col --> */}

                            </div>
                            {/* <!-- End Row --> */}
                        </div>
                        {/* <!-- End Page Header --> */}
                        {this.state.isJobCreated && this.state.alljobs.length <= 0 ?
                            <div className="card card-centered mb-3 mb-lg-5">
                                <div className="card-body py-10">
                                    <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                    <div className="text-center">

                                        <h1>You have not created any Job.</h1>
                                        <Link className="btn btn-primary" to="/createjob" style={this.state.sfc_AllowedJobsCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}><i className="bi bi-plus-lg me-1"></i>Create Job!</Link>
                                    </div>

                                </div>
                            </div>
                            : <>
                                <div className="row">
                                    {this.state.successInerview ?
                                        <div id="validationFormSuccessMessage" className="js-success-message">
                                            <div className="text-center">
                                                {(this.state.openInterviewLink != null && this.state.openInterviewLink != "") && <div className="alert alert-success" role="alert">

                                                    <h3>Interview Link</h3>
                                                    <div className="input-group flex-nowrap">
                                                        <input type="text" className="form-control" value={this.state.openInterviewLink} id="invitationlink" readOnly />
                                                        <i className="bi bi-clipboard input-group-text" style={{ cursor: 'pointer' }} onClick={this.invitationLinkCopy}></i>
                                                    </div>
                                                    <hr></hr>
                                                    <h3>Step by Step Guide to Interview Link</h3>
                                                    <div className="input-group flex-nowrap">
                                                        <input type="text" className="form-control" value={this.state.manualGuideLink} id="manuallink" readOnly />
                                                        <i className="bi bi-clipboard input-group-text" style={{ cursor: 'pointer' }} onClick={this.manualLinkCopy}></i>
                                                    </div>

                                                </div>}

                                                <img className="img-fluid mb-3" src={SuccessInterview} alt="Image Description" style={{ maxWidth: '15rem' }} />
                                                <div className="mb-4">

                                                    <h2>Successful!</h2>
                                                    <p>Your Interview have been successfully saved.</p>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button className="btn btn-white me-3" href="#" onClick={() => { window.location.reload(true); }}>Back to Create Interview</button>

                                                </div>
                                            </div>
                                        </div> :
                                        <div className="col-lg-12">
                                            <div className="d-grid gap-3 gap-lg-5 mb-4">
                                                {/* <!-- Job section --> */}
                                                {/* <!-- Card --> */}
                                                <div id="jobSection" className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Job</h4>
                                                    </div>
                                                    {/* <!-- Body --> */}
                                                    <div className="card-body">
                                                        <div className="row mb-4">
                                                            <label for="jobs" className="col-auto col-form-label form-label"><strong> Select Job<span className="form-label-secondary text-danger"> * </span>  </strong></label>
                                                            <div className="col-11">
                                                                {/* <!-- Select --> */}
                                                                <div className="tom-select-custom">
                                                                    {/*<Select*/}
                                                                    {/*    id="selectjobs"*/}
                                                                    {/*    name="jobs"*/}
                                                                    {/*    defaultValue={this.state.selectedjob.value}*/}
                                                                    {/*    options={this.state.alljobs}*/}
                                                                    {/*    onChange={this.handleChange}*/}
                                                                    {/*/>*/}
                                                                    <ReactSelect
                                                                        options={this.state.alljobs}
                                                                        value={this.state.alljobs.find(option => option.value === this.state.selectedJobId)}
                                                                        onChange={this.handleChange}
                                                                        placeholder="Select job..."
                                                                        isSearchable={true}
                                                                    />

                                                                </div>
                                                                {/* <!-- End Select --> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- End Body --> */}
                                                </div>
                                                {/* <!-- End Card --> */}
                                                {/* <!-- End Job section --> */}
                                                {/* <!-- Interview type section --> */}
                                                {/* <!-- Card --> */}
                                                {this.state.selectedjob != "" && <>
                                                    <div id="interviewTypeSection" className="card">
                                                        <div className="card-header mand">
                                                            <h4 className="card-title">Interview type</h4><span className="form-label-secondary text-danger"> *</span>
                                                        </div>
                                                        {/* <!-- Body --> */}
                                                        <div className="card-body">
                                                            <div className="row mb-4">

                                                                <div className="row">

                                                                    <div className="col-md mb-3">
                                                                        {/* <!-- Card --> */}
                                                                        <div className={"card h-100 card-interview border-swift-x1 " + (this.state.selectedinterview == 1
                                                                            ? "bg-success border-success"
                                                                            : "")}>
                                                                            <div className="card-header ">



                                                                                <h2 className="card-title h-6 text-dark">One Way Close</h2>
                                                                                <p className="card-text">Close Invitation</p>
                                                                            </div>

                                                                            <div className="card-body d-flex">
                                                                                {/* <!-- List Checked --> */}
                                                                                <ul className="list-checked list-checked-primary mb-0">
                                                                                    <li className="list-checked-item">Up to 25 applicants</li>
                                                                                    <li className="list-checked-item">No host</li>
                                                                                    <li className="list-checked-item">One-Way</li>
                                                                                    <li className="list-checked-item">Video recording</li>
                                                                                </ul>
                                                                                {/* <!-- End List Checked --> */}
                                                                            </div>

                                                                            <div className="card-footer border-0 text-center">
                                                                                <div className="d-grid mb-2">

                                                                                    <button type="button" className="form-check-select-stretched-btn btn btn-primary btn-p-color"
                                                                                        style={this.state.sfc_AllowedOneWayCloseCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                                                                                        onClick={async () => {
                                                                                            if (this.state.selectedinterview !== "") {
                                                                                                this.setState({
                                                                                                    selectedinterview: 1,
                                                                                                    interviewIdReturned: "",
                                                                                                    OpenInterviewAlert: false,
                                                                                                    OpenInterviewUrl: "",
                                                                                                    scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
                                                                                                    extensiondate: new Date(new Date().setDate(new Date().getDate() + 14)),
                                                                                                    firstname: "",
                                                                                                    lastname: "",
                                                                                                    email: "",
                                                                                                    PhoneNumber: "",
                                                                                                    addedapplicants: [],
                                                                                                    emailTemplateSelected: "",
                                                                                                    messageBodyRadio: "no",
                                                                                                    customMessageBody: "",
                                                                                                    selectedtab: "absolutedeadline",
                                                                                                    openTab: "Absolute",
                                                                                                    expandTableRow: false,
                                                                                                    rowId: "",
                                                                                                    selectedInterviewReviewersLive: [],
                                                                                                    selectedTimeZone: this.state.selectedTimeZone1,
                                                                                                    hourvalue: "23",
                                                                                                    minutevalue: "59",
                                                                                                    addedliveQuestions: [],
                                                                                                    interviewLevel: "FirstInterview",
                                                                                                    otherInterviewLevel: "",
                                                                                                    interviewTag: "",
                                                                                                    isDefaultInterviewLevel: 1,

                                                                                                });
                                                                                                if (this.state.jobDefaultQuestionSet == null) {
                                                                                                    this.setState({
                                                                                                        selectedquestionsforoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        },
                                                                                                        selectedquestionsforopenoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        }

                                                                                                    })
                                                                                                }



                                                                                            }
                                                                                            else {
                                                                                                this.setState({

                                                                                                    selectedinterview: 1,
                                                                                                });
                                                                                            }



                                                                                            //    .then(() => {
                                                                                            //    if (this.state.defaultQuestionSet != -1 && this.state.questionsset.length > 0) {
                                                                                            //        onSelectAllforquestionsforoneway(true, this.state.defaultQuestionSetObject.questions, this.state.defaultQuestionSetObject.questionSetId);
                                                                                            //    }
                                                                                            //});

                                                                                            let LevelList = this.state.interviewLevelList;
                                                                                            for (var i = 0; i < LevelList.length; i++) {
                                                                                                if (LevelList[i] === "FirstInterview") {
                                                                                                    displayWarningMessage('Already first interview exist!', "top-center");
                                                                                                    break;
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >Select interview <span class="badge bg-success rounded-pill swift-pills">{this.state.sfc_AllowedOneWayCloseCount}</span></button>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {/* <!-- End Card --> */}
                                                                    </div>

                                                                    {/* <!-- End Col --> */}
                                                                    <div className="col-md mb-3">
                                                                        {/* <!-- Card --> */}
                                                                        <div className={"card h-100 card-interview border-swift-x1 " + (this.state.selectedinterview == 2
                                                                            ? "text-white bg-success border-success"
                                                                            : "")}>
                                                                            <div className="card-header">

                                                                                <h2 className="card-title h-6 text-dark">One Way Open</h2>
                                                                                <p className="card-text">Open Invitation</p>
                                                                            </div>

                                                                            <div className="card-body d-flex ">
                                                                                {/* <!-- List Checked --> */}
                                                                                <ul className="list-checked list-checked-primary mb-0">
                                                                                    <li className="list-checked-item">Unlimited applicants</li>
                                                                                    <li className="list-checked-item">No host</li>
                                                                                    <li className="list-checked-item">One-Way</li>
                                                                                    <li className="list-checked-item">Video recording</li>
                                                                                </ul>
                                                                                {/* <!-- End List Checked --> */}
                                                                            </div>

                                                                            <div className="card-footer border-0 text-center">
                                                                                <div className="d-grid mb-2">
                                                                                    <button type="button" className="form-check-select-stretched-btn btn btn-primary btn-sc-color"
                                                                                        style={this.state.sfc_AllowedOneWayOpenCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                                                                                        onClick={async () => {
                                                                                            if (this.state.selectedinterview !== "") {
                                                                                                this.setState({
                                                                                                    selectedinterview: 2,
                                                                                                    interviewIdReturned: "",
                                                                                                    OpenInterviewAlert: false,
                                                                                                    OpenInterviewUrl: "",
                                                                                                    scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
                                                                                                    extensiondate: new Date(new Date().setDate(new Date().getDate() + 14)),
                                                                                                    firstname: "",
                                                                                                    lastname: "",
                                                                                                    email: "",
                                                                                                    PhoneNumber: "",
                                                                                                    addedapplicants: [], emailTemplateSelected: "",
                                                                                                    messageBodyRadio: "no", customMessageBody: "",
                                                                                                    selectedtab: "absolutedeadline",
                                                                                                    openTab: "Absolute",
                                                                                                    expandTableRow: false,
                                                                                                    rowId: "",
                                                                                                    selectedInterviewReviewersClose: [],
                                                                                                    selectedInterviewReviewersLive: [],
                                                                                                    selectedTimeZone: this.state.selectedTimeZone1,
                                                                                                    hourvalue: "23",
                                                                                                    minutevalue: "59",
                                                                                                    addedliveQuestions: [],
                                                                                                    interviewLevel: "FirstInterview",
                                                                                                    otherInterviewLevel: "",
                                                                                                    interviewTag: "",
                                                                                                    isDefaultInterviewLevel: 1
                                                                                                });

                                                                                                if (this.state.jobDefaultQuestionSet == null) {
                                                                                                    this.setState({
                                                                                                        selectedquestionsforoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        },
                                                                                                        selectedquestionsforopenoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        }

                                                                                                    })
                                                                                                }


                                                                                            }
                                                                                            else {
                                                                                                this.setState({
                                                                                                    selectedinterview: 2,
                                                                                                    OpenInterviewAlert: false,
                                                                                                    OpenInterviewUrl: "",


                                                                                                });

                                                                                            }

                                                                                            //    .then(() => {
                                                                                            //    if (this.state.defaultQuestionSet != -1 && this.state.questionsset.length > 0) {
                                                                                            //        onSelectAllforquestionsforopenoneway(true, this.state.defaultQuestionSetObject.questions, this.state.defaultQuestionSetObject.questionSetId);

                                                                                            //    };
                                                                                            //});


                                                                                            let LevelList = this.state.interviewLevelList;
                                                                                            for (var i = 0; i < LevelList.length; i++) {
                                                                                                if (LevelList[i] === "FirstInterview") {
                                                                                                    displayWarningMessage('Already first interview exist!', "top-center");
                                                                                                    break;
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                    >Select interview <span class="badge bg-success rounded-pill swift-pills">{this.state.sfc_AllowedOneWayOpenCount}</span></button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {/* <!-- End Card --> */}
                                                                    </div>

                                                                    {/* <!-- End Col --> */}
                                                                    <div className="col-md mb-3">
                                                                        {/* <!-- Card --> */}
                                                                        <div className={"card h-100 card-interview border-swift-x1 " + (this.state.selectedinterview == 3
                                                                            ? "text-white bg-success border-success"
                                                                            : "")}>
                                                                            <div className="card-header">


                                                                                <h2 className="card-title h-6 text-dark">Live</h2>
                                                                                <p className="card-text">One on one</p>
                                                                            </div>

                                                                            <div className="card-body d-flex ">
                                                                                {/* <!-- List Checked --> */}
                                                                                <ul className="list-checked list-checked-primary mb-0">
                                                                                    <li className="list-checked-item">Up to 2 hosts</li>
                                                                                    <li className="list-checked-item">Two-Way</li>
                                                                                    <li className="list-checked-item">Minimum 1 guest</li>
                                                                                    <li className="list-checked-item">Video recording</li>
                                                                                </ul>
                                                                                {/* <!-- End List Checked --> */}
                                                                            </div>

                                                                            <div className="card-footer border-0 text-center">
                                                                                <div className="d-grid mb-2">
                                                                                    <button type="button" className="form-check-select-stretched-btn btn btn-primary btn-ex-color"
                                                                                        style={this.state.sfc_AllowedLiveInterviewCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                                                                                        onClick={async () => {
                                                                                            if (this.state.selectedinterview !== "") {
                                                                                                this.setState({
                                                                                                    selectedinterview: 3,
                                                                                                    interviewIdReturned: "",
                                                                                                    OpenInterviewAlert: false,
                                                                                                    OpenInterviewUrl: "",
                                                                                                    scheduleddate: new Date(new Date().setDate(new Date().getDate() + 7)),
                                                                                                    extensiondate: new Date(new Date().setDate(new Date().getDate() + 14)),
                                                                                                    firstname: "",
                                                                                                    lastname: "",
                                                                                                    email: "",
                                                                                                    PhoneNumber: "",
                                                                                                    addedapplicants: [], emailTemplateSelected: "",
                                                                                                    messageBodyRadio: "no", customMessageBody: "",
                                                                                                    selectedtab: "absolutedeadline",
                                                                                                    openTab: "Absolute",
                                                                                                    expandTableRow: false,
                                                                                                    rowId: "",
                                                                                                    selectedInterviewReviewersClose: [],
                                                                                                    interviewLevel: "FirstInterview",
                                                                                                    otherInterviewLevel: "",
                                                                                                    interviewTag: "",
                                                                                                    isDefaultInterviewLevel: 1
                                                                                                });
                                                                                                if (this.state.jobDefaultQuestionSet == null) {
                                                                                                    this.setState({
                                                                                                        selectedquestionsforoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        },
                                                                                                        selectedquestionsforopenoneway: {
                                                                                                            Title: "",
                                                                                                            Description: "",
                                                                                                            questions: []
                                                                                                        }

                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                this.setState({
                                                                                                    selectedinterview: 3,

                                                                                                });
                                                                                            }

                                                                                            let LevelList = this.state.interviewLevelList;
                                                                                            for (var i = 0; i < LevelList.length; i++) {
                                                                                                if (LevelList[i] === "FirstInterview") {
                                                                                                    displayWarningMessage('Already first interview exist!', "top-center");
                                                                                                    break;
                                                                                                }
                                                                                            }

                                                                                        }}>Select interview <span class="badge bg-success rounded-pill swift-pills">{this.state.sfc_AllowedLiveInterviewCount}</span></button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        {/* <!-- End Card --> */}
                                                                    </div>

                                                                    {/* <!-- End Col --> */}


                                                                </div>
                                                                {/* <!-- End Row --> */}
                                                                {/* <!-- End Row --> */}
                                                            </div>
                                                            {/* <!-- Form --> */}

                                                        </div>
                                                        {/* <!-- End Body --> */}
                                                    </div>
                                                    {/* <!-- End Card --> */}
                                                    {/* <!-- End interview type section --> */}
                                                    {/* <!-- Card --> */}
                                                    {/* <!-- Question sets section --> */}
                                                    {this.state.selectedinterview != "" &&
                                                        <div className="card card-centered mb-3 mb-lg-5 card-table-box">
                                                            <div className="card-header p-relative mb-lg-5 mb-4 w-100">
                                                                <div className="mb-4 w-100">
                                                                    <h4 className="card-title">Interview Level</h4>
                                                                    <div className="row">
                                                                        <div className="swft-form-input col-md-2">
                                                                            <div class="form-check mb-2">
                                                                                <div class="swift-wrapper" key="FirstInterview">
                                                                                    <input
                                                                                        onChange={this.selectInterviewLevel}
                                                                                        class="form-check-input"
                                                                                        type="radio"
                                                                                        id="FirstInterview"
                                                                                        name="InterviewLevel"
                                                                                        value="FirstInterview"
                                                                                        checked={this.state.isDefaultInterviewLevel === 1}
                                                                                    />
                                                                                    <label
                                                                                        class="form-check-label"
                                                                                        for="partTime"
                                                                                    >
                                                                                        First
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="swft-form-input col-md-2">
                                                                            <div class="form-check mb-2">
                                                                                <div class="swift-wrapper" key="SecondInterview">
                                                                                    <input
                                                                                        onChange={this.selectInterviewLevel}
                                                                                        class="form-check-input"
                                                                                        type="radio"
                                                                                        id="SecondInterview"
                                                                                        name="InterviewLevel"
                                                                                        value="SecondInterview"
                                                                                        checked={this.state.isDefaultInterviewLevel === 2}
                                                                                    />
                                                                                    <label
                                                                                        class="form-check-label"
                                                                                        for="partTime"
                                                                                    >
                                                                                        Second
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="swft-form-input col-md-2">
                                                                            <div class="form-check mb-2">
                                                                                <div class="swift-wrapper" key="FinalInterview">
                                                                                    <input
                                                                                        onChange={this.selectInterviewLevel}
                                                                                        class="form-check-input"
                                                                                        type="radio"
                                                                                        id="FinalInterview"
                                                                                        name="InterviewLevel"
                                                                                        value="FinalInterview"
                                                                                        checked={this.state.isDefaultInterviewLevel === 3}
                                                                                    />
                                                                                    <label
                                                                                        class="form-check-label"
                                                                                        for="partTime"
                                                                                    >
                                                                                        Final
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="swft-form-input col-md-2">
                                                                            <div class="form-check mb-2">
                                                                                <div class="swift-wrapper" key="OtherInterview">
                                                                                    <input
                                                                                        onChange={this.selectInterviewLevel}
                                                                                        class="form-check-input"
                                                                                        type="radio"
                                                                                        id="OtherInterview"
                                                                                        name="InterviewLevel"
                                                                                        value="OtherInterview"
                                                                                        checked={this.state.isDefaultInterviewLevel === 4}
                                                                                    />
                                                                                    <label
                                                                                        class="form-check-label"
                                                                                        for="partTime"
                                                                                    >
                                                                                        Other
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.interviewLevel == "OtherInterview" &&
                                                                        <div className="col mt-3 mb-4 w-75">

                                                                            <input type="text" className="form-control" placeholder="Interview Level *"
                                                                                id="OtherInterviewLevel"
                                                                                maxLength="20"
                                                                                value={this.state.otherInterviewLevel}
                                                                                onChange={(e) => { this.setState({ otherInterviewLevel: e.target.value }) }} />
                                                                        </div>
                                                                    }

                                                                    <div className="col mb-4">
                                                                        <div className="col-md-9 mb-2" style={{ zIndex: "1" }}>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={this.state.interviewTag}
                                                                                onChange={this.handleInputChange}
                                                                                onKeyDown={this.handleInputKeyDown}
                                                                                onBlur={this.handleInputBlur}
                                                                                placeholder="Interview Tag *"
                                                                                maxLength="30"
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {this.state.tags.map((tag, index) => (
                                                                                <div key={index} className="tag float-start p-2 bg-secondary text-white rounded h4" style={{ marginRight: '5px' }}>
                                                                                    {tag}
                                                                                    <span onClick={() => this.handleTagRemove(tag)} style={{ cursor: 'pointer' }}><i class="bi bi-x"></i></span>

                                                                                </div>
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.selectedinterview != "" && <div id="interviewDetailsSection">
                                                        <div className="card card-centered mb-3 mb-lg-5 card-table-box">

                                                            {/*<div className={isSelectedInterviewThree ? 'card-body w-100 pad-20' : 'card-body w-100 '}>*/}


                                                            {(this.state.selectedinterview === 1) && <>
                                                                <div className="card-header p-relative">
                                                                    <h4 className="card-title">Questions <span className="form-label-secondary text-danger">* </span></h4>
                                                                    {(this.state.selectedquestionsforoneway.questions.length > 0) && <a className="link btn btn-primary p-right" href="javascript:;" onClick={() => {
                                                                        this.setState({ addQuestionModal: true })
                                                                        document.body.classList.add('modal-open');
                                                                    }}> <i className="bi bi-plus-circle "></i> Add a new question</a>}
                                                                </div>
                                                                {this.state.selectedquestionsforoneway.questions.length <= 0 &&
                                                                    <div className="text-center" style={{ padding: "10px" }}>
                                                                        <h5>Please add questions</h5>
                                                                        <p>Once you add question, they'll show up here.</p>
                                                                        {/* <!-- Button trigger modal --> */}
                                                                        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" onClick={() => {
                                                                            this.setState({ addQuestionModal: true })
                                                                            document.body.classList.add('modal-open')
                                                                        }} data-bs-target="#ModalCenteredScrollable" ><i className="bi bi-plus-circle"></i> Add questions</button>
                                                                        {/* <!-- End  Button trigger modal --> */}
                                                                    </div>
                                                                }

                                                                {this.state.selectedquestionsforoneway.questions.length > 0 &&
                                                                    <div className="table-responsive datatable-custom w-100">
                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                            <thead className="thead-light">
                                                                                <tr>
                                                                                    <th
                                                                                    >
                                                                                        Sr no
                                                                                    </th>
                                                                                    <th
                                                                                    >
                                                                                        Question
                                                                                    </th>
                                                                                    <th
                                                                                    >
                                                                                        Type
                                                                                    </th>
                                                                                    <th
                                                                                    >

                                                                                    </th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.selectedquestionsforoneway.questions.map((question, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td className="text-wrap" dangerouslySetInnerHTML={{
                                                                                                __html: question.title
                                                                                            }}></td>
                                                                                            <td className="fw-bold text-wrap" style={{ textTransform: 'capitalize' }}>
                                                                                                {question.questionContentType}
                                                                                            </td>
                                                                                            <td>

                                                                                                <button type="button" className="btn-close btn-close-red" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.handleDeleteClick(index)}></button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                }



                                                            </>
                                                            }
                                                            {(this.state.selectedinterview == 2) && <>
                                                                <div className="card-header p-relative">
                                                                    <h4 className="card-title">Questions <span className="form-label-secondary text-danger">* </span></h4>
                                                                    {(this.state.selectedquestionsforopenoneway.questions.length > 0) && <a className="link btn btn-primary p-right" href="javascript:;" onClick={() => {
                                                                        this.setState({ addQuestionModal: true })
                                                                        document.body.classList.add('modal-open');
                                                                    }}> <i className="bi bi-plus-circle "></i> Add a new question</a>}
                                                                </div>
                                                                {this.state.selectedquestionsforopenoneway.questions.length <= 0 &&
                                                                    <div className="text-center" style={{ padding: "10px" }}>
                                                                        <h5>Please add questions</h5>
                                                                        <p>Once you add question, they'll show up here.</p>
                                                                        {/* <!-- Button trigger modal --> */}
                                                                        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" onClick={() => { this.setState({ addQuestionModal: true }) }} data-bs-target="#ModalCenteredScrollable" ><i className="bi bi-plus"></i>Add questions</button>
                                                                        {/* <!-- End  Button trigger modal --> */}
                                                                    </div>
                                                                }



                                                                {
                                                                    this.state.selectedquestionsforopenoneway.questions.length > 0 &&
                                                                    <div className="table-responsive datatable-custom w-100">
                                                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" >
                                                                            <thead className="thead-light">
                                                                                <tr>
                                                                                    <th
                                                                                    >
                                                                                        Sr no
                                                                                    </th>
                                                                                    <th
                                                                                    >
                                                                                        Question
                                                                                    </th>
                                                                                    <th
                                                                                    >
                                                                                        Type
                                                                                    </th>
                                                                                    <th
                                                                                    >

                                                                                    </th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.selectedquestionsforopenoneway.questions.map((question, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                {index + 1}
                                                                                            </td>

                                                                                            <td className="text-wrap" dangerouslySetInnerHTML={{
                                                                                                __html: question.title
                                                                                            }}></td>

                                                                                            <td className="fw-bold text-wrap" style={{ textTransform: 'capitalize' }}>
                                                                                                {question.questionContentType}
                                                                                            </td>

                                                                                            <td>

                                                                                                <button type="button" className="btn-close btn-close-red" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.handleDeleteClick1(index)}></button>
                                                                                            </td>

                                                                                        </tr>
                                                                                    )

                                                                                })

                                                                                }


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                }

                                                            </>
                                                            }

                                                            {this.state.selectedinterview == 3 &&
                                                                <>
                                                                    <div className="card-header p-relative">
                                                                        <h4 className="card-title">Questions <span className="form-label-secondary text-danger">* </span></h4>
                                                                        {/* {<a className="link btn btn-primary p-right" href="javascript:;" onClick={() => {
                                                                        this.setState({ addQuestionModal: true })
                                                                        document.body.classList.add('modal-open');
                                                                    }}> <i className="bi bi-plus-circle "></i> Add a new question</a>} */}
                                                                    </div>
                                                                    <div className={isSelectedInterviewThree ? 'card-body w-100 pad-20' : 'card-body w-100 '}>
                                                                        <div className="mb-4 w-100">
                                                                            <label for="questionTitle" className="col-sm-3 col-form-label form-label">Question Title</label>

                                                                            <div className="col">
                                                                                <input type="text" className="form-control" placeholder="Live Question Title"
                                                                                    id="livequestiontitle"
                                                                                    value={this.state.liveQuestionTitle}
                                                                                    onChange={(e) => { this.setState({ liveQuestionTitle: e.target.value }) }} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-4 w-100">
                                                                            <label for="questionTitle" className="col-sm-3 col-form-label form-label">Question Description</label>

                                                                            <div className="col">
                                                                                <input type="text" className="form-control"
                                                                                    placeholder="Live Question Description"
                                                                                    id="liveQuestionDescription"
                                                                                    value={this.state.liveQuestionDescription}
                                                                                    onChange={(e) => { this.setState({ liveQuestionDescription: e.target.value }) }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end gap-3 w-100">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary"
                                                                                onClick={() => {
                                                                                    const minMaxLength = { min: 3, max: 70 }; // Define the minimum and maximum lengths

                                                                                    const { liveQuestionTitle, liveQuestionDescription } = this.state;

                                                                                    // Step 1: Check for minimum length validation for title
                                                                                    if (liveQuestionTitle.trim().length < minMaxLength.min) {
                                                                                        displayWarningMessage(`Minimum length of question title is ${minMaxLength.min}`, "top-center");
                                                                                        return; // Stop further processing if title min length validation fails
                                                                                    }

                                                                                    // Step 2: Check for minimum length validation for description
                                                                                    if (liveQuestionDescription.trim().length < minMaxLength.min) {
                                                                                        displayWarningMessage(`Minimum length of description is ${minMaxLength.min}`, "top-center");
                                                                                        return; // Stop further processing if description min length validation fails
                                                                                    }

                                                                                    // Step 3: Check for maximum length validation for title
                                                                                    if (liveQuestionTitle.length > minMaxLength.max) {
                                                                                        displayWarningMessage(`Max length of question title is ${minMaxLength.max}`, "top-center");
                                                                                        return; // Stop further processing if title max length validation fails
                                                                                    }

                                                                                    // Step 4: Check for maximum length validation for description
                                                                                    if (liveQuestionDescription.length > minMaxLength.max) {
                                                                                        displayWarningMessage(`Max length of description is ${minMaxLength.max}`, "top-center");
                                                                                        return; // Stop further processing if description max length validation fails
                                                                                    }

                                                                                    // If all validations pass, proceed to save the question
                                                                                    let liveQuestions = [...this.state.addedliveQuestions];

                                                                                    liveQuestions.push({
                                                                                        liveQuestionTitle: liveQuestionTitle,
                                                                                        liveQuestionDescription: liveQuestionDescription,
                                                                                    });

                                                                                    this.setState({
                                                                                        addedliveQuestions: liveQuestions,
                                                                                        liveQuestionTitle: "",
                                                                                        liveQuestionDescription: "",
                                                                                    });
                                                                                }}
                                                                            >
                                                                                Save Question
                                                                            </button>


                                                                        </div>
                                                                        {this.state.addedliveQuestions.length > 0 ?
                                                                            <div className="table-responsive datatable-custom w-100" style={{ 'padding-top': '1%' }}>
                                                                                <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                    <thead className="thead-light">
                                                                                        <tr>
                                                                                            <th
                                                                                            >
                                                                                                Sr no
                                                                                            </th>
                                                                                            <th
                                                                                            >
                                                                                                Title
                                                                                            </th>

                                                                                            <th
                                                                                            >
                                                                                                Description
                                                                                            </th>
                                                                                            <th
                                                                                            >

                                                                                            </th>


                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            this.state.addedliveQuestions.map((question, index) => {
                                                                                                return (
                                                                                                    <tr key={index}>
                                                                                                        <td>
                                                                                                            {index + 1}
                                                                                                        </td>


                                                                                                        <td className="text-wrap">
                                                                                                            {question.liveQuestionTitle}

                                                                                                        </td>
                                                                                                        <td className="text-wrap">
                                                                                                            {question.liveQuestionDescription}

                                                                                                        </td>


                                                                                                    </tr>
                                                                                                )

                                                                                            })
                                                                                        }

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </>
                                                            }

                                                            {/*</div>*/}


                                                        </div>
                                                        {/* </div><!-- End Card --> */}
                                                        {/* <!-- Start First Modal --> */}
                                                        {this.state.addQuestionModal && <div id="ModalCenteredScrollable" className="modal d-block" tabindex="-1" role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                                                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }}>
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="exampleModalCenteredScrollableTitle"><span className="whiteColor">Add Questions to Interview</span></h5>
                                                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                                            this.setState({ addQuestionModal: false })
                                                                            document.body.classList.remove('modal-open');
                                                                        }}></button>
                                                                    </div>
                                                                    <div className="modal-body" >
                                                                        {/* <!-- Question set list --> */}
                                                                        {(this.state.questionsset == []) && <div className="card card-centered mb-3 mb-lg-5">
                                                                            <div className="card-body py-10">
                                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                                <div className="text-center">

                                                                                    <h1>You have not created any Question Set.</h1>
                                                                                    <Link className="btn btn-primary" to="/createquestionset" style={{ cursor: 'pointer' }}><i className="bi bi-plus-lg me-1"></i>Create Question Set!</Link>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        }

                                                                        <ol className="list-group list-group-numbered">

                                                                            {this.state.questionsset.map((questionset, i) => {
                                                                                return (
                                                                                    <>
                                                                                        <a href="javascript:;" onClick={() => {
                                                                                            this.setState({
                                                                                                questionDetailsModal: true,
                                                                                                addQuestionModal: false,
                                                                                                questionsForQuestionDetailsModal: {
                                                                                                    ...questionset, questions: questionset.questions.filter(item => {
                                                                                                        if (this.state.selectedinterview == 1) {
                                                                                                            return !this.state.selectedquestionsforoneway.questions.some(item2 => item2.questionId === item.questionId)
                                                                                                        }
                                                                                                        if ((this.state.selectedinterview == 2)) {
                                                                                                            return !this.state.selectedquestionsforopenoneway.questions.some(item2 => item2.questionId === item.questionId)
                                                                                                        }
                                                                                                        return item.questionId

                                                                                                    }).map(item => ({ ...item, isChecked: false }))
                                                                                                },
                                                                                                // questionsForQuestionDetailsModal: {...questionset,questions: questionset.questions.filter(item=> !this.state.selectedquestionsforopenoneway.questions.some(item2=>item2.questionId=== item.questionId)).map(item=> ({...item, isChecked: false}))},
                                                                                                selectedquestionsforonewayChecked: [],
                                                                                                selectedquestionsforopenonewayChecked: [],
                                                                                                questionsForQuestionDetailsModalIndex: i
                                                                                            })
                                                                                        }}>
                                                                                            <li className="list-group-item d-flex justify-content-between align-items-start">

                                                                                                <div className="ms-2 me-auto">
                                                                                                    <div className="fw-semi-bold text-break">{questionset.questionSetName}</div>

                                                                                                </div>
                                                                                                <span> <i className="bi-chevron-right"></i></span>

                                                                                            </li>
                                                                                        </a>
                                                                                    </>
                                                                                )
                                                                            })
                                                                            }



                                                                        </ol>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>}

                                                        {/* <!-- End First Modal --> */}
                                                        {/* <!-- Start Second Modal --> */}
                                                        {this.state.questionDetailsModal &&


                                                            <div className="modal d-block" id="question-detail-modal" tabIndex="-1">

                                                                <div className=" modal-dialog-scrollable set-modal-size">
                                                                    <div className="modal-content">
                                                                        <div class="modal-header">
                                                                            <h5 class="modal-title text-white" id="question-detail-modal-label">Add Question by Select Checkbox</h5>

                                                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                                                this.setState({ questionDetailsModal: false, })
                                                                                document.body.classList.remove('modal-open');

                                                                            }}></button>

                                                                        </div>
                                                                        <div className="modal-body pt-3">
                                                                            <div className="card">
                                                                                {/* <!-- Header --> */}
                                                                                <div className="card-header d-none">
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <div className="col-md">
                                                                                            <center><h5 className="card-header-title">Add question by select checkbox.</h5></center>
                                                                                        </div>
                                                                                        <div className="col-auto">
                                                                                            {/* <!-- Datatable Info --> */}
                                                                                            <div id="datatableWithCheckboxSelectCounterInfo" className="me-2" style={{ display: 'none' }}>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <span className="fs-5 me-3"><span id="datatableWithCheckboxSelectCounter">0</span> Selected</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <!-- End Datatable Info --> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                {/* <!-- End Header --> */}
                                                                                {/* <!-- Table --> */}
                                                                                <div className="table-responsive datatable-custom">
                                                                                    <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                        <thead className="thead-light">
                                                                                            <tr>

                                                                                                <th >
                                                                                                    {this.state.questionsForQuestionDetailsModal.questions?.length ?
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="questions"
                                                                                                            style={{ cursor: 'pointer', marginLeft: '0px' }}
                                                                                                            checked={this.state.questionsForQuestionDetailsModal.questions.every((question) => question.isChecked)}
                                                                                                            onChange={e => {
                                                                                                                if (this.state.selectedinterview === 1) {
                                                                                                                    onSelectAllforquestionsforoneway(e, this.state.questionsForQuestionDetailsModal.questionSetId);
                                                                                                                }
                                                                                                                if (this.state.selectedinterview === 2) {
                                                                                                                    onSelectAllforquestionsforopenoneway(e, this.state.questionsForQuestionDetailsModal.questionSetId);
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        : null}
                                                                                                </th>
                                                                                                <th >
                                                                                                    Question Title
                                                                                                </th>
                                                                                                <th >
                                                                                                    Question
                                                                                                    Type
                                                                                                </th>
                                                                                                <th >
                                                                                                    Answer
                                                                                                    Type
                                                                                                </th>
                                                                                                {this.state.tenantSubscriptionDataPermissionNoOfAttempts.value === "true" &&

                                                                                                    <th style={{ width: "3%" }}>
                                                                                                        No of re-attempts
                                                                                                    </th>
                                                                                                }
                                                                                                {this.state.tenantSubscriptionDataPermissionThinkTime.value === "true" &&

                                                                                                    <th style={{ width: "3%" }}>
                                                                                                        Question think time (s)
                                                                                                    </th>
                                                                                                }
                                                                                                {this.state.tenantSubscriptionDataPermissionTimeLimit.value === "true" &&

                                                                                                    <th style={{ width: "3%" }}>
                                                                                                        Answer time (s)
                                                                                                    </th>
                                                                                                }

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.questionsForQuestionDetailsModal.questions.map((question, j) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={j}>

                                                                                                            <td className="table-column-pe-1">
                                                                                                                <div className="form-check p-0">
                                                                                                                    <input type="checkbox"
                                                                                                                        name="questions"
                                                                                                                        id={question.questionId}
                                                                                                                        value={question}
                                                                                                                        onChange={e => {
                                                                                                                            if (this.state.selectedinterview == 1) {
                                                                                                                                onRowSelectforquestionsforoneway(question, e, this.state.questionsForQuestionDetailsModal.questionSetId);
                                                                                                                            }
                                                                                                                            if (this.state.selectedinterview == 2) {
                                                                                                                                onRowSelectforquestionsforopenoneway(question, e, this.state.questionsForQuestionDetailsModal.questionSetId);
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        checked={question.isChecked}
                                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                                    />
                                                                                                                </div>


                                                                                                            </td>
                                                                                                            <td className="table-column-ps-1">
                                                                                                                <div className="ps-0">
                                                                                                                    <span className="d-block h5 text-wrap mb-0 fw-bold" dangerouslySetInnerHTML={{
                                                                                                                        __html: question.title
                                                                                                                    }}>

                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="h5 mb-0">
                                                                                                                {question.questionType}

                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {question.answerType}

                                                                                                            </td>
                                                                                                            {this.state.tenantSubscriptionDataPermissionNoOfAttempts.value === "true" &&
                                                                                                                <td>
                                                                                                                    <div className="quantity-counter" >
                                                                                                                        <div className="js-quantity-counter row align-items-center">
                                                                                                                            <div className="col">
                                                                                                                                {/*<input placeholder="Attempts"*/}

                                                                                                                                {/*    id="numberOfAttempts"*/}
                                                                                                                                {/*    value={question.numberOfAttempts}*/}
                                                                                                                                {/*    type="number"*/}
                                                                                                                                {/*    min="1"*/}
                                                                                                                                {/*    className="js-result form-control form-control-quantity-counter"*/}
                                                                                                                                {/*onChange={event => {*/}
                                                                                                                                {/*    if (event.target.value % 1 !== 0) {*/}
                                                                                                                                {/*        displayWarningMessage('Please add integer numberOfAttempts!', "top-center");*/}
                                                                                                                                {/*        return;*/}
                                                                                                                                {/*    }*/}

                                                                                                                                {/*    let values = [...this.state.questionsset];*/}

                                                                                                                                {/*    values[this.state.questionsForQuestionDetailsModalIndex].questions[j].numberOfAttempts = event.target.value;*/}
                                                                                                                                {/*    this.setState(prevState => {*/}
                                                                                                                                {/*        const updatedQuestionsSet = [...prevState.questionsset];*/}
                                                                                                                                {/*        updatedQuestionsSet[prevState.questionsForQuestionDetailsModalIndex].questions[j].numberOfAttempts = event.target.value;*/}

                                                                                                                                {/*        return { questionsset: updatedQuestionsSet };*/}
                                                                                                                                {/*    });*/}
                                                                                                                                {/*}}*/}
                                                                                                                                {/*/>*/}
                                                                                                                                <input
                                                                                                                                    id="numberOfAttempts"
                                                                                                                                    type="number"
                                                                                                                                    min="1"
                                                                                                                                    className="js-result form-control form-control-quantity-counter"
                                                                                                                                    value={question.numberOfAttempts}
                                                                                                                                    onChange={event => {
                                                                                                                                        const newValue = event.target.value;

                                                                                                                                        if (newValue % 1 !== 0) {
                                                                                                                                            displayWarningMessage('Please add an integer for numberOfAttempts!', 'top-center');
                                                                                                                                            return;
                                                                                                                                        }

                                                                                                                                        this.setState(prevState => ({
                                                                                                                                            questionsForQuestionDetailsModal: {
                                                                                                                                                ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                questions: prevState.questionsForQuestionDetailsModal.questions.map(q => {
                                                                                                                                                    if (q.questionId === question.questionId) {
                                                                                                                                                        return {
                                                                                                                                                            ...q,
                                                                                                                                                            numberOfAttempts: newValue
                                                                                                                                                        };
                                                                                                                                                    }
                                                                                                                                                    return q;
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        }));
                                                                                                                                    }}


                                                                                                                                    onBlur={() => {
                                                                                                                                        if (this.state.questionsForQuestionDetailsModal.questions.find(q => q.questionId === question.questionId).numberOfAttempts === '') {
                                                                                                                                            this.setState((prevState) => ({
                                                                                                                                                questionsForQuestionDetailsModal: {
                                                                                                                                                    ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                    questions: prevState.questionsForQuestionDetailsModal.questions.map(
                                                                                                                                                        (thisQuestion) => {
                                                                                                                                                            if (thisQuestion.questionId === question.questionId) {
                                                                                                                                                                return {
                                                                                                                                                                    ...thisQuestion,
                                                                                                                                                                    numberOfAttempts: 1,
                                                                                                                                                                };
                                                                                                                                                            }
                                                                                                                                                            return thisQuestion;
                                                                                                                                                        }
                                                                                                                                                    ),
                                                                                                                                                },
                                                                                                                                            }));
                                                                                                                                        }
                                                                                                                                    }}


                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.tenantSubscriptionDataPermissionThinkTime.value === "true" &&
                                                                                                                <td>
                                                                                                                    <div className="quantity-counter" >
                                                                                                                        <div className="js-quantity-counter row align-items-center">
                                                                                                                            <div className="col">
                                                                                                                                <input

                                                                                                                                    placeholder="Think Time"
                                                                                                                                    id="thinkTime"
                                                                                                                                    type="number"
                                                                                                                                    min="1"
                                                                                                                                    className="js-result form-control form-control-quantity-counter"
                                                                                                                                    value={question.thinkTime}
                                                                                                                                    onChange={event => {
                                                                                                                                        //console.log("index is", index)
                                                                                                                                        if (event.target.value % 1 !== 0) {
                                                                                                                                            displayWarningMessage('Please add integer Think Time!', "top-center");
                                                                                                                                            return;
                                                                                                                                        }
                                                                                                                                        const newValue = event.target.value
                                                                                                                                        this.setState(prevState => ({
                                                                                                                                            questionsForQuestionDetailsModal: {
                                                                                                                                                ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                questions: prevState.questionsForQuestionDetailsModal.questions.map(thisQuestion => {
                                                                                                                                                    if (thisQuestion.questionId === question.questionId) {
                                                                                                                                                        return {
                                                                                                                                                            ...thisQuestion,
                                                                                                                                                            thinkTime: newValue
                                                                                                                                                        };
                                                                                                                                                    }
                                                                                                                                                    return thisQuestion;
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        }))
                                                                                                                                        //let values = [...this.state.questionsset];

                                                                                                                                        //values[this.state.questionsForQuestionDetailsModalIndex].questions[j].thinkTime = event.target.value; // convert to minutes
                                                                                                                                        //this.setState({ questionsset: values });
                                                                                                                                    }}

                                                                                                                                    onBlur={() => {
                                                                                                                                        if (this.state.questionsForQuestionDetailsModal.questions.find(q => q.questionId === question.questionId).thinkTime === '') {
                                                                                                                                            this.setState((prevState) => ({
                                                                                                                                                questionsForQuestionDetailsModal: {
                                                                                                                                                    ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                    questions: prevState.questionsForQuestionDetailsModal.questions.map(
                                                                                                                                                        (thisQuestion) => {
                                                                                                                                                            if (thisQuestion.questionId === question.questionId) {
                                                                                                                                                                return {
                                                                                                                                                                    ...thisQuestion,
                                                                                                                                                                    thinkTime: 30,
                                                                                                                                                                };
                                                                                                                                                            }
                                                                                                                                                            return thisQuestion;
                                                                                                                                                        }
                                                                                                                                                    ),
                                                                                                                                                },
                                                                                                                                            }));
                                                                                                                                        }

                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.tenantSubscriptionDataPermissionTimeLimit.value === "true" &&
                                                                                                                <td>
                                                                                                                    <div className="quantity-counter" >
                                                                                                                        <div className="js-quantity-counter row align-items-center">
                                                                                                                            <div className="col">
                                                                                                                                <input placeholder="Time Limit"

                                                                                                                                    id="timeLimit"
                                                                                                                                    type="number"
                                                                                                                                    min="1"
                                                                                                                                    className="js-result form-control form-control-quantity-counter"
                                                                                                                                    value={question.timeLimit}
                                                                                                                                    onChange={event => {
                                                                                                                                        //console.log("index is", index)
                                                                                                                                        if (event.target.value % 1 !== 0) {
                                                                                                                                            displayWarningMessage('Please add integer Time Limit!', "top-center");
                                                                                                                                            return;
                                                                                                                                        }
                                                                                                                                        const newValue = event.target.value;


                                                                                                                                        this.setState((prevState) => ({
                                                                                                                                            questionsForQuestionDetailsModal: {
                                                                                                                                                ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                questions: prevState.questionsForQuestionDetailsModal.questions.map(
                                                                                                                                                    (thisQuestion) => {
                                                                                                                                                        if (thisQuestion.questionId === question.questionId) {
                                                                                                                                                            return {
                                                                                                                                                                ...thisQuestion,
                                                                                                                                                                timeLimit: newValue,
                                                                                                                                                            };
                                                                                                                                                        }
                                                                                                                                                        return thisQuestion;
                                                                                                                                                    }
                                                                                                                                                ),
                                                                                                                                            },
                                                                                                                                        }));

                                                                                                                                    }}



                                                                                                                                    onBlur={() => {
                                                                                                                                        if (this.state.questionsForQuestionDetailsModal.questions.find(q => q.questionId === question.questionId).timeLimit === '') {
                                                                                                                                            this.setState((prevState) => ({
                                                                                                                                                questionsForQuestionDetailsModal: {
                                                                                                                                                    ...prevState.questionsForQuestionDetailsModal,
                                                                                                                                                    questions: prevState.questionsForQuestionDetailsModal.questions.map(
                                                                                                                                                        (thisQuestion) => {
                                                                                                                                                            if (thisQuestion.questionId === question.questionId) {
                                                                                                                                                                return {
                                                                                                                                                                    ...thisQuestion,
                                                                                                                                                                    timeLimit: 30,
                                                                                                                                                                };
                                                                                                                                                            }
                                                                                                                                                            return thisQuestion;
                                                                                                                                                        }
                                                                                                                                                    ),
                                                                                                                                                },
                                                                                                                                            }));
                                                                                                                                        }

                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            }


                                                                                                        </tr>

                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                            }

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                {/* <!-- End Table --> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-primary me-3" data-bs-dismiss="modal" onClick={() => {

                                                                                if (this.state.selectedinterview == 1) {
                                                                                    onRowSelectforquestionsforonewaySubmit()
                                                                                }
                                                                                if (this.state.selectedinterview == 2) {
                                                                                    onRowSelectforquestionsforopenonewaySubmit()
                                                                                }
                                                                                document.body.classList.remove('modal-open');
                                                                            }}>Save</button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        }

                                                        {/* <!-- End Second Modal --> */}
                                                        {/* <!-- End question sets section --> */}
                                                        {/* <!-- applicants section --> */}
                                                        {/* <!-- Card --> */}
                                                        {(this.state.selectedinterview === 1 || this.state.selectedinterview === 3) &&
                                                            <div id="connectedAccountsSection">
                                                                <div className="card card-centered">
                                                                    <div className="card-header">
                                                                        <h4 className="card-title">
                                                                            {this.state.selectedinterview === 1 ?
                                                                                <label>Applicants <span className="form-label-secondary text-danger">* </span> </label>
                                                                                : <label>Applicant/Hosts</label>
                                                                            }
                                                                            {this.state.selectedinterview == 1 && <a className="btn btn-primary btn-sc-color" type="button" style={{ marginLeft: '1%', float: 'right' }}
                                                                                href={sampleDownloadCSV} target="_blank" rel="noreferrer" ><i class="bi bi-download pl-2 pr-2"></i> Download Sample Excel</a>}

                                                                        </h4>
                                                                        {(this.state.addedapplicants.length > 0) &&
                                                                            <>
                                                                                {this.state.isapplicantNotAdded == true || this.state.selectedinterview == 1 ?
                                                                                    <a className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => { this.setState({ addapplicantModal: true, isHost: false, applicantFormLabel: "Add Applicant" }) }}><i className="bi bi-plus"></i> Add Applicant</a>
                                                                                    : null}
                                                                                {this.state.selectedinterview === 3 && this.state.isHostLimit &&
                                                                                    <>
                                                                                        <a className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => { this.setState({ addapplicantModal: true, isHost: true, applicantFormLabel: "Add Host" }) }}><i className="bi bi-plus"></i> Add Host</a>
                                                                                    </>
                                                                                }
                                                                                {this.state.selectedinterview == 1 &&
                                                                                    <>
                                                                                        <a className="btn btn-outline-success me-2" data-bs-toggle="modal" data-bs-target="#addapplicantThroughExcelModal" onClick={() => { this.setState({ addapplicantThroughExcelModal: true }) }}><i class="bi bi-file-earmark-spreadsheet"></i> Add applicants Through Excel</a>
                                                                                        <a className="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => {
                                                                                            this.setState({ addShortlistedapplicants: true })
                                                                                            document.body.classList.add('modal-open');

                                                                                        }}><i className="bi bi-check"></i> Add Shortlisted Applicants</a>
                                                                                    </>
                                                                                }

                                                                            </>}

                                                                    </div>
                                                                    <div className="card-body">
                                                                        {this.state.addedapplicants.length <= 0 ? <div className="text-center">
                                                                            {this.state.selectedinterview === 3 ?
                                                                                <>
                                                                                    <h5>Please add applicant/hosts</h5>
                                                                                    <p>Once you add applicant/hosts, they'll show up here.</p>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img className="img-fluid pt-2 pb-4" src={swiftAddapplicant} alt="applicant" />

                                                                                    <h5>Please add applicants</h5>
                                                                                    <p>Once you add applicants, they'll show up here.</p>
                                                                                </>
                                                                            }

                                                                            {this.state.selectedinterview == 1 &&
                                                                                <>
                                                                                    <a className="btn btn-outline-success me-2" data-bs-toggle="modal" data-bs-target="#addapplicantThroughExcelModal" onClick={() => {
                                                                                        this.setState({ addapplicantThroughExcelModal: true })
                                                                                        document.body.classList.add('modal-open');

                                                                                    }}><i class="bi bi-file-earmark-spreadsheet"></i> Add Applicants Through Excel</a>
                                                                                </>
                                                                            }
                                                                            <a className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => {
                                                                                this.setState({ addapplicantModal: true, isHost: false, applicantFormLabel: "Add Applicant" })
                                                                                document.body.classList.add('modal-open');

                                                                            }}><i className="bi bi-plus-circle"></i> Add Applicant</a>
                                                                            {this.state.selectedinterview === 3 ?
                                                                                <a className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => {
                                                                                    this.setState({ addapplicantModal: true, isHost: true, applicantFormLabel: "Add Host" })
                                                                                    document.body.classList.add('modal-open');

                                                                                }}><i className="bi-plus-circle"></i> Add Host</a>
                                                                                : null}
                                                                            {this.state.selectedinterview == 1 &&
                                                                                <>

                                                                                    <a className="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#applicantsAddModal" onClick={() => {
                                                                                        this.setState({ addShortlistedapplicants: true })
                                                                                        document.body.classList.add('modal-open');

                                                                                    }}><i className="bi bi-check"></i> Add Shortlisted Applicants </a>
                                                                                </>
                                                                            }
                                                                        </div> : <div className="list-group list-group-flush list-group-no-gutters w-100">
                                                                            {/* <!-- Item --> */}
                                                                            {this.state.addedapplicants.map((applicant, index) => {
                                                                                return <div className="list-group-item-custom" key={index}>
                                                                                    <div className="d-flex">


                                                                                        <div className="flex-grow-1">
                                                                                            <div className="row align-items-center">
                                                                                                <div className="col-sm mb-1 mb-sm-0">
                                                                                                    <h5 className="mb-1">{applicant.firstname}  {applicant.lastname}</h5>
                                                                                                    <ul className="list-inline list-separator text-body small">
                                                                                                        <li className="list-inline-item col-10"><span className="fw-semi-bold text-muted">Email:</span>{applicant.email}</li>

                                                                                                        {/*{!(applicant.isHost) && <li className="list-inline-item">*/}
                                                                                                        {/*    <div className="tom-select-custom mb-4">*/}
                                                                                                        {/*        <select className="form-select"*/}
                                                                                                        {/*            name="interviewstages" onChange={(e) => {*/}
                                                                                                        {/*                let applicants = this.state.addedapplicants;*/}
                                                                                                        {/*                applicants[index].interviewStage = e.target.value;*/}
                                                                                                        {/*                this.setState({ addedapplicants: applicants })*/}
                                                                                                        {/*                this.setState({ interviewStageinterviewStage: e.target.value });*/}
                                                                                                        {/*            }}>*/}

                                                                                                        {/*            {this.state.interviewStages != [] && this.state.interviewStages.map((interviewstage, index) =>*/}
                                                                                                        {/*                <option key={index} value={interviewstage.value}>{interviewstage.name}</option>*/}


                                                                                                        {/*            )}*/}
                                                                                                        {/*        </select>*/}
                                                                                                        {/*    </div>*/}
                                                                                                        {/*</li>*/}
                                                                                                        {/*}*/}

                                                                                                    </ul>
                                                                                                    {/*<div className="row mb-4">*/}
                                                                                                    {/*    <label className="col-sm-3 col-form-label form-label">*/}
                                                                                                    {/*        Interview Stages<span style={{ color: 'red' }}>*</span>*/}
                                                                                                    {/*    </label>*/}

                                                                                                    {/*    <div className="col-sm-9">*/}


                                                                                                    {/*    </div>*/}
                                                                                                    {/*</div>*/}
                                                                                                </div>
                                                                                                {/* <!-- End Col --> */}


                                                                                            </div>
                                                                                            {/* <!-- End Row --> */}
                                                                                        </div>

                                                                                    </div>
                                                                                    <hr />
                                                                                </div>
                                                                            })}

                                                                            {/* <!-- End Item --> */}


                                                                        </div>}

                                                                    </div>
                                                                </div>
                                                            </div>}

                                                        {/* <!-- End Card --> */}

                                                        {/* <!-- Add applicants Modal --> */}
                                                        {this.state.addapplicantModal &&
                                                            <>
                                                                <div id="addapplicantModal" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth-addapplicantModal" role="document">
                                                                        <div className="modal-content">
                                                                            <div class="card-header d-flex">
                                                                                <img className="img-fluid pt-2 pb-2" src={swiftAddapplicantTick} alt=" Add applicant" />
                                                                                <div className="ms-2 pt-3 ">
                                                                                    <h2 class="card-title h4">{this.state.applicantFormLabel} </h2>
                                                                                    <p>Please enter more details.</p>
                                                                                </div>

                                                                            </div>
                                                                            {/* <!-- Body --> */}
                                                                            <div className="modal-body pt-0">
                                                                                <div className="mb-2">
                                                                                    <label for="addressLine1Label" className="col-sm-3 col-form-label form-label fw-bold">First Name<span style={{ color: 'red' }}> *</span></label>
                                                                                    <div className="col-sm-12">
                                                                                        <input type="text" className="form-control" name="addressLine1" id="addressLine1Label" placeholder="Enter first name"
                                                                                            value={this.state.firstname}
                                                                                            onChange={(e) => { this.setState({ firstname: e.target.value }) }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-2">
                                                                                    <label for="addressLine1Label" className="col-sm-3 col-form-label form-label fw-bold">Last Name</label>
                                                                                    <div className="col-sm-12">
                                                                                        <input type="text" className="form-control" name="addressLine1" id="addressLine1Label" placeholder="Enter last name" value={this.state.lastname}
                                                                                            onChange={(e) => { this.setState({ lastname: e.target.value }) }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-2">
                                                                                    <label for="zipCodeLabel" className="col-sm-3 col-form-label form-label fw-bold">Email<span style={{ color: 'red' }}> *</span></label>
                                                                                    <div className="col-sm-12">
                                                                                        <input type="email" className="js-masked-input form-control" name="zipCode" id="zipCodeLabel" placeholder="Enter email address"
                                                                                            value={this.state.email}
                                                                                            onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                                                                    </div>
                                                                                </div>
                                                                                {(this.state.selectedinterview == 3 || this.state.selectedinterview == 1) &&
                                                                                    <div className="mb-2">
                                                                                        <label for="PhoneNumber" className="col-sm-3 col-form-label form-label fw-bold">Phone No. <span style={{ color: 'red' }}> *</span></label>
                                                                                        <div className="col-sm-12">
                                                                                            <PhoneInput
                                                                                                international
                                                                                                countryCallingCodeEditable={false}
                                                                                                defaultCountry="US"
                                                                                                placeholder="Enter phone number"
                                                                                                name="PhoneNumber"
                                                                                                id="PhoneNumber"
                                                                                                value={this.state.PhoneNumber} onChange={(value) => { this.setState({ PhoneNumber: value }) }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {/* <!-- End Form --> */}
                                                                                {this.state.selectedinterview == 3 &&
                                                                                    <div className=" mb-2 d-none ">
                                                                                        <label for="isHost" className="col-sm-3 col-form-label form-label fw-bold">
                                                                                            IsHost <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='isHost'></i>
                                                                                            <ReactTooltip id="isHost" place="right" type="dark" effect="float">
                                                                                                <span> Select to choose Hosts for the interview </span>
                                                                                            </ReactTooltip>
                                                                                        </label>
                                                                                        <div className="col-sm-12">
                                                                                            <input id="isHost" className="mt-3" defaultChecked={this.state.isHost} name="isHost" type="checkbox"
                                                                                                onChange={(e) => this.handleCheckedChanged(e)} />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className="d-flex justify-content-between gap-sm-3">
                                                                                </div>
                                                                            </div>
                                                                            {/* <!-- End Body --> */}
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-white me-2" data-dismiss="modal" onClick={() => {
                                                                                    this.setState({ addapplicantModal: false })
                                                                                    document.body.classList.remove('modal-open');
                                                                                }}>Cancel</button>
                                                                                <button type="button" className="btn btn-primary "
                                                                                    onClick={() => {
                                                                                        var pattern = /^[a-zA-Z ]*$/;
                                                                                        if (this.state.selectedinterview == 1) {
                                                                                            if (this.state.firstname.trim() === "") {
                                                                                                displayWarningMessage('First Name is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (pattern.test(this.state.firstname) === false) {
                                                                                                displayWarningMessage('Special characters not allowed in first name', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (pattern.test(this.state.lastname) === false) {
                                                                                                displayWarningMessage('Special characters not allowed in last name', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (this.state.email === "") {
                                                                                                displayWarningMessage('Email is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                                                            let result = re.test(String(this.state.email).toLowerCase());
                                                                                            if (!result) {
                                                                                                displayWarningMessage('Email is not valid', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (this.state.PhoneNumber === "") {
                                                                                                displayWarningMessage('Phone No is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (!isValidPhoneNumber(this.state.PhoneNumber)) {
                                                                                                displayErrorMessage("Phone no is not valid", 'top-center');
                                                                                                return;
                                                                                            }
                                                                                            else {
                                                                                                this.isApplicantActiveInvite(this.state.email, 1);
                                                                                            }
                                                                                        }
                                                                                        if (this.state.selectedinterview == 3) {
                                                                                            if (this.state.firstname.trim() === "") {
                                                                                                displayWarningMessage('First Name is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (pattern.test(this.state.firstname) === false) {
                                                                                                displayWarningMessage('Special characters not allowed in first name', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (pattern.test(this.state.lastname) === false) {
                                                                                                displayWarningMessage('Special characters not allowed in last name', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (this.state.email === "") {
                                                                                                displayWarningMessage('Email is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                                                            let result = re.test(String(this.state.email).toLowerCase());
                                                                                            if (!result) {
                                                                                                displayWarningMessage('Email is not valid', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (this.state.PhoneNumber === "") {
                                                                                                displayWarningMessage('Phone number is required', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            if (!isValidPhoneNumber(this.state.PhoneNumber)) {
                                                                                                displayErrorMessage("Phone no is not valid", 'top-center');
                                                                                                return;
                                                                                            }
                                                                                            else {
                                                                                                if (this.state.isHost) {
                                                                                                    let applicants = [...this.state.addedapplicants];
                                                                                                    let applicant = {}
                                                                                                    
                                                                                                        applicant = {
                                                                                                            firstname: this.state.firstname,
                                                                                                            lastname: this.state.lastname,
                                                                                                            email: this.state.email,
                                                                                                            interviewStage: null,
                                                                                                            isHost: this.state.isHost,
                                                                                                            PhoneNumber: this.state.PhoneNumber
                                                                                                        }
                                                                                                    
                                                                                                    let tempAllReviewers = this.state.allInterviewReviewers;
                                                                                                    let tmpCount = 0;
                                                                                                    for (let i = 0; i < tempAllReviewers.length; i++) {
                                                                                                        if (applicant.email == tempAllReviewers[i] && applicant.isHost == false) {
                                                                                                            tmpCount++;
                                                                                                        }
                                                                                                    }
                                                                                                    if (tmpCount > 0) {
                                                                                                        displayWarningMessage('applicant Email cannot contain Reviewer Email!', "top-center");
                                                                                                        return;
                                                                                                    }
                                                                                                    tmpCount = 0;
                                                                                                    for (let i = 0; i < applicants.length; i++) {
                                                                                                        if (applicant.email == applicants[i].email) {
                                                                                                            tmpCount++;
                                                                                                        }
                                                                                                    }
                                                                                                    if (tmpCount > 0) {
                                                                                                        displayWarningMessage('Duplicate Email Found!', "top-center");
                                                                                                        return;
                                                                                                    }
                                                                                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                                                                    let result = re.test(String(applicant.email).toLowerCase());
                                                                                                    if (!result) {
                                                                                                        displayWarningMessage('Email is not valid', "top-center");
                                                                                                        return;
                                                                                                    }
                                                                                                    applicants.push(applicant);
                                                                                                    this.setState({
                                                                                                        addedapplicants: applicants,
                                                                                                        firstname: "",
                                                                                                        lastname: "",
                                                                                                        email: "",
                                                                                                        PhoneNumber: "",
                                                                                                        interviewStage: "FirstInterview",
                                                                                                        isHost: false
                                                                                                    });
                                                                                                    if (applicant.isHost === false) {
                                                                                                        this.setState({ isapplicantNotAdded: false });
                                                                                                    }
                                                                                                    let hostCount = 0;
                                                                                                    for (let j = 0; j < applicants.length; j++) {
                                                                                                        if (applicants[j].isHost) {
                                                                                                            hostCount++;
                                                                                                        }
                                                                                                    }
                                                                                                    if (hostCount === 10) {
                                                                                                        this.setState({ isHostLimit: false });
                                                                                                    }
                                                                                                    this.setState({ addapplicantModal: false });
                                                                                                    document.body.classList.remove('modal-open');
                                                                                                }
                                                                                                else {
                                                                                                    this.isApplicantActiveInvite(this.state.email, 3);
                                                                                                }
                                                                                            }

                                                                                            
                                                                                        }
                                                                                        
                                                                                    }}
                                                                                >Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }


                                                        {this.state.addapplicantThroughExcelModal && <div className="modal d-block" id="applicantsAddModal" tabindex="-1" aria-labelledby="applicantsAddModal" role="dialog" aria-hidden="true">
                                                            <div className="modal-dialog modal-dialog-centered" role="document">
                                                                <div className="modal-content" >
                                                                    {/* <!-- Header --> */}
                                                                    <div className="modal-header">
                                                                        <h4 className="modal-title" id="applicantsAddModal"><span className="whiteColor">Add File</span></h4>
                                                                        <button type="button" className="btn-close btn-close-white" onClick={() => {
                                                                            this.setState({ addapplicantThroughExcelModal: false })
                                                                            document.body.classList.remove('modal-open');

                                                                        }}></button>
                                                                    </div>
                                                                    {/* <!-- End Header --> */}

                                                                    {/* <!-- Body --> */}
                                                                    <div className="modal-body p-4">

                                                                        <div className="row mb-4">
                                                                            <label for="addressLine1Label" className="col-sm-3 col-form-label form-label">File Input (xls)</label>

                                                                            <div className="col-sm-9">
                                                                                <input name="excelReader" id="excelReader"
                                                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                                    type="file" className="form-control" />
                                                                            </div>
                                                                        </div>


                                                                        <div className="d-flex justify-content-end gap-sm-3">
                                                                            <button type="button" className="btn btn-white me-2 me-sm-0" data-dismiss="modal" onClick={() => {
                                                                                this.setState({ addapplicantThroughExcelModal: false })
                                                                                document.body.classList.remove('modal-open');

                                                                            }}>Cancel</button>
                                                                            <button type="button" className="btn btn-white me-2 me-sm-0" data-dismiss="modal" onClick={excelReader} >save</button>


                                                                        </div>

                                                                    </div>
                                                                    {/* <!-- End Body --> */}
                                                                </div>
                                                            </div>
                                                        </div>}

                                                        {this.state.addShortlistedapplicants &&

                                                            <div className="modal bd-example-modal-xl d-block" id="ExtraLargeModalLabel" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-xl modal-dialog-scrollable old-desing-modal" role="document">
                                                                    <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }}>
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title h4" id="ExtraLargeModalLabel"><span className="whiteColor">Add Shortlisted Applicants by select checkbox.</span></h5>
                                                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                                                this.setState({
                                                                                    addShortlistedapplicants: false,
                                                                                })
                                                                                document.body.classList.remove('modal-open');

                                                                            }}></button>
                                                                        </div>
                                                                        <div className="modal-body p-0">
                                                                            <div className="card">
                                                                                {/* <!-- Header --> */}
                                                                                <div className="card-header d-none">
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <div className="col-md">
                                                                                            <center><h5 className="card-header-title">Add Shortlisted Applicants by select checkbox.</h5></center>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                                {/* <!-- End Header --> */}
                                                                                {/* <!-- Table --> */}
                                                                                <div className="table-responsive datatable-custom">
                                                                                    <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                        <thead className="thead-light">
                                                                                            <tr>

                                                                                                <th className="table-column-pe-1">
                                                                                                    Select
                                                                                                </th>
                                                                                                <th className="table-column-ps-1">
                                                                                                    First Name
                                                                                                </th>
                                                                                                <th>
                                                                                                    Last Name
                                                                                                </th>
                                                                                                <th>
                                                                                                    Email
                                                                                                </th>


                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.shortlistedApplicants.map((shortlistedApplicant, j) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={j}>

                                                                                                            <td className="table-column-pe-1">
                                                                                                                <div className="form-check">
                                                                                                                    <input type="checkbox"
                                                                                                                        name="shortlistedApplicants"
                                                                                                                        id={shortlistedApplicant.leadId}
                                                                                                                        value={shortlistedApplicant}
                                                                                                                        onChange={e => {
                                                                                                                            onRowSelectForShortlistedApplicants(shortlistedApplicant, e, shortlistedApplicant.email);


                                                                                                                        }}
                                                                                                                        checked={shortlistedApplicant.isChecked}
                                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                                    />
                                                                                                                </div>


                                                                                                            </td>
                                                                                                            <td className="table-column-ps-1">
                                                                                                                <div className="ps-0">
                                                                                                                    <span className="d-block h5 text-inherit mb-0">
                                                                                                                        {shortlistedApplicant.firstName}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="h5 mb-0">
                                                                                                                {shortlistedApplicant.lastName}

                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {shortlistedApplicant.email}

                                                                                                            </td>


                                                                                                        </tr>

                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                            }

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                {/* <!-- End Table --> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                                                                this.setState({
                                                                                    addShortlistedapplicants: false,
                                                                                })
                                                                                document.body.classList.remove('modal-open');

                                                                            }}>Cancel</button>

                                                                            <div>
                                                                                <input type="button" className="btn btn-white" onClick={() => { this.refreshShortListedapplicants() }} value="Refresh Applicants" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>}


                                                        {/* <!-- End applicants Modal --> */}
                                                        {/* <!-- End applicants section --> */}

                                                        {/* <!-- Date picker --> */}
                                                        {/* <!--Card--> */}
                                                        <div className="card mb-4">
                                                            <div className="card-header">
                                                                <h2 className="card-title h4">Deadline <span className="form-label-secondary text-danger">* </span> </h2>
                                                            </div>
                                                            {/* <!-- Body --> */}
                                                            <div className="card-body">
                                                                {/* <!-- Form --> */}
                                                                <form>
                                                                    <div id="accountType" className="row mb-4">
                                                                        <label className="col-auto  mr-5">Due date</label>
                                                                        <div className="col-sm-4">
                                                                            <div className="row">
                                                                                {/* <div className="col-sm-9 mb-2 mb-sm-0">
                                                                                    <DatePicker
                                                                                        selected={this.state.scheduleddate}
                                                                                        onChange={(date) => {
                                                                                            this.setState({ scheduleddate: date });
                                                                                        }}
                                                                                        minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}
                                                                                        locale={navigator.language}
                                                                                        inline
                                                                                        dateFormat="yyyy-dd-MM"
                                                                                        placeholderText="Select Date"
                                                                                    />
                                                                                    

                                                                                </div> */}
                                                                                <div className="col-sm-9 mb-2 mb-sm-0">
                                                                                    <DatePicker selected={this.state.scheduleddate}
                                                                                        inline
                                                                                        onChange={(date) => {
                                                                                            // Validation: Prevent selecting dates earlier than the current date
                                                                                            const currentDate = new Date();
                                                                                            if (date < currentDate) {
                                                                                                // If selected date is before current date, don't update state
                                                                                                return;
                                                                                            }
                                                                                            this.setState({ scheduleddate: date });
                                                                                        }}
                                                                                        minDate={new Date()} />
                                                                                </div>

                                                                            </div>
                                                                            {/* <!-- End Row --> */}
                                                                        </div>
                                                                        <label className="col-auto" >Extension date <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} id="extentionDate" data-tip data-for='extentionDate'></i>
                                                                            <ReactTooltip id="extentionDate" place="right" type="dark" effect="float" >
                                                                                <span> applicant can extend his/her interview to extended date </span>
                                                                            </ReactTooltip>
                                                                        </label>
                                                                        <div className="col-sm-4">

                                                                            <div className="row">
                                                                                {/* <div className="col-sm-9 mb-2 mb-sm-0">
                                                                                    <DatePicker
                                                                                        selected={this.state.extensiondate}
                                                                                        onChange={(date) => {
                                                                                            this.setState({ extensiondate: date });
                                                                                        }}
                                                                                        minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}
                                                                                        locale={navigator.language}
                                                                                        inline
                                                                                        dateFormat="yyyy-dd-MM"
                                                                                        placeholderText="Select Date"
                                                                                    />

                                                                                </div> */}
                                                                                <div className="col-sm-9 mb-2 mb-sm-0">

                                                                                    <DatePicker selected={this.state.extensiondate}
                                                                                        inline
                                                                                        onChange={(date) => {
                                                                                            this.setState({ extensiondate: date });
                                                                                        }} />
                                                                                </div>
                                                                                {/* <Calendar
          onChange={this.onChange} // Define the onChange event handler
          value={this.state.date} // Pass the date value from state
        /> */}
                                                                                {/* <!-- End Row --> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="accountType" className="row mb-4">
                                                                        <label className="col-sm-3 col-form-label form-label">Time (24 Hour Format)</label>
                                                                        <div className="col-sm-9">
                                                                            <div className="row">
                                                                                <div className="col-sm-3 mb-2 mb-sm-0">

                                                                                    <Select
                                                                                        id="hours"
                                                                                        name="hours"
                                                                                        defaultValue={this.state.hours[0]}
                                                                                        options={this.state.hours}
                                                                                        onChange={this.handleHour}
                                                                                        value={{ value: this.state.hourvalue, label: this.state.hourvalue }}

                                                                                    />
                                                                                    {/* <!-- End Col --> */}

                                                                                </div>

                                                                                <div className="col-sm-3 mb-2 mb-sm-0">
                                                                                    <Select
                                                                                        id="minutes"
                                                                                        name="minutes"
                                                                                        defaultValue={this.state.minutes[0]}

                                                                                        options={this.state.minutes}
                                                                                        onChange={this.handleMinute}
                                                                                        value={{ value: this.state.minutevalue, label: this.state.minutevalue }}

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/* <!-- End Row --> */}
                                                                        </div>
                                                                    </div>
                                                                    <div id="accountType" className="row mb-4">
                                                                        <label className="col-sm-3 col-form-label form-label">Time zone</label>
                                                                        <div className="col-sm-9">
                                                                            <div className="row">
                                                                                <div className="tom-select-custom">
                                                                                    <select className="js-select form-select"
                                                                                        name="timezone" type="text" placeholder="TimeZone" onChange={this.handleTimeZone}>

                                                                                        {

                                                                                            this.state.timeZones.map(timeZone =>
                                                                                            (
                                                                                                <option key={timeZone.Id} selected={timeZone.Id == this.state.selectedTimeZone && "selected"} value={timeZone.Id}>{timeZone.DisplayName} </option>
                                                                                            ))
                                                                                        }

                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            {/* <!-- End Row --> */}
                                                                        </div>
                                                                    </div>


                                                                </form>
                                                                {/* <!-- End Form --> */}
                                                            </div>
                                                            {/* <!-- End Body --> */}
                                                        </div>
                                                        {/* <!--Card-->
                                    <!-- End date picker -->
                                    <!-- Reviewer section -->
                                    <!-- Card --> */}
                                                        {this.state.selectedinterview === 1 &&
                                                            <div id="connectedAccountsSection">
                                                                <div className="card card-centered">
                                                                    <div className="card-header">
                                                                        <h2 className="card-title h4">Reviewer <span className="form-label-secondary text-danger">* </span> <i className="bi-question-circle text-body" style={{ cursor: 'pointer' }} data-tip data-for='reviewers'></i><div></div> <ReactTooltip id="reviewers" place="right" type="dark" effect="float">
                                                                            <span> Interview will be reviewed by selected reviewers </span>
                                                                        </ReactTooltip></h2>
                                                                    </div>


                                                                    <div className="card-body">
                                                                        <div className="table-responsive datatable-custom w-100">
                                                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                <thead className="thead-light">
                                                                                    <tr>

                                                                                        <th
                                                                                        >
                                                                                            Select
                                                                                        </th>
                                                                                        <th
                                                                                        >
                                                                                            Reviewer Email
                                                                                        </th>




                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.interviewReviewers.map((interviewreviewer, index) => {
                                                                                            return (

                                                                                                <tr key={index}>

                                                                                                    <td >
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer"
                                                                                                            name="questions"
                                                                                                            id={index}
                                                                                                            value={interviewreviewer.userName}
                                                                                                            onChange={e => {
                                                                                                                if (this.state.selectedinterview === 1)
                                                                                                                    onRowSelectforinterviewreviewersonewayclose(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 2)
                                                                                                                    onRowSelectforinterviewreviewersonewayopen(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 3)
                                                                                                                    onRowSelectforinterviewreviewerslive(interviewreviewer.userName, e);
                                                                                                            }
                                                                                                            }

                                                                                                        />

                                                                                                    </td>

                                                                                                    <td >
                                                                                                        {interviewreviewer.userName}
                                                                                                    </td>

                                                                                                </tr>


                                                                                            )

                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <!-- End Card --> */}
                                                        {this.state.selectedinterview === 2 &&
                                                            <div id="connectedAccountsSection">
                                                                <div className="card card-centered">
                                                                    <div className="card-header">
                                                                        <h2 className="card-title h4">Reviewer <span className="form-label-secondary text-danger">* </span> <i className="bi-question-circle text-body" style={{ cursor: 'pointer' }} data-tip data-for='reviewers'></i><div></div> <ReactTooltip id="reviewers" place="right" type="dark" effect="float">
                                                                            <span> Interview will be reviewed by selected reviewers </span>
                                                                        </ReactTooltip></h2>
                                                                    </div>


                                                                    <div className="card-body">
                                                                        <div className="table-responsive datatable-custom w-100">
                                                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                <thead className="thead-light">
                                                                                    <tr>

                                                                                        <th
                                                                                        >
                                                                                            Select
                                                                                        </th>
                                                                                        <th
                                                                                        >
                                                                                            Reviewer Email
                                                                                        </th>




                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.interviewReviewers.map((interviewreviewer, index) => {
                                                                                            return (

                                                                                                <tr key={index}>

                                                                                                    <td >
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer"
                                                                                                            name="questions"
                                                                                                            id={index}
                                                                                                            value={interviewreviewer.userName}
                                                                                                            onChange={e => {
                                                                                                                if (this.state.selectedinterview === 1)
                                                                                                                    onRowSelectforinterviewreviewersonewayclose(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 2)
                                                                                                                    onRowSelectforinterviewreviewersonewayopen(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 3)
                                                                                                                    onRowSelectforinterviewreviewerslive(interviewreviewer.userName, e);
                                                                                                            }
                                                                                                            }

                                                                                                        />

                                                                                                    </td>

                                                                                                    <td >
                                                                                                        {interviewreviewer.userName}

                                                                                                    </td>

                                                                                                </tr>

                                                                                            )

                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.selectedinterview === 3 &&
                                                            <div id="connectedAccountsSection">
                                                                <div className="card card-centered">
                                                                    <div className="card-header">
                                                                        <h2 className="card-title h4">Reviewer <span className="form-label-secondary text-danger">* </span> <i className="bi-question-circle text-body" style={{ cursor: 'pointer' }} data-tip data-for='reviewers'></i><div></div> <ReactTooltip id="reviewers" place="right" type="dark" effect="float">
                                                                            <span> Interview will be reviewed by selected reviewers </span>
                                                                        </ReactTooltip></h2>
                                                                    </div>


                                                                    <div className="card-body">
                                                                        <div className="table-responsive datatable-custom w-100">
                                                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                                                <thead className="thead-light">
                                                                                    <tr>

                                                                                        <th
                                                                                        >
                                                                                            Select
                                                                                        </th>
                                                                                        <th
                                                                                        >
                                                                                            Reviewer Email
                                                                                        </th>




                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.interviewReviewers.map((interviewreviewer, index) => {
                                                                                            return (
                                                                                                <tr key={index}>

                                                                                                    <td >
                                                                                                        <input type="checkbox"
                                                                                                            className="form-checkbox cursor-pointer"
                                                                                                            name="questions"
                                                                                                            id={index}
                                                                                                            value={interviewreviewer.userName}
                                                                                                            onChange={e => {
                                                                                                                if (this.state.selectedinterview === 1)
                                                                                                                    onRowSelectforinterviewreviewersonewayclose(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 2)
                                                                                                                    onRowSelectforinterviewreviewersonewayopen(interviewreviewer.userName, e);
                                                                                                                if (this.state.selectedinterview === 3)
                                                                                                                    onRowSelectforinterviewreviewerslive(interviewreviewer.userName, e);
                                                                                                            }
                                                                                                            }

                                                                                                        />

                                                                                                    </td>

                                                                                                    <td >
                                                                                                        {interviewreviewer.userName}

                                                                                                    </td>

                                                                                                </tr>
                                                                                            )

                                                                                        })
                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                        {/* <!-- End Reviewer section --> */}
                                                    </div>}
                                                    <div className="card mb-3 mb-lg-5 ">
                                                        <div className="card-body w-100">
                                                            <div className="d-flex justify-content-between gap-3">
                                                                <Link type="button" className="btn btn-white" to="/">Cancel</Link>
                                                                <button type="button" className="btn btn-primary" disabled={this.state.disableSaveBtn} onClick={this.createInterview}>Create Interview</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>}


                                            </div>
                                        </div>
                                    }


                                </div>
                            </>
                        }
                    </div>

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
                {/*tool tips*/}

            </>
        );
    }
} export default CreateInterview;
