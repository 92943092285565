import * as React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting";
HC_exporting(Highcharts);

function BarChart(props) {
    //console.log("chart data", props);
    const chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: props.chartData.title
        },
        credits: {
            enabled: false
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category',
            title: {
                text: props.chartData.xaxistitle

            }
        },
        yAxis: {
            title: {
                text: props.chartData.yaxistitle
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y}'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        },
        series: props.chartData.series,
        drilldown: props.chartData.drilldown
    };
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default BarChart;
