import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';

import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseJwt } from '../../modules/TokenParser';

import { CheckPermission } from '../../modules/CheckPermission';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../Loader/BeatLoader";
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import StarRatingComponent from 'react-star-rating-component';
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import DatePicker from "../DataPicker/index"
import "react-datepicker/dist/react-datepicker.css";
import ApplicantDetailComponent from '../Leads/ApplicantDetailComponent'
import ReviewInterviewDetailsComponent from '../Interviews/ReviewInterviewDetailsComponent'
import EvaluateInterviewComponent from '../Interviews/EvaluateInterviewComponent'
import ReviewSummaryComponent from '../Interviews/ReviewSummaryComponent'
import Refresh from '../../assets/svg/illustrations/gui-refresh.svg'
import { Oval } from 'react-loader-spinner'

class Applicants extends BaseComponent {

    constructor() {
        super();
        this.state = {

            leads: [],
            isLoadedGetleads: true,
            interviewId: 0,
            activeInterviewsForInvitaion: [],
            leadHeaders: [
                {
                    label: "Candidate Name", key: "fullName"
                },

                {
                    label: "Email", key: "email"
                },
                {
                    label: "Mobile", key: "mobile"
                },
                {
                    label: "Experience", key: "experienceYears"
                },
                {
                    label: "Job Name", key: "jobTitle"
                },
                {
                    label: "Job City Name", key: "jobCityName"
                },
                {
                    label: "Is ShortListed", key: "isShortListed"
                },
                {
                    label: "CV Path", key: "cvFilePath"
                },
                {
                    label: "Lead City Name", key: "leadCityName"
                },

                {
                    label: "Country Name", key: "countryName"
                },
                { label: "SSN", key: "cnic" },
                { label: "Call Center Experience", key: "callCenterExperience" },
                { label: "Lead Date", key: "createdDate" },
                { label: "Current Salary", key: "currentSalary" },
                { label: "Current Company", key: "currentEmployer" },
                { label: "Total Years of Experience", key: "experienceYears" },
                { label: "Facebook Profile", key: "facebook" },
                { label: "How Soon Can You Join", key: "howSoonYouCanJoin" },
                { label: "Lead Id", key: "leadId" },
                { label: "LinkedIn Profile", key: "linkedIn" },
                { label: "Refered By", key: "referredBy" },
                { label: "Seconday Email", key: "secondaryEmail" },
                { label: "Short Listed Date", key: "shortListedDateUtc" },
            ],
            filteredData: [],
            hideFilteration: false,
            order: "DSC",
            candidateCity: "",
            candidateExpYear: { min: 0, max: 350 },
            itemOffset: 0,
            closeLeadsCount: 0,
            openLeadsCount: 0,
            allLeadsCount:0,
            inProcessLeadsCount: 0,
            selectedStage: "",
            leadStatuses: [],
            leadStages: [],
            leadStageStatuses: [],
            selectedRating: 0,
            leadIsShortListed: false,
            leadInterviewsForInvitaion: [],
            interviewInviteModal: false,
            interivewData: false,
            leadId: -1,
            startFilterDate: null,
            endFilterDate: null,
            states: [],
            cities: [],
            countries: [],
            country: "",
            stateSelected: false,
            countrySelected: false,
            filterLeadName: "",
            filterLeadEmail: "",
            enableRating: false,
            ratingModal: false,
            selectedLead: 0,
            leadsFilterModal: false,
            displayfilterLeadEmail: "",
            displayCandidateCity: "",
            displayEndFilterDate: null,
            displayStartFilterDate: null,
            displayCandidateExpYear: { min: 0, max: 350 },
            displayCandidateExpYearStatus: false,
            relevanceScoreFilter: { min: 0, max: 100 },
            showRelevanceScoreFilter: { min: 0, max: 100 },
            searchedCandidate: '',
            resultedSearchedData: [],
            showSelectedCountry: "",
            showCountryValue: "",
            selectedCountryValue: "",
            showIsCountrySelected: false,
            selectedStateValue: "",
            showStateValue: "",
            selectedStatus: "all",
            applicantDetailModal: false,
            applicantId: "",
            reviewInterviewModal: false,
            evaluateInterviewModal: false,
            summaryInterviewModal: false,
            getApplicantsReadyForReview:false,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeApplicantDetailAndOpenRequiredModal = this.closeApplicantDetailAndOpenRequiredModal.bind(this);

    }
    async componentDidMount() {
        if (verifyTokenOnLocal()) {

            await isActiveAccessToken();

            document.title = "Tenant App - Leads";
            await this.getLeadStatuses();
            await this.getCountries();
        }
    }
    // Function to remove the new tag on a lead by its ID
     removeNewTag = async (leadId) => {
        let bearer = validateAndGetFormatedToken();
        let tenantId = null;
        if (bearer) {
            tenantId = parseJwt(bearer).tenantid;
            try {
                let url = `${process.env.REACT_APP_CORE_API}/api/Leads/${leadId}`;
                const response = await axios.put(url, {}, {
                    headers: {
                        'Authorization': `Bearer ${bearer}`,
                        'Content-Type': 'application/json'
                    }
                });
                this.getLeads(this.props.jobId)
            } catch (error) {
                console.error('Error while removing new tag:', error);
                displayErrorMessage('Error while removing new tag!', "top-center");
            }
        } else {
            console.error('Invalid bearer token.');
            displayErrorMessage('Invalid bearer token!', "top-center");
        }
    };


    //Get Leads on job id
    getLeads(jobId) {
        let bearer = validateAndGetFormatedToken();
        let tenantId = null
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            
            this.setState({ IsApplicantsLoading  : 1 });
            let url = process.env.REACT_APP_CORE_API + "/api/Leads/" + tenantId + "/" + jobId;
            axios(url,
                {
                    method: 'Get',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    let temp = [];
                    response.data.jobPositionLeadsDTOs.forEach((item, index) => {
                        Object.keys(this.state.leadStatuses).forEach((val, child_index) => {
                            if (this.state.leadStatuses[val] == item.leadStatus) {
                                response.data.jobPositionLeadsDTOs[index].leadStatus = val
                            }
                        });

                        Object.keys(this.state.leadStages).forEach((val, child_index) => {
                            if (this.state.leadStages[val] == item.leadStage) {
                                response.data.jobPositionLeadsDTOs[index].leadStage = val
                            }
                        });

                        Object.keys(this.state.leadStageStatuses).forEach((val, child_index) => {
                            if (this.state.leadStageStatuses[val] == item.leadStageStatus) {
                                response.data.jobPositionLeadsDTOs[index].leadStageStatus = val
                            }
                        });

                        temp.push({
                            callCenterExperience: item.callCenterExperience != null ? item.callCenterExperience.toString() : "0",
                            leadCityName: item.leadCityName,
                            jobCityName: item.jobCityName,
                            cnic: item.cnic == null ? "" : item.cnic.toString(),
                            countryName: item.countryName,
                            coverLetterFilePath: item.coverLetterFilePath,
                            createdBy: item.createdBy,
                            createdDate: convertUTCDateToBrowserLocale(item.createdDate),
                            currentEmployer: item.currentEmployer,
                            currentSalary: item.currentSalary,
                            cvFilePath: item.cvFilePath,
                            email: item.email,
                            experienceYears: item.experienceYears.toString(),
                            facebook: item.facebook,
                            fullName: item.firstName + " " + item.lastName,
                            howSoonYouCanJoin: item.howSoonYouCanJoin,
                            isShortListed: item.isShortListed,
                            jobTitle: item.jobTitle,
                            jobId: item.jobId,
                            leadId: item.leadId,
                            linkedIn: item.linkedIn,
                            mobile: item.mobile,
                            referredBy: item.referredBy,
                            secondaryEmail: item.secondaryEmail,
                            shortListedDateUtc: item.shortListedDateUtc,
                            stateName: item.stateName,
                            tenantId: item.tenantId,
                            isForFemaleOnly: item.isForFemaleOnly,
                            leadStage: item.leadStage,
                            leadStatus: item.leadStatus,
                            inviteStatus: item.inviteStatus,
                            leadStageStatus: item.leadStageStatus,
                            rating: item.rating,
                            relevanceScore: item.relevanceScore,
                            tag: item.tag == null ? "" : item.tag

                        })
                    });



                    this.setState({
                        leads: temp,
                        filteredData: temp,
                        closeLeadsCount: response.data.closeLeads,
                        openLeadsCount: response.data.openLeads,
                        inProcessLeadsCount: response.data.inProcessLeads,
                        isLoadedGetleads: false,
                        allLeadsCount: response.data.closeLeads + response.data.openLeads + response.data.inProcessLeads,
                        IsApplicantsLoading: 0
                    }, () => {
                        this.filteration();
                    } );

                }).then(() => {
                    this.getInterviewsOnJobId(jobId);
                })
                .catch(err => {
                    this.setState({ IsApplicantsLoading: 0 });
                    console.log("err from get leads", err);

                    if (err.response.data == "No data found") {
                        this.setState({
                            leads: [],
                            isLoadedGetleads: false
                        });
                    }
                    else {
                        displayErrorMessage('Error while fetching leads!', "top-center");
                    }
                });
        }
    }

    /** Clear Applicant Cache */
    ClearApplicantCache() {
        var jobId = this.props.jobId;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/CacheClear/ClearApplicantCache/" + jobId;
            const requestOptions = {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            };

            fetch(url, requestOptions)

                .then((response) => response.json())
                .then((result) => {
                    this.getLeads(this.props.jobId);
                })
                .catch((error) => {
                    console.log("error during ClearApplicantCache", error);
                });
        }
    }



    // Get interviews on job id
    getInterviewsOnJobId(jobId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/Interview/" + jobId;
            axios(url,
                {
                    method: 'Get',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let tempres = [];
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].interviewType == "onewayopen" && new Date(response.data[i].expiryDate) >= new Date()) {
                            tempres.push(response.data[i]);
                        }
                    }
                    this.setState({ activeInterviewsForInvitaion: tempres, interivewData: true })

                })
                .catch(err => {
                    this.setState({ interivewData: true })
                    displayErrorMessage('Error while fetching Interviews!', "top-center");

                });
        }
    }

    //Send email of interview 
    sendEmail(leadId, interviewId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/CandidateInterviewInvites";
            var model = JSON.stringify({ leadId: leadId, interviewId: interviewId, userId: parseJwt(bearer).sub });

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };

            fetch(url, requestOptions)
                .then(response => {
                    console.log("response send email invitaiton", response);
                    displaySuccessMessage('Interview invitation sent !', "top-center");
                    this.setState({ interviewInviteModal: false });

                })
                .catch(err => {
                    displayErrorMessage('Error while Sending Interview Email !', "top-center");

                })



        }
    }

    //Get lead statuses
    async getLeadStatuses() {

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/LeadStatusTypesLookup/";
            axios(url,
                {
                    method: 'Get',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ leadStatuses: response.data });
                }).then(() => {

                    this.getLeadStages();

                })
                .catch(err => {

                    displayErrorMessage('Error while fetching lead status types!', "top-center");

                });
        }
    }

    /** 
     *  Get lead stages
     */
    getLeadStages() {

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/LeadStagesLookup/";
            axios(url,
                {
                    method: 'Get',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ leadStages: response.data });
                }).then(() => {

                    this.getLeadStagesStatuses();
                })
                .catch(err => {

                    displayErrorMessage('Error while fetching lead status types!', "top-center");

                });
        }
    }

    /**
     * Get lead status stages
     */
    getLeadStagesStatuses() {

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/LeadStatusStages/";
            axios(url,
                {
                    method: 'Get',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ leadStageStatuses: response.data });
                }).then(() => {

                    this.getLeads(this.props.jobId);
                })
                .catch(err => {

                    displayErrorMessage('Error while fetching lead status stages!', "top-center");

                });
        }
    }

    /**
       * Update Lead stage & status
       *
       * */
    updateLeadStatus(leadId, stage, status) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            var subId = null;

            subId = parseJwt(bearer).sub;
            let url = process.env.REACT_APP_CORE_API + "/api/Leads/" + leadId + "/" + stage + "/" + status;

            axios(url,
                {
                    method: 'PUT',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data == true) {
                        displaySuccessMessage("Lead status & stage updated");
                        this.getLeads(this.props.jobId);
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Lead details!', "top-center")

                });
        }
    }


    /**
   * Update Lead Rating*/
    updateLeadRatings(leadId, rating) {
        this.setState({ enableRating: true })

        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {

            var subId = null;

            subId = parseJwt(bearer).sub;
            let url = process.env.REACT_APP_CORE_API + "/api/Leads/" + leadId + "/" + rating + "/" + subId;

            axios(url,
                {
                    method: 'PUT',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.data == true) {
                        displaySuccessMessage("Lead rating status updated");                      
                        this.getLeadStatuses();
                        this.setState({ enableRating: false, ratingModal: false })

                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Lead details!', "top-center")
                    this.setState({ enableRating: true })
                });
        }
    }
    /**
    * Get Countries
    * @param {any} event
    */
    async getCountries() {
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_CORE_API + '/api/TenantCountryLookup?tenantid=' + tenantId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            countries: result,

                        })

                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Countries!', "top-center");

                });
        }
    }

    /**
   * Get States
   * @param {any} event
   */
    getStates(countryId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + '/api/StateLookup/' + countryId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            states: result,

                        })

                    }
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching states!', "top-center");

                });
        }
    }


    /**
 * Get Cities
 * @param {any} event
 */
    getCities(stateId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + '/api/CitiesLookup/' + stateId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            cities: result,
                            candidateCity: result[0].locationName

                        })

                    }
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching cities!', "top-center");

                });
        }
    }

    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    filteration = () => {

        let filteredData = this.state.leads.length > 0 && this.state.leads.filter((item) => {
            if (this.state.startFilterDate == null && this.state.endFilterDate == null) {
                return item.leadCityName != null &&
                    item.leadCityName.toLowerCase().indexOf(this.state.candidateCity != null && this.state.candidateCity.trim().toLowerCase()) > -1 &&
                    item.experienceYears <= this.state.candidateExpYear.max &&
                    (this.state.selectedStatus === "all" ? true : item.leadStatus.toLowerCase() === this.state.selectedStatus) &&
                    item.experienceYears >= this.state.candidateExpYear.min &&
                    item.relevanceScore <= this.state.relevanceScoreFilter.max &&
                    item.relevanceScore >= this.state.relevanceScoreFilter.min &&
                    typeof item.fullName === 'string' &&
                    item.fullName.indexOf(this.state.filterLeadName.trim()) > -1 &&
                    typeof item.email === 'string' &&
                    item.email.toLowerCase().indexOf(this.state.filterLeadEmail.trim().toLowerCase()) > -1 &&
                    (
                        item.fullName.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1 ||
                        item.email.toLowerCase().indexOf(this.state.searchedCandidate.trim().toLowerCase()) > -1
                    );
            } else {
                return typeof item.leadCityName === 'string' && item.leadCityName != null &&
                    item.leadCityName.toLowerCase().indexOf(this.state.candidateCity != null && this.state.candidateCity.trim().toLowerCase()) > -1 &&
                    item.experienceYears <= this.state.candidateExpYear.max &&
                    item.experienceYears >= this.state.candidateExpYear.min &&
                    (this.state.selectedStatus === "all" ? true : item.leadStatus.toLowerCase() === this.state.selectedStatus) &&
                    item.relevanceScore <= this.state.relevanceScoreFilter.max &&
                    item.relevanceScore >= this.state.relevanceScoreFilter.min &&
                    typeof item.fullName === 'string' &&
                    item.fullName.indexOf(this.state.filterLeadName.trim()) > -1 &&
                    typeof item.email === 'string' &&
                    item.email.toLowerCase().indexOf(this.state.filterLeadEmail.trim().toLowerCase()) > -1 &&
                    new Date(this.state.startFilterDate) <= new Date(item.createdDate) &&
                    new Date(new Date(this.state.endFilterDate).setDate(new Date(this.state.endFilterDate).getDate() + 1)) >= new Date(item.createdDate);
            }
        });
        if (this.state.getApplicantsReadyForReview) {
            filteredData = filteredData.filter((item) => {
                return item.inviteStatus.toLowerCase() === "completed" || item.inviteStatus.toLowerCase() === "reviewed"
            })
        }
        this.setState({
            filteredData: filteredData,
            resultedSearchedData: filteredData,
            itemOffset:0
            
        })
    }
   
    // Function to handle status change
    handleStatusChange = (event) => {
        this.setState({ selectedStatus: event.target.value },
            () => {
                this.filteration();
            }
        );
    };

    closeApplicantDetailAndOpenRequiredModal = (jobId, interviewId, interviewInviteId, whichModal) => {
        if (whichModal === "review") {
            this.setState({
                applicantDetailModal: false,
                reviewInterviewModal: true,
                jobId: jobId,
                interviewId: interviewId,
                interviewInviteId: interviewInviteId
            })
        }
        else if (whichModal === "evaluate") {
            this.setState({
                applicantDetailModal: false,
                evaluateInterviewModal: true,
                jobId: jobId,
                interviewId: interviewId,
                interviewInviteId: interviewInviteId
            })
        }
        else  {
            this.setState({
                applicantDetailModal: false,
                summaryInterviewModal: true,
                jobId: jobId,
                interviewId: interviewId,
                interviewInviteId: interviewInviteId
            })
        }
        
    }
    //close evaluate interview modal on a certain condition
    closeEvaluateInterviewModal = () => {
        this.setState({
            evaluateInterviewModal: !this.state.evaluateInterviewModal,
            applicantDetailModal:true,
        })
        document.body.classList.remove('modal-open');

    }
    //open the evalute modal in place of review modal
    handleEvaluateModalOpen = () => {
        this.setState({
            reviewInterviewModal: false,
            evaluateInterviewModal: true,
        })
    }

    render() {
        
        const { leads } = this.state;
       

        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC"
                })
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC"
                })
            }
        }

        const shortlistEvent = (event, lead) => {
            if (event.target.checked) {
                let bearer = validateAndGetFormatedToken();
                if (bearer != "" || bearer != null) {

                    let url = process.env.REACT_APP_CORE_API + "/api/LeadsStatus/" + lead.leadId + "/" + parseJwt(bearer).sub + "/" + true;
                    axios(url,
                        {
                            method: 'Get',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            }
                        }).then((response) => {
                            displaySuccessMessage('Shortlist status updated!', "top-center");

                            this.getLeads(this.props.jobId);
                        })
                        .catch(err => {
                            displayErrorMessage('Error from leads change status api!', "top-center");
                            console.log("err from leads change status api", err);
                        });
                }
            }

            else {
                let bearer = validateAndGetFormatedToken();
                if (bearer != "" || bearer != null) {

                    let url = process.env.REACT_APP_CORE_API + "/api/LeadsStatus/" + lead.leadId + "/" + parseJwt(bearer).sub + "/" + false;
                    axios(url,
                        {
                            method: 'Get',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            }
                        }).then((response) => {
                            console.log("response from leads change status api", response);
                            displaySuccessMessage('Shortlist status updated!', "top-center");

                            this.getLeads(this.props.jobId);
                        })
                        .catch(err => {
                            displayErrorMessage('Error from leads change status api!', "top-center");
                        });
                }

            }
        }

        ///Update Rating
        const onStarClick = (rating, leadId) => {
            this.setState({ selectedRating: rating, ratingModal: true, selectedLead: leadId });
            //this.updateLeadRatings(leadId, rating); 
        }
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.filteredData.slice(this.state.itemOffset, endOffset);
        const pageCount = Math.ceil(this.state.filteredData.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredData.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({ itemOffset: newOffset });
        };
        return (
            <>



                {/* <!-- Content --> */}

                {/* <!-- Page Header --> */}

                {/* <!-- End Page Header --> */}
                {/*<!--Card--> */}
                {
                    (this.state.isLoadedGetleads) ?
                        <BeatLoaderComponent />
                        :
                        <>
                            {this.state.leads.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                <div className="card-body py-10">
                                    <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                    <div className="text-center">

                                        <h1>No applicants found.</h1>
                                    </div>

                                </div>
                            </div>
                                :
                                <>
                                    {/*<!-- Start Leads Overview below this line -->*/}
                                    {this.props.displayHeader && (
                                        <div className="row d-none d-lg-flex g-0 mb-2 " >
                                            <div className="col-12 col-md-auto">
                                                <div className="d-flex justify-content-between align-items-center mb-3  ">
                                                    <h5 className="card-header-title fs-2 ">{this.state.leads[0].jobTitle}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                  

                                    {/*<!-- Start Job Data table-->*/}




                                    <div className="row my-0 d-none d-lg-block">

                                        <div className="col-md-12">

                                            <div className="card">
                                                {/*<!-- Header --> */}
                                                <div className="p-3 pb-5 mb-0">

                                                    <div className="row g-3 mb-2 d-flex justify-content-between align-items-center">
                                                        <div className="col-md-auto col-lg-7">
                                                            <h4 className="pt-2 mb-0 pb-2">Applicant Stages</h4>
                                                            <div className="btn-group applicants-status-radio-group d-flex align-items-center" role="group" aria-label="Status Radio button group">
                                                                <div className="form-check me-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="all"
                                                                        id="all"
                                                                        value="all"
                                                                        checked={this.state.selectedStatus === 'all'}
                                                                        onChange={this.handleStatusChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="all">
                                                                        All ({this.state.allLeadsCount})
                                                                    </label>
                                                                </div>
                                                                <div className="form-check me-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="closed"
                                                                        id="closed"
                                                                        value="closed"
                                                                        checked={this.state.selectedStatus === 'closed'}
                                                                        onChange={this.handleStatusChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="closed">
                                                                        Closed ({this.state.closeLeadsCount})
                                                                    </label>
                                                                </div>
                                                                <div className="form-check me-3">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="open"
                                                                        id="open"
                                                                        value="open"
                                                                        checked={this.state.selectedStatus === 'open'}
                                                                        onChange={this.handleStatusChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="open">
                                                                        Open ({this.state.openLeadsCount})
                                                                    </label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="inprocess"
                                                                        id="inprocess"
                                                                        value="inprocess"
                                                                        checked={this.state.selectedStatus === 'inprocess'}
                                                                        onChange={this.handleStatusChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="inprocess">
                                                                        Inprocess ({this.state.inProcessLeadsCount})
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto mt-0 interview-invites-box pt-2 pb-2 pe-3 ps-3">
                                                            <h4 className="mb-0">Interview Invites</h4>
                                                            <div className=" d-flex align-items-center">
                                                                <input type="checkbox" className="me-2 " onChange={() => {
                                                                    this.setState({ getApplicantsReadyForReview: !this.state.getApplicantsReadyForReview }, () => {
                                                                        this.filteration();
                                                                    })
                                                                }}></input>
                                                                <span class="material-symbols-outlined checked-person-icon me-2 f-size-14">
                                                                    how_to_reg
                                                                </span>
                                                                <label className="me-2 mb-0">
                                                                    Ready for review
                                                                </label>
                                                                {/*<span class="material-symbols-outlined f-size-16 green-check-icon">*/}
                                                                {/*    check_circle*/}
                                                                {/*</span>*/}
                                                            </div>                                                            
                                                        </div>

                                                    </div>

                                                        <div className="row">
                                                        <div className="col-md  d-flex">
                                                            <div className="input-group input-group-merge col-md-9">
                                                                <div className="input-group-prepend input-group-text">
                                                                    <i className="bi-search"></i>
                                                                </div>
                                                                <input type="search" className="form-control search-bar-focus"
                                                                    placeholder="Search applicants by name or email..."
                                                                    onChange={(e) => {
                                                                        if (e.target.value === "") {
                                                                            this.setState({ filteredData: leads, searchedCandidate: "" },
                                                                                () => { this.filteration(); });
                                                                        }

                                                                    }}

                                                                    onKeyDown={(event) => {
                                                                        if (event.key === 'Enter') {
                                                                            this.setState({ searchedCandidate: event.target.value },
                                                                                () => { this.filteration(); })
                                                                            event.preventDefault();

                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-3 d-flex justify-content-end align-items-center">
                                                                <div class="icon-wrapper me-2 " >

                                                                    <i className="bi bi-funnel-fill filter-icon-hover" style={{ padding: "7px" }}
                                                                        onClick={() => {
                                                                            this.setState({ leadsFilterModal: true, })
                                                                            document.body.classList.add('modal-open');
                                                                            if (this.state.displayCandidateCity) {
                                                                                this.setState({ candidateCity: this.state.displayCandidateCity } )
                                                                            }


                                                                        }}
                                                                    >
                                                                    </i>
                                                                </div>
                                                                <div class="icon-wrapper " >

                                                                    <CSVLink data={this.state.filteredData} filename={"Leads.csv"}
                                                                        headers={this.state.leadHeaders} className="">
                                                                        <i className="bi bi-filetype-csv filter-icon-hover" style={{ fontSize: "1.5rem", color: "green", padding: "7px" }}></i>
                                                                    </CSVLink>
                                                                </div>
                                                                <div className="col-md-auto d-flex align-items-center  mx-2">
                                                                    <img className="Refresh-Button" src={Refresh} alt="Refresh Jobs" data-hs-theme-appearance="default" data-tip data-for="refreshIcon"
                                                                        onClick={() => {
                                                                            this.ClearApplicantCache();
                                                                        }}
                                                                    />
                                                                    <ReactTooltip id="refreshIcon" place="top" type="dark" effect="float">
                                                                        <span>Refresh</span>
                                                                    </ReactTooltip>
                                                                </div>
                                                            </div>

                                                            
                                                        </div>
                                                       
                                                     
                                                       
                                                    </div>
                                                    <div className="col d-flex justify-content-end">

                                                        {this.state.displayfilterLeadEmail && (
                                                            <div className="filtered-div f-size-14 mx-1">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="filter-overflow " >
                                                                        {this.state.displayfilterLeadEmail}
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({ displayfilterLeadEmail: '', filterLeadEmail: "" },
                                                                                () => { this.filteration(); }
                                                                            )
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>


                                                        )}
                                                        {this.state.displayCandidateCity && (
                                                            <div className="filtered-div f-size-14 mx-1 d-flex align-items-center" >
                                                                {this.state.displayCandidateCity}
                                                                <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ marginLeft: '8px' }}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            displayCandidateCity: '', candidateCity: "",
                                                                            stateSelected: false,
                                                                            countrySelected: false,
                                                                            country: ""
                                                                        },
                                                                            () => { this.filteration(); }
                                                                        )
                                                                    }}
                                                                >
                                                                </button>
                                                            </div>
                                                        )}
                                                        {this.state.displayStartFilterDate && (
                                                            <div className="filtered-div f-size-14 mx-1 d-flex align-items-center" >
                                                                <span className=" " >
                                                                    {this.state.displayStartFilterDate.toDateString()}, {this.state.displayEndFilterDate.toDateString()}
                                                                </span>
                                                                <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ marginLeft: '8px' }}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            displayStartFilterDate: null, startFilterDate: null,
                                                                            displayEndFilterDate: null, endFilterDate: null,


                                                                        },
                                                                            () => { this.filteration(); }
                                                                        )
                                                                    }}
                                                                >
                                                                </button>
                                                            </div>
                                                        )}
                                                        {this.state.displayCandidateExpYearStatus && (
                                                            (this.state.displayCandidateExpYear.min !== 0 || this.state.displayCandidateExpYear.max !== 350) && (
                                                                <div className="filtered-div f-size-14 mx-1 d-flex align-items-center">
                                                                    ({this.state.displayCandidateExpYear.min} - {this.state.displayCandidateExpYear.max}) Months

                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ marginLeft: '8px' }}
                                                                        onClick={(e) => {
                                                                            this.setState(
                                                                                {
                                                                                    displayCandidateExpYear: "",
                                                                                    candidateExpYear: { min: 0, max: 350 },
                                                                                    displayCandidateExpYearStatus: false,
                                                                                },
                                                                                () => {
                                                                                    this.filteration();
                                                                                }
                                                                            );
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            )
                                                        )}
                                                        {this.state.showRelevanceScoreFilter && (
                                                            (this.state.showRelevanceScoreFilter.min !== 0 || this.state.showRelevanceScoreFilter.max !== 100) && (
                                                                <div className="filtered-div f-size-14 mx-1 d-flex align-items-center">
                                                                    ({this.state.showRelevanceScoreFilter.min} - {this.state.showRelevanceScoreFilter.max}) Score

                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ marginLeft: '8px' }}
                                                                        onClick={(e) => {
                                                                            this.setState(
                                                                                {
                                                                                    showRelevanceScoreFilter: "",
                                                                                    relevanceScoreFilter: { min: 0, max: 100 },
                                                                                },
                                                                                () => {
                                                                                    this.filteration();
                                                                                }
                                                                            );
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            )
                                                        )}







                                                       
                                                    </div>

                                                </div>

                                                {/*<!-- End Header --> */}

                                                {/*<!-- Table --> */}
                                                <div className="table-responsive datatable-custom">

                                                    <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" >
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th style={{ width: '0px' }} > <strong>Id <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='shortlist'></i> <ReactTooltip id="shortlist" place="right" type="dark" effect="float" >
                                                                    <span> To short list applicant please select checkbox</span>
                                                                </ReactTooltip></strong></th>
                                                                    
                                                                <th onClick={() => sorting("fullName")} style={{ cursor: 'pointer', width: '0px' }}><strong>Applicant <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                <th style={{ width: '0px' }} ><strong> Applied On  <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='submittedDate'></i> <ReactTooltip id="submittedDate" place="right" type="dark" effect="float" >
                                                                    <span>Applicant Generated Date</span>
                                                                </ReactTooltip></strong></th>
                                                                <th style={{ width: '0px' }}><strong>Experience</strong></th>
                                                                <th style={{ width: '0px' }}><strong> Status  <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} id="stageAndStatus" data-tip data-for='stageAndStatus'></i> <ReactTooltip id="stageAndStatus" place="right" type="dark" effect="float" >
                                                                    <span> Current staus, stage & stage status of lead</span>
                                                                </ReactTooltip></strong></th>
                                                                <th style={{ width: '15%' }}><strong> Rating</strong></th>
                                                                <th style={{ width: '15%' }}><strong>Relevancy Score</strong></th>
                                                                <th style={{ width: '0px' }}><strong>Action</strong></th>
                                                            </tr>

                                                        </thead>

                                                        <tbody>
                                                            {currentItems.length > 0 && currentItems.map((lead, index) => {
                                                                return <tr key={index} >
                                                                    <td>
                                                                       
                                                                        <div className="d-flex align-items-center">
                                                                            {this.state.getApplicantsReadyForReview &&
                                                                                <span class="material-symbols-outlined checked-person-icon me-1">
                                                                                    how_to_reg
                                                                                </span>
                                                                            }
                                                                            {(lead.tag.toLowerCase() == "new") && <label className='badge rounded-pill bg-danger text-white fw-bold mb-1 me-0'>New</label>}
                                                                             
                                                                        </div>
                                                                       
                                                                        <div className="d-block ms-1">
                                                                            <input type="checkbox"
                                                                                checked={lead.isShortListed}
                                                                                style={{ cursor: 'pointer', width: '12px', height: '12px', paddingLeft: '0px', paddingRight: '0px', marginRight: '5px' }}
                                                                                onChange={(event) => {
                                                                                    shortlistEvent(event, lead);
                                                                                }}
                                                                            />
                                                                            <span className=" h5 text-wrap f-gray">{lead.leadId}</span>
                                                                        </div>

                                                                    </td>
                                                                    <td data-tip data-for={'leadDetails' + index} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        this.setState({
                                                                            applicantDetailModal: true,
                                                                            applicantId: lead.leadId
                                                                        })
                                                                        if (lead.tag.toLowerCase() == "new") {
                                                                            this.removeNewTag(lead.leadId)
                                                                        }
                                                                    } } >
                                                                        <span className="d-block h5 mb-0 text-wrapper inter-ff">{lead.fullName}</span>
                                                                            <span className="d-block  f-size-14 text-wrapper f-gray inter-ff text-overflow-leads-table">{lead.email}</span>
                                                                            <span className="d-block  f-size-14 text-wrapper f-gray inter-ff text-overflow-leads-table">{lead.mobile}</span>

                                                                        <ReactTooltip id={'leadDetails' + index} place="right" type="dark" effect="float" >
                                                                            <span className="d-block fs-5 text-wrap">{lead.fullName}</span>
                                                                            <span className="d-block fs-5 text-wrap">{lead.email}</span>
                                                                            <span className="badge bg-secondary text-white rounded-pill">{lead.mobile}</span>
                                                                            <span className="d-block fs-5 text-wrap">{lead.leadCityName}</span>
                                                                        </ReactTooltip>
                                                                    </td>
                                                                   
                                                                    <td>
                                                                        <span className="d-block mb-0 f-size-14  f-gray inter-ff">{lead.createdDate}</span>


                                                                    </td>


                                                                    <td>
                                                                        <span className="d-block mb-0 f-size-14  f-gray inter-ff">{lead.experienceYears} Months <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='exp'></i> <ReactTooltip id="exp" place="right" type="dark" effect="float" >
                                                                            <span> Experience Months</span>
                                                                        </ReactTooltip></span>
                                                                        {(lead.cvFilePath == null || lead.cvFilePath == "") ? "[No CV]" : <a className="btn-link" type="button" style={{ marginLeft: '1%' }}
                                                                            href={lead.cvFilePath} target="_blank"><i className="bi bi-cloud-arrow-down me-1" style={{ fontSize: '2rem' }}></i></a>}
                                                                    </td>
                                                                    <td>
                                                                        <span className="text-wrap d-block mb-0 f-size-14  f-gray inter-ff">{lead.leadStatus}</span>



                                                                    </td>
                                                                    <td>
                                                                        <span className="d-block h4" data-tip data-for={'rating' + index} id={"rating" + index} style={{width:"90px"} }>
                                                                            <div style={{ fontSize: '20px' }}><StarRatingComponent
                                                                                id={"rating" + index}
                                                                                name={"rate" + index}
                                                                                starCount={5}
                                                                                value={parseInt(lead.rating)}
                                                                                onStarClick={(e) => {
                                                                                    onStarClick(e, lead.leadId);
                                                                                }}
                                                                            />
                                                                            </div>
                                                                            <ReactTooltip id={"rating" + index} place="right" type="dark" effect="float">
                                                                                <span> On select will update rating</span>
                                                                            </ReactTooltip>
                                                                        </span>
                                                                    </td>
                                                                    <td>

                                                                        {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                            lead.relevanceScore <= 10 &&
                                                                            <label
                                                                                data-tip
                                                                                data-for='relevanceScore'
                                                                                className='lead-rating-badge f-wieght-500 badge-red-color fw-bold fs-6 inter-ff'
                                                                            >
                                                                                {lead.relevanceScore}% Match
                                                                                <ReactTooltip id='relevanceScore' place='right' type='dark' effect='float'>
                                                                                    <span> Relevance Score</span>
                                                                                </ReactTooltip>
                                                                            </label>

                                                                        }
                                                                        {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                            (lead.relevanceScore > 11 && lead.relevanceScore <= 30) &&
                                                                            <label data-tip data-for='relevanceScore'
                                                                                className='lead-rating-badge f-wieght-500 badge-blue-color fw-bold fs-6 inter-ff'
                                                                            >{lead.relevanceScore}% Match
                                                                                <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                    <span> Relevance Score</span>
                                                                                </ReactTooltip>
                                                                            </label>
                                                                        }

                                                                        {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                            (lead.relevanceScore > 30 && lead.relevanceScore < 50) &&
                                                                            <label data-tip data-for='relevanceScore'
                                                                                className='lead-rating-badge f-wieght-500 badge-yellow-color fw-bold fs-6 inter-ff'
                                                                            >{lead.relevanceScore}% Match
                                                                                <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                    <span> Relevance Score</span>
                                                                                </ReactTooltip>
                                                                            </label>
                                                                        }

                                                                        {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                            (lead.relevanceScore >= 50 && lead.relevanceScore < 80) &&
                                                                            <label data-tip data-for='relevanceScore'
                                                                                className='lead-rating-badge f-wieght-500 badge-gray-color fw-bold fs-6 inter-ff'
                                                                            >{lead.relevanceScore}% Match
                                                                                <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                    <span> Relevance Score</span>
                                                                                </ReactTooltip>
                                                                            </label>
                                                                        }
                                                                        {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                            (lead.relevanceScore > 80) &&
                                                                            <label data-tip data-for='relevanceScore'
                                                                                className='lead-rating-badge f-wieght-500 badge-green-color fw-bold fs-6 inter-ff'
                                                                            >{lead.relevanceScore}% Match
                                                                                <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                    <span> Relevance Score</span>
                                                                                </ReactTooltip>
                                                                            </label>
                                                                        }
                                                                    </td>

                                                                    <td>
                                                                        <button 
                                                                            className="btn btn-outline-primary btn-icon btn-sm me-2"
                                                                            id="leadDetails"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    applicantDetailModal: true,
                                                                                    applicantId: lead.leadId
                                                                                })
                                                                                if (lead.tag.toLowerCase() == "new") {
                                                                                    this.removeNewTag(lead.leadId)
                                                                                }                                                                            }}
                                                                        >
                                                                            <i className="bi bi-info-square" data-tip data-for='leadDetails'></i>

                                                                            <ReactTooltip id="leadDetails" place="right" type="dark" effect="float">
                                                                                <span> View Applicant Details </span>
                                                                            </ReactTooltip>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-outline-primary btn-icon btn-sm me-2"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    interviewInviteModal: true,
                                                                                    leadIsShortListed: lead.isShortListed,
                                                                                    leadId: lead.leadId
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i class="bi bi-send" data-tip data-for='interviewAnswerReviews'></i>
                                                                            <ReactTooltip id="interviewAnswerReviews" place="right" type="dark" effect="float">
                                                                                <span> Send Interview Invite</span>
                                                                            </ReactTooltip>
                                                                        </button>


                                                                    </td>
                                                                </tr>
                                                            })}


                                                        </tbody>
                                                    </table>
                                                </div>


                                                {/*<!-- End Table --> */}






                                                {/*<!-- Footer --> */}
                                                {this.state.filteredData.length === 0 ? (
                                                    <div className="card card-centered  m-3 mb-lg-5">
                                                        <div className="card-body py-10">
                                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                            <div className="text-center">

                                                                <h1>No search results were found </h1>
                                                                <p>Please refine your search</p>

                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="card-footer">
                                                            <div className="d-flex justify-content-center">
                                                                
                                                                <ReactPaginate
                                                                    key={this.state.itemOffset} // Add key prop here to force re-render
                                                                    breakLabel="..."
                                                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                    onPageChange={handlePageClick}
                                                                    pageRangeDisplayed={5}
                                                                    pageCount={pageCount}
                                                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                    renderOnZeroPageCount={null}
                                                                    breakClassName={'page-item'}
                                                                    breakLinkClassName={'page-link'}
                                                                    containerClassName={'pagination'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={'active'}
                                                                    forcePage={this.state.itemOffset / 10} 
                                                                   
                                                                />
                                                        </div>

                                                    </div>
                                                )}

                                                {this.state.IsApplicantsLoading > 0 && (
                                                    <div class="overlay">
                                                        <div className="overlay-content">
                                                            <Oval
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                color="#4fa94d"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {/*<!-- End Footer --> */}
                                            </div>

                                        </div>
                                    </div>

                                    {/*Mobile start here*/}
                                    <div className="row justify-content-center align-items-center g-0 p-0 mt-1 d-lg-none">
                                        <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>
                                            <div className="col" style={{ marginRight: '.6rem' }}>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend input-group-text">
                                                            <i className="bi-search"></i>
                                                        </div>
                                                        <input
                                                            type="search"
                                                            className="form-control"
                                                            placeholder="Search applicants..."
                                                            onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    this.setState({ filteredData: leads, searchedCandidate: "" },
                                                                        () => { this.filteration(); });
                                                                }

                                                            }}

                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    this.setState({ searchedCandidate: event.target.value },
                                                                        () => { this.filteration(); })
                                                                    event.preventDefault();

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn  no-border py-2 me-2 " style={{ backgroundColor: '#3FB5C3' }}
                                                onClick={() => {
                                                    this.setState({ leadsFilterModal: true })
                                                    this.getCities(this.state.states[this.state.showStateValue].locationId);
                                                    document.body.classList.add('modal-open');
                                                    this.getCities(this.state.states[this.state.showStateValue].locationId);


                                                }}
                                            >
                                                <i className="bi bi-funnel" style={{ fontSize: '0.8rem', color: 'white' }}></i>
                                            </button>
                                            <CSVLink data={this.state.filteredData} filename={"Leads.csv"}
                                                headers={this.state.leadHeaders} className="btn btn-primary float-end btn-sm d-flex align-items-center">
                                                <i class="bi bi-download me-2"></i>Export CSV
                                            </CSVLink>
                                        </div>

                                        <div className="row mt-2   g-0 d-flex filter-chips-mb  mb-2 b d-lg-none" style={{ overflowX: 'auto' }}>
                                            <div className="col-12">
                                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                                                        {this.state.displayfilterLeadEmail && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="">{this.state.displayfilterLeadEmail}</span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({ displayfilterLeadEmail: '', filterLeadEmail: "" },
                                                                                () => { this.filteration(); }
                                                                            )
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.displayCandidateCity && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="">{this.state.displayCandidateCity}</span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                displayCandidateCity: '', candidateCity: "",
                                                                                stateSelected: false,
                                                                                countrySelected: false,
                                                                                country: ""
                                                                            },
                                                                                () => { this.filteration(); }
                                                                            )
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.displayStartFilterDate && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="">
                                                                        {this.state.displayStartFilterDate.toDateString()}, {this.state.displayEndFilterDate.toDateString()}
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                displayStartFilterDate: null, startFilterDate: null,
                                                                                displayEndFilterDate: null, endFilterDate: null,


                                                                            },
                                                                                () => { this.filteration(); }
                                                                            )
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state.displayCandidateExpYearStatus && (
                                                            (this.state.displayCandidateExpYear.min !== 0 || this.state.displayCandidateExpYear.max !== 350) && (
                                                                <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="">
                                                                            ({this.state.displayCandidateExpYear.min} - {this.state.displayCandidateExpYear.max}) Months
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState(
                                                                                    {
                                                                                        displayCandidateExpYear: "",
                                                                                        candidateExpYear: { min: 0, max: 350 },
                                                                                        displayCandidateExpYearStatus: false,
                                                                                    },
                                                                                    () => {
                                                                                        this.filteration();
                                                                                    }
                                                                                );
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>
                                                            ))}


                                                        {this.state.showRelevanceScoreFilter && (
                                                            (this.state.showRelevanceScoreFilter.min !== 0 || this.state.showRelevanceScoreFilter.max !== 100) && (
                                                                <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="">
                                                                            ({this.state.showRelevanceScoreFilter.min} - {this.state.showRelevanceScoreFilter.max}) Score
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState(
                                                                                    {
                                                                                        showRelevanceScoreFilter: "",
                                                                                        relevanceScoreFilter: { min: 0, max: 100 },
                                                                                    },
                                                                                    () => {
                                                                                        this.filteration();
                                                                                    }
                                                                                );
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" col-md-12">
                                            <div className="card border-0 jc-radius pb-3">
                                                <table className="table jobs-overview-table">
                                                    <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                        <tr>
                                                            <th scope="col " className="p-3" colSpan={3}>
                                                                {this.state.leads[0].jobTitle}

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {currentItems.length > 0 && currentItems.map((lead, index) => (
                                                            <tr key={index}>
                                                                <td className="p-3">
                                                                    <div className="d-flex align-items-center mb-0">
                                                                        <div className="col d-flex">
                                                                            <div>
                                                                                <div className="d-block ms-1">
                                                                                    <input type="checkbox"
                                                                                        checked={lead.isShortListed}
                                                                                        style={{ cursor: 'pointer', width: '12px', height: '12px', paddingLeft: '0px', paddingRight: '0px', marginRight: '5px' }}
                                                                                        onChange={(event) => {
                                                                                            shortlistEvent(event, lead);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div>
                                                                                    <h2 className="inter-ff f-size-14 f-weight-500 mx-2">{lead.fullName} </h2>
                                                                                </div>


                                                                                {(lead.tag.toLowerCase() == "new") && <label className='badge rounded-pill bg-danger text-white fw-bold mx-2 mb-1'>New</label>}

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3  align-middle d-flex gap-2 justify-content-end mb-1">
                                                                            <div>
                                                                                <Link to={"/leaddetails/" + lead.leadId}
                                                                                    className="btn btn-outline-primary btn-icon btn-sm "
                                                                                    id="leadDetails"
                                                                                >
                                                                                    <i className="bi bi-info-square" data-tip data-for='leadDetails'></i>

                                                                                    <ReactTooltip id="leadDetails" place="right" type="dark" effect="float">
                                                                                        <span> View Lead Details </span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            </div>
                                                                            <div>
                                                                                <button
                                                                                    className="btn btn-outline-primary btn-icon btn-sm "
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            interviewInviteModal: true,
                                                                                            leadIsShortListed: lead.isShortListed,
                                                                                            leadId: lead.leadId
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <i class="bi bi-send" data-tip data-for='interviewAnswerReviews'></i>
                                                                                    <ReactTooltip id="interviewAnswerReviews" place="right" type="dark" effect="float">
                                                                                        <span> Send Interview Invite</span>
                                                                                    </ReactTooltip>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span className="material-symbols-outlined f-size-12">
                                                                                    location_on
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Location:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {lead.leadCityName}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span className="material-symbols-outlined f-size-12">mail</span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Email:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {lead.email}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span className="material-symbols-outlined f-size-12">
                                                                                    pending_actions
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Lead Date:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {lead.createdDate}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span className="material-symbols-outlined f-size-12">
                                                                                    work_history
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Experience:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {lead.experienceYears} Months
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    monitoring
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Lead Status:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {lead.leadStatus}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    star
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Lead Rating:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 d-flex align-items-center " style={{ fontSize: '20px' }}>
                                                                                <StarRatingComponent
                                                                                    id={"rating" + index}
                                                                                    name={"rate" + index}
                                                                                    starCount={5}
                                                                                    value={parseInt(lead.rating)}
                                                                                    onStarClick={(e) => {
                                                                                        onStarClick(e, lead.leadId);
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    star
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Relevancy Score:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                                    lead.relevanceScore <= 10 &&
                                                                                    <label
                                                                                        data-tip
                                                                                        data-for='relevanceScore'
                                                                                        className='lead-rating-badge-mb f-wieght-500 badge-red-color-mb f-size-10 f-weight-500 inter-ff'
                                                                                    >
                                                                                        {lead.relevanceScore}% Match
                                                                                        <ReactTooltip id='relevanceScore' place='right' type='dark' effect='float'>
                                                                                            <span> Relevance Score</span>
                                                                                        </ReactTooltip>
                                                                                    </label>
                                                                                }
                                                                                {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                                    (lead.relevanceScore > 11 && lead.relevanceScore <= 30) &&
                                                                                    <label data-tip data-for='relevanceScore'
                                                                                        className='lead-rating-badge-mb f-wieght-500 badge-blue-color-mb f-size-10 f-weight-500 inter-ff'
                                                                                    >{lead.relevanceScore}% Match
                                                                                        <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                            <span> Relevance Score</span>
                                                                                        </ReactTooltip>
                                                                                    </label>
                                                                                }

                                                                                {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                                    (lead.relevanceScore > 30 && lead.relevanceScore < 50) &&
                                                                                    <label data-tip data-for='relevanceScore'
                                                                                        className='lead-rating-badge-mb f-wieght-500 badge-yellow-color-mb f-size-10 f-weight-500 inter-ff'
                                                                                    >{lead.relevanceScore}% Match
                                                                                        <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                            <span> Relevance Score</span>
                                                                                        </ReactTooltip>
                                                                                    </label>
                                                                                }

                                                                                {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                                    (lead.relevanceScore >= 50 && lead.relevanceScore < 80) &&
                                                                                    <label data-tip data-for='relevanceScore'
                                                                                        className='lead-rating-badge-mb f-wieght-500 badge-gray-color-mb f-size-10 f-weight-500 inter-ff'
                                                                                    >{lead.relevanceScore}% Match
                                                                                        <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                            <span> Relevance Score</span>
                                                                                        </ReactTooltip>
                                                                                    </label>
                                                                                }
                                                                                {process.env.REACT_APP_RELEVANCE_SCORE === "true" &&
                                                                                    (lead.relevanceScore > 80) &&
                                                                                    <label data-tip data-for='relevanceScore'
                                                                                        className='lead-rating-badge-mb f-wieght-500 badge-green-color-mb f-size-10 f-weight-500 inter-ff'
                                                                                    >{lead.relevanceScore}% Match
                                                                                        <ReactTooltip id="relevanceScore" place="right" type="dark" effect="float">
                                                                                            <span> Relevance Score</span>
                                                                                        </ReactTooltip>
                                                                                    </label>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/*<!-- Footer --> */}
                                                {currentItems.length === 0 ? (
                                                    <div className="card card-centered  m-3 mb-lg-5">
                                                        <div className="card-body py-10">
                                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                            <div className="text-center">

                                                                <h1>No search results were found </h1>
                                                                <p>Please refine your search</p>

                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="card-footer">
                                                        <div className="d-flex justify-content-center">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                            />
                                                        </div>
                                                        <div className="d-flex md-2 float-end">
                                                            <CSVLink data={this.state.filteredData} filename={"Jobs.csv"}
                                                                headers={this.state.jobHeaders} className="btn btn-secondary float-end">
                                                                Export CSV
                                                            </CSVLink>
                                                        </div>
                                                    </div>
                                                )}
                                                {/*<!-- End Footer --> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/*Mobile end here*/}



                                </>
                            }
                        </>
                }

                {/*<!--End Card--> */}
                {/*<!-- End Job Data table--> */}



                {/* <!-- End Content --> */}

                {/*Applicant Detail Modal start here*/}

                {this.state.applicantDetailModal && (
                    <div className="modal d-block" id="applicantDetailModal" tabIndex="-1">
                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Applicant Detail </h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({ applicantDetailModal: false })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>

                                </div>
                                <div className="modal-body pt-3">

                                    <ApplicantDetailComponent applicantId={this.state.applicantId} closeApplicantDetailAndOpenRequiredModal={this.closeApplicantDetailAndOpenRequiredModal }></ApplicantDetailComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Applicant Detail Modal end here*/}

                {/*Review Interview Modal start here*/}

                {this.state.reviewInterviewModal && (
                    <div className="modal d-block" id="reviewInterviewModal" tabIndex="-1">
                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Review Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({
                                                reviewInterviewModal: false,
                                                applicantDetailModal:true,
                                            })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>

                                </div>
                                <div className="modal-body pt-3">

                                    <ReviewInterviewDetailsComponent interviewId={this.state.interviewId} jobId={this.state.jobId} interviewInviteId={this.state.interviewInviteId} handleEvaluateModalOpen={this.handleEvaluateModalOpen }></ReviewInterviewDetailsComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Review Interview Modal end here*/}

                {/*Evaluate Interview Modal Starts here*/}

                {this.state.evaluateInterviewModal && (

                    <div className="modal d-block" id="evaluateInterviewModal" tabIndex="-1">

                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Evaluate Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({
                                                evaluateInterviewModal: false,
                                                applicantDetailModal: true,
                                            })

                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>
                                </div>
                                <div className="modal-body pt-3">
                                    <EvaluateInterviewComponent interviewId={this.state.interviewId} interviewInviteId={this.state.interviewInviteId} closeEvaluateInterviewModal={this.closeEvaluateInterviewModal}></EvaluateInterviewComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Evaluate Interview Modal end here*/}

                {/*Summary Interview Modal start here*/}



                {this.state.summaryInterviewModal && (
                    <div className="modal d-block" id="summaryInterviewModal" tabIndex="-1">

                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Review Summary Of Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({
                                                summaryInterviewModal: false,
                                                applicantDetailModal: true,
                                            })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>
                                </div>

                                <div className="modal-body pt-3">
                                    <ReviewSummaryComponent interviewId={this.state.interviewId}  interviewInviteId={this.state.interviewInviteId} ></ReviewSummaryComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}

                {
                    this.state.interviewInviteModal &&
                    <div className=" d-block modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header ">
                                    <h4 className="modal-title whiteColor" id="accountUpdatePlanModalLabel">Interview Invitation</h4>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ interviewInviteModal: false }) }}></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">


                                    <div className="row">
                                        {this.state.leadIsShortListed ? <>
                                            {!(this.state.interivewData) ? <BeatLoaderComponent /> :

                                                    this.state.activeInterviewsForInvitaion.length > 0 ? <>
                                                    <div className="tom-select-custom mb-0" >
                                                        <label>Interviews</label>
                                                        <select className="form-select d-inline"
                                                            name="country" onChange={(e) => {
                                                                this.setState({ interviewId: e.target.value })
                                                            }}>

                                                            <>
                                                                <option value="-1">Select Interview</option>
                                                                    {this.state.activeInterviewsForInvitaion != [] && this.state.activeInterviewsForInvitaion.map((interviewinvitation, index) =>
                                                                    <option key={index} value={interviewinvitation.interviewId}>{interviewinvitation.expiryDate}=>{interviewinvitation.interviewType}</option>
                                                                )}
                                                            </>

                                                        </select>

                                                    </div>
                                                </>
                                                    :
                                                    <h4>No interview found</h4>
                                            }
                                        </>
                                            :
                                                <h3 className="h4 inter-ff">Please shortlist the lead first</h3>

                                        }

                                    </div>
                                    {/* <!-- End Row --> */}



                                    <div className="d-flex justify-content-center justify-content-sm-end gap-3 mt-3">
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            if (this.state.interviewId != 0 && this.state.interviewId != null || this.state.interviewId == -1) {
                                                this.sendEmail(this.state.leadId, this.state.interviewId);
                                            }
                                            else {
                                                displayWarningMessage("Please select interview first", "top-center");
                                                return;
                                            }
                                        }}>Send Invite</button>
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ interviewInviteModal: false }) }}>Cancel</button>

                                    </div>
                                </div>
                                {/* <!-- End Body --> */}
                            </div>
                        </div>
                    </div>
                }

                {this.state.ratingModal &&
                    <div className=" d-block modal" id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                {/* <!-- Header --> */}
                                <div className="modal-header ">
                                    <h4 className="modal-title whiteColor" id="accountUpdatePlanModalLabel">Rating Update</h4>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ ratingModal: false }) }}></button>
                                </div>
                                {/* <!-- End Header --> */}

                                {/* <!-- Body --> */}
                                <div className="modal-body">
                                    <div className="row">
                                        {/* <!-- Card --> */}
                                        <h3 className="h4 inter-ff" >Do you want to update rating?</h3>
                                        {/* <!-- End Card --> */}
                                    </div>
                                    {/* <!-- End Row --> */}
                                        <div className="d-flex justify-content-center justify-content-sm-end gap-3">
                                            <button type="button" className="btn btn-primary" disabled={this.state.enableRating} data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.updateLeadRatings(this.state.selectedLead, this.state.selectedRating) }}>Yes</button>
                                            <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ ratingModal: false }) }}>No</button>
                                        </div>
                                {/* <!-- End Body --> */}
                                </div>
                            </div>
                        </div>

                    </div>


                }

                {/* leads filter modal start Here */}

                {this.state.leadsFilterModal && (
                    <>
                        {/* leads filter start here */}
                        <div className="row justify-content-center align-items-center g-0 p-0">
                            <div className="col-md-12 ">
                                <div
                                    className="d-block modal  right"
                                    id="AddIntroVideo"
                                    tabIndex={-1}
                                    aria-labelledby="accountUpdatePlanModalLabel"
                                    role="dialog"
                                    aria-hidden="true"
                                >
                                    <div
                                        className="modal-dialog filter-modal-width jobs-filter-modal-dialog "
                                    >
                                        <div className="modal-content jobs-filter-modal no-border  ">
                                            {/* Header */}
                                            <div
                                                className="modal-header no-border p-3 pb-4 pt-4 d-flex align-items-center"
                                                style={{ backgroundColor: "#F0F2F7" }}
                                            >
                                                <h1 className="f-size-24 f-weight-700">Applicants Filters</h1>
                                                <button type="button"
                                                    onClick={() => {
                                                        this.setState({
                                                            leadsFilterModal: false,
                                                            candidateExpYear: this.state.displayCandidateExpYear,
                                                            startFilterDate: this.state.displayStartFilterDate,
                                                            endFilterDate: this.state.displayEndFilterDate,
                                                            relevanceScoreFilter: this.state.showRelevanceScoreFilter,
                                                            country: this.state.showSelectedCountry,
                                                            selectedStateValue: this.state.showStateValue,
                                                            countrySelected: this.state.showIsCountrySelected


                                                        });
                                                        document.body.classList.remove('modal-open');
                                                        this.getStates(this.state.countries[this.state.showCountryValue].locationId);


                                                    }}
                                                    className="btn-close" aria-label="Close" />
                                            </div>
                                            {/* End Header */}
                                            {/* Body */}
                                            <div className="modal-body">
                                                <div className="row g-0 mb-4">

                                                    <input type="text" className="form-control" value={this.state.filterLeadEmail}
                                                        onChange={(e) => { this.setState({ filterLeadEmail: e.target.value }) }}
                                                        name="leadEmail" id="leadEmail" placeholder="Search Email"
                                                        />
                                                </div>
                                                <div className="row mb-4">
                                                    <label
                                                        htmlFor="created-date"
                                                        className="f-gray f-weight-600 f-size-14 mb-3"
                                                    >
                                                        LEAD DATE
                                                    </label>
                                                    <div className="d-flex gap-2">
                                                        <div className="col">
                                                            <label
                                                                htmlFor="from-date"
                                                                className="f-gray f-weight-600 f-size-12 mb-1"
                                                            >
                                                                FROM
                                                            </label>
                                                            <DatePicker selected={this.state.startFilterDate}
                                                                onChange={(date) => this.setState({ startFilterDate: date, endFilterDate: date })}
                                                                selectsStart
                                                                startDate={this.state.startFilterDate}
                                                                endDate={this.state.endFilterDate}
                                                                customInput={<input className="form-control"></input>}
                                                                placeholderText="From Create Date"
                                                                placeholder="From Create Date" />
                                                        </div>
                                                        <div className="col">
                                                            <label
                                                                htmlFor="to-date"
                                                                className="f-gray f-weight-600 f-size-12 mb-1"
                                                            >
                                                                TO
                                                            </label>
                                                            <DatePicker
                                                                selected={this.state.endFilterDate}
                                                                onChange={(date) => this.setState({ endFilterDate: date })}
                                                                selectsEnd
                                                                startDate={this.state.startFilterDate}
                                                                endDate={this.state.endFilterDate}
                                                                minDate={this.state.startFilterDate}
                                                                customInput={<input className="form-control"></input>}
                                                                placeholder="To Create Date"

                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                {/*End Row*/}
                                                <div className="row">
                                                    <div className="swft-form-input mb-2">
                                                        <label
                                                            htmlFor="country"
                                                            className="f-gray f-weight-600 f-size-14 mb-3"
                                                        >
                                                            LOCATION
                                                        </label>
                                                        <select className="form-select"
                                                            name="country"
                                                            onChange={(e) => {
                                                                if (e.target.value !== "-1") {
                                                                    this.getStates(this.state.countries[e.target.value].locationId);
                                                                    this.setState({ country: this.state.countries[e.target.value].locationName });
                                                                    this.setState({ countrySelected: true });
                                                                    this.setState({ stateSelected: false });
                                                                    this.setState({ selectedCountryValue: e.target.value })
                                                                }
                                                                else {
                                                                    this.setState({ countrySelected: false });
                                                                    this.setState({ stateSelected: false });
                                                                }
                                                            }}>
                                                            <option key="-1" value="-1">All Countries</option>
                                                            {this.state.countries !== [] && this.state.countries.map((country, index) =>
                                                                <option key={index} value={index} selected={country.locationName === this.state.country}>{country.locationName}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>



                                                {/* End Row */}
                                                <div className="row mb-3 g-0 gap-2">

                                                    {this.state.countrySelected &&
                                                        <div className="col-6 mb-3">
                                                            <select className="form-select"
                                                                name="state"
                                                                onChange={(e) => {
                                                                    if (e.target.value !== "-1") {
                                                                        this.getCities(this.state.states[e.target.value].locationId);
                                                                        this.setState({
                                                                            stateSelected: true,
                                                                            selectedStateValue: e.target.value
                                                                        });
                                                                    } else {
                                                                        this.setState({
                                                                            stateSelected: false,
                                                                        });
                                                                    }
                                                                }}>
                                                                <option key="-1" value="-1" selected={!this.state.stateSelected}>All States</option>
                                                                {this.state.states.length !== 0 && this.state.states.map((state, index) => (
                                                                    <option key={index} value={index} selected={index.toString() === this.state.selectedStateValue}>{state.locationName}</option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                    }
                                                    {this.state.stateSelected &&
                                                        <div className="col mb-3">
                                                            <select className="form-select"
                                                                name="city" onChange={(e) => {
                                                                    this.setState({ candidateCity: e.target.value });
                                                                }}>
                                                                {this.state.cities !== [] && this.state.cities.map((city, index) =>
                                                                    <option key={index} value={city.locationName} selected={city.locationName === this.state.displayCandidateCity}>{city.locationName}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="row">
                                                    <div className="swft-form-input mb-2">
                                                        <label
                                                            htmlFor="customRange"
                                                            className="f-gray f-weight-600 f-size-14 mb-3"
                                                        >
                                                            EXPERIENCE (MONTHS)
                                                        </label>
                                                        <RangeSlider min={0} max={350} step={1} value={[this.state.candidateExpYear.min, this.state.candidateExpYear.max]} onInput={value => {
                                                            if (value[0] > value[1]) {
                                                                displayWarningMessage("Invalid min or max value ", "top-center");
                                                                return;
                                                            }
                                                            let obj = { min: value[0], max: value[1] };
                                                            this.setState({ candidateExpYear: obj })
                                                        }}
                                                            id="react-range-input"
                                                            className="mb-2" />

                                                        <h6 className="mt-2 float-end">Min:{this.state.candidateExpYear.min} - Max:{this.state.candidateExpYear.max}</h6>


                                                    </div>
                                                </div>
                                                {/* End Row */}
                                                <div className="row mb-3 mt-4">
                                                    <div className="swft-form-input ">
                                                        <label
                                                            htmlFor="customRange"
                                                            className="f-gray f-weight-600 f-size-14 mb-3"
                                                        >
                                                            RELEVANCE SCORE{" "}
                                                            <i className="bi-question-circle text-body ms-1 f-gray" />{" "}
                                                        </label>
                                                        <RangeSlider min={0} max={100} step={1} value={[this.state.relevanceScoreFilter.min, this.state.relevanceScoreFilter.max]} onInput={value => {
                                                            if (value[0] > value[1]) {
                                                                displayWarningMessage("Invalid min or max value ", "top-center");
                                                                return;
                                                            }
                                                            let obj = { min: value[0], max: value[1] };
                                                            this.setState({ relevanceScoreFilter: obj })
                                                        }}
                                                            id="react-range-input"
                                                            className="mb-2" />
                                                        <h6 className="mt-2 float-end">Min:{this.state.relevanceScoreFilter.min} - Max:{this.state.relevanceScoreFilter.max}</h6>


                                                    </div>
                                                </div>
                                                {/* End Row */}
                                                <div className="d-flex justify-content-end gap-4 mb-3">
                                                    <button
                                                        type="button"
                                                        className="btn filter-clear-btn f-size-14 f-weight-700"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            this.setState({
                                                                candidateCity: "",
                                                                candidateExpYear: { min: 0, max: 350 },
                                                                startFilterDate: null,
                                                                endFilterDate: null,
                                                                filterLeadName: "",
                                                                filterLeadEmail: "",
                                                                stateSelected: false,
                                                                countrySelected: false,
                                                                displayfilterLeadEmail: "",
                                                                displayCandidateCity: "",
                                                                country: "",
                                                                selectedCountryValue: "",
                                                                showSelectedCountry:"",
                                                                selectedStateValue: "",
                                                                showStateValue: "",
                                                                showIsCountrySelected:false,
                                                                displayCandidateExpYearStatus: false,
                                                                displayStartFilterDate: null, displayEndFilterDate: null,
                                                                displayCandidateExpYear: { min: 0, max: 350 },
                                                                relevanceScoreFilter: { min: 0, max: 100 },


                                                            }, () => {
                                                                this.setState({ filteredData: this.state.resultedSearchedData })
                                                                this.filteration();

                                                            }

                                                            );

                                                        }}
                                                    >
                                                        Clear
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary f-size-14 f-weight-700"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={(event) => {
                                                            if (this.state.startFilterDate != null) {
                                                                if (this.state.endFilterDate == null) {
                                                                    displayWarningMessage('Please select end date !', "top-center");
                                                                    return;
                                                                }
                                                            }
                                                            if (this.state.endFilterDate != null) {
                                                                if (this.state.startFilterDate == null) {
                                                                    displayWarningMessage('Please select start date !', "top-center");
                                                                    return;
                                                                }
                                                            }
                                                            if (this.state.startFilterDate > this.state.endFilterDate) {
                                                                displayErrorMessage("To date should be equal or greater than From date", "top-center");
                                                                return
                                                            }
                                                            this.setState({
                                                                displayfilterLeadEmail: this.state.filterLeadEmail, leadsFilterModal: false, displayCandidateCity: this.state.candidateCity,
                                                                displayStartFilterDate: this.state.startFilterDate,
                                                                displayEndFilterDate: this.state.endFilterDate,
                                                                displayCandidateExpYear: this.state.candidateExpYear,
                                                                displayCandidateExpYearStatus: true,
                                                                showRelevanceScoreFilter: this.state.relevanceScoreFilter,
                                                                showSelectedCountry: this.state.country,
                                                                showStateValue: this.state.selectedStateValue,
                                                                showCountryValue: this.state.selectedCountryValue,
                                                                showIsCountrySelected: this.state.countrySelected


                                                            })
                                                            event.preventDefault();
                                                            if (this.state.startFilterDate != null) {
                                                                if (this.state.endFilterDate == null) {
                                                                    displayWarningMessage('Please select end date !', "top-center");
                                                                    return;
                                                                }
                                                            }
                                                            this.filteration();
                                                            document.body.classList.remove('modal-open');

                                                        }}
                                                    >
                                                        Apply
                                                    </button>

                                                </div>
                                            </div>
                                            {/* End Body */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* leads filter End Here */}
                    </>
                )}


            </>
        );
    }
} export default Applicants;