import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import axios from 'axios';
import StarRatingComponent from 'react-star-rating-component';
import { ToastContainer } from 'react-toastify';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html'
import ReactTooltip from 'react-tooltip';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import { isFirefox } from 'react-device-detect';
import ReactPaginate from 'react-paginate';
import { Oval } from 'react-loader-spinner';
import VideoDownloadButton from '../Helpers/VideoDownloadButton';
import ReviewVideoCommentIcon from '../../assets/svg/illustrations/review-comment-video.svg'
import HlsVideoPlayer from '../Shared/HlsVideoPlayer';
import { getVideoMimeType } from '../../modules/Utilities';

class ReviewInterviewDetailsComponent extends Component {
    //by export module
    // static liveInterviewConst = "live";
    // static tenantAdminRoleConst ="TenantAdmin";


    constructor() {
        super();
        this.state = {


            ratings: [
                { label: 1, value: 1 },
                { label: 2, value: 2 }
                , { label: 3, value: 3 }
                , { label: 4, value: 4 }
                , { label: 5, value: 5 }

            ],
            editorState: EditorState.createEmpty(),
            editorStateMobile: EditorState.createEmpty(),
            overallComment: "",
            fetchedOverallComment: "",
            updateOverallComment: false,

            liveInterviewQuestionsWithReviews: [],
            questionAnswers: [],
            videoUrl: "",
            localVideoUrl: "",
            comment: "",
            openCloseComment: "",
            selectedRating: 0,
            interviewAnswerId: "",
            interviewOverallRating: {},
            interviewAnswerReviewForQuestions: [],
            liveInterviewOverallRating: {},
            liveInterviewOveralRating: 0,
            liveInterviewVideo: "",
            viewReviewPermission: true,
            editReviewPermission: true,
            viewResume: false,
            viewComments: false,
            interviewInvite: {},
            selectedQuestion: 0,
            selectedQuestionObject: {},
            jobDetails: {},
            accessReviewPermission: false,
            evaluations: [{
                label: "Passed",
                value: "Passed"

            },
            {
                label: "Failed",
                value: "Failed"
            },
            {
                label: "Undecided",
                value: "Undecided"
            }
            ],
            selectedEvaluation: "",
            numPages: null,
            pageNumber: 1,
            liveInterviewQuestions: [],
            selectedQuestionObject: {},
            answerReviewModal: false,
            bearer: "",
            disableSaveBtn: false,
            IsEvaluationLoading: 0,
            IsTypeVideo: 0,
            IsTypeVideoAllow: 0,
            autoReviewdata: [],
            isvideoquestion: 0,
            itemOffset: 0,
            activeTab: "details-tab-pane",
            IsAutoReviewLoading: 0,
            disableRatingBtn: false,
            isReviewUpdating: 0,
            autoRateNotWorkedModal: false,
            videoPlayModal: false,
            videoURLForPlayModal:"",
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorStateChangeMobile = this.onEditorStateChangeMobile.bind(this);

        /** Save Live and Open-Closed Interview Answer Reviews
         * Given by reviewer*/
        this.saveLiveInterviewAnswerReviewsHandler = this.saveLiveInterviewAnswerReviewsHandler.bind(this);
        this.saveOpenCloseInterviewAnswerReviewsHandler = this.saveOpenCloseInterviewAnswerReviewsHandler.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Interviews";

        let interviewInviteId = this.props.interviewInviteId;
        this.GetOverallComment(interviewInviteId);
        this.getJobDetails();
        this.interviewReviewPermissions();
        this.interviewReviewAccessPermissions();
    }


    /** get interview invite details */
    getInterviewInvitesForReviews(interviewInviteId, jobTitle) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ bearer: bearer })
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewCandidateInfo/" + interviewInviteId; //Get all filtered interview invites where role!=moderator

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    let interviewInvite =
                    {
                        candidateEmail: result.candidateEmail,
                        firstName: result.firstName,
                        interviewId: result.interviewId,
                        interviewInviteId: result.interviewInviteId,
                        interviewType: result.interviewType,
                        invitationLink: result.invitationLink,
                        inviteExpiredDate: result.displayExpiryDate,
                        inviteRequestedDate: result.displayRequestedDate,
                        inviteStatus: result.inviteStatus,
                        jobId: result.jobId,
                        lastName: result.lastName,
                        cvLink: result.cvLink,
                        jobTitle: jobTitle,
                        profileImageUrl: result.profileImageUrl,
                        isAutoReview: result.isAutoReview,
                    };

                    this.setState({
                        interviewInvite: interviewInvite,
                    });

                    if (interviewInvite.interviewType === "live") {
                        console.log("Interview type is =>" + interviewInvite.interviewType);
                        this.LiveInterviewDetails(this.props.interviewInviteId);
                        this.GetLiveInterviewQuestions(this.props.interviewInviteId);
                    }
                    else {
                        /*Overall interview rating (Average)*/
                        this.InterviewOverallRating();

                        /*Get the question, its text or video answer with rating 0 */
                        this.GetQuestionAndCandidateAnswerWithAvgRating();

                    }

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching interview Invites!', "top-center")

                });

        }


    }


    /** Get Interview review Permission */
    interviewReviewPermissions() {
        let bearer = validateAndGetFormatedToken();
        var viewReviewPermission = false;
        var editReviewPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                viewReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.View", bearer);
                this.setState({ viewReviewPermission: viewReviewPermission });

                editReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.Edit", bearer);
                this.setState({ editReviewPermission: editReviewPermission });
            }

        }
    }

    interviewReviewAccessPermissions() {
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewReviewers/" + + this.props.interviewId + "/" + parseToken(bearer).sub;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data !== "") {
                        this.setState({
                            accessReviewPermission: true,
                        });
                    }
                })
                .catch(err => {
                    displayErrorMessage('Error!', "top-center");

                });

        }
    }


    /** Get Interview Answer Review
     * Get the question, its text or video answer 
     * BUT with rating = 0*/
    GetQuestionAndCandidateAnswerWithAvgRating() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewAnswerReview/" + this.props.interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log("questionAnswers", result);
                    this.setState({
                        questionAnswers: response.data,
                    });
                    this.InterviewAnswerReviewForQuestions();
                })
                .catch(err => {
                    displayErrorMessage('Error while fetching questionAnswers!', "top-center");

                });
        }
    }



    /** Get Rating of answer given by candidate, Multiple reviewers can give 
     * diffrent rating on candidate answer, InterviewAnswerId is key
     *  We need to calculate rating so we get InterviewAnswerId and get its rating
     */

    InterviewAnswerReviewForQuestions() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewResult/GetInterviewAnswerReviews?interviewInviteId=" + this.props.interviewInviteId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                .then((result) => {
                    this.setState({
                        interviewAnswerReviewForQuestions: result
                    });
                    let questionAnswers = this.state.questionAnswers;
                    let selectedQuestion = this.state.selectedQuestionObject;
                    for (let i = 0; i < this.state.questionAnswers.length; i++) {
                        let avgrating = 0;
                        let reviewers = 0;
                        if (selectedQuestion.questionId === this.state.questionAnswers[i].questionId) {
                            this.setState({
                                selectedQuestionObject: this.state.questionAnswers[i],
                            });
                        }
                        for (let j = 0; j < result.length; j++) {
                            if (result[j].questionId === this.state.questionAnswers[i].questionId) {
                                avgrating += result[j].rating;
                                reviewers++;
                            }
                        }
                        if (Number(avgrating / reviewers)) {
                            questionAnswers[i].rating = avgrating / reviewers;

                        }
                        else {
                            questionAnswers[i].rating = 0;

                        }
                        questionAnswers[i].reviewers = reviewers;

                    }
                    this.setState({
                        questionAnswers: questionAnswers,

                    });
                    //verify if comprehension rating is -1 then display error message in the modal
                    if (questionAnswers.length > 0) {
                        for (let i = 0; i < questionAnswers.length; i++) {
                            if (questionAnswers[i].questionContentType === "comprehension" && questionAnswers[i].rating===-1) {
                                this.setState({
                                    autoRateNotWorkedModal: true,
                                });
                            }
                        }
                    }

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching questionAnswers!', "top-center");


                });
        }
    }

    /**
     * Get Job details to preview*/
    getJobDetails() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewJob/" + this.props.interviewId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        jobDetails: result,
                    })
                }).then(() => {
                    this.getInterviewInvitesForReviews(this.props.interviewInviteId, this.state.jobDetails.title);

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Interview Review!', "top-center")

                });
        }
    }

    /** Get Interview
     *  Overall interview rating (Average)
     */
    InterviewOverallRating() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewResult/GetInterviewAnswerRating?interviewInviteId=" + this.props.interviewInviteId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        interviewOverallRating: result,
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Interview Review!', "top-center")

                });
        }
    }




    /** Get Live Interview Overall Review*/

    LiveInterviewDetails() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewResult/" + this.props.interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    this.setState({
                        liveInterviewOverallRating: response.data,
                    })
                    let url1 = process.env.REACT_APP_CORE_API + "/api/LiveInterviewVideos?archiveId=" + response.data.archiveId;
                    axios(url1,
                        {
                            method: 'GET',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            }
                        }).then(response => {
                            this.setState({
                                liveInterviewVideo: response.data,
                            })

                        })
                        .catch(err => {
                            console.log(err);
                            displayErrorMessage('Error while fetching Live Interview Review!', "top-center")

                        });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Live Interview Review!', "top-center")

                });
        }
    }



    /**
     * Get Live Interview Questions
     * @param {any} nextValue
     * @param {any} prevValue
     * @param {any} name
     */

    GetLiveInterviewQuestions(interviewInviteId) {

        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewQuestionAnswerReview/" + interviewInviteId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    console.log("response live interview questions", response);
                    let questionAnswers = response.data;
                    let overallAvgRating = 0;
                    let overallReviewers = 0;

                    for (let i = 0; i < questionAnswers.length; i++) {
                        let avgrating = 0;
                        let reviewers = 0;
                        overallAvgRating = questionAnswers[i].stars;
                        for (let j = 0; j < questionAnswers.length; j++) {
                            if (questionAnswers[j].liveInterviewQuestionId === questionAnswers[i].liveInterviewQuestionId) {
                                avgrating += questionAnswers[j].stars;
                                reviewers++;
                                overallReviewers++;
                            }
                        }
                        if (Number(avgrating / reviewers)) {
                            questionAnswers[i].stars = avgrating / reviewers;

                        }
                        else {
                            questionAnswers[i].stars = 0;

                        }
                        questionAnswers[i].reviewers = reviewers;

                    }
                    overallAvgRating = overallAvgRating / overallReviewers;
                    this.setState({ liveInterviewQuestions: questionAnswers, liveInterviewOveralRating: overallAvgRating });
                    console.log("question", questionAnswers)

                    if (questionAnswers.length > 0) {
                        this.setState({
                            selectedRating: questionAnswers[0].stars,
                            interviewAnswerId: questionAnswers[0].liveInterviewQuestionAnswerId
                        })

                    }
                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Live Interview Questions!', "top-center")

                });
        }
    }



    onStarClick(nextValue, prevValue, name) {
        this.setState({ selectedRating: nextValue });
    }

    /**
   * Text Editor state saving
   * @returns 
   */



    /**
  * Save reviews given by a reviewer against answer
  * gave by candidate
  * OPEN | CLOSED Interview*/
    saveOpenCloseInterviewAnswerReviewsHandler() {

        const interviewInviteId = this.props.interviewInviteId;
        const comments = this.state.openCloseComment;
     
        let reviewModel = {
            interviewAnswerId: this.state.interviewAnswerId,
            rating: this.state.selectedRating,
            comments: comments
        }
        reviewModel = JSON.stringify(reviewModel);

        const url = process.env.REACT_APP_CORE_API + "/api/InterviewAnswerReview";
        let bearer = validateAndGetFormatedToken();
        this.setState({ isReviewUpdating: 1 })
        if (bearer !== "" || bearer !== null) {
            this.setState({ disableSaveBtn: true })
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: reviewModel
                }).then((response) => {
                    /* Set the status of interview invite to Reviewed*/
                    this.updateInterviewInviteStatusToReviewed(interviewInviteId, parseJwt(bearer).sub);
                    //setTimeout(() => {
                    //    window.location.reload();
                    //}, 4000)



                }).then(() => {
                    console.log("------------------------------------ finish ----------------------------");

                })
                .catch(err => {
                    console.log(err);
                    this.setState({ comment: "", openCloseComment: "", disableSaveBtn: false, selectedRating: 0, answerReviewModal: false, isReviewUpdating: 0 });
                    displayErrorMessage('Error while fetching Reviews!', "top-center")
                });
        }
    }


    /**
     * Set the interview invite status to reviewed
     * @param {any} interviewInviteId
     */
    updateInterviewInviteStatusToReviewed(interviewInviteId, userId) {
        let inviteStatusModel = {
            interviewInviteId: interviewInviteId,
            userId: userId
        }
        inviteStatusModel = JSON.stringify(inviteStatusModel);
        const url = process.env.REACT_APP_CORE_API + "/api/InterviewInviteStatus/UpdateStatusToReviewed";
        let bearer = validateAndGetFormatedToken();
        fetch(url,
            {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: inviteStatusModel
            }).then((response) => {
                //success
                if (response === true) {
                    displaySuccessMessage("Interview invite status updated");
                }
                setTimeout(() => {
                    this.GetOverallComment(interviewInviteId);
                    this.getJobDetails();
                    this.interviewReviewPermissions();
                    const { getJobInterviews, jobId } = this.props;

                    if (getJobInterviews) {
                        getJobInterviews(jobId);
                    } else {
                        console.log("Default action executed: No getJobInterviews function provided");
                    }
                    this.setState({ disableSaveBtn: false, answerReviewModal: false, isReviewUpdating: 0, comment: "", openCloseComment: "" });
                    displaySuccessMessage('Review saved!', "top-center");


                }, 1500);

            })
            .catch(err => {
                console.log(err);
                displayErrorMessage('Error Unable to update interview invite status!', "top-center")
            });
    }



    /**
     * Save reviews given by a reviewer against answer
     * gave by candidate
     * LIVE INTERRVIEW */
    saveLiveInterviewAnswerReviewsHandler() {
        const interviewInviteId = this.props.interviewInviteId;
        let bearer = validateAndGetFormatedToken();
        const comments = this.state.comment;
        let liveInterviewQuestionAnswerId = this.state.interviewAnswerId;
        let stars = this.state.selectedRating;
        let url = "";
        console.log("")
        if (this.state.liveInterviewQuestions.length < 0) {
            displayWarningMessage('Please atleast add 1 question', "top-center");
            return;
        }
        if (stars <= 0) {
            displayWarningMessage('Please Give some rating', "top-center");
            return;
        }



        if (bearer !== "" || bearer != null) {
            this.setState({ disableSaveBtn: true });
            let liveInterviewQuestionAnswerReviewModel = {
                liveInterviewQuestionAnswerId: liveInterviewQuestionAnswerId,
                stars: stars,
                comments: comments
            }
            liveInterviewQuestionAnswerReviewModel = JSON.stringify(liveInterviewQuestionAnswerReviewModel);
            url = process.env.REACT_APP_CORE_API + "/api/LiveInterviewQuestionAnswerReview";
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    },
                    body: liveInterviewQuestionAnswerReviewModel
                }).then((response) => {
                    this.updateInterviewInviteStatusToReviewed(interviewInviteId, parseJwt(bearer).sub);
                    this.setState({ comment: "", disableSaveBtn: false, selectedRating: 0 });
                    //displaySuccessMessage('Review Updated Successfully!', "top-center")

                    setTimeout(() => {
                        window.location.reload();
                    }, 4000)

                })
                .catch(err => {
                    console.log(err);
                    this.setState({ comment: "", disableSaveBtn: false, selectedRating: 0 });
                    displayErrorMessage('Error while updating review!', "top-center")

                });

            this.LiveInterviewDetails();
        }

    }

    /**
     * Save update overall comments*/
    SaveOverallComment() {



        //if (this.state.overallComment.trim() == "") {
        //    displayWarningMessage('Please add some comment!', "top-center");

        //    return;
        //}
        if (this.state.overallComment.includes('<p></p>')) {
            displayWarningMessage('Please add some comment!', "top-center");
            return

        }
        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });
            var url = null;
            var subId = null;
            var tenantEmail = null;

            subId = parseJwt(bearer).sub;
            url = process.env.REACT_APP_IDENTITY_API + "/Account/GetUsername?userId=" + subId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    tenantEmail = response.data;

                }).then(() => {
                    let url = process.env.REACT_APP_CORE_API + "/api/ReviewerOverallComments";

                    axios.post(url, {
                        createdby: tenantEmail,
                        createdDate: new Date(),
                        interviewInviteId: this.props.interviewInviteId,
                        reviewerUsername: tenantEmail,
                        overallComments: this.state.overallComment || this.state.fetchedOverallComment,

                    }, {
                        headers: {
                            'Authorization': bearer,
                            'Content-Type': 'application/json',
                        },
                    }).then((response) => {
                        console.log("response is", response.data);
                        this.setState({ disableSaveBtn: false });
                        displaySuccessMessage('Overall Comment Saved!', "top-center");
                        this.GetOverallComment(this.props.interviewInviteId);
                    }).then(() => {
                        //window.location.reload();

                    }).catch((error) => {
                        console.log("error is", error);
                        this.setState({ disableSaveBtn: false });
                        displayErrorMessage('Could not able to save overall comment!', "top-center");
                    });
                    this.setState({
                        overallComment: "",
                        updateOverallComment: false
                    });

                })
                .catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");


                });




        }

    }

    AutoRatingWithConfirmation() {
        this.setState({ IsTypeVideo: 0 });
        this.setState({ IsTypeVideoAllow: 1 });
        let autoReview = this.state.autoReviewdata;

        this.GetAutoRating(autoReview.interviewAnswerId, autoReview.answerType, autoReview.answerText, autoReview.answerVideoURL, autoReview.questionTitle, autoReview.questionType, 1);
    }

    /**
     * This function take some parameters, Pass it to Video Transcription service, Get Transcription, Pass Transcription
     * to Chatbot Service and Give suggestted rating like 0-5   * 
     * @param {any} interviewAnswerId
     * @param {any} answerType
     * @param {any} answerText
     * @param {any} answerVideoURL
     * @param {any} questionTitle
     * @param {any} questionType
     * @param {any} isTypeVideoAllow
     * @returns integrer value ranger from 0 to 5
     */
    GetAutoRating = async (interviewAnswerId, answerType, answerText, answerVideoURL, questionTitle, questionType, isTypeVideoAllow) => {
        this.setState({ autoReviewdata: [] });

        if (isTypeVideoAllow === 0) {
            this.setState({ IsTypeVideo: 0 });
        }
        if (questionType == "video" && isTypeVideoAllow === 0) {
            let autoReview = {
                interviewAnswerId: interviewAnswerId,
                answerType: answerType,
                answerText: answerText,
                answerVideoURL: answerVideoURL,
                questionTitle: questionTitle,
                questionType: questionType
            };

            this.setState({ autoReviewdata: autoReview });
            this.setState({ IsTypeVideo: 1 });
            return;
        }
        else if (answerType == "video") {

            displayWarningMessage('Your request is being processed. This may take few minutes.', "top-center");
        }
        this.setState({ IsAutoReviewLoading: 1 });

        this.setState({ IsTypeVideoAllow: 0 });
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });

            var url = process.env.REACT_APP_CORE_API + "/api/CandidateAnswerRatingByChatbot/";

            try {
                const response = await axios.post(
                    url,
                    {
                        interviewAnswerId: interviewAnswerId,
                        answerType: answerType,
                        answerText: answerText,
                        answerVideoURL: answerVideoURL,
                        questionTitle: questionTitle,
                        questionType: questionType
                    }
                );
              
                let questionAnswers = this.state.questionAnswers;
                for (let i = 0; i < this.state.questionAnswers.length; i++) {
                    if (interviewAnswerId === this.state.questionAnswers[i].interviewAnswerId) {
                        questionAnswers[i].autoStarsRating = response.data;
                    }
                }
                this.setState({ questionAnswers: questionAnswers, IsAutoReviewLoading: 0, disableSaveBtn: false });
                this.setState({ IsAutoReviewLoading: 0, disableSaveBtn: false });

                displaySuccessMessage("Auto Rating has been processed!", "top-center");

            } catch (error) {
                this.setState({ IsAutoReviewLoading: 0, disableSaveBtn: false });
                // Handle error response
                if (error.response) {
                    // Server responded with a status other than 2xx
                    if (error.response.status === 400) {
                        displayErrorMessage("Unable to Auto Transcript!", "top-center");
                    } else {
                        displayErrorMessage("Unable to Auto Transcript!", "top-center");
                    }
                } else if (error.request) {
                    // Request was made but no response received
                    console.error('Error: No response received', error.request);
                    displayErrorMessage("Unable to Auto Rating!", "top-center");
                } else {
                    // Other errors
                    console.error('Error:', error.message);
                    displayErrorMessage("Unable to Auto Transcript the video!", "top-center");
                }
            }
        }
    }



    /**
     * Save update overall comments*/
    GetOverallComment(interviewInviteId) {
        if (interviewInviteId == "") {
            displayWarningMessage('interviewInviteId is missing!', "top-center");

            return;
        }

        else {
            let bearer = validateAndGetFormatedToken();


            if (bearer != "" || bearer != null) {
                var url = null;
                var subId = null;
                var tenantEmail = null;

                subId = parseJwt(bearer).sub;
                url = process.env.REACT_APP_IDENTITY_API + "/Account/GetUsername?userId=" + subId;

                axios(url,
                    {
                        method: 'GET',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        tenantEmail = response.data;

                    }).then(() => {
                        let url = process.env.REACT_APP_CORE_API + "/api/ReviewerOverallCommentsFetch";
                        let interviewInviteReviewerOverallComment = {};
                        interviewInviteReviewerOverallComment.InterviewInviteId = interviewInviteId;
                        interviewInviteReviewerOverallComment.ReviewerUsername = tenantEmail;

                        var model = JSON.stringify(interviewInviteReviewerOverallComment);

                        const requestOptions = {
                            method: 'POST',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            },
                            body: model
                        };


                        fetch(url, requestOptions)
                            .then((response) => response.json())
                            .then((result) => {
                                console.log("log", result)
                                if (result != undefined && result != {}) {
                                    this.setState({
                                        fetchedOverallComment: result.overallComments
                                    });
                                }
                            }).catch((error) => {
                                console.log("error is", error);
                                displayErrorMessage('Error while fetcing overall comments!', "top-center");
                            });


                    })
                    .catch(err => {
                        console.error("Unable to fetch email of tenant == >" + err);
                        displayErrorMessage('Error while Fetching email of tenant!', "top-center");
                    });


            }

        }

    }
    formatInterviewType(interviewType) {
        if (interviewType === 'onewayopen') {
            return 'One-Way Open';
        }
        if (interviewType === 'oneway') {
            return 'One-Way Close';
        }
        if (interviewType === 'live') {
            return 'Two-Way Live';
        }
        // Add more transformations as needed
        return interviewType;
    };

    renderLiveInterviews() {
        const { selectedRating } = this.state;


        return <>

            <div className="">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col lg-10">                            
                            <h1 className="f-size-18 f-weight-600">
                                <i class="bi bi-briefcase me-2 job-title-icon-bg"></i>
                                <span className="me-2">
                                    {this.state.jobDetails.title}
                                </span>                                
                            </h1>
                            <div className="card mt-3 d-none d-lg-block">
                                <div className="card-body">
                                    <div className="row g-0 mb-1">
                                        <div className="col-md-12">
                                            <h1 className="f-weight-700 f-size-20">{this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}</h1>
                                        </div>
                                    </div>
                                    <div className="row g-0  ">
                                        <div className="col-md-12 d-flex gap-2">
                                            <p className="">
                                                <span className="f-weight-500 ">{this.state.interviewInvite.candidateEmail}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card p-3 mb-3">
                                        <div className="row g-0 mb-2 pt-3">
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    INTERVIEW RATING
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0">
                                                    {isNaN(this.state.interviewOverallRating.rating) ? 0 : this.state.interviewOverallRating.rating}
                                                </p>
                                            </div>
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    INTERVIEW STATUS
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0"> {this.state.interviewInvite.inviteStatus}</p>
                                            </div>

                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    REQUESTED DATE
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0">{convertUTCDateToBrowserLocale(this.state.interviewInvite.inviteRequestedDate)}</p>
                                            </div>
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    INTERVIEW TYPE
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0"> {this.formatInterviewType(this.state.interviewInvite.interviewType)}</p>
                                            </div>

                                        </div>


                                    </div>



                                </div>

                            </div>

                            {/* <!-- End Breadcrumbs --> */}

                        </div>


                        {/* <!-- End Col --> */}

                    </div>
                    {/* <!-- End Row --> */}
                </div>
                {/* <!-- End Page Header --> */}


                {(this.state.interviewInvite.inviteStatus !== "Requested" && this.state.interviewInvite.inviteStatus !== "Accepted") && (
                    <>
                        <div className="row mt-6 d-lg-flex d-none">
                            {this.state.liveInterviewQuestions.length > 0 && this.state.liveInterviewQuestions != [] &&
                                <>
                                    <div className="col-lg-6">

                                        <div className="card">

                                            <div className="table-responsive datatable-custom">

                                                <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }}>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th

                                                            >
                                                                Sr No
                                                            </th>
                                                            <th

                                                            >
                                                                Title
                                                            </th>
                                                            <th

                                                            >
                                                                Start Time
                                                            </th>
                                                            <th

                                                            >
                                                                End Time
                                                            </th>
                                                            <th

                                                            >
                                                                Ratings
                                                            </th>
                                                            <th

                                                            >
                                                                Reviewers
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.state.liveInterviewQuestions.map((question, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={index} className={"cursor-pointer " + (this.state.selectedQuestion == index
                                                                        ? "text-white bg-info"
                                                                        : "text-dark bg-white")}
                                                                        onClick={() => {
                                                                            var time = question.startTime;
                                                                            var actualTime = time.split(':');
                                                                            var totalSeconds = (+actualTime[0]) * 60 + (+actualTime[1]);
                                                                            this.video.currentTime = totalSeconds;
                                                                            let temp = this.state.liveInterviewOverallRating;
                                                                            temp.comments = question.comments;
                                                                            temp.reviews = question.reviewBy;
                                                                            this.setState({ selectedQuestion: index, selectedQuestionObject: question, liveInterviewOverallRating: temp, selectedRating: question.stars, interviewAnswerId: question.liveInterviewQuestionAnswerId });
                                                                        }}
                                                                    >
                                                                        <td >
                                                                            {index + 1}
                                                                        </td>
                                                                        <td >
                                                                            {question.liveQuestionTitle}


                                                                        </td>
                                                                        <td >
                                                                            {question.startTime}

                                                                        </td>
                                                                        <td >
                                                                            {question.endTime}

                                                                        </td>
                                                                        <td >
                                                                            {question.stars}

                                                                        </td>
                                                                        <td >
                                                                            {question.reviewers}

                                                                        </td>


                                                                    </tr>
                                                                </>
                                                            )

                                                        })
                                                        }



                                                    </tbody>
                                                </table>


                                            </div>







                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card" >

                                            <div class="card-header">
                                                Answers
                                            </div>
                                            <div className="card-body text-dark bg-light">


                                                {this.state.liveInterviewVideo != "" && <div style={{ maxWidth: '80%', margin: '0px auto' }} > <video ref={function (ref) { this.video = ref }.bind(this)}
                                                    controls autoplay src={this.state.liveInterviewVideo} /> </div>
                                                }


                                                <div class="mb-3">
                                                    {this.state.editReviewPermission &&
                                                        <>

                                                            <div class="mb-3">

                                                                <label for="selectratings"><h5>Select Rating</h5></label>
                                                                <div style={{ fontSize: '25px' }}>
                                                                    <StarRatingComponent
                                                                        id="selectratings"
                                                                        name="rate1"
                                                                        starCount={5}
                                                                        value={selectedRating}
                                                                        onStarClick={this.onStarClick.bind(this)}
                                                                    />
                                                                </div>



                                                            </div>

                                                            <div className="mb-3">
                                                                <label className="mb-2">Comments</label>
                                                                <textarea name="comments"
                                                                    type="text"
                                                                    placeholder="Comments (Maximum 500 charachters)"
                                                                    maxLength="500"
                                                                    value={this.state.comment}
                                                                    onChange={(e) => { this.setState({ comment: e.target.value }) }}
                                                                    className="form-control"
                                                                />
                                                            </div>




                                                            <button id="btnSave" className="btn btn-primary" type="button"
                                                                disabled={this.state.disableSaveBtn}
                                                                onClick={this.saveLiveInterviewAnswerReviewsHandler}>
                                                                Save Answer Review
                                                            </button>

                                                        </>
                                                    }
                                                    {this.state.viewReviewPermission &&

                                                        <div>

                                                            <h6 className="px-3 mt-3 py-3 mb-2 border mt-2 rounded d-flex align-items-center justify-content-between w-100" style={{ cursor: 'pointer' }} onClick={() => {
                                                                {
                                                                    if (this.state.viewComments)
                                                                        this.setState({ viewComments: false })
                                                                    else
                                                                        this.setState({ viewComments: true })
                                                                }
                                                            }}>
                                                                View Comments
                                                                {this.state.viewComments ?
                                                                    <span style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>-</span>
                                                                    :
                                                                    <span style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>+</span>
                                                                }
                                                            </h6>
                                                            {this.state.viewComments &&

                                                                <>
                                                                    {this.state.liveInterviewQuestions !== [] && this.state.liveInterviewQuestions !== null && this.state.liveInterviewQuestions !== undefined ?

                                                                        this.state.liveInterviewQuestions.map((interviewAnswerReviewForQuestion, index) => {
                                                                            if (this.state.interviewAnswerId == interviewAnswerReviewForQuestion.liveInterviewQuestionAnswerId) {
                                                                                return (
                                                                                    <>
                                                                                        <div className="row" style={{ overflow: 'auto' }}>
                                                                                            <div className="col" >
                                                                                                <strong>{interviewAnswerReviewForQuestion.reviewBy} : </strong>
                                                                                            </div>
                                                                                            <div className="col" >
                                                                                                {interviewAnswerReviewForQuestion.comments}

                                                                                            </div>
                                                                                        </div>


                                                                                        <hr />
                                                                                    </>
                                                                                )
                                                                            }

                                                                        })
                                                                        :
                                                                        <div className="w-full px-4 flex-1">
                                                                            <h4 className="text-3xl font-normal leading-normal mt-0 mb-2 text-black-500" >
                                                                                No Comments Found
                                                                            </h4>

                                                                        </div>

                                                                    }


                                                                </>

                                                            }

                                                        </div>

                                                    }


                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                </>


                            }

                        </div>


                       


                        <div className="card mt-3 mb-lg-5 d-none d-lg-block">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Over All Comments</h4>

                            </div>
                            <div class="card-body">
                                {(this.state.fetchedOverallComment == "" || this.state.fetchedOverallComment == null) || (this.state.updateOverallComment) ?
                                    <div className="row">

                                        {/* <!--Quill --> */}
                                        <div className="col-sm-12">
                                            <Editor
                                                name="overallComment"

                                                editorState={this.state.editorState}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                onEditorStateChange={this.onEditorStateChange}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'fontFamily']
                                                }}
                                                handlePastedText={() => false}
                                            />


                                        </div>
                                        <div className="col-sm-12 mx-2 mt-3">
                                            <button className="btn btn-secondary float-end"
                                                disabled={this.state.disableSaveBtn}

                                                onClick={() => {
                                                    this.SaveOverallComment()
                                                }}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mx-2 my-2">
                                        <div className="col-sm-12" >
                                            <div dangerouslySetInnerHTML={{ __html: this.state.fetchedOverallComment }}>
                                            </div>

                                            <hr />
                                            <button className="btn btn-secondary float-end" onClick={() => {
                                                this.setState({
                                                    updateOverallComment: true,
                                                    editorState: EditorState.createWithContent(stateFromHTML(this.state.fetchedOverallComment)),


                                                })
                                            }}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>
                        {/* Mobile Start here */}
                        <div className="row justify-content-center align-items-center g-0 d-lg-none d-block ">
                            <div className="col-md-12">
                                <div className="card border-0 jc-radius mb-3">
                                    <div className="row g-0 p-3 pb-0">
                                        <div className="col-12 d-flex">
                                            <div className="col-12 ">
                                                <div className="d-flex">
                                                    <h1
                                                        className="f-size-18 f-weight-700 "
                                                        style={{ marginRight: 8 }}
                                                    >
                                                        {this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}                                                        </h1>

                                                </div>
                                                <p className="f-size-12 ">{this.state.interviewInvite.candidateEmail}</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row g-0 p-4 pt-0">
                                        <div className="col-12">
                                            <ul
                                                className="nav nav-tabs col-12 nav-tabs-bordered"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <li className="nav-item col-4" role="presentation">
                                                    <button
                                                        className={`nav-link  f-size-14 f-weight-500 ${this.state.activeTab === "details-tab-pane" ? "active" : ""}`}

                                                        id="details-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#details-tab-pane"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="details-tab-pane"
                                                        aria-selected="true"

                                                        onClick={() => this.handleTabClick('details-tab-pane')}

                                                    >
                                                        Details
                                                    </button>

                                                </li>
                                                <li className="nav-item  col-4" role="presentation">
                                                    <button
                                                        className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "description-tab-pane" ? "active" : ""}`}
                                                        id="description-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#description-tab-pane"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="description-tab-pane"
                                                        aria-selected="false"
                                                        onClick={() => this.handleTabClick('description-tab-pane')}


                                                    >
                                                        Questions
                                                    </button>
                                                </li>
                                                <li className="nav-item  col-4" role="presentation">
                                                    <button
                                                        className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "comments-tab-pane" ? "active" : ""}`}
                                                        id="comments-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#comments-tab-pane"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="comments-tab-pane"
                                                        aria-selected="false"
                                                        onClick={() => this.handleTabClick('comments-tab-pane')}


                                                    >
                                                        Comments
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div
                                                    className={`tab-pane fade pt-3 ${this.state.activeTab === "details-tab-pane" ? "show active" : ""}`}
                                                    id="details-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="details-tab"
                                                    tabIndex={0}

                                                >
                                                    <div className="row pt-3 ">
                                                        <div className="col-6 ">
                                                            <div className="mb-4">
                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                    INTERVIEW RATING
                                                                </h1>
                                                                <p className="f-size-13 f-weight-600 f-dark-color">
                                                                    {isNaN(this.state.interviewOverallRating.rating) ? 0 : this.state.interviewOverallRating.rating}                                                        </p>
                                                            </div>
                                                            <div className="mb-4">
                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                    INTERVIEW STATUS
                                                                </h1>
                                                                <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                    {this.state.interviewInvite.inviteStatus}
                                                                </p>
                                                            </div>



                                                        </div>
                                                        <div className="col-6 ">
                                                            <div className="mb-4">
                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                    REQUESTED DATE
                                                                </h1>
                                                                <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                    {convertUTCDateToBrowserLocale(this.state.interviewInvite.inviteRequestedDate)}
                                                                </p>
                                                            </div>
                                                            <div className="mb-4">
                                                                <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                                    INTERVIEW TYPE
                                                                </h1>
                                                                <p className="f-size-13 f-weight-600 f-dark-color ">
                                                                  {this.formatInterviewType(this.state.interviewInvite.interviewType)}                                                      </p>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`tab-pane fade pt-3 ${this.state.activeTab === "description-tab-pane" ? "show active" : ""}`}

                                                    id="description-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="description-tab"
                                                    tabIndex={0}
                                                >
                                                    {(this.state.interviewInvite.inviteStatus !== "Requested" && this.state.interviewInvite.inviteStatus !== "Accepted") && (
                                                        <>
                                                            <div className="row mt-6 ">
                                                                {this.state.liveInterviewQuestions.length > 0 && this.state.liveInterviewQuestions != [] &&
                                                                    <>
                                                                        <div className="col-lg-6">

                                                                            <div className="card">

                                                                                <div className="table-responsive datatable-custom">

                                                                                    <table className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }}>
                                                                                        <thead className="thead-light">
                                                                                            <tr>
                                                                                                <th

                                                                                                >
                                                                                                    Sr No
                                                                                                </th>
                                                                                                <th

                                                                                                >
                                                                                                    Title
                                                                                                </th>
                                                                                                <th

                                                                                                >
                                                                                                    Start Time
                                                                                                </th>
                                                                                                <th

                                                                                                >
                                                                                                    End Time
                                                                                                </th>
                                                                                                <th

                                                                                                >
                                                                                                    Ratings
                                                                                                </th>
                                                                                                <th

                                                                                                >
                                                                                                    Reviewers
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>

                                                                                            {this.state.liveInterviewQuestions.map((question, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr key={index} className={"cursor-pointer " + (this.state.selectedQuestion == index
                                                                                                            ? "text-white bg-info"
                                                                                                            : "text-dark bg-white")}
                                                                                                            onClick={() => {
                                                                                                                var time = question.startTime;
                                                                                                                var actualTime = time.split(':');
                                                                                                                var totalSeconds = (+actualTime[0]) * 60 + (+actualTime[1]);
                                                                                                                this.video.currentTime = totalSeconds;
                                                                                                                let temp = this.state.liveInterviewOverallRating;
                                                                                                                temp.comments = question.comments;
                                                                                                                temp.reviews = question.reviewBy;
                                                                                                                this.setState({ selectedQuestion: index, selectedQuestionObject: question, liveInterviewOverallRating: temp, selectedRating: question.stars, interviewAnswerId: question.liveInterviewQuestionAnswerId });
                                                                                                            }}
                                                                                                        >
                                                                                                            <td >
                                                                                                                {index + 1}
                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {question.liveQuestionTitle}


                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {question.startTime}

                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {question.endTime}

                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {question.stars}

                                                                                                            </td>
                                                                                                            <td >
                                                                                                                {question.reviewers}

                                                                                                            </td>


                                                                                                        </tr>
                                                                                                    </>
                                                                                                )

                                                                                            })
                                                                                            }



                                                                                        </tbody>
                                                                                    </table>


                                                                                </div>







                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="card" >

                                                                                <div class="card-header">
                                                                                    Answers
                                                                                </div>
                                                                                <div className="card-body text-dark bg-light">


                                                                                    {this.state.liveInterviewVideo != "" && <div style={{ maxWidth: '80%', margin: '0px auto' }} >
                                                                                        <video preload="metadata" ref={function (ref) { this.video = ref }.bind(this)}
                                                                                            controls autoplay src={this.state.liveInterviewVideo} /> </div>
                                                                                    }


                                                                                    <div class="mb-3">
                                                                                        {this.state.editReviewPermission &&
                                                                                            <>

                                                                                                <div class="mb-3">

                                                                                                    <label for="selectratings"><h5>Select Rating</h5></label>
                                                                                                    <div style={{ fontSize: '25px' }}>
                                                                                                        <StarRatingComponent
                                                                                                            id="selectratings"
                                                                                                            name="rate1"
                                                                                                            starCount={5}
                                                                                                            value={selectedRating}
                                                                                                            onStarClick={this.onStarClick.bind(this)}
                                                                                                        />
                                                                                                    </div>



                                                                                                </div>

                                                                                                <div className="mb-3">
                                                                                                    <label className="mb-2">Comments</label>
                                                                                                    <textarea name="comments"
                                                                                                        type="text"
                                                                                                        placeholder="Comments (Maximum 500 charachters)"
                                                                                                        maxLength="500"
                                                                                                        value={this.state.comment}
                                                                                                        onChange={(e) => { this.setState({ comment: e.target.value }) }}
                                                                                                        className="form-control"
                                                                                                    />
                                                                                                </div>




                                                                                                <button id="btnSave" className="btn btn-primary" type="button"
                                                                                                    disabled={this.state.disableSaveBtn}
                                                                                                    onClick={this.saveLiveInterviewAnswerReviewsHandler}>
                                                                                                    Save Answer Review
                                                                                                </button>

                                                                                            </>
                                                                                        }
                                                                                        {this.state.viewReviewPermission &&

                                                                                            <div>

                                                                                                <h6 className="px-3 mt-3 py-3 mb-2 border mt-2 rounded d-flex align-items-center justify-content-between w-100" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                    {
                                                                                                        if (this.state.viewComments)
                                                                                                            this.setState({ viewComments: false })
                                                                                                        else
                                                                                                            this.setState({ viewComments: true })
                                                                                                    }
                                                                                                }}>
                                                                                                    View Comments
                                                                                                    {this.state.viewComments ?
                                                                                                        <span style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>-</span>
                                                                                                        :
                                                                                                        <span style={{ float: 'right', fontWeight: 'bold', fontSize: '16px' }}>+</span>
                                                                                                    }
                                                                                                </h6>
                                                                                                {this.state.viewComments &&

                                                                                                    <>
                                                                                                        {this.state.liveInterviewQuestions !== [] && this.state.liveInterviewQuestions !== null && this.state.liveInterviewQuestions !== undefined ?

                                                                                                            this.state.liveInterviewQuestions.map((interviewAnswerReviewForQuestion, index) => {
                                                                                                                if (this.state.interviewAnswerId == interviewAnswerReviewForQuestion.liveInterviewQuestionAnswerId) {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div className="row" style={{ overflow: 'auto' }}>
                                                                                                                                <div className="col" >
                                                                                                                                    <strong>{interviewAnswerReviewForQuestion.reviewBy} : </strong>
                                                                                                                                </div>
                                                                                                                                <div className="col" >
                                                                                                                                    {interviewAnswerReviewForQuestion.comments}

                                                                                                                                </div>
                                                                                                                            </div>


                                                                                                                            <hr />
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }

                                                                                                            })
                                                                                                            :
                                                                                                            <div className="w-full px-4 flex-1">
                                                                                                                <h4 className="text-3xl font-normal leading-normal mt-0 mb-2 text-black-500" >
                                                                                                                    No Comments Found
                                                                                                                </h4>

                                                                                                            </div>

                                                                                                        }


                                                                                                    </>

                                                                                                }

                                                                                            </div>

                                                                                        }


                                                                                    </div>




                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>


                                                                }

                                                            </div>
                                                        </>

                                                    )}
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6">
                                                        </div>
                                                        <div className="col-lg-6">

                                                            <Link className="btn btn-primary" type="button" style={{ paddingLeft: '10%', paddingRight: '10%', float: 'right' }}
                                                                to={{
                                                                    pathname: "/reviewinterviews"

                                                                }}
                                                                onClick={() => {

                                                                }}>Cancel</Link>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div
                                                    className={`tab-pane fade pt-3 ${this.state.activeTab === "comments-tab-pane" ? "show active" : ""}`}
                                                    id="comments-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="comments-tab"
                                                    tabIndex={0}

                                                >
                                                    <div className="row pt-3 ">

                                                        <div className="card mt-3 mb-lg-5 ">
                                                            <div className="card-header card-header-content-between">
                                                                <h4 className="card-header-title">Over All Comments</h4>

                                                            </div>
                                                            <div class="card-body">
                                                                {(this.state.fetchedOverallComment === "" || this.state.fetchedOverallComment == null) || (this.state.updateOverallComment) ?
                                                                    <div className="row">

                                                                        {/* <!--Quill --> */}
                                                                        <div className="col-sm-12">
                                                                            <Editor
                                                                                name="overallComment"

                                                                                editorState={this.state.editorStateMobile}
                                                                                wrapperClassName="wrapper-class"
                                                                                editorClassName="editor-class"
                                                                                toolbarClassName="toolbar-class"
                                                                                onEditorStateChange={this.onEditorStateChangeMobile}
                                                                                toolbar={{
                                                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'fontFamily']
                                                                                }}
                                                                                handlePastedText={() => false}
                                                                            />


                                                                        </div>
                                                                        <div className="col-sm-12 mx-2 mt-3">
                                                                            <button className="btn btn-secondary float-end"
                                                                                disabled={this.state.disableSaveBtn}

                                                                                onClick={() => {
                                                                                    this.SaveOverallComment()
                                                                                }}>
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="row mx-2 my-2">
                                                                        <div className="col-sm-12" >
                                                                            <div dangerouslySetInnerHTML={{ __html: this.state.fetchedOverallComment }}>
                                                                            </div>

                                                                            <hr />
                                                                            <button className="btn btn-secondary float-end" onClick={() => {
                                                                                this.setState({
                                                                                    updateOverallComment: true,
                                                                                    editorStateMobile: EditorState.createWithContent(stateFromHTML(this.state.fetchedOverallComment)),


                                                                                })
                                                                            }}>
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Mobile end here */}

                    </>
                )
                }
            </div>

        </>
    }
    
    renderOpenCloseInterviews() {
        const { selectedRating } = this.state;
        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.questionAnswers.slice(this.state.itemOffset, endOffset);
        const pageCount = Math.ceil(this.state.questionAnswers.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.questionAnswers.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({ itemOffset: newOffset });
        };

        return <>

            <div className="">                
                {/* <!-- Page Header --> */}
                <div className="page-header">
                    <div className="row align-items-center">
                        <div className="col lg-10">
                            <div className="">
                            </div>
                            <h1 className="f-size-18 f-weight-600 mb-4 mt-2">
                                <i class="bi bi-briefcase me-2 job-title-icon-bg mb-4"></i>
                                <span className="me-2">
                                    {this.state.jobDetails.title}
                                </span>
                            </h1>
                            <div className="card mt-3 d-none d-lg-block">
                                <div className="card-body">
                                    <div className="row g-0 mb-1">
                                        <div class="d-flex mb-2">
                                            {this.state.interviewInvite.profileImageUrl != null ?
                                                <div class="">
                                                    <img
                                                        width="60"
                                                        height="60"
                                                        src={this.state.interviewInvite.profileImageUrl}
                                                        alt="Profile"
                                                        className="applicant-profile-image"
                                                    />
                                                </div>
                                                :
                                                <div class="flex-shrink-0">
                                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M46.7578 45.0234C44.168 40.5234 39.3047 37.5 33.75 37.5H26.25C20.6953 37.5 15.832 40.5234 13.2422 45.0234C17.3672 49.6172 23.3438 52.5 30 52.5C36.6562 52.5 42.6328 49.6055 46.7578 45.0234ZM60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM30 31.875C34.6641 31.875 38.4375 28.1016 38.4375 23.4375C38.4375 18.7734 34.6641 15 30 15C25.3359 15 21.5625 18.7734 21.5625 23.4375C21.5625 28.1016 25.3359 31.875 30 31.875Z" fill="#C7C7C7">
                                                        </path>
                                                    </svg>
                                                </div>
                                            }
                                            <div class="flex-grow-1 ms-3">
                                                <h4 className="text-start mb-0" style={{ fontWeight: "bold" }}>{this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}  </h4>
                                                <p className="text-start mb-1">{this.state.interviewInvite.candidateEmail}</p>
                                                <span class="badge bg-secondary rounded-pill text-white">{this.state.interviewInvite.inviteStatus}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card p-3 mb-3 ">

                                        <div className="row g-0 mb-2 pt-3">
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    Interview Rating
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0">
                                                    {isNaN(this.state.interviewOverallRating.rating) ? 0 : this.state.interviewOverallRating.rating}
                                                </p>
                                            </div>
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    Interview Status
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0"> {this.state.interviewInvite.inviteStatus}</p>
                                            </div>

                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    Requested Date
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0 txt-overflow" >{convertUTCDateToBrowserLocale(this.state.interviewInvite.inviteRequestedDate)}</p>
                                            </div>
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    Interview Type
                                                </h1>
                                                <p className="f-size-14 f-weight-600 m-0">{this.formatInterviewType(this.state.interviewInvite.interviewType)}</p>
                                            </div>
                                            <div className="col">
                                                <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                    Resume
                                                </h1>
                                                {(this.state.interviewInvite.cvLink != null && this.state.interviewInvite.cvLink !== "") &&
                                                    <a className="btn-link" type="button" href={this.state.interviewInvite.cvLink} target="_blank" rel="noreferrer"><i className="bi bi-cloud-arrow-down me-1" style={{ fontSize: '1.6rem' }}></i> </a>
                                                }
                                            </div>
                                            {this.state.interviewInvite.inviteStatus === "Reviewed" && this.state.editReviewPermission === true &&
                                                <div className="col">
                                                    <h1 className="f-gray f-size-14 f-weight-600 m-0 mb-2">
                                                        Evaluate Interview
                                                    </h1>
                                                    <button
                                                        className="btn btn-outline-primary btn-icon btn-sm me-2"
                                                        onClick={() => {
                                                            this.props.handleEvaluateModalOpen()
                                                        }}
                                                    >
                                                        <i class="bi bi-person-bounding-box" data-tip data-for='evaluateInterview'></i>
                                                        <ReactTooltip id="evaluateInterview" place="top" type="dark" effect="float">
                                                            <span> Evaluate Interview </span>
                                                        </ReactTooltip>
                                                    </button>
                                                </div>
                                            }
                                        </div>


                                    </div>



                                    {/* <!-- Card --> */}
                                    {this.state.interviewInvite.isAutoReview &&
                                        <div class="mt-2 d-flex">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle mb-1 text-warning" viewBox="0 0 16 16">
                                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                                </svg>
                                            </span>
                                            <label className="mx-2">Preview Feature: The auto-review rating feature is in the preview version. Currently, we only support the English language for this feature.</label>

                                        </div>
                                    }
                                    <div className="card mt-3">
                                        {/* <!-- Header --> */}
                                        <div className="card-header card-header-content-md-between">
                                            <h4 className="card-header-title">Questions</h4>
                                        </div>
                                        {/* <!-- End Header --> */}

                                        {/* <!-- Table --> */}
                                        <div className="table-responsive datatable-custom">
                                            <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
                                                <div className="dt-buttons"> <button className="dt-button buttons-copy buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Copy</span></button> <button className="dt-button buttons-excel buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>Excel</span></button> <button className="dt-button buttons-csv buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>CSV</span></button> <button className="dt-button buttons-pdf buttons-html5 d-none" tabindex="0" aria-controls="datatable" type="button"><span>PDF</span></button> <button className="dt-button buttons-print d-none" tabindex="0" aria-controls="datatable" type="button"><span>Print</span></button> </div>
                                                {/* <div id="datatable_filter" className="dataTables_filter"><label>Search:<input type="search" className="" placeholder="" aria-controls="datatable" /></label></div> */}
                                                {isFirefox && <div className="alert alert-warning">Note: We are facing issue to play video in Firefox, If you don't have Microrsoft Edge then you can install it https://www.microsoft.com/en-us/edge/download?form=MA13FJ</div>}
                                                <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                                    <thead className="thead-light">
                                                        <tr role="row">
                                                            <th className="table-column-ps-1 sorting" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Project: activate to sort column ascending" >Question Title</th>
                                                            {this.state.interviewInvite.isAutoReview &&
                                                                <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >
                                                                    <label> Auto Rating</label> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning mr-5" viewBox="0 0 16 16">
                                                                        <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1z"></path>
                                                                    </svg>
                                                                </th>
                                                            }
                                                            <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >Content Type</th>
                                                            <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >Question/Answer Type</th>
                                                            <th className="sorting_disabled" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" >Reviewers</th>
                                                            {parseToken(this.state.bearer).roles == "TenantAdmin" && <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Members">Avg. Rating </th>}
                                                            <th className="sorting_disabled" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1" aria-label="Status: activate to sort column ascending" >Review</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {currentItems.map((questionAnswer, index) => {
                                                            return (
                                                                <>
                                                                    <tr role="row" className="odd" >
                                                                        <td className="table-column-ps-1">

                                                                            <span className="d-block h5 text-inherit text-wrap inter-ff"
                                                                                onClick={() => {

                                                                                    if (questionAnswer.videoUrl === "" || questionAnswer.videoUrl == null) {
                                                                                        let completeLink = process.env.REACT_APP_RESOURCE_MANAGEMENT + "/" + questionAnswer.localVideoUrl;
                                                                                        this.setState({ videoUrl: completeLink, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                    } else {
                                                                                        this.setState({ videoUrl: questionAnswer.videoUrl, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                    }
                                                                                    this.setState({ selectedQuestion: index, selectedQuestionObject: questionAnswer, answerReviewModal: true })
                                                                                    document.body.classList.add('modal-open');
                                                                                }}
                                                                                style={{ width: '100%', cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: questionAnswer.questionTitle }}></span>


                                                                        </td>
                                                                        {this.state.interviewInvite.isAutoReview && questionAnswer.questionContentType === "basic" &&
                                                                            <td>

                                                                                {(questionAnswer.autoStarsRating === -1) &&
                                                                                    <span >
                                                                                        <button type="button" className="btn btn-soft-primary"
                                                                                            onClick={() => {
                                                                                                console.log("aeem", questionAnswer)
                                                                                                if ((questionAnswer.answerType == "text" && questionAnswer.textAnswer == "") || (questionAnswer.answerType == "video" && questionAnswer.videoUrl == "")) {
                                                                                                    displayErrorMessage('Unable to auto rate, The applicant has not submitted any answer for evaluation!', "top-center")
                                                                                                    return
                                                                                                }
                                                                                                this.GetAutoRating(questionAnswer.interviewAnswerId, questionAnswer.answerType, questionAnswer.textAnswer, questionAnswer.videoUrl, questionAnswer.questionTitle, questionAnswer.questionType, 0)
                                                                                            }}>

                                                                                            Rate</button>

                                                                                    </span>
                                                                                }

                                                                                {
                                                                                    questionAnswer.autoStarsRating > -1 &&
                                                                                    <span >

                                                                                            <div style={{ fontSize: '20px' }}>
                                                                                                <StarRatingComponent
                                                                                                    id="autoStarsRatingDisplayForBasic"
                                                                                                    name="autoStarsRatingDisplayForBasic"
                                                                                                    starCount={5}
                                                                                                    value={questionAnswer.autoStarsRating}
                                                                                                    disbled={true}
                                                                                                />
                                                                                            </div>
                                                                                    </span>
                                                                                }

                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.state.interviewInvite.isAutoReview && questionAnswer.questionContentType === "comprehension" && questionAnswer.autoStarsRating > -1 &&
                                                                            <td>
                                                                                <span >

                                                                                        <div style={{ fontSize: '20px' }}>
                                                                                            <StarRatingComponent
                                                                                                id="autoStarsRatingDisplayForBasic"
                                                                                                name="autoStarsRatingDisplayForBasic"
                                                                                                starCount={5}
                                                                                                value={questionAnswer.autoStarsRating}
                                                                                                disbled={true}
                                                                                            />
                                                                                        </div>
                                                                                </span>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            this.state.interviewInvite.isAutoReview && questionAnswer.questionContentType === "comprehension" && questionAnswer.autoStarsRating === -1 &&
                                                                            <td>
                                                                                <span >
                                                                                    <button type="button" className="btn btn-soft-primary"
                                                                                            onClick={() => {
                                                                                                if ((questionAnswer.answerType == "text" && questionAnswer.textAnswer == "") || (questionAnswer.answerType == "video" && questionAnswer.videoUrl == "")) {
                                                                                                    displayErrorMessage('Unable to auto rate, The applicant has not submitted any answer for evaluation!', "top-center")
                                                                                                    return
                                                                                                }
                                                                                                this.GetAutoRating(questionAnswer.interviewAnswerId, questionAnswer.answerType, questionAnswer.textAnswer, questionAnswer.videoUrl, questionAnswer.questionTitle, questionAnswer.questionType, 0)
                                                                                            }}>

                                                                                        Rate</button>

                                                                                </span>
                                                                            </td>
                                                                        }

                                                                        {
                                                                            this.state.interviewInvite.isAutoReview && questionAnswer.questionContentType === "grammar" &&
                                                                            <td>
                                                                                <span >
                                                                                    <button type="button" className="btn btn-soft-primary disabled-btn" disabled>

                                                                                        Rate</button>

                                                                                </span>
                                                                            </td>
                                                                        }
                                                                        <td>


                                                                            <span className="inter-ff" style={{ textTransform: 'capitalize' }}>
                                                                                {questionAnswer.questionContentType}

                                                                            </span>

                                                                        </td>
                                                                        <td className="">
                                                                            <span className="inter-ff" style={{ textTransform: 'capitalize' }}>
                                                                                {questionAnswer.questionType.toLowerCase() === "video" && (
                                                                                    <span className="text-decorated-url" onClick={() => { this.setState({ videoPlayModal: true, videoURLForPlayModal: questionAnswer.questionVideoUrl }) }}>
                                                                                        Video
                                                                                    </span>
                                                                                )}
                                                                                {questionAnswer.questionType.toLowerCase() === "text" && questionAnswer.questionType}
                                                                                / {questionAnswer.answerType}
                                                                            </span>
                                                                        </td>


                                                                        <td className="inter-ff">
                                                                            {questionAnswer.reviewers}
                                                                        </td>
                                                                        {parseToken(this.state.bearer).roles === "TenantAdmin" && <td className="inter-ff">
                                                                            {questionAnswer.questionContentType === "comprehension" && Math.round(questionAnswer.rating) === -1 ?
                                                                                <>
                                                                                    <span data-tip data-for="unable-to-rate">
                                                                                        Unable to Auto Rate
                                                                                    </span>
                                                                                    <ReactTooltip id="unable-to-rate" place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">We are unable to auto rate this question at the moment, please rate it manually or try again later</span>
                                                                                    </ReactTooltip>
                                                                                </>                                                                                
                                                                                : Math.round(questionAnswer.rating)}
                                                                        </td>}

                                                                        <td style={{ whiteSpace: 'nowrap' }}>
                                                                            <button type="button" className="btn btn-outline-primary p-1" data-bs-toggle="modal" data-bs-target="#videoLargeModalLabel" data-tip data-for={"ReviewVideoCommentIcon" }
                                                                                onClick={() => {

                                                                                if (questionAnswer.videoUrl === "" || questionAnswer.videoUrl == null) {
                                                                                    let completeLink = process.env.REACT_APP_RESOURCE_MANAGEMENT + "/" + questionAnswer.localVideoUrl;
                                                                                    this.setState({ videoUrl: completeLink, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                } else {
                                                                                    this.setState({ videoUrl: questionAnswer.videoUrl, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                }
                                                                                this.setState({ selectedQuestion: index, selectedQuestionObject: questionAnswer, answerReviewModal: true })
                                                                                document.body.classList.add('modal-open');
                                                                            }}>

                                                                                <img
                                                                                    src={ReviewVideoCommentIcon}                                                                                    
                                                                                    alt="Review Video Comment Icon"
                                                                                    className="reviewvideocomment-icon"

                                                                                />
                                                                            </button>
                                                                            <ReactTooltip id="ReviewVideoCommentIcon" place="top" type="dark" effect="float">                                                                               
                                                                                <span className="d-block fs-5">Review</span>
                                                                            </ReactTooltip>

                                                                            
                                                                        </td>

                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* <!-- End Table --> */}

                                       
                                        {this.state.videoPlayModal && (
                                            <div className="modal d-block video-play-modal" id="demovideo" tabIndex="-1" aria-labelledby="introvideo" aria-modal="true" role="dialog">
                                                <div className="modal-dialog modal-dialog-centered" role="document" >
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title text-white" id="exampleModalLabel">Question</h5>
                                                            <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        videoPlayModal: false,
                                                                        videoURLForPlayModal:""
                                                                    });
                                                                }}>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body p-0">
                                                            {(() => {
                                                                const videoMimeType = getVideoMimeType(this.state.videoURLForPlayModal);
                                                                const videoJsOptions = {
                                                                    autoplay: false,
                                                                    controls: true,
                                                                    responsive: true,
                                                                    fluid: true,
                                                                    sources: [{
                                                                        src: this.state.videoURLForPlayModal,
                                                                        type: videoMimeType
                                                                    }]
                                                                };

                                                                return (
                                                                    <HlsVideoPlayer options={videoJsOptions}  />
                                                                );
                                                            })()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                        {/* <!-- AutoRateNotWorked Modal Start Here--> */}
                                        {this.state.autoRateNotWorkedModal &&
                                            <div className=" d-block modal" tabIndex="-1" role="dialog">
                                                <div className="modal-dialog modal-dialog-centered auto-rate-not-worked-modal" role="document">
                                                    <div className="modal-content">
                                                        {/* <!-- Header --> */}
                                                        <div className="modal-header ">
                                                            <h4 className="modal-title whiteColor" id="accountUpdatePlanModalLabel">
                                                                <i class="bi bi-exclamation-triangle-fill text-warning me-2" style={{ fontSize: "1rem" }}></i>Attension!</h4>
                                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ autoRateNotWorkedModal: false }) }}></button>
                                                        </div>
                                                        {/* <!-- End Header --> */}
                                                        {/* <!-- Body --> */}
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div class="d-flex align-items-center">
                                                                </div>

                                                                <h3 className="h4 inter-ff text-danger">Auto rate service is unable to rate one or more answers at the moment</h3>

                                                            </div>
                                                        </div>
                                                        {/* <!-- End Body --> */}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* <!-- AutoRateNotWorked Modal End Here--> */}

                                        {/* <!-- Video Question Modal --> */}
                                        {this.state.answerReviewModal &&
                                            <div className="modal d-block bd-example-modal-xl" tabindex="-1" role="dialog" id="videoLargeModalLabel" aria-hidden="true">
                                                <div className="modal-dialog-scrollable set-review-answer-modal" role="document">
                                                    <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >



                                                        <div className="modal-header">
                                                            <h5 class="modal-title text-white" id="exampleModalLabel"> Answer</h5>
                                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                                this.setState({ answerReviewModal: false })
                                                                //    document.body.classList.remove('modal-open');
                                                            }}></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            {this.state.isReviewUpdating > 0 && (
                                                                <div class="overlay-auto-rating">
                                                                    <div className="overlay-content">
                                                                        <Oval
                                                                            visible={true}
                                                                            height="80"
                                                                            width="80"
                                                                            color="#4fa94d"
                                                                            ariaLabel="oval-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClass=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="row">

                                                                <div className="col-lg-12 ">


                                                                    <div className="card" >

                                                                        <div class="card-header">
                                                                            {this.state.selectedQuestionObject.answerType.toLowerCase() === "video" ? "Video Answer" : "Text Answer"}
                                                                        </div>


                                                                        <div className="card-body text-dark bg-light mb-3">
                                                                            <label for="selectratings"><h5>Question</h5></label>
                                                                            <h5 dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.questionTitle }}></h5>

                                                                            <hr></hr>

                                                                            <label for="selectratings"><h5>Candidate's Answer:</h5></label>
                                                                            {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && this.state.selectedQuestionObject.questionContentType !== "grammar" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.textAnswer }}></p>}

                                                                            {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && this.state.selectedQuestionObject.questionContentType === "grammar" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.selectedOptionsAnswer }}></p>}
                                                                            {this.state.selectedQuestionObject.answerType.toLowerCase() === "video" && (isFirefox ? <a className="btn btn-primary" href={`microsoft-edge:${this.state.videoUrl}`}> [ --  Sorry, Firefox not supported,  Please watch video answer in Edge --] </a> : <div className="row">
                                                                                <div className="col-md-12 d-flex justify-content-center">
                                                                                    <video className="col-md-8" controls autoPlay src={this.state.videoUrl} />
                                                                                </div>
                                                                            </div>)}

                                                                            {this.state.selectedQuestionObject.answerType.toLowerCase() === "video" &&
                                                                                <div className="row g-0 mt-2">
                                                                                    <div className="offset-md-8 col-md-2 d-flex justify-content-end ">
                                                                                        <div className="d-flex align-items-center">                                                                                           
                                                                                                <VideoDownloadButton videoUrl={this.state.selectedQuestionObject.videoUrl} />                                                                                      
                                                                                            

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            
                                                                            <hr></hr>

                                                                            {this.state.selectedQuestionObject.questionContentType === "grammar" && (
                                                                                <div>
                                                                                    <label for="selectratings"><h5>Correct Answer:</h5></label>
                                                                                    {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.correctOptionsCSV }}></p>}
                                                                                    <hr></hr>
                                                                                </div>
                                                                            )}



                                                                            <div class="mb-6">
                                                                                {this.state.accessReviewPermission &&
                                                                                    <>
                                                                                        <div class="mb-3">

                                                                                            <label for="selectratings"><h5>Rate Candidate's Answer</h5></label>


                                                                                            {this.state.selectedQuestionObject.questionContentType === "comprehension" && (
                                                                                                <div style={{ fontSize: '25px' }}>
                                                                                                    <StarRatingComponent
                                                                                                        id="selectratings"
                                                                                                        name="rate1"
                                                                                                        starCount={5}
                                                                                                        value={selectedRating}
                                                                                                        onStarClick={this.onStarClick.bind(this)}
                                                                                                    />
                                                                                                </div>

                                                                                            )}

                                                                                            {this.state.selectedQuestionObject.questionContentType === "grammar" && (
                                                                                                <div style={{ fontSize: '25px' }}>
                                                                                                    <StarRatingComponent
                                                                                                        id="selectratings"
                                                                                                        name="rate1"
                                                                                                        starCount={5}
                                                                                                        value={selectedRating}
                                                                                                        disbled={true}
                                                                                                    />
                                                                                                </div>

                                                                                            )}

                                                                                            {this.state.selectedQuestionObject.questionContentType === "basic" && (
                                                                                                <div style={{ fontSize: '25px' }}>
                                                                                                    <StarRatingComponent
                                                                                                        id="selectratings"
                                                                                                        name="rate1"
                                                                                                        starCount={5}
                                                                                                        value={selectedRating}
                                                                                                        onStarClick={this.onStarClick.bind(this)}
                                                                                                    />
                                                                                                </div>

                                                                                            )}



                                                                                        </div>

                                                                                        <div class="mb-3">
                                                                                            <label class="mb-2">Comments</label>
                                                                                            <textarea name="comments"
                                                                                                type="text"
                                                                                                placeholder="Comments (Maximum 500 charachters)"
                                                                                                maxLength="500"
                                                                                                onChange={(e) => { this.setState({ openCloseComment: e.target.value }) }}
                                                                                                className="form-control"
                                                                                                rows="4">
                                                                                            </textarea>
                                                                                        </div>

                                                                                        <button id="btnSave" type="button" className="btn btn-primary float-end"
                                                                                            disabled={this.state.disableSaveBtn}
                                                                                            onClick={this.saveOpenCloseInterviewAnswerReviewsHandler

                                                                                            }
                                                                                        >Submit
                                                                                        </button>
                                                                                    </>
                                                                                }

                                                                            </div>


                                                                            <div className="col-lg-12 mb-3 mb-lg-0">

                                                                                <h4 className="mb-2">
                                                                                    Comments:
                                                                                </h4>


                                                                                <ul className="list-comment">
                                                                                    {this.state.interviewAnswerReviewForQuestions !== [] && this.state.interviewAnswerReviewForQuestions !== null && this.state.interviewAnswerReviewForQuestions !== undefined ?

                                                                                        this.state.interviewAnswerReviewForQuestions.map((interviewAnswerReviewForQuestion, index) => {
                                                                                            if (this.state.interviewAnswerId == interviewAnswerReviewForQuestion.interviewAnswerId && interviewAnswerReviewForQuestion.comments != null) {
                                                                                                return (
                                                                                                    <>
                                                                                                        {this.state.accessReviewPermission &&
                                                                                                            <li className="list-comment-item">
                                                                                                                {/* <!-- Media --> */}
                                                                                                                <div className="d-flex mb-2">


                                                                                                                    <div className="flex-grow-1">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col">
                                                                                                                                <h5 className="mb-0 text-decoration-underline">{interviewAnswerReviewForQuestion.reviewBy}</h5>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col">
                                                                                                                                <h5 className="mb-0"> {interviewAnswerReviewForQuestion.reviews}</h5>


                                                                                                                            </div>
                                                                                                                            {/* <!-- End Col --> */}


                                                                                                                        </div>
                                                                                                                        {/* <!-- End Row --> */}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <!-- End Media --> */}


                                                                                                                <p>{interviewAnswerReviewForQuestion.comments}</p>


                                                                                                            </li>
                                                                                                        }
                                                                                                        <div className="row">
                                                                                                            <hr />

                                                                                                        </div>



                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                        })
                                                                                        :
                                                                                        <div className="w-full px-4 flex-1">
                                                                                            <h4 className="text-3xl font-normal leading-normal mt-0 mb-2 text-black-500" >
                                                                                                No Comments Found
                                                                                            </h4>

                                                                                        </div>

                                                                                    }


                                                                                </ul>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    {/* <!-- End Card --> */}

                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* <!-- End Video Question Modal --> */}

                                        {/* <!-- Text Question Modal --> */}

                                        {/*<!-- Footer --> */}
                                        <div className="card-footer">
                                            <div className="d-flex justify-content-center">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                                    renderOnZeroPageCount={null}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link'}
                                                    containerClassName={'pagination'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link'}
                                                    activeClassName={'active'}
                                                />
                                            </div>

                                        </div>
                                        {/*<!-- End Footer --> */}
                                    </div>

                                    {/* <!-- End Card --> */}

                                </div>

                            </div>


                        </div>


                        {/* <!-- End Col --> */}

                    </div>
                    {/* <!-- End Row --> */}
                </div>




                <div className="card mt-3 mb-lg-5 d-none  d-lg-block">
                    <div className="card-header card-header-content-between justify-content-normal">
                        <h4 className="card-header-title">Over All Comments </h4><span className="form-label-secondary text-danger">*</span>

                    </div>
                    {(this.state.fetchedOverallComment == "" || this.state.fetchedOverallComment == null) || (this.state.updateOverallComment) ?
                        <div className="row mx-2 my-2">

                            {/* <!--Quill --> */}
                            <div className="col-sm-12">
                                <Editor
                                    name="overallComment"
                                    editorState={this.state.editorState}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    onEditorStateChange={this.onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'list', 'fontFamily']
                                    }}
                                    handlePastedText={() => false}

                                />


                            </div>
                            <div className="col-sm-12 mt-2 ">
                                <button className="btn btn-secondary float-end"
                                    disabled={this.state.disableSaveBtn}
                                    onClick={() => {
                                        this.SaveOverallComment()
                                    }}>
                                    Save
                                </button>
                            </div>
                        </div>
                        :
                        <div className="row mx-2 my-2">
                            <div className="col-sm-12" >
                                <div dangerouslySetInnerHTML={{ __html: this.state.fetchedOverallComment }}>
                                </div>

                                <hr />
                                <button className="btn btn-secondary float-end mt-2" onClick={() => {
                                    this.setState({
                                        updateOverallComment: true,
                                        editorState: EditorState.createWithContent(stateFromHTML(this.state.fetchedOverallComment)),


                                    })
                                }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    }



                </div>

                {/* Mobile Start here */}
                <div className="row justify-content-center align-items-center g-0 d-lg-none d-block ">
                    <div className="col-md-12">
                        <div className="card border-0 jc-radius mb-3">
                            <div className="row g-0 p-3 pb-0">
                                <div className="col-12 d-flex">
                                    <div className="col-12 ">
                                        <div className="d-flex">
                                            <h1
                                                className="f-size-18 f-weight-700 "
                                                style={{ marginRight: 8 }}
                                            >
                                                {this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}                                                        </h1>

                                        </div>
                                        <p className="f-size-12 ">{this.state.interviewInvite.candidateEmail}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="row g-0 p-4 pt-0">
                                <div className="col-12">
                                    <ul
                                        className="nav nav-tabs col-12 nav-tabs-bordered"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item col-4" role="presentation">
                                            <button
                                                className={`nav-link  f-size-14 f-weight-500 ${this.state.activeTab === "details-tab-pane" ? "active" : ""}`}

                                                id="details-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#details-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="details-tab-pane"
                                                aria-selected="true"

                                                onClick={() => this.handleTabClick('details-tab-pane')}

                                            >
                                                Details
                                            </button>

                                        </li>
                                        <li className="nav-item  col-4" role="presentation">
                                            <button
                                                className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "description-tab-pane" ? "active" : ""}`}
                                                id="description-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#description-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="description-tab-pane"
                                                aria-selected="false"
                                                onClick={() => this.handleTabClick('description-tab-pane')}


                                            >
                                                Questions
                                            </button>
                                        </li>
                                        <li className="nav-item  col-4" role="presentation">
                                            <button
                                                className={`nav-link f-size-14 f-weight-500 ${this.state.activeTab === "comments-tab-pane" ? "active" : ""}`}
                                                id="comments-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#comments-tab-pane"
                                                type="button"
                                                role="tab"
                                                aria-controls="comments-tab-pane"
                                                aria-selected="false"
                                                onClick={() => this.handleTabClick('comments-tab-pane')}


                                            >
                                                Comments
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className={`tab-pane fade pt-3 ${this.state.activeTab === "details-tab-pane" ? "show active" : ""}`}
                                            id="details-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="details-tab"
                                            tabIndex={0}

                                        >
                                            <div className="row pt-3 ">
                                                <div className="col-6 ">
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            INTERVIEW RATING
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color">
                                                            {isNaN(this.state.interviewOverallRating.rating) ? 0 : this.state.interviewOverallRating.rating}                                                        </p>
                                                    </div>
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            INTERVIEW STATUS
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                            {this.state.interviewInvite.inviteStatus}
                                                        </p>
                                                    </div>
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            RESUME
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                            {(this.state.interviewInvite.cvLink != null && this.state.interviewInvite.cvLink !== "") &&
                                                                <a className="btn-link" type="button" href={this.state.interviewInvite.cvLink} target="_blank"><i className="bi bi-cloud-arrow-down me-1" style={{ fontSize: '1.6rem' }}></i> </a>
                                                            }
                                                        </p>
                                                    </div>


                                                </div>
                                                <div className="col-6 ">
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            REQUESTED DATE
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                            {convertUTCDateToBrowserLocale(this.state.interviewInvite.inviteRequestedDate)}
                                                        </p>
                                                    </div>
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            INTERVIEW TYPE
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                            {this.state.interviewInvite.interviewType}
                                                        </p>
                                                    </div>
                                                    <div className="mb-4">
                                                        <h1 className="f-gray f-size-12 f-weight-600 mb-1">
                                                            EVALUATE INTERVIEW
                                                        </h1>
                                                        <p className="f-size-13 f-weight-600 f-dark-color ">
                                                            <Link to={`/evaluateinterview/${this.state.interviewInvite.interviewInviteId}/${this.state.interviewInvite.interviewId}`}
                                                                className="btn btn-outline-primary btn-icon btn-sm me-2"
                                                            >
                                                                <i class="bi bi-person-bounding-box" data-tip data-for='evaluateInterview'></i>
                                                                <ReactTooltip id="evaluateInterview" place="top" type="dark" effect="float">
                                                                    <span> Evaluate Interview </span>
                                                                </ReactTooltip>
                                                            </Link>
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div
                                            className={`tab-pane fade pt-3 ${this.state.activeTab === "description-tab-pane" ? "show active" : ""}`}

                                            id="description-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="description-tab"
                                            tabIndex={0}
                                        >
                                            <table className="table jobs-overview-table">
                                                <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                    <tr>
                                                        <th scope="col " className="p-3" colSpan={3}>
                                                            <span className="h5">Questions</span>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {currentItems != [] && currentItems.map((questionAnswer, index) => (
                                                        <tr >
                                                            <td className="p-3">
                                                                <div className="d-flex align-items-center mb-0">
                                                                    <div className="col d-flex">
                                                                        <div>
                                                                            <div className="d-block ms-1">

                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <h2 className="inter-ff f-size-14 f-weight-500 ">{questionAnswer.questionTitle}</h2>
                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 mb-2 align-middle d-flex gap-0 justify-content-end">

                                                                        {
                                                                            <button type="button" className="btn btn-outline-primary btn-icon btn-sm me-2" data-bs-toggle="modal" data-bs-target="#videoLargeModalLabel" onClick={() => {

                                                                                if (questionAnswer.videoUrl === "" || questionAnswer.videoUrl == null) {
                                                                                    let completeLink = process.env.REACT_APP_RESOURCE_MANAGEMENT + "/" + questionAnswer.localVideoUrl;
                                                                                    this.setState({ videoUrl: completeLink, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                }
                                                                                else {
                                                                                    this.setState({ videoUrl: questionAnswer.videoUrl, interviewAnswerId: questionAnswer.interviewAnswerId, selectedRating: questionAnswer.rating });
                                                                                }
                                                                                this.setState({ selectedQuestion: index, selectedQuestionObject: questionAnswer, answerReviewModal: true })
                                                                                document.body.classList.add('modal-open');
                                                                            }}>

                                                                                <span class="material-symbols-outlined">
                                                                                    slideshow
                                                                                </span>
                                                                            </button>
                                                                        }


                                                                    </div>
                                                                </div>
                                                                <div className="d-flex  mb-1">
                                                                    <div className="col-6 d-flex align-items-center ">
                                                                        <span class="material-symbols-outlined f-size-12">
                                                                            contact_support
                                                                        </span>
                                                                        <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1">
                                                                            Question: {questionAnswer.questionType}
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-6 d-flex align-items-center ">
                                                                        <span class="material-symbols-outlined f-size-12">
                                                                            checkbook
                                                                        </span>
                                                                        <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter text-overflow-leads mx-1">
                                                                            Answer: {questionAnswer.answerType}

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="col-6 d-flex align-items-center ">

                                                                        <span class="material-symbols-outlined f-size-12">
                                                                            task_alt
                                                                        </span>
                                                                        <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads">
                                                                            {questionAnswer.reviewers}

                                                                        </p>
                                                                    </div>
                                                                    <div className="col-6 d-flex align-items-center ">
                                                                        <span class="material-symbols-outlined f-size-12">
                                                                            award_star
                                                                        </span>

                                                                        {parseToken(this.state.bearer).roles == "TenantAdmin" &&
                                                                            <p className="f-size-11 mb-0 f-gray-2 f-weight-400 f-inter mx-1 text-overflow-leads" >
                                                                                {questionAnswer.rating}
                                                                            </p>

                                                                        }




                                                                    </div>

                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {/*<!-- Footer --> */}
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-center">
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel={<i className='bi bi-chevron-right'></i>}
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel={<i className='bi bi-chevron-left'></i>}
                                                        renderOnZeroPageCount={null}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link'}
                                                        containerClassName={'pagination'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link'}
                                                        activeClassName={'active'}
                                                    />
                                                </div>

                                            </div>
                                            {/*<!-- End Footer --> */}
                                            {/* <!-- Video Question Modal --> */}
                                            {this.state.answerReviewModal &&
                                                <div className="modal d-block bd-example-modal-xl" tabindex="-1" role="dialog" id="videoLargeModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                                                        <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >

                                                            <div className="modal-header">
                                                                <h5 id="videoLargeModalLabel"></h5>
                                                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                                                    this.setState({ answerReviewModal: false })
                                                                    document.body.classList.remove('modal-open');
                                                                }}></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <div className="col-lg-12 mb-3 mb-lg-0">
                                                                        <h4>{this.state.selectedQuestionObject.questionTitle}</h4>
                                                                        <div className="row">
                                                                            <h4>
                                                                                Comments:
                                                                            </h4>
                                                                        </div>

                                                                        <ul className="list-comment">
                                                                            {this.state.interviewAnswerReviewForQuestions !== [] && this.state.interviewAnswerReviewForQuestions !== null && this.state.interviewAnswerReviewForQuestions !== undefined ?

                                                                                this.state.interviewAnswerReviewForQuestions.map((interviewAnswerReviewForQuestion, index) => {
                                                                                    if (this.state.interviewAnswerId == interviewAnswerReviewForQuestion.interviewAnswerId && interviewAnswerReviewForQuestion.comments != null) {
                                                                                        return (
                                                                                            <>
                                                                                                {this.state.viewReviewPermission &&
                                                                                                    <li className="list-comment-item">
                                                                                                        {/* <!-- Media --> */}
                                                                                                        <div className="d-flex mb-2">


                                                                                                            <div className="flex-grow-1">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <h5 className="mb-0 text-decoration-underline">{interviewAnswerReviewForQuestion.reviewBy}</h5>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <h5 className="mb-0"> {interviewAnswerReviewForQuestion.reviews}</h5>


                                                                                                                    </div>
                                                                                                                    {/* <!-- End Col --> */}


                                                                                                                </div>
                                                                                                                {/* <!-- End Row --> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <!-- End Media --> */}


                                                                                                        <p>{interviewAnswerReviewForQuestion.comments}</p>


                                                                                                    </li>
                                                                                                }
                                                                                                <div className="row">
                                                                                                    <hr />

                                                                                                </div>



                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                })
                                                                                :
                                                                                <div className="w-full px-4 flex-1">
                                                                                    <h4 className="text-3xl font-normal leading-normal mt-0 mb-2 text-black-500" >
                                                                                        No Comments Found
                                                                                    </h4>

                                                                                </div>

                                                                            }


                                                                        </ul>
                                                                    </div>


                                                                    <div className="col-lg-12">


                                                                        <div className="card" >

                                                                            <div class="card-header">
                                                                                {this.state.selectedQuestionObject.answerType.toLowerCase() === "video" ? "Video Answer" : "Text Answer"}
                                                                            </div>
                                                                            <div className="card-body text-dark bg-light">
                                                                                <label for="selectratings"><h5>Question</h5></label>
                                                                                <h5 dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.questionTitle }}></h5>

                                                                                <hr></hr>

                                                                                <label for="selectratings"><h5>Candidate's Answer:</h5></label>
                                                                                {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && this.state.selectedQuestionObject.questionContentType !== "grammar" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.textAnswer }}></p>}
                                                                                {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && this.state.selectedQuestionObject.questionContentType === "grammar" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.selectedOptionsAnswer }}></p>}
                                                                                {this.state.selectedQuestionObject.answerType.toLowerCase() === "video" && (isFirefox ? <a className="btn btn-primary" href={`microsoft-edge:${this.state.videoUrl}`}> [ --  Sorry, Firefox not supported,  Please watch video answer in Edge --] </a> : <div style={{ maxWidth: '80%', margin: '0px auto' }} > <video controls autoplay src={this.state.videoUrl} /> </div>)}
                                                                                <hr></hr>

                                                                                {this.state.selectedQuestionObject.questionContentType === "grammar" && (
                                                                                    <div>
                                                                                        <label for="selectratings"><h5>Correct Answer:</h5></label>
                                                                                        {this.state.selectedQuestionObject.answerType.toLowerCase() === "text" && <p style={{ margin: '2%' }} dangerouslySetInnerHTML={{ __html: this.state.selectedQuestionObject.correctOptionsCSV }}></p>}
                                                                                        <hr></hr>
                                                                                    </div>

                                                                                )}

                                                                                <div class="mb-6">
                                                                                    {this.state.accessReviewPermission &&
                                                                                        <>
                                                                                            <div class="mb-3">

                                                                                                <label for="selectratings"><h5>Rate Candidate's Answer</h5></label>

                                                                                                {this.state.selectedQuestionObject.questionContentType === "comprehension" && this.state.selectedQuestionObject.autoStarsRating > -1 && (
                                                                                                    <div style={{ fontSize: '25px' }}>
                                                                                                        <StarRatingComponent
                                                                                                            id="selectratings"
                                                                                                            name="rate1"
                                                                                                            starCount={5}
                                                                                                            value={selectedRating}
                                                                                                            disbled={true}
                                                                                                        />
                                                                                                    </div>

                                                                                                )}

                                                                                                {this.state.selectedQuestionObject.questionContentType === "comprehension" && this.state.selectedQuestionObject.autoStarsRating === -1 && (
                                                                                                    <div style={{ fontSize: '25px' }}>
                                                                                                        <StarRatingComponent
                                                                                                            id="selectratings"
                                                                                                            name="rate1"
                                                                                                            starCount={5}
                                                                                                            value={selectedRating}
                                                                                                            onStarClick={this.onStarClick.bind(this)}
                                                                                                        />
                                                                                                    </div>

                                                                                                )}

                                                                                                {this.state.selectedQuestionObject.questionContentType === "grammar" && (
                                                                                                    <div style={{ fontSize: '25px' }}>
                                                                                                        <StarRatingComponent
                                                                                                            id="selectratings"
                                                                                                            name="rate1"
                                                                                                            starCount={5}
                                                                                                            value={selectedRating}
                                                                                                            disbled={true}
                                                                                                        />
                                                                                                    </div>

                                                                                                )}

                                                                                                {this.state.selectedQuestionObject.questionContentType === "basic" && (
                                                                                                    <div style={{ fontSize: '25px' }}>
                                                                                                        <StarRatingComponent
                                                                                                            id="selectratings"
                                                                                                            name="rate1"
                                                                                                            starCount={5}
                                                                                                            value={selectedRating}
                                                                                                            onStarClick={this.onStarClick.bind(this)}
                                                                                                        />
                                                                                                    </div>

                                                                                                )}

                                                                                            </div>

                                                                                            <div class="mb-3">
                                                                                                <label class="mb-2">Comments</label>
                                                                                                <textarea name="comments"
                                                                                                    type="text"
                                                                                                    placeholder="Comments (Maximum 500 charachters)"
                                                                                                    maxLength="500"
                                                                                                    onChange={(e) => { this.setState({ openCloseComment: e.target.value }) }}
                                                                                                    className="form-control"
                                                                                                    rows="4">
                                                                                                </textarea>
                                                                                            </div>

                                                                                            <button id="btnSave" type="button" className="btn btn-primary float-end"
                                                                                                disabled={this.state.disableSaveBtn}
                                                                                                onClick={this.saveOpenCloseInterviewAnswerReviewsHandler

                                                                                                }
                                                                                            >Submit
                                                                                            </button>
                                                                                        </>
                                                                                    }

                                                                                </div>




                                                                            </div>
                                                                        </div>

                                                                        {/* <!-- End Card --> */}

                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {/* <!-- End Video Question Modal --> */}
                                        </div>
                                        <div
                                            className={`tab-pane fade pt-3 ${this.state.activeTab === "comments-tab-pane" ? "show active" : ""}`}
                                            id="comments-tab-pane"
                                            role="tabpanel"
                                            aria-labelledby="comments-tab"
                                            tabIndex={0}

                                        >
                                            <div className="row pt-3 ">

                                                <div className="card mt-3 mb-lg-5 ">
                                                    <div className="card-header card-header-content-between justify-content-normal">
                                                        <h4 className="card-header-title">Over All Comments</h4><span className="form-label-secondary text-danger">*</span>

                                                    </div>
                                                    {(this.state.fetchedOverallComment == "" || this.state.fetchedOverallComment == null) || (this.state.updateOverallComment) ?
                                                        <div className="row mx-2 my-2">

                                                            {/* <!--Quill --> */}
                                                            <div className="col-sm-12">
                                                                <Editor
                                                                    name="overallComment"
                                                                    editorState={this.state.editorStateMobile}
                                                                    wrapperClassName="wrapper-class"
                                                                    editorClassName="editor-class"
                                                                    toolbarClassName="toolbar-class"
                                                                    onEditorStateChange={this.onEditorStateChangeMobile}
                                                                    toolbar={{
                                                                        options: ['inline', 'blockType', 'fontSize', 'list', 'fontFamily']
                                                                    }}
                                                                    handlePastedText={() => false}

                                                                />


                                                            </div>
                                                            <div className="col-sm-12 mt-2 ">
                                                                <button className="btn btn-secondary float-end"
                                                                    disabled={this.state.disableSaveBtn}
                                                                    onClick={() => {
                                                                        this.SaveOverallComment()
                                                                    }}>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="row mx-2 my-2">
                                                            <div className="col-sm-12" >
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.fetchedOverallComment }}>
                                                                </div>

                                                                <hr />
                                                                <button className="btn btn-secondary float-end mt-2" onClick={() => {
                                                                    this.setState({
                                                                        updateOverallComment: true,
                                                                        editorStateMobile: EditorState.createWithContent(stateFromHTML(this.state.fetchedOverallComment)),


                                                                    })
                                                                }}>
                                                                    Update
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile end here */}

                {this.state.IsAutoReviewLoading > 0 && (
                    <div class="overlay-auto-rating">
                        <div className="overlay-content">
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                )}

            </div>


            {/* <!-- End Content --> */}
        </>
    }
    handleTabClick = (tab) => {
        this.setState({ activeTab: tab })

    }
    onEditorStateChange(editorState) {
        let temp = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.setState({
            editorState: editorState,
            overallComment: temp
        });
    };
    onEditorStateChangeMobile(editorState) {
        let temp = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.setState({
            editorStateMobile: editorState,
            overallComment: temp
        });
    };

    render() {

        return (
            <>





                {
                    this.state.interviewInvite.interviewType === "live" ?

                        this.renderLiveInterviews()
                        :
                        this.renderOpenCloseInterviews()
                }

                {/* <!-- Content --> */}




                {this.state.IsEvaluationLoading > 0 && (
                    <div
                        className=" d-block modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                        aria-hidden="true"
                    >

                        <div
                            className="modal-dialog modal-dialog-centered modal-xl"
                            role="document"
                        >
                            <div className="modal-content">
                                <div class="modal-header no-border">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <h5 class="text-white">Please wait..</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    {/*<img src="/ClientApp/src/assets/img/preloader.gif" alt="" />*/}
                                    <h5>Your request is being processed.</h5>

                                </div>


                            </div>
                        </div>
                    </div>
                )}

                {this.state.IsTypeVideo === 1 && (
                    <div
                        className=" d-block modal"
                        id="AddIntroVideo"
                        tabIndex="-1"
                        aria-labelledby="accountUpdatePlanModalLabel"
                        role="dialog"
                        aria-hidden="true"
                    >

                        <div
                            className="modal-dialog modal-dialog-centered modal-xl"
                            role="document"
                        >
                            <div className="modal-content" style={{ width: '56%', marginLeft: '20%' }}>
                                <div class="modal-header no-border">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <h5 class="text-white">Confirmation</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    {/*<img src="/ClientApp/src/assets/img/preloader.gif" alt="" />*/}
                                    <h5>Confirm if the question title you entered accurately reflects your intended question?</h5>

                                    <div className="d-flex justify-content-between justify-content-sm-between gap-3 mt-5">
                                        <button
                                            type="button"
                                            className="btn btn-primary mod-b-d-size"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({
                                                    IsTypeVideo: 0,
                                                    IsTypeVideoAllow: 1
                                                });
                                                document.body.classList.remove('modal-open');
                                                this.AutoRatingWithConfirmation();
                                            }}
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-white mod-b-d-size"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => {
                                                this.setState({ IsTypeVideo: 0, IsTypeVideoAllow: 0, autoReviewdata: [] });
                                                /* document.body.classList.remove('modal-open');*/

                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}


            </>
        );
    }
}
// Set default prop
ReviewInterviewDetailsComponent.defaultProps = {
    getJobInterviews: null,
};
export default ReviewInterviewDetailsComponent;