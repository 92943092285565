import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';
import { parseJwt } from '../../modules/TokenParser';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Oval } from 'react-loader-spinner'
import Select from "react-select";
import DatePicker from "../DataPicker/index"
import StarRatingComponent from 'react-star-rating-component';
import moment from 'moment';

class Analytics extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            analyticsList: [],
            JobsPerIndustry: {},
            IsJobperIndusrty: 0,
            barChartOptions: {},
            jobsListByIndustry: [],
            selectedIndustry: "",
            selectedIndustryPopUpLabel: "",
            IsAddAnalytics: 0,
            isJobperIndusrtySelected: false,
            isTotalActiveJobs: false,
            isTotalInActiveJobs: false,
            isPublishedinMonthJobs: false,
            CountOfActivejobs: 0,
            CountOfInActiveJobs: 0,
            InActiveJobsList: [],
            CountOfPublishedJobsInMonth: 0,
            PublishedinMonthJobsList: [],
            CurrentJobsList: [],
            ActiveJobsList: [],
            SelectedMenuItem: "Jobs",
            CounActiveInterview: 0,
            ActiveInterviewList: [],
            isTotalActiveInterviews: false,
            IsInterviewPopup: 0,
            InterviewsList: [],
            CountInActiveInterview: 0,
            InActiveInterviewList: [],
            isTotalInActiveInterviews: false,
            CountAutoInterviews: 0,
            AutoInterviewsList: [],
            isTotalAutoInterviews: false,
            InterviewsPerJob: {},
            BarChartForInterviews: {},
            isInterviewsperJob: false,
            InterviewsPerType: {},
            BarChartForInterviewsType: {},
            isInterviewsperType: false,
            InvitesPerStatus: {},
            BarChartForInvitesStatus: {},
            isInvitesperStatus: false,
            InviteList: [],
            IsInvitePopup: 0,
            selectedInvitePopUpLabel: "",
            isInvitesPerStatusWithCustomization: false,
            InvitesPerStatusCustomization: {},
            BarChartForInvitesStatusCustomization: {},
            IsInviteCustomizationPopup: 0,
            IsReviewDetailsPopup: 0,
            InviteCustomizationList:[],
            ReviewsList:[],
            ReviewDetailsList:[],
            selectedInviteCustomizationPopUpLabel: "",
            ReviewerList: [],
            TotalLeadsList: [],
            CountTotalLeadsList: 0,
            isLeads: 0,
            leadsList: [],
            selectedLeadsPopUpLabel: "",
            isTotalLeads: false,
            ShortListedLeadsList: [],
            CountShortListedLeadsList: 0,
            isShortListedLeads: false,
            InOneWeekLeadsList: [],
            CountInOneWeekLeadsList: 0,
            isInOneWeekLeads: false,
            LeadsPerJob: {},
            BarChartForLeads: {},
            isLeadsperJob: false,

            EvaluationPerStatus: {},
            BarChartForEvaluationStatus: {},
            isEvaluationPerStatus: false,
            EvaluationHiredList: [],
            EvaluationNotEvaluatedList: [],
            EvaluationRejectedList: [],
            EvaluationFailedList: [],
            EvaluationList: [],
            IsEvaluationPopup: 0,
            selectedEvaluationPopUpLabel: "",
            IsLoading: 0,
            startFilterDate: null,
            endFilterDate: null,
            SelectedReviewer: "ALL",
        }
    }

    async componentDidMount() {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();

            document.title = "Tenant App - Analytics";
            await this.getAnalyticsStats();
            await this.getAnalytics();
        }
    }

    async componentDidUpdate() {
        if (
            this.state.IsJobperIndusrty === 1 ||
            this.state.IsInterviewPopup === 1 ||
            this.state.IsInvitePopup === 1 ||
            this.state.isLeads === 1 ||
            this.state.IsAddAnalytics === 1 ||
            this.state.IsEvaluationPopup === 1
        ) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }

    // Load jobs tab data
    async getAnalyticsStats() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics";

            this.setState({
                IsLoading: 1
            });
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let jobsPerIndustries = result.jobsPerIndustry;
                    let jobsPerIndustriesList = [];

                    const temp = jobsPerIndustries.reduce((a, {
                        title,
                        jobCount
                    }) => (a[title] = (a[title] || 0) + + jobCount, a), {});

                    var jobsCount = 0;
                    Object.keys(temp).forEach(function (key) {
                        jobsCount = jobsCount + temp[key];
                        jobsPerIndustriesList.push({
                            name: key,
                            y: temp[key],
                            drilldown: key
                        })
                    });
                    let countOfInActiveJobs = result.inActiveJobs.length;
                    let countPublishedinMonthJobs = result.publishedinMonthJobs.length;

                    let countActiveJobs = result.activeJobs.length;
                    
                    this.setState({
                        JobsPerIndustry: {
                            series: [{
                                name: "Industries",
                                colorByPoint: true,
                                data: jobsPerIndustriesList

                            }],
                            title: "Industries with number of Active Jobs count",
                            xaxistitle: "Industries",
                            yaxistitle: "Jobs"
                        },


                        isloadedgetDashboardStats: false,

                        CountOfActivejobs: countActiveJobs,
                        CountOfInActiveJobs: countOfInActiveJobs,
                        CountOfPublishedJobsInMonth: countPublishedinMonthJobs,
                        ActiveJobsList: result.activeJobs,
                        InActiveJobsList: result.inActiveJobs,
                        PublishedinMonthJobsList: result.publishedinMonthJobs,
                    });
                    this.setBarChartOptions(this.state.JobsPerIndustry);

                    this.setState({
                        IsLoading: 0
                    });
                })
                .catch(err => {
                    this.setState({
                        IsLoading: 0
                    });
                    console.log("err");
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic stats!', "top-center");

                });

        }

        else
            return;
    };

    // Load interviews tab data
    async getInterviewsAnalytics() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/GetInterviewsAnalytics";

            this.setState({
                IsLoading: 1
            });
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let counActiveInterview = result.activeInterview.length;
                    let countInActiveInterview = result.inActiveInterview.length;
                    let countAutoInterviews = result.autoInterviews.length;

                    let interviewsPerJobList = [];

                    for (var i = 0; i < result.interviewsPerJob.length; i++) {
                        interviewsPerJobList.push({
                            name: result.interviewsPerJob[i].jobName + " - " + result.interviewsPerJob[i].jobLocation,
                            y: result.interviewsPerJob[i].interviewCount,
                            drilldown: result.interviewsPerJob[i].jobName,
                            jobId: result.interviewsPerJob[i].jobId
                        })
                    }
                    let interviewsPerTypeList = [];

                    for (var i = 0; i < result.interviewsPerType.length; i++) {
                        interviewsPerTypeList.push({
                            name: this.formatInterviewType(result.interviewsPerType[i].interviewType),
                            y: result.interviewsPerType[i].interviewCount,
                            drilldown: result.interviewsPerType[i].interviewType
                        })
                    }

                    this.setState({
                        CounActiveInterview: counActiveInterview,
                        ActiveInterviewList: result.activeInterview,
                        CountInActiveInterview: countInActiveInterview,
                        InActiveInterviewList: result.inActiveInterview,
                        CountAutoInterviews: countAutoInterviews,
                        AutoInterviewsList: result.autoInterviews,

                        InterviewsPerJob: {
                            series: [{
                                name: "Jobs",
                                colorByPoint: true,
                                data: interviewsPerJobList

                            }],
                            title: "Active Jobs with number of Interviews count",
                            xaxistitle: "Jobs",
                            yaxistitle: "Interviews"
                        },

                        InterviewsPerType: {
                            series: [{
                                name: "Types",
                                colorByPoint: true,
                                data: interviewsPerTypeList

                            }],
                            title: "Type with number of Active Interviews count",
                            xaxistitle: "Types",
                            yaxistitle: "Interviews"
                        },
                    });
                    

                    this.setBarChartForInterviews(this.state.InterviewsPerJob);
                    this.setBarChartForInterviewTypes(this.state.InterviewsPerType);

                    this.setState({
                        IsLoading: 0
                    });
                })
                .catch(err => {
                    this.setState({
                        IsLoading: 0
                    });
                    console.log("err");
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic stats!', "top-center");

                });

        }

        else
            return;
    };

    // Load Invites tab data
    async getInvitesAnalytics() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/GetInvitesAnalytics";

            this.setState({
                IsLoading: 1
            });
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let invitesPerStatusList = [];

                    for (var i = 0; i < result.inviteByStatus.length; i++) {
                        invitesPerStatusList.push({
                            name: result.inviteByStatus[i].inviteStatus,
                            y: result.inviteByStatus[i].inviteCount,
                            drilldown: result.inviteByStatus[i].inviteStatus
                        })
                    }
                    this.setState({
                        
                        InvitesPerStatus: {
                            series: [{
                                name: "Status",
                                colorByPoint: true,
                                data: invitesPerStatusList

                            }],
                            title: "Invites count by status",
                            xaxistitle: "Status",
                            yaxistitle: "Invites"
                        },

                    });

                    this.setBarChartForInvitesStatus(this.state.InvitesPerStatus);

                    this.setState({
                        IsLoading: 0
                    });
                })
                .catch(err => {
                    this.setState({
                        IsLoading: 0
                    });
                    console.log("err");
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic stats!', "top-center");

                });

        }

        else
            return;
    };

    // Load Candidates tab data
    async getCandidatesAnalytics() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/GetCandidatesAnalytics";

            this.setState({
                IsLoading: 1
            });
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let leadsPerJobList = [];
                    for (var i = 0; i < result.leadsPerJob.length; i++) {
                        leadsPerJobList.push({
                            name: result.leadsPerJob[i].jobName + ' - ' + result.leadsPerJob[i].jobLocation,
                            y: result.leadsPerJob[i].leadsCount,
                            drilldown: result.leadsPerJob[i].jobName,
                            jobId: result.leadsPerJob[i].jobId
                        })
                    }

                    this.setState({
                        TotalLeadsList: result.totalLeads,
                        CountTotalLeadsList: result.totalLeads.length,
                        CountShortListedLeadsList: result.leadsShortListed.length,
                        ShortListedLeadsList: result.leadsShortListed,
                        InOneWeekLeadsList: result.leadsInOneWeek,
                        CountInOneWeekLeadsList: result.leadsInOneWeek.length,
                        LeadsPerJob: {
                            series: [{
                                name: "Jobs",
                                colorByPoint: true,
                                data: leadsPerJobList

                            }],
                            title: "Active Jobs with number of Applicants count",
                            xaxistitle: "Jobs",
                            yaxistitle: "Applicants"
                        },

                        
                    });

                    this.setBarChartForLeads(this.state.LeadsPerJob);

                    this.setState({
                        IsLoading: 0
                    });
                })
                .catch(err => {
                    this.setState({
                        IsLoading: 0
                    });
                    console.log("err");
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic stats!', "top-center");

                });

        }

        else
            return;
    };

    // Load Evaluations tab data
    async getEvaluationsAnalytics() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/GetEvaluationsAnalytics";

            this.setState({
                IsLoading: 1
            });
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    let evaluationPerStatusList = [];
                    evaluationPerStatusList.push({
                        name: "Hired",
                        y: result.evaluationHired.length,
                        drilldown: "Hired"
                    });
                    evaluationPerStatusList.push({
                        name: "NotEvaluated",
                        y: result.evaluationNotEvaluated.length,
                        drilldown: "NotEvaluated"
                    });
                    evaluationPerStatusList.push({
                        name: "Rejected",
                        y: result.evaluationRejected.length,
                        drilldown: "Rejected"
                    });
                    evaluationPerStatusList.push({
                        name: "Failed",
                        y: result.evaluationFailed.length,
                        drilldown: "Failed"
                    });

                    this.setState({
                        EvaluationPerStatus: {
                            series: [{
                                name: "Status",
                                colorByPoint: true,
                                data: evaluationPerStatusList

                            }],
                            title: "Evaluation count by status",
                            xaxistitle: "Status",
                            yaxistitle: "Evaluation"
                        },

                        EvaluationHiredList: result.evaluationHired,
                        EvaluationNotEvaluatedList: result.evaluationNotEvaluated,
                        EvaluationRejectedList: result.evaluationRejected,
                        EvaluationFailedList: result.evaluationFailed,
                    });

                    this.setBarChartForEvaluationStatus(this.state.EvaluationPerStatus);

                    this.setState({
                        IsLoading: 0
                    });
                })
                .catch(err => {
                    this.setState({
                        IsLoading: 0
                    });
                    console.log("err");
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic stats!', "top-center");

                });

        }

        else
            return;
    };

    // Load Report settings
    async getAnalytics() {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/" + tenantId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(result => {
                    if (result != null) {
                        for (var i = 0; i < result.length; i++) {
                            if (result[i].reportTitle === "JobperIndusrty") {
                                this.setState({
                                    isJobperIndusrtySelected: true,
                                });
                            }

                            if (result[i].reportTitle === "TotalActiveJobs") {
                                this.setState({
                                    isTotalActiveJobs: true,
                                });
                            }
                            if (result[i].reportTitle === "TotalInActiveJobs") {
                                this.setState({
                                    isTotalInActiveJobs: true,
                                });
                            }
                            if (result[i].reportTitle === "PublishedinMonthJobs") {
                                this.setState({
                                    isPublishedinMonthJobs: true,
                                });
                            }

                            if (result[i].reportTitle === "TotalActiveInterviews") {
                                this.setState({
                                    isTotalActiveInterviews: true,
                                });
                            }

                            if (result[i].reportTitle === "TotalInActiveInterviews") {
                                this.setState({
                                    isTotalInActiveInterviews: true,
                                });
                            }

                            if (result[i].reportTitle === "TotalAutoInterviews") {
                                this.setState({
                                    isTotalAutoInterviews: true,
                                });
                            }

                            if (result[i].reportTitle === "InterviewsperJob") {
                                this.setState({
                                    isInterviewsperJob: true,
                                });
                            }

                            if (result[i].reportTitle === "InterviewsperType") {
                                this.setState({
                                    isInterviewsperType: true,
                                });
                            }

                            if (result[i].reportTitle === "InvitesPerStatus") {
                                this.setState({
                                    isInvitesPerStatus: true,
                                });
                            }

                            if (result[i].reportTitle === "InvitesPerStatusWithCustomization") {
                                this.setState({
                                    isInvitesPerStatusWithCustomization: true,
                                });
                            }
                            if (result[i].reportTitle === "TotalLeads") {
                                this.setState({
                                    isTotalLeads: true,
                                });
                            }
                            if (result[i].reportTitle === "ShortListedLeads") {
                                this.setState({
                                    isShortListedLeads: true,
                                });
                            }

                            if (result[i].reportTitle === "InOneWeekLeads") {
                                this.setState({
                                    isInOneWeekLeads: true,
                                });
                            }

                            if (result[i].reportTitle === "LeadsperJob") {
                                this.setState({
                                    isLeadsperJob: true,
                                });
                            }


                            if (result[i].reportTitle === "EvaluationPerStatus") {
                                this.setState({
                                    isEvaluationPerStatus: true,
                                });
                            }

                        }
                    }
                    else {
                        this.setState({
                            isJobperIndusrtySelected: false,
                            isTotalActiveJobs: false,
                            isTotalInActiveJobs: false,
                            isPublishedinMonthJobs: false,
                            isTotalActiveInterviews: false,
                            isTotalInActiveInterviews: false,
                            isTotalAutoInterviews: false,
                            isInterviewsperJob: false,
                            isInterviewsperType: false,
                            isInvitesPerStatus: false,
                            isInvitesPerStatusWithCustomization: false,
                            isTotalLeads: false,
                            isShortListedLeads: false,
                            isInOneWeekLeads: false,
                            isLeadsperJob: false,
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Analytic!', "top-center");
                });

        }

        else
            return;
    };

    // add reprot setting active or inactive
    async addAnalytics(reportTitle, isActive) {
        var bearer = null;
        var url = null;

        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantReport";
            let Analytic = {
                ReportTitle: reportTitle,
                TenantId: tenantId,
                IsActive: isActive,
            };
            var model = JSON.stringify(Analytic);

            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions).then(response => response.json())
                .then(result => {
                    console.log(result);
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while updating Analytic!', "top-center")

                });

        }

        else
            return;
    };

    // get jobs data per industry
    async getJobsByIndustry(industry) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        let RequestBaseDTO = {
            Title: industry,
        };
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Analytics/";
            var model = JSON.stringify(RequestBaseDTO);
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: model,
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        jobsListByIndustry: result,
                        CurrentJobsList: result,
                        selectedIndustryPopUpLabel: " Jobs List for Industry " + this.state.selectedIndustry
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching JobsByIndustry!', "top-center");
                });
        }

        else
            return;
    };

    // Reviewers data for popup
    async getReviewesPerJob(selectedReviewer, startFilterDate, endFilterDate) {
        var bearer = null;
        var url = null;

        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/ReviewerAnalytics";
            let RevieweRequest = {
                TenantId: tenantId,
                SelectedReviewer: selectedReviewer,
                StartFilterDate: startFilterDate,
                EndFilterDate: endFilterDate,
                JobId: 0
            };
            var model = JSON.stringify(RevieweRequest);
            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
                body: model,
            };

            fetch(url, requestOptions).then(response => response.json())
                .then(result => {
                    let invitesPerStatusCustomizationList = [];

                    for (var i = 0; i < result.length; i++) {
                        invitesPerStatusCustomizationList.push({
                            name: result[i].jobName + " - " + result[i].jobLocation,
                            y: result[i].reviewesCount,
                            drilldown: result[i].jobName,
                            jobId: result[i].jobId
                        })
                    }

                    this.setState({
                        InvitesPerStatusCustomization: {
                            series: [{
                                name: "Status",
                                colorByPoint: true,
                                data: invitesPerStatusCustomizationList

                            }],
                            title: "Reviews Count By Jobs",
                            xaxistitle: "Jobs",
                            yaxistitle: "Reviews"
                        }
                    }, () => {
                        this.setBarChartForInvitesStatusCustomization(this.state.InvitesPerStatusCustomization);
                    })

                })
                .catch(err => {
                   console.log(err);
                    displayErrorMessage('Error while Get Reviews Per Job!', "top-center")

                });

        }

        else
            return;
    };

    // Set chart values
    setBarChartOptions(jobsPerIndustry) {
        this.setState({
            barChartOptions: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: jobsPerIndustry.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: jobsPerIndustry.xaxistitle

                    }
                },
                yAxis: {
                    title: {
                        text: jobsPerIndustry.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: jobsPerIndustry.series,
                drilldown: jobsPerIndustry.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        },
                        point: {
                            events: {
                                click: this.handleBarClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }

    // Set chart values
    setBarChartForInterviews(InterviewsPerJob) {
        this.setState({
            BarChartForInterviews: {
                chart: {
                    type: 'column'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: InterviewsPerJob.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: InterviewsPerJob.xaxistitle

                    }
                },
                yAxis: {
                    title: {
                        text: InterviewsPerJob.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: InterviewsPerJob.series,
                drilldown: InterviewsPerJob.drilldown,
                jobId: InterviewsPerJob.jobId,
                 plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleInterviewColumnClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }

    // Set chart values
    setBarChartForInterviewTypes(InterviewsPerType) {
        this.setState({
            BarChartForInterviewsType: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: InterviewsPerType.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: InterviewsPerType.xaxistitle
                    }
                },
                yAxis: {
                    title: {
                        text: InterviewsPerType.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: InterviewsPerType.series,
                drilldown: InterviewsPerType.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleInterviewTypeClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }
    // Set chart values
    setBarChartForInvitesStatus(InvitesPerStatus) {
        this.setState({
            BarChartForInvitesStatus: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: InvitesPerStatus.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: InvitesPerStatus.xaxistitle
                    }
                },
                yAxis: {
                    title: {
                        text: InvitesPerStatus.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: InvitesPerStatus.series,
                drilldown: InvitesPerStatus.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleInvitesPerStatusClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }
    // Set chart values
    setBarChartForInvitesStatusCustomization(InvitesPerStatusCustomization) {
        this.setState({
            BarChartForInvitesStatusCustomization: {
                chart: {
                    type: 'column'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: InvitesPerStatusCustomization.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: InvitesPerStatusCustomization.xaxistitle
                    }
                },
                yAxis: {
                    title: {
                        text: InvitesPerStatusCustomization.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: InvitesPerStatusCustomization.series,
                drilldown: InvitesPerStatusCustomization.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleInvitesPerStatusCustomizationClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }
    // Set chart values
    setBarChartForLeads(LeadsPerJob) {
        this.setState({
            BarChartForLeads: {
                chart: {
                    type: 'column'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: LeadsPerJob.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: LeadsPerJob.xaxistitle

                    }
                },
                yAxis: {
                    title: {
                        text: LeadsPerJob.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: LeadsPerJob.series,
                drilldown: LeadsPerJob.drilldown,
                jobId: LeadsPerJob.jobId,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleLeadsColumnClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }
    // Set chart values
    setBarChartForEvaluationStatus(EvaluationPerStatus) {
        this.setState({
            BarChartForEvaluationStatus: {
                chart: {
                    type: 'bar'
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                title: {
                    text: EvaluationPerStatus.title
                },
                xAxis: {
                    type: 'category',
                    title: {
                        text: EvaluationPerStatus.xaxistitle
                    }
                },
                yAxis: {
                    title: {
                        text: EvaluationPerStatus.yaxistitle
                    }

                },
                legend: {
                    enabled: false
                },

                series: EvaluationPerStatus.series,
                drilldown: EvaluationPerStatus.drilldown,
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}'
                        }
                        ,
                        point: {
                            events: {
                                click: this.handleEvaluationPerStatusClick
                            }
                        },
                        cursor: 'pointer'
                    }
                },
            }
        });
    }
    // event on chart click
    handleEvaluationPerStatusClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        if (event.point.drilldown === "Hired") {
            this.setState({
                IsEvaluationPopup: 1,
                EvaluationList: this.state.EvaluationHiredList,
                selectedEvaluationPopUpLabel: " Evaluation List for Status Hired"
            });
        }
        if (event.point.drilldown === "NotEvaluated") {
            this.setState({
                IsEvaluationPopup: 1,
                EvaluationList: this.state.EvaluationNotEvaluatedList,
                selectedEvaluationPopUpLabel: " Evaluation List for Status Not Evaluated"
            });
        }
        if (event.point.drilldown === "Rejected") {
            this.setState({
                IsEvaluationPopup: 1,
                EvaluationList: this.state.EvaluationRejectedList,
                selectedEvaluationPopUpLabel: " Evaluation List for Status Rejected"
            });
        }
        if (event.point.drilldown === "Failed") {
            this.setState({
                IsEvaluationPopup: 1,
                EvaluationList: this.state.EvaluationFailedList,
                selectedEvaluationPopUpLabel: " Evaluation List for Status Failed"
            });
        }
    }
    // event on chart click
    handleInvitesPerStatusClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        this.getInvitesPerStatus(event.point.drilldown);
    }

    // get invites data for popup
    async getInvitesPerStatus(InvitesStatus) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let RequestBaseDTO = {
                Title: InvitesStatus,
            };
            url = process.env.REACT_APP_CORE_API + "/api/InterviewInvite/GetInvitesByStatus";
            var model = JSON.stringify(RequestBaseDTO);
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: model,
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        IsInvitePopup: 1,
                        InviteList: result,
                        selectedInvitePopUpLabel: " Invite List for Status " + InvitesStatus
                    });

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Invites By Status!', "top-center");
                });
        }
        else
            return;
    };
    // event on chart click
    handleInvitesPerStatusCustomizationClick = (event) => {
        console.log("custom :");
        if (event.point.y < 1) {
            return;
        }
        console.log(event.point.jobId);
        this.GetReviewesDetailsPerJob(event.point.jobId);
    }

    // Get Reviewes Details Per Job
    async GetReviewesDetailsPerJob(jobId) {
        console.log("custom : 2");
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let tenantId = parseJwt(bearer).tenantid;
            let startFilterDate = null;
            let endFilterDate = null;
            if (this.state.startFilterDate) {
                startFilterDate = moment(this.state.startFilterDate).format('YYYY-MM-DD');
            }

            if (this.state.endFilterDate) {
                endFilterDate = moment(this.state.endFilterDate).format('YYYY-MM-DD');
            }

            let selectedReviewer = this.state.SelectedReviewer;
            let RevieweRequest = {
                TenantId: tenantId,
                SelectedReviewer: selectedReviewer,
                StartFilterDate: startFilterDate,
                EndFilterDate: endFilterDate,
                JobId: jobId
            };
            url = process.env.REACT_APP_CORE_API + "/api/ReviewerAnalytics/GetReviewesDetailsPerJob";
            var model = JSON.stringify(RevieweRequest);
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: model,
                }).then(response => response.json())
                .then(result => {
                    console.log(" custom");
                    console.log(result);
                    if (result.length > 0) {
                        this.setState({
                            IsInviteCustomizationPopup: 1,
                            InviteCustomizationList: result,
                            selectedInviteCustomizationPopUpLabel: " Review Details"
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Review Details!', "top-center");
                });
        }
        else
            return;
    };
    // load Review Detail in popup
    async loadReviewDetail(interviewInviteId, reviewBy) {

        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let tenantId = parseJwt(bearer).tenantid;
            
            let startFilterDate = null;
            let endFilterDate = null;
            if (this.state.startFilterDate) {
                startFilterDate = moment(this.state.startFilterDate).format('YYYY-MM-DD');
            }

            if (this.state.endFilterDate) {
                endFilterDate = moment(this.state.endFilterDate).format('YYYY-MM-DD');
            }
            let RevieweRequest = {
                TenantId: tenantId,
                SelectedReviewer: reviewBy,
                StartFilterDate: startFilterDate,
                EndFilterDate: endFilterDate,
                InterviewInviteId: interviewInviteId
            };
            url = process.env.REACT_APP_CORE_API + "/api/ReviewerAnalytics/LoadReviewDetail";
            var model = JSON.stringify(RevieweRequest);
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: model,
                }).then(response => response.json())
                .then(result => {
                    console.log(" loadReviewDetail");
                    console.log(result);
                    if (result.length > 0) {
                        this.setState({
                            IsInviteCustomizationPopup: 0,
                            IsReviewDetailsPopup: 1,
                            ReviewsList: result,
                            selectedInviteCustomizationPopUpLabel: " Review Details"
                        });
                    }
                    else {
                        displayErrorMessage('Access Denied: You do not have the necessary rights to view the ratings.', "top-center");
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Review Details!', "top-center");
                });
        }
        else
            return;
    };
    // get tenant users for filter
    async getUsers() {
        console.log("users are");
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {

           // let appUserId = this.getApplicationUserIdFromToken();

            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsers?hideTenantAdmin=" + false;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    console.log("users are", result);
                    let tmpReviewerList = [];
                    tmpReviewerList.push({
                        label: "ALL",
                        title: "ALL",
                        value: "ALL",
                    });
                    for (var i = 0; i < result.length; i++) {
                        console.log(result[i].firstName + " " + result[i].lastName);
                        tmpReviewerList.push({
                            label: result[i].firstName + " " + result[i].lastName,
                            title: result[i].firstName + " " + result[i].lastName,
                            value: result[i].email,
                        });
                    }
                    tmpReviewerList.push({
                        label: "Auto",
                        title: "Auto",
                        value: "Auto",
                    });
                    this.setState({
                        ReviewerList: tmpReviewerList
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching reviewers!', "top-center");

                });
        }
    }
    // event on chart click
    handleInterviewTypeClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        this.getInterviewByType(event.point.drilldown);
    }
    // get interview detail by type on popup
    async getInterviewByType(InterviewType) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            let RequestBaseDTO = {
                Title: InterviewType,
            };
            url = process.env.REACT_APP_CORE_API + "/api/InterviewInfo";
            var model = JSON.stringify(RequestBaseDTO);
            fetch(url,
                {
                    method: 'POST',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                    body: model,
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        IsInterviewPopup: 1,
                        InterviewsList: result,
                        selectedInterviewsPopUpLabel: " Interviews List for Type " + this.formatInterviewType(InterviewType)
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching InterviewsByType!', "top-center");
                });
        }
        else
            return;
    };
    // event on chart click
    handleInterviewColumnClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        this.getInterviewByjob(event.point.jobId, event.point.drilldown);
    }
    // get interview details by job on popup
    async getInterviewByjob(jobId, jobName) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/Interview/" + jobId;
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        IsInterviewPopup: 1,
                        InterviewsList: result,
                        selectedInterviewsPopUpLabel: " Interviews List for Job " + jobName
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching InterviewsByJob!', "top-center");
                });
        }
        else
            return;
    };
    // event on chart click
    handleLeadsColumnClick = (event) => {
        if (event.point.y < 1) {
            return;
        }
        this.getLeadsByjob(event.point.jobId, event.point.drilldown);
    }
    // get data for leads by job on popup
    async getLeadsByjob(jobId, jobName) {
        var bearer = null;
        var url = null;
        bearer = validateAndGetFormatedToken();
        if ((bearer !== null) && (bearer !== undefined) && (bearer !== '') && (bearer !== 'undefined')) {
            url = process.env.REACT_APP_CORE_API + "/api/LeadsTracking/" + jobId;
            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    },
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        isLeads: 1,
                        leadsList: result,
                        selectedLeadsPopUpLabel: "Applicants List for Job " + jobName
                    });
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Applicants By Job!', "top-center");
                });
        }
        else
            return;
    };
    // event on chart click
    handleBarClick = (event) => {
        console.log(event);
        this.setState({
            IsJobperIndusrty: 1,
            selectedIndustry: event.point.drilldown
        });
        this.getJobsByIndustry(event.point.drilldown);
    }
    // event on chart click
    handleReviewerAnalytic(selecter) {
        if (this.state.startFilterDate && this.state.endFilterDate == null) {
            console.error('end date empty.');
            return false;
        }

        if (this.state.startFilterDate == null && this.state.endFilterDate) {
            console.error('start date empty.');
            return false;
        }

        if (this.state.startFilterDate && this.state.endFilterDate) {
            if (new Date(this.state.endFilterDate) < new Date(this.state.startFilterDate)) {
                console.error('To Date must be greater than From Date');
                displayErrorMessage("Error: To Date must be greater than From Date!", "top-center");
                return false;
            }
        }

        let startFilterDate = null;
        let endFilterDate = null;
        if (this.state.startFilterDate) {
            startFilterDate = moment(this.state.startFilterDate).format('YYYY-MM-DD');
        }

        if (this.state.endFilterDate) {
            endFilterDate = moment(this.state.endFilterDate).format('YYYY-MM-DD');
        }
        
        let SelectedReviewer = this.state.SelectedReviewer;
        this.getReviewesPerJob(SelectedReviewer, startFilterDate, endFilterDate);
    };
    // event on chart click
    handleWidgetClick(name) {
        if (name === "CountOfActivejobs") {
            this.setState({
                IsJobperIndusrty: 1,
                jobsListByIndustry: this.state.ActiveJobsList,
                selectedIndustryPopUpLabel: " Active Jobs List",
            });
        }
        else if (name === "CountOfInActivejobs") {
            this.setState({
                IsJobperIndusrty: 1,
                jobsListByIndustry: this.state.InActiveJobsList,
                selectedIndustryPopUpLabel: " InActive Jobs List",
            });
        }
        else if (name === "CountOfPublishedJobsInMonth") {
            this.setState({
                IsJobperIndusrty: 1,
                jobsListByIndustry: this.state.PublishedinMonthJobsList,
                selectedIndustryPopUpLabel: "Published Jobs List 30 Days",
            });
        }
        else if (name === "CounActiveInterview") {
            this.setState({
                IsInterviewPopup: 1,
                InterviewsList: this.state.ActiveInterviewList,
                selectedInterviewsPopUpLabel: "Active Interviews List",
            });
        }
        else if (name === "CountInActiveInterview") {
            this.setState({
                IsInterviewPopup: 1,
                InterviewsList: this.state.InActiveInterviewList,
                selectedInterviewsPopUpLabel: "InActive Interviews List",
            });
        }
        else if (name === "CountAutoInterviews") {
            this.setState({
                IsInterviewPopup: 1,
                InterviewsList: this.state.AutoInterviewsList,
                selectedInterviewsPopUpLabel: "Auto Interviews List",
            });
        }
        else if (name === "InvitesPerStatus") {
            this.setState({
                IsInvitePopup: 1,
                InviteList: this.state.InviteList,
                selectedInvitePopUpLabel: "Invite List for Status",
            });
        }
        else if (name === "InvitesPerStatusWithCustomization") {
            this.setState({
                IsInviteCustomizationPopup: 1,
                InviteCustomizationList: this.state.InviteCustomizationList,
                selectedInviteCustomizationPopUpLabel: " Review Details",
            });
        }
        else if (name === "CountTotalLeadsList") {
            this.setState({
                isLeads: 1,
                leadsList: this.state.TotalLeadsList,
                selectedLeadsPopUpLabel: "Total Applicants",
            });
        }
        else if (name === "CountShortListedLeadsList") {
            this.setState({
                isLeads: 1,
                leadsList: this.state.ShortListedLeadsList,
                selectedLeadsPopUpLabel: "Short Listed Applicants",
            });
        }
        else if (name === "CountInOneWeekLeadsList") {
            this.setState({
                isLeads: 1,
                leadsList: this.state.InOneWeekLeadsList,
                selectedLeadsPopUpLabel: "Applicants in 7 days",
            });
        }

    }
    // on tabs click set settings for selected tab
    MenuClick(name) {
        if (name === "Jobs") {
            this.setState({
                IsJobperIndusrty: 1,
                jobsListByIndustry: this.state.ActiveJobsList,
            });
        }
        else if (name === "CountOfInActivejobs") {
            this.setState({
                IsJobperIndusrty: 1,
                jobsListByIndustry: this.state.InActiveJobsList,
            });
        }
    }
    formatInterviewType(interviewType) {
        if (interviewType === 'onewayopen') {
            return 'One-Way Open';
        }
        if (interviewType === 'oneway') {
            return 'One-Way Close';
        }
        if (interviewType === 'live') {
            return 'Two-Way Live';
        }
        // Add more transformations as needed
        return interviewType;
    };
    render() {

        const onReportChange = (e) => {
            if (e.target.value === "JobperIndusrty") {
                if (e.target.checked) {
                    this.setState({
                        isJobperIndusrtySelected: true,
                    });
                } else {
                    this.setState({
                        isJobperIndusrtySelected: false,
                    });

                }
            }

            if (e.target.value === "TotalActiveJobs") {
                if (e.target.checked) {
                    this.setState({
                        isTotalActiveJobs: true,
                    });
                } else {
                    this.setState({
                        isTotalActiveJobs: false,
                    });

                }
            }

            if (e.target.value === "TotalInActiveJobs") {
                if (e.target.checked) {
                    this.setState({
                        isTotalInActiveJobs: true,
                    });
                } else {
                    this.setState({
                        isTotalInActiveJobs: false,
                    });

                }
            }

            if (e.target.value === "PublishedinMonthJobs") {
                if (e.target.checked) {
                    this.setState({
                        isPublishedinMonthJobs: true,
                    });
                } else {
                    this.setState({
                        isPublishedinMonthJobs: false,
                    });

                }
            }

            if (e.target.value === "TotalActiveInterviews") {
                if (e.target.checked) {
                    this.setState({
                        isTotalActiveInterviews: true,
                    });
                } else {
                    this.setState({
                        isTotalActiveInterviews: false,
                    });

                }
            }

            if (e.target.value === "TotalInActiveInterviews") {
                if (e.target.checked) {
                    this.setState({
                        isTotalInActiveInterviews: true,
                    });
                } else {
                    this.setState({
                        isTotalInActiveInterviews: false,
                    });

                }
            }

            if (e.target.value === "TotalAutoInterviews") {
                if (e.target.checked) {
                    this.setState({
                        isTotalAutoInterviews: true,
                    });
                } else {
                    this.setState({
                        isTotalAutoInterviews: false,
                    });

                }
            }


            if (e.target.value === "InterviewsperJob") {
                if (e.target.checked) {
                    this.setState({
                        isInterviewsperJob: true,
                    });
                } else {
                    this.setState({
                        isInterviewsperJob: false,
                    });

                }
            }

            if (e.target.value === "InterviewsperType") {
                if (e.target.checked) {
                    this.setState({
                        isInterviewsperType: true,
                    });
                } else {
                    this.setState({
                        isInterviewsperType: false,
                    });
                }
            }

            if (e.target.value === "InvitesPerStatus") {
                if (e.target.checked) {
                    this.setState({
                        isInvitesPerStatus: true,
                    });
                } else {
                    this.setState({
                        isInvitesPerStatus: false,
                    });
                }
            }

            if (e.target.value === "InvitesPerStatusWithCustomization") {
                if (e.target.checked) {
                    this.setState({
                        isInvitesPerStatusWithCustomization: true,
                    });
                } else {
                    this.setState({
                        isInvitesPerStatusWithCustomization: false,
                    });
                }
            }

            if (e.target.value === "TotalLeads") {
                if (e.target.checked) {
                    this.setState({
                        isTotalLeads: true,
                    });
                } else {
                    this.setState({
                        isTotalLeads: false,
                    });
                }
            }

            if (e.target.value === "ShortListedLeads") {
                if (e.target.checked) {
                    this.setState({
                        isShortListedLeads: true,
                    });
                } else {
                    this.setState({
                        isShortListedLeads: false,
                    });
                }
            }


            if (e.target.value === "InOneWeekLeads") {
                if (e.target.checked) {
                    this.setState({
                        isInOneWeekLeads: true,
                    });
                } else {
                    this.setState({
                        isInOneWeekLeads: false,
                    });
                }
            }

            if (e.target.value === "LeadsperJob") {
                if (e.target.checked) {
                    this.setState({
                        isLeadsperJob: true,
                    });
                } else {
                    this.setState({
                        isLeadsperJob: false,
                    });

                }
            }


            if (e.target.value === "EvaluationPerStatus") {
                if (e.target.checked) {
                    this.setState({
                        isEvaluationPerStatus: true,
                    });
                } else {
                    this.setState({
                        isEvaluationPerStatus: false,
                    });
                }
            }

            this.addAnalytics(e.target.value, e.target.checked);
        };


        return (
            <>
                <main id="content" role="main"
                    className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}
                >
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div className="float-start">
                                        <h1 className="page-header-title">Analytics</h1>
                                    </div>
                                    <div className="float-end">

                                        <button type="button" className="btn btn-primary mod-b-d-size" data-bs-dismiss="modal" aria-label="Add Analytics"
                                            onClick={() => {
                                                this.setState({ IsAddAnalytics: 1 })
                                                document.body.classList.add('modal-open');

                                            }}>Add Analytics</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="page-header">
                            <div className="MenuDiv">
                                <ul className="horizontal-menu">
                                    <li><a className={this.state.SelectedMenuItem === "Jobs" ? "horizontal-menu-selected" : ""}
                                    href
                                        onClick={() => {
                                            this.setState({
                                                SelectedMenuItem: "Jobs",
                                            });
                                            this.getAnalyticsStats();
                                        }}>Jobs</a></li>
                                    <li><a href className={this.state.SelectedMenuItem === "Interviews" ? "horizontal-menu-selected" : ""}
                                        onClick={() => {
                                            this.setState({
                                                SelectedMenuItem: "Interviews",
                                            });
                                            this.getInterviewsAnalytics();
                                        }}>Interviews</a></li>
                                    <li><a href className={this.state.SelectedMenuItem === "Invites" ? "horizontal-menu-selected" : ""}

                                        onClick={() => {
                                            this.setState({
                                                SelectedMenuItem: "Invites",
                                            });
                                            this.getInvitesAnalytics();
                                            this.getReviewesPerJob("ALL", "", "");
                                            this.getUsers();
                                        }}>Invites</a></li>
                                    <li><a href className={this.state.SelectedMenuItem === "Applicants" ? "horizontal-menu-selected" : ""}

                                        onClick={() => {
                                            this.setState({
                                                SelectedMenuItem: "Applicants",
                                            });
                                            this.getCandidatesAnalytics();
                                        }}>Applicants</a></li>
                                    <li><a href className={this.state.SelectedMenuItem === "Evaluations" ? "horizontal-menu-selected" : ""}

                                        onClick={() => {
                                            this.setState({
                                                SelectedMenuItem: "Evaluations",
                                            });
                                            this.getEvaluationsAnalytics();
                                        }}>Evaluations</a></li>
                                </ul>
                            </div>
                        </div>

                        {
                            this.state.SelectedMenuItem === "Jobs" &&
                            <>
                                <div className="row">

                                    {
                                        this.state.isTotalActiveJobs === true &&
                                        <div className="float-sm-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5 ">
                                                {this.state.CountOfActivejobs > 0 &&
                                                    <div className="bg-success pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountOfActivejobs");
                                                        document.body.classList.add('modal-open');

                                                    }}>
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Active Jobs</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountOfActivejobs}
                                                            </span>

                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountOfActivejobs === 0 &&
                                                    <div className="bg-success pe-auto">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Active Jobs</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountOfActivejobs}
                                                            </span>

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.isTotalInActiveJobs === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                {this.state.CountOfInActiveJobs > 0 &&
                                                    <div className="bg-danger pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountOfInActivejobs");
                                                        document.body.classList.add('modal-open');

                                                    }}>
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">InActive Jobs</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountOfInActiveJobs}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountOfInActiveJobs === 0 &&
                                                    <div className="bg-danger">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">InActive Jobs</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountOfInActiveJobs}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }

                                    {
                                        this.state.isPublishedinMonthJobs === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="bg-info pe-auto" onClick={() => {
                                                    this.handleWidgetClick("CountOfPublishedJobsInMonth");
                                                    document.body.classList.add('modal-open');

                                                }}>
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">Published in 30 days</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2">
                                                            {this.state.CountOfPublishedJobsInMonth}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>


                                <div>
                                    {
                                        this.state.isJobperIndusrtySelected === true &&
                                        <div className="row">

                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                        <h4 className="card-header-title mb-2 mb-sm-0">Active Jobs per industry</h4>


                                                    </div>
                                                    <div className="card-body">

                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.barChartOptions}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </>
                        }


                        {
                            this.state.SelectedMenuItem === "Interviews" &&
                            <>
                                <div className="row">

                                    {
                                        this.state.isTotalActiveInterviews === true &&
                                        <div className="float-sm-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5 ">
                                                {this.state.CounActiveInterview > 0 &&
                                                    <div className="bg-success pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CounActiveInterview");
                                                    }}>
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Active Interviews</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CounActiveInterview}
                                                            </span>

                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CounActiveInterview === 0 &&
                                                    <div className="bg-success pe-auto">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Active Interviews</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CounActiveInterview}
                                                            </span>

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.isTotalInActiveInterviews === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                {this.state.CountInActiveInterview > 0 &&
                                                    <div className="bg-danger pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountInActiveInterview");
                                                    }}>
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">InActive Interviews</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountInActiveInterview}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountInActiveInterview === 0 &&
                                                    <div className="bg-danger">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">InActive Interview</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountInActiveInterview}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }


                                    {
                                        this.state.isTotalAutoInterviews === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                {this.state.CountAutoInterviews > 0 &&
                                                    <div className="bg-info pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountAutoInterviews");
                                                    }}>
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Auto Interviews</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountAutoInterviews}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountAutoInterviews === 0 &&
                                                    <div className="bg-danger">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Auto Interviews</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountAutoInterviews}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }


                                </div>

                                <div>
                                    {
                                        this.state.isInterviewsperJob === true &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                        <h4 className="card-header-title mb-2 mb-sm-0">Interviews per Active Jobs</h4>

                                                    </div>
                                                    <div className="card-body">

                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.BarChartForInterviews}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div>
                                    {
                                        this.state.isInterviewsperType === true &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                        <h4 className="card-header-title mb-2 mb-sm-0">Active Interviews per Type</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.BarChartForInterviewsType}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {
                            this.state.SelectedMenuItem === "Invites" &&
                            <>
                                <div>
                                    {
                                        this.state.isInvitesPerStatus === true &&

                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                        <h4 className="card-header-title mb-2 mb-sm-0">Invites per Status</h4>

                                                    </div>
                                                    <div className="card-body">
                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.BarChartForInvitesStatus}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.isInvitesPerStatusWithCustomization === true &&

                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                            <h4 className="card-header-title mb-2 mb-sm-0">
                                                                Reviews Per Job
                                                            </h4>
                                                    </div>
                                                        <div className="card-header col-md-12 d-flex align-items-center">
                                                            <label
                                                                class="form-label f-size-14 fw-bold me-2 mt-4">
                                                                Reviewer
                                                            </label>
                                                            
                                                            <Select
                                                                id="Reviewers"
                                                                name="Reviewers"
                                                                value={this.state.ReviewerList.find(item => item.title === this.state.SelectedReviewer)}
                                                                options={this.state.ReviewerList}
                                                                onChange={(e) => {
                                                                    this.setState({ SelectedReviewer: e.value }, () => {
                                                                        this.handleReviewerAnalytic("Reviewer");
                                                                    })
                                                                }}
                                                                className="col-md-4"
                                                                style={{ width: "20%", }}
                                                            />

                                                            <label
                                                                class="form-label f-size-14 fw-bold mx-2 mt-4">
                                                                From
                                                            </label>
                                                            <DatePicker
                                                                selected={this.state.startFilterDate}
                                                                onChange={(date) =>
                                                                    this.setState({ startFilterDate: date }, () => {
                                                                        this.handleReviewerAnalytic("start");
                                                                    } )
                                                                }
                                                                selectsStart
                                                                startDate={this.state.startFilterDate}
                                                                endDate={this.state.endFilterDate}
                                                                placeholder="From Date"
                                                                className="form-control mr-2 f-dark f-size-14"
                                                            />
                                                            <label
                                                                class="form-label f-size-14 fw-bold mx-2 mt-4">
                                                                To
                                                            </label>
                                                            <DatePicker
                                                                selected={this.state.endFilterDate}
                                                                onChange={(date) =>
                                                                    this.setState({ endFilterDate: date }, () => {
                                                                        this.handleReviewerAnalytic("end");
                                                                    } )}
                                                                selectsEnd
                                                                startDate={this.state.startFilterDate}
                                                                endDate={this.state.endFilterDate}
                                                                minDate={this.state.startFilterDate}
                                                                placeholder="To Date"
                                                                className="form-control f-dark f-size-14"
                                                            />
                                                            
                                                        </div>

                                                    <div className="card-body">
                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                        options={this.state.BarChartForInvitesStatusCustomization}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </>
                        }
                        {
                            this.state.SelectedMenuItem === "Applicants" &&
                            <>
                                <div className="row">

                                    {
                                        this.state.isTotalLeads === true &&
                                        <div className="float-sm-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5 ">
                                                {this.state.CountTotalLeadsList > 0 &&
                                                    <div className="bg-success pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountTotalLeadsList");
                                                    }}>
                                                        <div className="card-body text-center">
                                                                <small className="text-cap">Total Applicants</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountTotalLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountTotalLeadsList === 0 &&
                                                    <div className="bg-success pe-auto">
                                                        <div className="card-body text-center">
                                                            <small className="text-cap">Total Applicants</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountTotalLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }


                                    {
                                        this.state.isShortListedLeads === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                {this.state.CountShortListedLeadsList > 0 &&
                                                    <div className="bg-danger pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountShortListedLeadsList");
                                                    }}>
                                                        <div className="card-body text-center">
                                                                <small className="text-cap">ShortListed Applicants</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountShortListedLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountShortListedLeadsList === 0 &&
                                                    <div className="bg-danger">
                                                        <div className="card-body text-center">
                                                                <small className="text-cap">ShortListed Applicants</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountShortListedLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }

                                    {
                                        this.state.isInOneWeekLeads === true &&
                                        <div className="float-md-start" style={{ width: "33%" }}>
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                {this.state.CountInOneWeekLeadsList > 0 &&
                                                    <div className="bg-danger pe-auto" onClick={() => {
                                                        this.handleWidgetClick("CountInOneWeekLeadsList");
                                                    }}>
                                                        <div className="card-body text-center">
                                                                <small className="text-cap">Applicants in 7 days</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountInOneWeekLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                {this.state.CountInOneWeekLeadsList === 0 &&
                                                    <div className="bg-danger">
                                                        <div className="card-body text-center">
                                                                <small className="text-cap">Applicants in 7 days</small>
                                                            <span className="js-counter d-block display-3 text-dark mb-2">
                                                                {this.state.CountInOneWeekLeadsList}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    }



                                </div>



                                <div>
                                    {
                                        this.state.isLeadsperJob === true &&
                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5 mr-2">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                            <h4 className="card-header-title mb-2 mb-sm-0">Applicants per Active Jobs</h4>
                                                    </div>
                                                    <div className="card-body">

                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.BarChartForLeads}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }


                        {
                            this.state.SelectedMenuItem === "Evaluations" &&
                            <>

                                <div>
                                    {
                                        this.state.isEvaluationPerStatus === true &&

                                        <div className="row">
                                            <div className="col-lg-12 mb-3 mb-lg-5">
                                                <div className="card h-100">
                                                    <div className="card-header card-header-content-sm-between">
                                                        <h4 className="card-header-title mb-2 mb-sm-0">Evaluation per Status</h4>

                                                    </div>
                                                    <div className="card-body">
                                                        {/*<!-- Bar Chart -->*/}
                                                        <div className="chartjs-custom">

                                                            <div>
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.BarChartForEvaluationStatus}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/*<!-- End Bar Chart -->*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {/*<!-- End Content -->*/}

                    {
                        this.state.IsJobperIndusrty === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedIndustryPopUpLabel}</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            this.setState({ IsJobperIndusrty: 0, jobsListByIndustry: [] })
                                            document.body.classList.remove('modal-open');

                                        }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            JobId
                                                        </th>
                                                        <th>
                                                            Job Title
                                                        </th>
                                                        <th>
                                                            Location
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.jobsListByIndustry.map((job, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Link to={`/viewjob/${job.jobId}`}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "details");

                                                                            }}
                                                                        >
                                                                            {job.jobId}
                                                                        </Link>
                                                                    </td>
                                                                    <td >
                                                                        <Link to={`/viewjob/${job.jobId}`}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "details");

                                                                            }}>
                                                                            {job.title}
                                                                        </Link>
                                                                    </td>

                                                                    <td >
                                                                        {job.location} {job.state} <br />
                                                                        {job.country}
                                                                    </td>

                                                                </tr>
                                                            )

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ IsJobperIndusrty: 0, jobsListByIndustry: [] })
                                            document.body.classList.remove('modal-open');

                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {
                        this.state.IsAddAnalytics === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">Select Analytics</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            this.setState({ IsAddAnalytics: 0 })
                                            document.body.classList.remove('modal-open');

                                        }}></button>
                                    </div>
                                    <div className="modal-body bg-white" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>

                                        <div style={{ paddingLeft: '5%' }}>
                                            <h2>Jobs</h2>


                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Total Active Jobs</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalActiveJobs"
                                                            value="TotalActiveJobs"
                                                            onChange={e => onReportChange(e)}

                                                            checked={this.state.isTotalActiveJobs}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Total InActive Jobs</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalInActiveJobs"
                                                            value="TotalInActiveJobs"
                                                            onChange={e => onReportChange(e)}

                                                            checked={this.state.isTotalInActiveJobs}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Job Published in 30 days</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="PublishedinMonthJobs"
                                                            value="PublishedinMonthJobs"
                                                            onChange={e => onReportChange(e)}

                                                            checked={this.state.isPublishedinMonthJobs}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Jobs per Indusrty</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="JobperIndusrty"
                                                            value="JobperIndusrty"
                                                            onChange={e => onReportChange(e)}

                                                            checked={this.state.isJobperIndusrtySelected}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <h2>Interviews</h2>

                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Total Active Interviews</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalActiveInterviews"
                                                            value="TotalActiveInterviews"
                                                            onChange={e => onReportChange(e)}

                                                            checked={this.state.isTotalActiveInterviews}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Total InActive Interviews</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalInActiveInterviews"
                                                            value="TotalInActiveInterviews"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isTotalInActiveInterviews}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Total Auto Interviews</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalAutoInterviews"
                                                            value="TotalAutoInterviews"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isTotalAutoInterviews}
                                                        />
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Interviews per Job</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="InterviewsperJob"
                                                            value="InterviewsperJob"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isInterviewsperJob}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Interviews per Type</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="InterviewsperType"
                                                            value="InterviewsperType"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isInterviewsperType}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <h2>Invites</h2>

                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Invites Per Status</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="InvitesPerStatus"
                                                            value="InvitesPerStatus"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isInvitesPerStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="row-container mb-2 p-2">
                                                    <div className="fs-6 fw-bold question-text text-break">Reviews Per Job</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="InvitesPerStatusWithCustomization"
                                                            value="InvitesPerStatusWithCustomization"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isInvitesPerStatusWithCustomization}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <h2>Applicants</h2>

                                            <div className="row-container mb-2 p-2">
                                                    <div className="fs-6 fw-bold question-text text-break">Total Applicants</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="TotalLeads"
                                                            value="TotalLeads"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isTotalLeads}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                    <div className="fs-6 fw-bold question-text text-break">ShortListed Applicants</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="ShortListedLeads"
                                                            value="ShortListedLeads"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isShortListedLeads}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                    <div className="fs-6 fw-bold question-text text-break">Applicants in 7 days</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="InOneWeekLeads"
                                                            value="InOneWeekLeads"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isInOneWeekLeads}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row-container mb-2 p-2">
                                                    <div className="fs-6 fw-bold question-text text-break">Applicants per Job</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="LeadsperJob"
                                                            value="LeadsperJob"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isLeadsperJob}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <h2>Evaluations</h2>


                                            <div className="row-container mb-2 p-2">
                                                <div className="fs-6 fw-bold question-text text-break">Evaluation Per Status</div>
                                                <div className="switch-container">
                                                    <div className="form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="EvaluationPerStatus"
                                                            value="EvaluationPerStatus"
                                                            onChange={e => onReportChange(e)}
                                                            checked={this.state.isEvaluationPerStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ IsAddAnalytics: 0 })
                                            document.body.classList.remove('modal-open');

                                        }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {
                        this.state.IsInterviewPopup === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedInterviewsPopUpLabel}</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ IsInterviewPopup: 0, InterviewsList: [] }) }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            InterviewId
                                                        </th>
                                                        <th>
                                                            JobTitle
                                                        </th>
                                                        <th>
                                                            Interview Level
                                                        </th>
                                                        <th>
                                                            Interview Type
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.InterviewsList.map((interview, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Link to={"viewjob/" + interview.jobId} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {interview.interviewId}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={"viewjob/" + interview.jobId} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {interview.jobName}
                                                                        </Link>
                                                                    </td>

                                                                    <td >
                                                                        <Link to={"viewjob/" + interview.jobId} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {interview.interviewLevel}
                                                                        </Link>
                                                                    </td>

                                                                    <td >
                                                                        <Link to={"viewjob/" + interview.jobId} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {this.formatInterviewType(interview.interviewType)}
                                                                        </Link>
                                                                    </td>

                                                                </tr>
                                                            )

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ IsInterviewPopup: 0, InterviewsList: [] })
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.IsInvitePopup === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedInvitePopUpLabel}</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ IsInvitePopup: 0, InviteList: [] }) }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            First Name
                                                        </th>
                                                        <th>
                                                            Last Name
                                                        </th>
                                                        <th>
                                                            Job Title

                                                        </th>
                                                        <th>
                                                            Applicant Email
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.InviteList.map((invite, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Link to={`/viewjob/${invite.jobId}?interviewId=${invite.interviewId}&interviewInviteId=${invite.interviewInviteId}`} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {invite.firstName}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={`/viewjob/${invite.jobId}?interviewId=${invite.interviewId}&interviewInviteId=${invite.interviewInviteId}`} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {invite.lastName}
                                                                        </Link>
                                                                    </td>
                                                                    <td >
                                                                        <Link to={`/viewjob/${invite.jobId}?interviewId=${invite.interviewId}&interviewInviteId=${invite.interviewInviteId}`}  id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {invite.jobName}
                                                                        </Link>
                                                                    </td>
                                                                    <td >
                                                                        <Link to={`/viewjob/${invite.jobId}?interviewId=${invite.interviewId}&interviewInviteId=${invite.interviewInviteId}`} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "interviews");
                                                                            }}
                                                                        >
                                                                            {invite.candidateEmail}
                                                                        </Link>
                                                                    </td>

                                                                </tr>
                                                            )

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ IsInvitePopup: 0, InviteList: [] })
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.IsInviteCustomizationPopup === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true" style={{ width: '120%', left: '-7%' }}>
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                            <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedInviteCustomizationPopUpLabel}  </h4>
                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ IsInviteCustomizationPopup: 0, InviteCustomizationList: [] }) }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                        <tr>
                                                            <th>
                                                                Job Title
                                                            </th>
                                                        <th>
                                                            First Name
                                                        </th>
                                                        <th>
                                                            Last Name
                                                            </th>
                                                            <th>
                                                                Applicant Email
                                                            </th>
                                                            <th>
                                                                Reviewer
                                                            </th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                            this.state.InviteCustomizationList.map((invite, index) => {
                                                            return (
                                                                <tr key={index} onClick={() => {
                                                                    this.loadReviewDetail(invite.interviewInviteId, invite.reviewBy === "@System" || invite.reviewBy === "AI" ? "Auto" : invite.reviewBy)
                                                                }} style={{ cursor: 'pointer' }}>
                                                                    <td>
                                                                            {invite.jobTitle}
                                                                    </td>
                                                                    <td>
                                                                            {invite.applicantFirstName}
                                                                    </td>
                                                                    <td>
                                                                            {invite.applicantLastName}
                                                                    </td>
                                                                    <td>
                                                                            {invite.applicantEmail}

                                                                    </td>
                                                                    <td >
                                                                        {invite.reviewBy === "@System" || invite.reviewBy === "AI" ? "Auto" : invite.reviewBy}
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                                this.setState({ IsInviteCustomizationPopup: 0, InviteCustomizationList: [] })
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.IsReviewDetailsPopup === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true" style={{ width: '120%', left: '-7%' }}>
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedInviteCustomizationPopUpLabel}  </h4>
                                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ IsReviewDetailsPopup: 0, ReviewsList: [], IsInviteCustomizationPopup: 0, InviteCustomizationList: [] }) }}></button>
                                    </div>
                                        <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0', paddingTop: '0' }}>
                                           
                                            <div className="table-responsive datatable-custom w-100 mt-3">

                                            
                                                <table id="datatable" className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer" role="grid" aria-describedby="datatable_info">
                                                    <thead className="thead-light">
                                                        <tr role="row">
                                                            <th className="table-column-ps-1" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1">
                                                                Question Title</th>
                                                           
                                                            <th className="sorting_disabled" rowspan="1" colspan="1" aria-label="Tasks" >
                                                                Rating</th>
                                                            
                                                            <th className="sorting_disabled" tabindex="0" aria-controls="datatable" rowspan="1" colspan="1">
                                                                Reviewer</th>
                                                            
                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        {this.state.ReviewsList.map((review, index) => {
                                                            return (
                                                                <>
                                                                    <tr role="row" className="odd" >
                                                                        <td className="table-column-ps-1">

                                                                            <span className="d-block h5 text-inherit text-wrap inter-ff" style={{ width: '100%', cursor: 'pointer' }}
                                                                                dangerouslySetInnerHTML={{ __html:  review.questionTitle  }}></span>


                                                                        </td>
                                                 
                                                                        <td>
                                                                            <div style={{ fontSize: '25px', width: '110px' }}>
                                                                                <StarRatingComponent
                                                                                    id="selectratings"
                                                                                    name="rate1"
                                                                                    starCount={5}
                                                                                    value={review.stars}
                                                                                    
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        
                                                                        <td className="inter-ff">
                                                                            {review.reviewBy === "@System" || review.reviewBy === "AI" ? "Auto" : review.reviewBy}
                                                                        </td>
                                                                       
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                        }

                                                    </tbody>
                                                </table>                                               
                                                </div>

                                    </div>
                                        <div className="modal-footer  d-flex justify-content-between">
                                            
                                                <button
                                                    onClick={() => { this.setState({ IsInviteCustomizationPopup: 1, IsReviewDetailsPopup: 0, ReviewsList: [], selectedInviteCustomizationPopUpLabel: " Review Details" }) }}
                                                    class="border-swift btn-swift-text btn btn-light swift-shadow"
                                                    type="button"
                                                >
                                                    <span>
                                                        <i class="bi bi-arrow-left mr-1"></i>Back
                                                    </span>
                                                </button>
                                                <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                                    this.setState({ IsReviewDetailsPopup: 0, ReviewsList: [], IsInviteCustomizationPopup: 0, InviteCustomizationList: [] })
                                                }}>Cancel</button>
                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.isLeads === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedLeadsPopUpLabel}</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ isLeads: 0, leadsList: [] }) }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th
                                                        >
                                                            First Name
                                                        </th>
                                                        <th>
                                                            Last Name
                                                        </th>
                                                        <th>
                                                            Email

                                                        </th>
                                                        <th>
                                                            Contact
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.leadsList.map((lead, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <Link to={"viewjob/" + lead.jobId}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "applicants");

                                                                            }}
                                                                        >
                                                                            {lead.firstName}
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <Link to={"viewjob/" + lead.jobId}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "applicants");

                                                                            }}
                                                                        >
                                                                            {lead.lastName}
                                                                        </Link>
                                                                    </td>
                                                                    <td >
                                                                        <Link to={"viewjob/" + lead.jobId}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "applicants");

                                                                            }}
                                                                        >
                                                                            {lead.email}
                                                                        </Link>
                                                                    </td>
                                                                    <td >
                                                                        <Link to={"viewjob/" + lead.jobId}
                                                                            onClick={() => {
                                                                                sessionStorage.setItem('activeTabLink', "applicants");

                                                                            }}
                                                                        >
                                                                            {lead.mobile}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ isLeads: 0, leadsList: [] })
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.IsEvaluationPopup === 1 &&
                        <div id="ModalCenteredScrollable" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                    <div className="modal-header">
                                        <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">{this.state.selectedEvaluationPopUpLabel}</h4>
                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ IsEvaluationPopup: 0, EvaluationList: [] }) }}></button>
                                    </div>
                                    <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                        <div className="table-responsive datatable-custom w-100">
                                            <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Applicant </th>
                                                        <th>Job Title </th>
                                                        <th>Interview Type</th>
                                                        <th>Evaluation Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {this.state.EvaluationList.length > 0 && this.state.EvaluationList.map((candidateInterview, index) => (
                                                        <tr key={index}>
                                                            <td style={{ paddingRight: '0px', marginRight: '0px' }} id={"candidateDetails" + index} data-tip data-for={"candidateDetails" + index}>
                                                                {candidateInterview.evaluationStatus == "Reviewed" &&
                                                                     <span className="d-block h5 mb-0 truncate inter-ff"> {candidateInterview.fullName} 
                                                                     <ReactTooltip id={"candidateDetails" + index} place="top" type="dark" effect="float">
                                                                            <span className="d-block fs-5"> {candidateInterview.fullName}</span>
                                                                            <span className="d-block fs-5"> {candidateInterview.email}</span>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                }
                                                                {candidateInterview.evaluationStatus != "Reviewed" &&

                                                                    <span className="d-block h5 mb-0 truncate inter-ff"> {candidateInterview.fullName}
                                                                        <ReactTooltip id={"candidateDetails" + index} place="top" type="dark" effect="float">
                                                                            <span className="d-block fs-5"> {candidateInterview.fullName}</span>
                                                                            <span className="d-block fs-5"> {candidateInterview.email}</span>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td style={{ paddingRight: '0px', paddingLeft: '0px', marginRight: '0px' }} id={"jobTitle" + index} to={`/evaluateinterview/${candidateInterview.interviewInviteId}/${candidateInterview.interviewId}`}>
                                                                {candidateInterview.evaluationStatus == "Reviewed" &&
                                                                        <span className="d-block h5 mb-0"> {candidateInterview.jobTitle}
                                                                        <ReactTooltip id={"jobTitle" + index} place="top" type="dark" effect="float">
                                                                            <span className="d-block fs-5"> {candidateInterview.jobTitle}</span>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                }
                                                                {candidateInterview.evaluationStatus != "Reviewed" &&
                                                                    <span className="d-block h5 mb-0"> {candidateInterview.jobTitle}
                                                                        <ReactTooltip id={"jobTitle" + index} place="top" type="dark" effect="float">
                                                                            <span className="d-block fs-5"> {candidateInterview.jobTitle}</span>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {candidateInterview.evaluationStatus == "Reviewed" &&
                                                                    
                                                                        <span className="d-block h5 mb-0">
                                                                            {candidateInterview.interviewType == "oneway" &&
                                                                                <span>  One-Way Close</span>

                                                                            }
                                                                            {candidateInterview.interviewType == "onewayopen" &&
                                                                                <span>  One-Way Open
                                                                                </span>}

                                                                            {candidateInterview.interviewType == "live" &&
                                                                                <span> Two-Way Live</span>
                                                                            }

                                                                        </span>
                                                                }
                                                                {candidateInterview.evaluationStatus != "Reviewed" &&
                                                                    <span className="d-block h5 mb-0">
                                                                        {candidateInterview.interviewType == "oneway" &&
                                                                            <span>  One-Way Close</span>

                                                                        }
                                                                        {candidateInterview.interviewType == "onewayopen" &&
                                                                            <span>  One-Way Open
                                                                            </span>}

                                                                        {candidateInterview.interviewType == "live" &&
                                                                            <span> Two-Way Live</span>
                                                                        }

                                                                    </span>

                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    candidateInterview.evaluationStatus == "Hired" &&
                                                                    <span class="review-interviews-status status-accepted f-size-12 inter-ff f-weight-500 ms-2">{candidateInterview.evaluationStatus}</span>
                                                                    
                                                                }
                                                                {
                                                                    candidateInterview.evaluationStatus == "Not Evaluated" &&
                                                                    <span class="review-interviews-status status-reviewed f-size-12 inter-ff f-weight-500">{candidateInterview.evaluationStatus}</span>

                                                                }
                                                                {
                                                                    candidateInterview.evaluationStatus == "Failed" &&
                                                                    <span class="review-interviews-status status-requested f-size-12 inter-ff f-weight-500 ms-2">{candidateInterview.evaluationStatus}</span>

                                                                }
                                                                {
                                                                    candidateInterview.evaluationStatus == "Rejected" &&

                                                                    <span class="review-interviews-status status-evaluated f-size-12 inter-ff f-weight-500 ms-2">{candidateInterview.evaluationStatus}</span>

                                                                }

                                                            </td>
                                                        </tr>

                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                            this.setState({ IsEvaluationPopup: 0, EvaluationList: [] })
                                        }}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.IsLoading > 0 && (
                        <div class="overlay">
                            <div className="overlay-content">
                                <Oval
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        </div>
                    )}

                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}
                />

            </>

        );
    }
}
export default Analytics;