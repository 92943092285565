import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import SuccessQuestion from '../../assets/svg/illustrations/oc-hi-five.svg'
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "../DataPicker/index"

class EvaluateInterviewComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            evalutaionDetails: true,
            evalutation: "",
            disposition: "",
            hiredrejected: "Rejected",
            isEvalutaionDoneBefore: false,
            hireinternalorexternal: "",
            amount: 0,
            emailcandidate: "",
            emailbody: "",
            interviewInvite: [],
            Rejections: [],
            selectedRejection: 0,
            isDocUploadRequested: 0,
            isOfferLetterSend: 0,
            offerDate: new Date(),
            currentDate: new Date(),
            offerExpiryDate: new Date(new Date().getTime() + 172800000), // 172800000 miliseconds in 2 days  
            offerMessage: "",
            jobDetails: {}

        }
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        document.title = "Tenant App - Interviews";

        let interviewInviteId = this.props.interviewInviteId;
        this.interviewAnswerReviewValidate(interviewInviteId);
        this.checkInterviewEvaluation(this.props.interviewInviteId);
        this.getInterviewInvitesForReviews(interviewInviteId);
        this.getJobDetails(this.props.interviewId);
        this.getReasonOfRejections();

    }


    /**
 * Disable button
 * @param {any} state true to disable button
 */
    toggleButton(state) {
        var btnSave = document.getElementById("btnSave");
        btnSave.disabled = state;
        if (state == true) {
            btnSave.innerText = "Processing...";
        }
        else {
            btnSave.innerText = "Evaluate";
        }
    }


    /** get interview invite details */
    getInterviewInvitesForReviews(interviewInviteId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewCandidateInfo/" + interviewInviteId; //Get all filtered interview invites where role!=moderator


            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    this.setState({
                        interviewInvite: result,
                        evalutaionDetails: false
                    });

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Interview Candidate Info!', "top-center");

                });
        }
    }

    getReasonOfRejections() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/ReasonOfRejection";

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then((result) => {
                    let i = 0;
                    let temp = [];
                    for (i = 0; i < result.length; i++) {
                        temp.push({ value: result[i].rejectedReasonId, label: result[i].title })
                    }

                    this.setState({
                        Rejections: temp
                    });



                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Reason Of Rejection!', "top-center");
                });
        }
    }

    /**
     * Get Interview Evaluation to check
     * @param {any} event
     */
    checkInterviewEvaluation(interviewInviteId) {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewEvaluate/" + interviewInviteId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (typeof response.data === 'string' || response.data instanceof String) {
                    }
                    else {
                        this.setState({ isEvalutaionDoneBefore: true });

                    }
                })
                .catch(err => {
                    console.error("Interview Reviewers" + err);
                    displayErrorMessage('Error while fetching Interview Reviewers!', "top-center");


                });


        }
    }


    /**
  * Get validation
  * @param {any} interviewInviteId
  */
    interviewAnswerReviewValidate(interviewInviteId) {

        //Validate interviewInviteId
        if (interviewInviteId == null || interviewInviteId == '' || interviewInviteId == undefined) {
            console.error("interviewInviteId is undefiend, it should be valid value");
            alert("Validation error");
            return;
        }
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewAnswerReviewValidate/" + interviewInviteId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json()).
                then((result) => {
                    if (!result) {
                        displayWarningMessage("Error: At least one review is required to validate this Interview !", "top-center");
                        this.props.history.push('/reviewinterviews');
                    }

                    //return result;
                })


                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching interview answer review validation!', "top-center");

                });
        }

    }

    /**
   * Get Job details to preview*/
    getJobDetails(interviewId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/InterviewJob/" + interviewId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        jobDetails: result,
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while fetching Interview Review!', "top-center")

                });
        }
    }



    /**
     * Save reviews sumitted by Reviewe
     * of candidate answer
     * @param {any} event
     */
    handleSubmit(event) {
        if (this.state.emailcandidate === "Yes" && this.state.emailbody === "") {
            displayWarningMessage('Email message is required!', "top-center");
            return;
        }

        if (this.state.hiredrejected === "Hired" && this.state.isOfferLetterSend === "Yes" && (this.state.offerExpiryDate.setHours(0, 0, 0, 0) <= this.state.offerDate.setHours(0, 0, 0, 0))) {
            displayErrorMessage('Expiry Date should be greater than Offer Date!', "top-center");
            return;
        }
        if (this.state.hiredrejected === "Hired" && this.state.isOfferLetterSend === "Yes" && this.state.offerMessage === "") {
            displayWarningMessage('Offer letter message is required!', "top-center");
            return;
        }
        if (this.state.evalutation === "") {
            displayWarningMessage('Evaluation Not Selected!', "top-center");
            return;
        }
        if (this.state.evalutation === "Passed" && this.state.disposition === "") {
            displayWarningMessage('Disposition Not Selected!', "top-center");
            return;
        }
        if (this.state.hiredrejected === "") {
            displayWarningMessage('Evaluation Not Selected!', "top-center");
            return;
        }
        let inputModel = {};
        let bearer = validateAndGetFormatedToken();
        inputModel.IsInterviewPassedByUser = this.state.evalutation === "Passed" ? true : false;
        inputModel.IsCandidateGetHired = this.state.hiredrejected === "Hired" ? true : false;
        inputModel.isInternalHiring = this.state.hireinternalorexternal === "Internal" ? true : false;
        inputModel.isDocUploadRequested = this.state.isDocUploadRequested === "Required" ? true : false;
        inputModel.isOfferLetterSend = this.state.isOfferLetterSend === "Yes" ? true : false;
        inputModel.offerDate = this.state.offerDate;
        inputModel.offerExpiryDate = this.state.offerExpiryDate;
        inputModel.offerMessage = this.state.offerMessage;

        inputModel.comments = "";
        inputModel.emailbody = this.state.emailbody;
        inputModel.rejectedReasonId = this.state.selectedRejection;
        inputModel.placementFeeEarned = this.state.amount;
        //ToDo:Pending
        inputModel.createdBy = parseJwt(bearer).sub;
        inputModel.interviewInviteId = this.state.interviewInvite.interviewInviteId;
        inputModel.interviewId = this.state.interviewInvite.interviewId;
        inputModel.userId = parseJwt(bearer).sub;

        this.toggleButton(true);
        var model = JSON.stringify(inputModel);

        if (bearer !== "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/InterviewEvaluate";

            const requestOptions = {
                method: 'POST',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };



            fetch(url, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    var isSuccess = this.sendEmail(bearer, url);
                    this.setState({ SuccessMessage: true });

                    //updat the interview invite status to evaluated
                    this.updateInterviewInviteStatusToEvaluated(this.state.interviewInvite.interviewInviteId);
                    
                    //setTimeout(() => {
                    ////    window.location.reload();
                    //}, 4000)

                })
                .catch(() => {
                    displayErrorMessage('Error During Evaluating Interview!', "top-center")
                    this.toggleButton(false);
                });
        }

    }





    /**
   * Set the interview invite status to reviewed
   * @param {any} interviewInviteId
   */
    updateInterviewInviteStatusToEvaluated(interviewInviteId) {
        const url = process.env.REACT_APP_CORE_API + "/api/InterviewInviteStatus/UpdateStatusToEvaluated/" + interviewInviteId;
        let bearer = validateAndGetFormatedToken();
        fetch(url,
            {
                method: 'GET',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                if (data === true) { 
                    displaySuccessMessage("Interview invite status updated");
                    this.props.getJobInterviews(this.props.jobId)
                }

            })

            .catch(err => {
                console.log(err);
                displayErrorMessage('Error Unable to update interview invite status!', "top-center")
            });
    }


    /**
     * Send email
     * @param {any} bearer
     * @param {any} url
     */
    sendEmail(bearer, url) {
        if (this.state.emailcandidate == "Yes" || this.state.isDocUploadRequested == "Required") {


            let dataModel = {};
            dataModel.emailbody = this.state.emailbody;
            dataModel.candidateemail = this.state.interviewInvite.candidateEmail;
            dataModel.isHired = this.state.hiredrejected == "Hired" ? true : false;
            dataModel.isDocUploadRequested = this.state.isDocUploadRequested == "Required" ? true : false;
            dataModel.InterviewInviteId = this.state.interviewInvite.interviewInviteId;


            var emailModel = JSON.stringify(dataModel);


            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: emailModel
            };

            url = process.env.REACT_APP_CORE_API + '/api/InterviewEvaluateMessage';
            fetch(url, requestOptions)
                .then((response) => {
                    console.log("result", response);
                    console.log("Email has been sent");


                    return true;
                })
                .catch(() => {
                    displayErrorMessage('Error During  Interview Evaluation Message!', "top-center");
                    return false;
                });
        }

        return false;
    }

    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        if (nam == "evalutation" && val == "Failed") {
            console.log("eval");
            this.setState({
                hiredrejected: "Rejected",
                emailcandidate: "No",
                isOfferLetterSend: "No",
                offerMessage: "",
                emailbody: "",
                disposition:"",

            });
            
        }
        if (nam == "evalutation" && val == "Passed") {
            
            this.setState({
                hiredrejected: "Rejected",
                emailbody: "",
                emailcandidate: "No"
            });
        }
        if (nam == "hiredrejected" && val == "Hired") {
            this.setState({
                emailbody: "",
                emailcandidate:"No"
         });
        }
        if (nam == "hiredrejected" && val == "Rejected") {
            this.setState({
                emailbody: "",
                emailcandidate: "No",
                isOfferLetterSend: "No",
                offerMessage:"",
            });
        }
      
        
    }

    render() {


        return (
            <>
                    {/* <!-- Content --> */}
                    <div className="">
                        {this.state.SuccessMessage ? <div id="validationFormSuccessMessage" className="js-success-message" >
                            <div className="text-center">
                                <img className="img-fluid mb-3" src={SuccessQuestion} alt="Image Description" style={{ maxWidth: '15rem' }} />

                                <div className="mb-4">
                                    <h2>Successful!</h2>
                                    <p>Interview evaluation have been completed.</p>
                                </div>

                                <div className="d-flex justify-content-center">
                                <button className="btn btn-white me-3" onClick={() => {
                                    this.props.closeEvaluateInterviewModal()
                                }}>
                                        <i className="bi-chevron-left small ms-1"></i> Back to evaluations
                                    </button>

                                </div>
                            </div>
                        </div> :

                            this.state.isEvalutaionDoneBefore ? <>
                                {/* <!-- Message Body --> */}
                                <div id="validationFormSuccessMessage" className="js-success-message">
                                    <div className="text-center">
                                        <img className="img-fluid mb-3" src={SuccessQuestion} alt="Image Description" style={{ maxWidth: '15rem' }} />

                                        <div className="mb-4">
                                            <h2>Evaluation already done !</h2>
                                            <p>Do you want to evaluate again.</p>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                        <button className="btn btn-white me-3" onClick={() => {
                                            this.props.closeEvaluateInterviewModal()
                                        }}>
                                                <i className="bi-chevron-left small ms-1"></i> Back to Interviews
                                            </button>
                                            <button className="btn btn-primary" onClick={() => { this.setState({ isEvalutaionDoneBefore: false }) }}>
                                                <i className="tio-city me-1"></i> Evaluate Again
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Message Body --> */}
                            </> :
                                <>
                                    {/* <!-- Page Header --> */}
                                    <div className="page-header">
                                        <div className="row align-items-center">
                                            <div className="col">
                                               
                                            <h1 className="f-size-18 f-weight-600 mb-4 mt-2">
                                                <i class="bi bi-briefcase me-2 job-title-icon-bg mb-4"></i>
                                                <span className="me-2">
                                                    {this.state.jobDetails.title}
                                                </span>
                                            </h1>
                                                <div className="mt-2">

                                                </div>
                                            </div>
                                            {/* <!-- End Col --> */}
                                            {/* <!-- End Col --> */}
                                        </div>
                                        {/* <!-- End Row --> */}
                                    </div>
                                    {/* <!-- End Page Header --> */}
                                    <div className="card"><div className="card-body">
                                        <div className="row g-0">
                                            <>

                                                <div className="pb-3 border-bottom">

                                                    <div class="d-flex">
                                                    {this.state.interviewInvite.profileImageUrl != null ?
                                                        <div class="">
                                                            <img
                                                                width="60"
                                                                height="60"
                                                                src={this.state.interviewInvite.profileImageUrl}
                                                                alt="Profile"
                                                                className="applicant-profile-image"
                                                            />

                                                        </div>
                                                        :
                                                        <div class="flex-shrink-0">
                                                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M46.7578 45.0234C44.168 40.5234 39.3047 37.5 33.75 37.5H26.25C20.6953 37.5 15.832 40.5234 13.2422 45.0234C17.3672 49.6172 23.3438 52.5 30 52.5C36.6562 52.5 42.6328 49.6055 46.7578 45.0234ZM60 30C60 46.5703 46.5703 60 30 60C13.4297 60 0 46.5703 0 30C0 13.4297 13.4297 0 30 0C46.5703 0 60 13.4297 60 30ZM30 31.875C34.6641 31.875 38.4375 28.1016 38.4375 23.4375C38.4375 18.7734 34.6641 15 30 15C25.3359 15 21.5625 18.7734 21.5625 23.4375C21.5625 28.1016 25.3359 31.875 30 31.875Z" fill="#C7C7C7">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                    }

                                                        <div class="flex-grow-1 ms-3">

                                                            <h4 className="text-start mb-0" style={{ fontWeight: "bold" }}>{this.state.interviewInvite.firstName} {this.state.interviewInvite.lastName}  </h4>
                                                            <p className="text-start mb-1">{this.state.interviewInvite.candidateEmail}</p>
                                                            <span class="badge bg-secondary rounded-pill text-white">{this.state.interviewInvite.inviteStatus}</span>

                                                        </div>
                                                    </div>

                                                    
                                                </div>
                                                <div >
                                                    <div className="row mt-2 mb-3">

                                                        <div className="col-lg-12" onChange={this.handleInputChange}>
                                                            <label>Would you like to mark this Interview Failed or passed?</label>
                                                            <br />
                                                            <input type="radio" value="Passed" style={{ margin: "10px" }} name="evalutation" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Passed</span>
                                                            <input type="radio" value="Failed" style={{ margin: "10px" }} name="evalutation" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Failed</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                {this.state.evalutation == "Passed" &&
                                                    <>
                                                        <div >
                                                        <div className="row mb-3">

                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                    <label>Do you want to enter a disposition for this applicant?</label>
                                                                    <br />
                                                                    <input type="radio" value="Yes" style={{ margin: "10px" }} name="disposition" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Yes</span>
                                                                    <input type="radio" value="No" style={{ margin: "10px" }} name="disposition" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>No</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {this.state.disposition == "Yes" &&
                                                            <>
                                                                <div >
                                                            <div className="row mb-3">

                                                                        <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                            <label>Would you like to mark this applicant as hired or rejected?</label>
                                                                            <br />
                                                                            <input type="radio" value="Hired" style={{ margin: "10px" }} name="hiredrejected" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Hired</span>
                                                                            <input type="radio" value="Rejected" style={{ margin: "10px" }} name="hiredrejected" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rejected</span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                {this.state.hiredrejected == "Hired" &&
                                                                    <>
                                                                        <div >
                                                                <div className="row mb-3">

                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                    <label>Send offer letter to applicant?</label>
                                                                                    <br />
                                                                                    <input type="radio" value="Yes" style={{ margin: "10px" }} name="isOfferLetterSend" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Yes</span>
                                                                                    <input type="radio" value="No" style={{ margin: "10px" }} name="isOfferLetterSend" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>No</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }

                                                                {this.state.hiredrejected == "Hired" && this.state.isOfferLetterSend == "Yes" &&
                                                                    <>
                                                                        <div >
                                                                <div className="row mb-3">

                                                                                <div className="col-md-6">

                                                                                    <label className="form-label">Offer date</label>

                                                                                    <DatePicker
                                                                                        className="form-control"
                                                                                        selected={this.state.offerDate}
                                                                                        onChange={(date) => {
                                                                                            if (date < this.state.currentDate.setHours(0, 0, 0, 0)) {
                                                                                                displayErrorMessage('The offer date cannot be set to a past date!', "top-center");
                                                                                                return;
                                                                                            }
                                                                                            this.setState({ offerDate: date });
                                                                                        }}
                                                                                        minDate={new Date()}
                                                                                        dateFormat="yyyy-dd-MM"
                                                                                        placeholderText="Offer Date"
                                                                                    />
                                                                                </div>

                                                                                <div className="col-md-6">

                                                                                    <label className="form-label">Offer Expire</label>
                                                                                    <DatePicker
                                                                                        className="form-control"
                                                                                        selected={this.state.offerExpiryDate}
                                                                                        onChange={(date) => {
                                                                                            this.setState({ offerExpiryDate: date });
                                                                                        }}
                                                                                        minDate={new Date()}
                                                                                        dateFormat="yyyy-dd-MM"
                                                                                        placeholderText="Expire Date"
                                                                                    />
                                                                                </div>



                                                                            </div>

                                                                            <div className="row mt-3">

                                                                                <div className="col-lg-12" >
                                                                                    <label className="form-label">Offer Message </label><span className="form-label-secondary text-danger">*</span>
                                                                                    <textarea id="offerMessage" name="offerMessage" className="form-control"
                                                                                        style={{ width: '100%' }}

                                                                                        onChange={(e) => {
                                                                                            this.setState({ offerMessage: e.target.value })
                                                                                        }}
                                                                                        value={this.state.offerMessage}

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </>
                                                                }

                                                                {this.state.hiredrejected == "Hired" &&
                                                                    <>
                                                                        <div >
                                                                            <div className="row">

                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                    <label>Is applicant documents are required?</label>
                                                                                    <br />
                                                                                    <input type="radio" value="Required" style={{ margin: "10px" }} name="isDocUploadRequested" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Required</span>
                                                                                    <input type="radio" value="Optional" style={{ margin: "10px" }} name="isDocUploadRequested" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Optional</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }
                                                                {this.state.hiredrejected == "Rejected" &&
                                                                    <>
                                                                        <div >
                                                                            <div className="row mb-3">

                                                                                <div className="col-lg-12" >
                                                                                    <label class="mb-2">What is the reason for rejection?</label>
                                                                                    <Select
                                                                                        id="rejection"
                                                                                        name="rejection"
                                                                                        defaultValue={this.state.Rejections[0]}

                                                                                        options={this.state.Rejections}
                                                                                        onChange={(selectedOption) => this.setState({ selectedRejection: selectedOption.value })}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div >
                                                                            <div className="row mb-3">

                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                                    <label>Would you like to send an email to the applicant?</label>
                                                                                    <br />
                                                                                    <input type="radio" value="Yes" style={{ margin: "10px" }} name="emailcandidate" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Yes</span>
                                                                                    <input type="radio" value="No" style={{ margin: "10px" }} name="emailcandidate" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>No</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        {this.state.emailcandidate == "Yes" &&
                                                                            <>
                                                                                <div >
                                                                                    <div className="row">

                                                                                        <div className="col-lg-12" >
                                                                                            <label>Email Body</label><span className="form-label-secondary text-danger">*</span>
                                                                                            <textarea id="emailbody" name="emailbody" className="form-control"
                                                                                                style={{ width: '100%' }}

                                                                                                onChange={(e) => {
                                                                                                    this.setState({ emailbody: e.target.value })
                                                                                                }}
                                                                                                value={this.state.emailbody}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>

                                                                }

                                                                {this.state.hiredrejected == "Hired" &&
                                                                    <>
                                                                        <div>
                                                                            <div className="row">

                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                    <label>Is this applicant being hired internally or externally?</label>
                                                                                    <br />
                                                                                    <input type="radio" value="Internal" style={{ margin: "10px" }} name="hireinternalorexternal" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Internal</span>
                                                                                    <input type="radio" value="External" style={{ margin: "10px" }} name="hireinternalorexternal" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>External</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        {/*this.state.hireinternalorexternal == "External" &&
                                                                        <div style={{ margin: '1%' }}>

                                                                            <div className="row">


                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                                    <label>Placement Fee Earned </label>

                                                                                    <br />
                                                                                    <input name="amount" type="number" placeholder="Amount" value={this.state.amount} onChange={this.handleInputChange} className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full" />

                                                                                </div>

                                                                            </div>

                                                            </div>*/
                                                                        }
                                                                        <div >
                                                                            <div className="row">

                                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                                    <label>Would you like to send an email to the applicant?</label>
                                                                                    <br />
                                                                                    <input type="radio" value="Yes" style={{ margin: "10px" }} name="emailcandidate" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Yes</span>
                                                                                    <input type="radio" value="No" style={{ margin: "10px" }} name="emailcandidate" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>No</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        {this.state.emailcandidate == "Yes" &&
                                                                            <>
                                                                                <div >
                                                                                    <div className="row">

                                                                                        <div className="col-lg-12" >
                                                                            <label>Email Body</label><span className="form-label-secondary text-danger">*</span>
                                                                                            <textarea id="emailbody" name="emailbody" className="form-control"
                                                                                                style={{ width: '100%' }}
                                                                                                onChange={(e) => {
                                                                                                    this.setState({ emailbody: e.target.value })
                                                                                                }}
                                                                                                value={this.state.emailbody}

                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }





                                                            </>
                                                        }
                                                    </>
                                                }

                                                {
                                                    this.state.evalutation == "Failed" &&

                                                    <>
                                                        <div >
                                                            <div className="row mb-3">

                                                                <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                    <label>Would you like to mark this applicant as hired or rejected?</label>
                                                                    <br />
                                                                    <input type="radio" value="Hired" style={{ margin: "10px" }} name="hiredrejected" disabled /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Hired</span>
                                                                    <input type="radio" value="Rejected" style={{ margin: "10px" }} name="hiredrejected" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rejected</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {this.state.hiredrejected == "Rejected" &&
                                                            <>
                                                                <div s>
                                                                    <div className="row mb-3">

                                                                        <div className="col-lg-12" >
                                                                            <label className="mb-2">What is the reason for rejection?</label>
                                                                            <Select
                                                                                id="rejection"
                                                                                name="rejection"
                                                                                defaultValue={this.state.Rejections[0]}
                                                                                options={this.state.Rejections}
                                                                                onChange={(selectedOption) => this.setState({ selectedRejection: selectedOption.value })}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div>
                                                                    <div className="row mb-3">

                                                                        <div className="col-lg-12" onChange={this.handleInputChange}>
                                                                            <label>Would you like to send an email to the applicant?</label>
                                                                            <br />
                                                                            <input type="radio" value="Yes" style={{ margin: "10px" }} name="emailcandidate" /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Yes</span>
                                                                            <input type="radio" value="No" style={{ margin: "10px" }} name="emailcandidate" defaultChecked /> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>No</span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                {this.state.emailcandidate == "Yes" &&
                                                                    <>
                                                                        <div >
                                                                            <div className="row mb-3">

                                                                                <div className="col-lg-12" >
                                                                            <label>Email Body</label><span className="form-label-secondary text-danger">*</span>
                                                                                    <textarea id="emailbody" name="emailbody" className="form-control"
                                                                                        style={{ width: '100%' }}

                                                                                        onChange={(e) => {
                                                                                            this.setState({ emailbody: e.target.value })
                                                                                        }}
                                                                                        value={this.state.emailbody}

                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>

                                                }
                                                <div className="card-footer" >
                                                    <div className="d-flex justify-content-end gap-3">
                                                    <button onClick={() => { this.props.closeEvaluateInterviewModal() } } className="btn btn-white">Cancel</button>
                                                        <button type="button" id="btnSave" className="btn btn-primary"
                                                            onClick={this.handleSubmit}>Save</button>
                                                    </div>
                                                </div>


                                            </>
                                        </div>
                                    </div></div>
                                </>
                        }


                    </div>



                
            </>

        );
    }
}

export default EvaluateInterviewComponent;
