import React from 'react';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { Link } from 'react-router-dom';
import profileImage from "../../../assets/img/160x160/img1.jpg";
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'

class UserProfile extends React.Component {
    constructor() {
        super();
        this.state =
        {
            updatePhoto: false,
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            editPhoneModal: "",
            editPhoneModalCopy:"",
            editFirstNameModal: "",
            editLastNameModal: "",
            editEmailModal: "",
            editProfileImageUrlModal: null,
            applicationUserId: null,
            formDataObject: null,
            profileImageUrl: '',
            file: "",
            editUserModal: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);

    }


    componentDidMount() {
        document.title = "Tenant App - Settings";

        //load the current user data and populate the fields with current user data
        this.getUserProfile();
    }


    componentDidMount() {
        //load the current user data and populate the fields with current user data
        this.getUserProfile();
    }
    getUserProfile() {
        var appUserId = null;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            appUserId = parseJwt(bearer).sub;
            let url = process.env.REACT_APP_IDENTITY_API + '/Account/GetApplicationUser?applicationUserId=' + appUserId;

            const requestOptions = {
                method: 'GET',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
            };

            fetch(url, requestOptions)
                .then(response => response.json())
                .then((userProfileObjectResult) => {
                    this.setState({
                        email: userProfileObjectResult.email,
                        firstName: userProfileObjectResult.firstName,
                        lastName: userProfileObjectResult.lastName,
                        phone: userProfileObjectResult.phoneNumber,
                        profileImageUrl: userProfileObjectResult.profileImageUrl,
                        editEmailModal: userProfileObjectResult.email,
                        editFirstNameModal: userProfileObjectResult.firstName,
                        editLastNameModal: userProfileObjectResult.lastName,
                        editPhoneModal: userProfileObjectResult.phoneNumber,
                        editPhoneModalCopy: userProfileObjectResult.phoneNumber,
                        editProfileImageUrlModal: userProfileObjectResult.profileImageUrl
                    })

                })

                .catch(() => {
                    displayErrorMessage('Error During profile fetching!', "top-center");

                });
        }

    }

    /** Get the Application User id from token
     * return {string} : applicationUserId | null */
    getApplicationUserIdFromToken() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var appUserId = null;

            appUserId = parseJwt(bearer).sub;
            return appUserId;
        }
        return null;
    }




    /**
     * Prevent default event 
     * Get the FormDataObject from state
     * Send formData to api endpoint
     * @param {any} event
     * @summary Upload image to server
     */
    uploadImage(event) {
        event.preventDefault();
        if (this.state.formDataObject == null) {
            displayErrorMessage("Please select a file to upload", "top-center");
            return false;
        }

        var coreApiBaseUri = process.env.REACT_APP_CORE_API;;
        var profileImageEndpoint = coreApiBaseUri + '/api/UserProfileImage/';
        var formData = this.state.formDataObject;
        let bearer = validateAndGetFormatedToken();
        this.uploadImageToServer(formData, profileImageEndpoint, bearer);
    };


    /**
     * Post data to endpoint
     * @param {any} formData   this is FormData object that was build to send to server
     * @param {URL} profileImageEndpoint this is endpoint where we will send data
     * @summary Upload image to server (api endpoint)
     */
    uploadImageToServer = (formData, profileImageEndpoint, bearerToken) => {
        fetch(profileImageEndpoint,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': bearerToken
                },
                body: formData
            }
        ).then(() => {
            //displaySuccessMessage("Successfully Uploaded Image", "top-center");
            window.location.reload(true);
        }).catch(() => {
            displayErrorMessage("Error: Unable to Upload Image", "top-center");
        });
    }

    /**
     * When data input change then set the FormDataObject in state
     * @param {any} e file input control
     */
    handleImageChange(e) {

        e.preventDefault();
        let file = e.target.files[0];
        let size = file.size;
        if (size > 5242880) {
            displayWarningMessage("File size exceeds", "top-center")
            return;
        }
        if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {

            let formData = new FormData();
            for (var index = 0; index < e.target.files.length; index++) {
                var element = e.target.files[index];
                formData.append('imageFile', element);
            }

            var appUserId = this.getApplicationUserIdFromToken();
            formData.append('applicationUserId', appUserId);
            this.setState({
                formDataObject: formData,
            });

            let reader = new FileReader();
            reader.readAsDataURL(file);

            //file reader on load end event
            reader.onloadend = () => {
                this.setState({
                    //profileImageUrl: reader.result,
                    editProfileImageUrlModal: reader.result,
                });
            };
        } else {
            displayWarningMessage("Invalid file type", "top-center")
            return;

        }
    };


    /**
     * Chekc the token
     * Case 1: Update Profile
     * Case 2: Update Password
     * @param {any} event
     */
    handleSubmit(event) {
        var coreApiBaseUri = null;
        var identityEndpoint = null;
        var appUserId = null;
        var model;
        let bearer = validateAndGetFormatedToken();
        var message = "";

        if (this.state.editPhoneModal && !isValidPhoneNumber(this.state.editPhoneModal)) {
            displayErrorMessage("Phone no is not valid", 'top-center');
            return; // Do not proceed with saving if validation fails
        }

        if (bearer != "" || bearer != null) {
            appUserId = parseJwt(bearer).sub;
            identityEndpoint = process.env.REACT_APP_CORE_API + '/api/Identity/';

            //event.target whcih hits
            //event.target.name attribut

            this.updateUserProfile(identityEndpoint, bearer);
            if (this.state.formDataObject != null) {
                var coreApiBaseUri = process.env.REACT_APP_CORE_API;;
                var profileImageEndpoint = coreApiBaseUri + '/api/UserProfileImage/';
                var formData = this.state.formDataObject;
                let bearer = validateAndGetFormatedToken();
                this.uploadImageToServer(formData, profileImageEndpoint, bearer);
                this.setState({ editUserModal: false });
            }
            else {
                this.setState({ editUserModal: false });
              

            }
            
        }

        else
            displayErrorMessage("Token not found");

        //Enable form submit
        //this.enableControls();

    }


    /**
     * Update user profile 
     * @param {any} identityEndpoint
     */
    updateUserProfile(identityEndpoint, bearer) {
        var isSuccess = this.updateProfie(bearer, identityEndpoint);
        if (isSuccess) {
            displaySuccessMessage("Profile updated Successfully", "top-center");

        }
        else {
            displayErrorMessage("Error: Unable to update profile", "top-center");
        }

        //this.enableControls();
    }

    /**
     * Update the user profile
     * @param {string} bearer     //Bearer Token
     * @param {url} endpoint   //Endpoint to call
     */
    updateProfie(bearer, endpoint) {
        var appUserId = parseJwt(bearer).sub;
        var message = "";

        if (this.state.editFirstNameModal != '' && this.state.editFirstNameModal != null && this.state.editFirstNameModal != undefined) {

        }
        else
            message += " First name required";
        if (this.state.editLastNameModal != '' && this.state.editLastNameModal != null && this.state.editLastNameModal != undefined) {

        }
        else
            message += " Last name required";
        //if (this.state.editPhoneModal != '' && this.state.editPhoneModal != null && this.state.editPhoneModal != undefined) {

        //}
        //else
        //    message += " Phone required";
        //if (isValidPhoneNumber((this.state.editPhoneModal.toString()))) {

        //}
        //else {
        //    message += " Invalid Phone Number ";

        //}
        if (message != "") {
            displayErrorMessage(message, 'top-center');
            return;
        }


        let inputModel = {};
        inputModel.firstName = this.state.editFirstNameModal;
        inputModel.lastName = this.state.editLastNameModal;
        inputModel.email = this.state.editEmailModal;
        inputModel.phone = this.state.editPhoneModal;
        inputModel.applicationUserId = appUserId;
        //this.toggleButton(true, "btnUpdateProfile");
        var model = JSON.stringify(inputModel);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json'
            },
            body: model
        };

        fetch(endpoint, requestOptions)
            .then(response => response.json())
            .then((result) => {
                var message = "Profile updated Successfully";
                console.log(message, result);
                this.getUserProfile();


            })

            .catch((err) => {
                console.log("error for profile updation failed", err)
                //this.toggleButton(false, "btnUpdateProfile");

            });

        return true;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {


        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>

                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                   
                                    <h1 className="page-header-title">Settings</h1>

                                </div>
                           
                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>

                                <SettingsTabNavigations></SettingsTabNavigations>

                            </div>
                        </div>
                        <div id="addUserStepProfile" className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Profile information</h4>

                                <button className="btn btn-white btn-sm" data-bs-toggle="modal" data-bs-target="#editUserModal" onClick={() => {
                                    this.setState({ editUserModal: true })
                                    document.body.classList.add('modal-open');

                                }}>
                                    <i className="bi-pencil-fill me-1"></i> Edit
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Avatar</label>

                                    <div className="col-sm-9">
                                        <div className="d-flex align-items-center">
                                            {this.state.profileImageUrl != null ?
                                                <img src={this.state.profileImageUrl} width="254" class="rounded rounded-3" height="254" /> :
                                                <img src={profileImage} type="file" className="avatar avatar-xl avatar-circle avatar-uploader me-5" id="avatarUploader" />

                                            }

                                            {/*    <button type="button" className="js-file-attach-reset-img btn btn-white">Delete</button>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="firstNameLabel" className="col-sm-3 col-form-label form-label">Full Name <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Displayed on public forums, such as Front." aria-label="Displayed on public forums, such as Front."></i></label>

                                    <div className="col-sm-9">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" className="form-control" name="firstName" id="firstNameLabel" value={this.state.firstName} placeholder="First Name" aria-label="Clarice" disabled />
                                            </div>

                                            <div className="col-md-6">
                                                <input type="text" className="form-control" name="lastName" id="lastNameLabel" value={this.state.lastName} placeholder="Last Name" aria-label="Boone" disabled />
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="emailLabel" className="col-sm-3 col-form-label form-label">Email</label>

                                    <div className="col-sm-9">
                                        <input type="email" className="form-control" name="email" id="emailLabel" placeholder="Email" value={this.state.email} disabled />
                                    </div>
                                </div>
                                <div className="js-add-field row mb-4" >
                                    <label for="phoneLabel" className="col-sm-3 col-form-label form-label">Phone Number <span className="form-label-secondary">(Optional)</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="js-input-mask form-control" name="phone" id="phoneLabel" value={this.state.phone} placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" disabled />

                                        </div>


                                    </div>
                                </div>




                            </div>


                        </div>

                    </div>
                    {this.state.editUserModal && <div className="modal d-block" id="editUserModal" tabIndex="-1" aria-labelledby="editUserModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editUserModalLabel"><span className="whiteColor">Edit Profile</span></h5>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                        this.setState({ editUserModal: false, editPhoneModal: this.state.editPhoneModalCopy, editFirstNameModal: this.state.firstName, editLastNameModal: this.state.lastName, editProfileImageUrlModal: this.state.profileImageUrl, formDataObject: null })
                                        document.body.classList.remove('modal-open');

                                    }}></button>
                                </div>
                                
                                <div className="modal-body">


                                    <div className="tab-content" id="editUserModalTabContent">
                                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <form>
                                                <div className="row mb-4">
                                                    <label className="col-sm-3 col-form-label form-label">Profile Photo</label>

                                                    <div className="col-sm-9">
                                                        <div className="d-flex align-items-center">

                                                            {this.state.editProfileImageUrlModal != null ?
                                                                <img src={this.state.editProfileImageUrlModal} width="254" height="254" class="rounded" /> :
                                                                <img src={profileImage} type="file" className="avatar avatar-xl avatar-circle avatar-uploader me-5" id="avatarUploader" />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    
                                                    <div className="col-sm-3">
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input name="profileImageUrl" id="profileImageUrl"
                                                            accept="image/x-png,image/jpeg,image/jpg"
                                                            type="file" onChange={(e) => this.handleImageChange(e)} className="form-control" />
                                                    </div>

                                                    {/*<div className="col-sm-3">*/}
                                                    {/*    <button type="button" className="js-file-attach-reset-img w-100 btn btn-white" onClick={this.uploadImage}*/}
                                                    {/*    >Save Photo</button>*/}
                                                    {/*</div>*/}
                                                </div>


                                                <div className="row mb-2">
                                                    <hr />
                                                </div>
                                                <div className="row mb-4">
                                                    <label for="editFirstNameModalLabel" className="col-sm-3 col-form-label form-label">Full name <i className="tio-help-outlined text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="Displayed on public forums, such as Front."></i></label>

                                                    <div className="col-sm-9">

                                                        <div class="row">

                                                            <div className="col-md-6">

                                                                <input type="text" className="form-control" name="editFirstNameModal" value={this.state.editFirstNameModal} onChange={this.handleInputChange} id="editFirstNameModalLabel" placeholder="Your first name" aria-label="Your first name" />

                                                            </div>

                                                            <div className="col-md-6">

                                                                <input type="text" className="form-control" name="editLastNameModal" value={this.state.editLastNameModal} onChange={this.handleInputChange} id="editLastNameModalLabel" placeholder="Your last name" aria-label="Your last name" />

                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <label for="editEmailModalLabel" className="col-sm-3 col-form-label form-label">Email</label>

                                                    <div className="col-sm-9">
                                                        <input type="email" className="form-control" name="editEmailModal" id="editEmailModalLabel" placeholder="Email" aria-label="Email" value={this.state.editEmailModal} disabled />
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <label for="editPhoneLabel" className="col-sm-3 col-form-label form-label">Phone Number <span className="form-label-secondary">(Optional)</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-sm-vertical">
                                                            <PhoneInput
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry="US"
                                                                placeholder="Enter phone number"
                                                                name="editPhoneModal"
                                                                id="editPhoneModal"
                                                                value={this.state.editPhoneModal} onChange={(value) => {
                                                                    this.setState({ editPhoneModal: value || "" })
                                                                }}
                                                            />
                                                            {/*<input type="text" className="js-masked-input form-control" name="editPhoneModal" value={this.state.editPhoneModal} onChange={this.handleInputChange} id="editPhoneLabel" placeholder="Phone no" aria-label="+x(xxx)xxx-xx-xx" />*/}

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </form>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-white" onClick={() => {
                                        this.setState({ editUserModal: false, editPhoneModal: this.state.editPhoneModalCopy, editFirstNameModal: this.state.firstName, editLastNameModal: this.state.lastName, editProfileImageUrlModal: this.state.profileImageUrl, formDataObject: null })
                                        document.body.classList.remove('modal-open');

                                    }}>Cancel</button>
                                    <button type="button" className="btn btn-primary" name="btnUpdateProfile" onClick={this.handleSubmit}
                                    >Save changes</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        );
    }
}

export default UserProfile;