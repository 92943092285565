import React, { Component } from 'react';
// import "../../assets/js/sidebar";
import "../../assets/js/hs.theme-appearance.js"

import "../../assets/vendor/hs-navbar-vertical-aside/dist/hs-navbar-vertical-aside-mini-cache.js";
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { Link, withRouter, NavLink } from "react-router-dom";
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import Cookies from 'js-cookie';


class SideBar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            tenantLogo: null,
            permissioncreatejob: true,
            permissioncreateinterview: true,
            permissionviewinterview: true,
            permissioncreatequestionset: true,
            permissioncreatevideolibrary: true,
            permissionviewquestion: true,
            permissionviewjobs: true,
            permissionviewvideolibrary: true,
            tenantSubscriptionId: 0,
            tenantSubscriptionDataPermissionLeads: {},
            tenantSubscriptionDataPermissionCandidateListing: {},
            sfc_AllowedJobsCount: 0,
            activeLink: Cookies.get('activeLink') !== '' ? Cookies.get('activeLink') : 'dashboard',
            showLibraryMenu:true
        };
    }


    componentDidMount() {
        if (verifyTokenOnLocal()) {
            this.logoSet();
            this.getTenantSubscriptionid();
        }
        else
            return;

        //this.getPermissions();
    }
    componentDidUpdate() {
        const myLink = Cookies.get('activeLink')

        if (myLink !== this.state.activeLink) {

            this.setState({ activeLink: myLink }, () => {
                Cookies.set('newFreeLink', myLink, { expires: 7 });

            });
        }
    }
    resetInterviewState = () => {
        // Logic to reset the interview state
        localStorage.removeItem("ReviewInterviewsState");
    };

    /**
     * Get Role base permissions
     * */
    async getPermissions() {
        var tenantId = null;
        var permissioncreatejob = false;
        var permissioncreateinterview = false;
        var permissionviewinterview = false;
        var permissioncreatequestionset = false;
        var permissioncreatevideolibrary = false;
        var permissionviewquestion = false;
        var permissionviewjobs = false;
        var permissionviewvideolibrary = false;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {



                tenantId = parseJwt(bearer).tenantid;


                this.setState({
                    permissioncreatejob: CheckPermission(parseToken(bearer).roles, "Permissions.Job.Create", bearer),
                    permissioncreateinterview: CheckPermission(parseToken(bearer).roles, "Permissions.Interview.Create", bearer),
                    permissionviewinterview: CheckPermission(parseToken(bearer).roles, "Permissions.Interview.View", bearer),
                    permissioncreatequestionset: CheckPermission(parseToken(bearer).roles, "Permissions.QuestionSet.Create", bearer),
                    permissioncreatevideolibrary: CheckPermission(parseToken(bearer).roles, "Permissions.VideoLibraray.Create", bearer),
                    permissionviewquestion: CheckPermission(parseToken(bearer).roles, "Permissions.Question.View", bearer),
                    permissionviewjobs: CheckPermission(parseToken(bearer).roles, "Permissions.Job.View", bearer),
                    permissionviewvideolibrary: CheckPermission(parseToken(bearer).roles, "Permissions.VideoLibraray.View", bearer)
                });

            }
        }
    }

    /**
     * Logo fetch and set 
     * */
    logoSet() {

        var url = null;
        var tenantId = null;

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;




            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({ tenantLogo: result.tenantLogoUrl });

                    //localStorage.setItem('Tenant', JSON.stringify(result));

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error (Sidebar) while setting tenant details!', "top-center")
                });

        }
    }


    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Permissions.Leads.View");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "CandidateListing");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }



    /**
 * Get the tenant subscriotion feature key value 
 * @param {number} tenantSubscriptionId
 * @param {string} featureKey
 */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.featureKey === "Permissions.Leads.View") {
                        this.setState({
                            tenantSubscriptionDataPermissionLeads: response.data
                        })
                    }
                    if (response.data.featureKey === "CandidateListing") {
                        this.setState({
                            tenantSubscriptionDataPermissionCandidateListing: response.data
                        })
                    }
                    if (response.data.featureKey === "CandidateListing") {
                        this.setState({
                            tenantSubscriptionDataPermissionCandidateListing: response.data
                        })
                    }
                    if (response.data.featureKey == "Jobs") {
                        let tenantSubscriptionData = response.data;
                        let jobsCount = parseInt(tenantSubscriptionData.value);
                        this.setState({
                            sfc_AllowedJobsCount: jobsCount

                        })

                    }

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }

    handleLinkClick(linkName) {
        if (linkName === "reviewinterviews") {
            localStorage.removeItem("ReviewInterviewsState");
        }

    }

    render() {
        const { isCollapsed } = this.props;
        const { activeLink } = this.state;
        const { location } = this.props;
        const currentPath = location.pathname;

        return (
            //   <!-- Navbar Vertical -->
            <>
                <aside className={`js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered bg-white ${isCollapsed ? ' collapsed' : ''}`}>
                    <div className="navbar-vertical-container">
                        <div className="navbar-vertical-footer-offset">
                            {/* <!-- Logo --> */}

                            <Link className="navbar-brand mt-2" to="/" aria-label="Front">
                                {this.state.tenantLogo == "" || this.state.tenantLogo == null ? "SwiftRecruit" : <img className="navbar-brand-logo" src={this.state.tenantLogo} alt="Logo" data-hs-theme-appearance="default" />
                                }

                            </Link>

                            {/* <!-- End Logo --> */}

                            {/* <!-- Navbar Vertical Toggle --> */}
                            {/* <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
                                <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
                                <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
                            </button> */}
                            <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler" onClick={this.props.toggleMenu} data-bs-toggle="tooltip" data-bs-placement="right" title={isCollapsed ? 'Expand' : 'Collapse'}>
                                <i className="bi bi-list" />
                            </button>

                            {/* <!-- End Navbar Vertical Toggle --> */}

                            {/* <!-- Content --> */}
                            <div className="navbar-vertical-content " >
                                <div id="navbarVerticalMenu" className="nav nav-pills overflow-sidebar-menu nav-vertical card-navbar "  >
                                    {/* <!-- Collapse past here 1--> */}


                                    <div className="nav-item" >

                                        <div className="nav-item"
                                            data-tip
                                            data-for="dashboard-id"
                                            id="dashboard-id">
                                            <Link className={`nav-link ${currentPath === "/" || currentPath === "/dashboard" ? "active" : ""
                                                }`} to="/" data-placement="left"

                                            >
                                                <i className="bi bi-house-door-fill nav-icon"></i>
                                                <span className="nav-link-title">Dashboard </span>
                                            </Link>
                                            <ReactTooltip
                                                id="dashboard-id"
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="mytooltip"
                                            >Dashboard</ReactTooltip>
                                        </div>


                                        {process.env.REACT_APP_ANALYTICS_ENABLE === "true" &&
                                            <div className="nav-item"
                                                data-tip
                                                data-for="analytics-id"
                                                id="analytics-id">
                                                <Link className={`nav-link ${currentPath === "/analytics" ? "active" : ""
                                                    }`} style={this.state.sfc_AllowedJobsCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }} to="/analytics" data-placement="left"


                                                >
                                                    <i className="bi bi-clipboard-data nav-icon"></i>
                                                    <span className="nav-link-title">Analytics </span>
                                                </Link>
                                                <ReactTooltip
                                                    id="analytics-id"
                                                    place="top"
                                                    type="dark"
                                                    effect="float"
                                                    className="mytooltip"
                                                >Analytics</ReactTooltip>
                                            </div>
                                        }
                                    </div>



                                    {this.state.permissionviewjobs && <div className="nav-item"
                                        data-tip
                                        data-for="jobs-id"
                                        id="jobs-id">
                                        <NavLink
                                            exact
                                            isActive={(match, location) => {
                                                const pattern = /^\/(viewjob|updatejob|createjob|clonejob|leaddetails|jobinterviews|createinterview|interviewdetails)(\/\d+)?$/;
                                                return pattern.test(location.pathname) || currentPath === "/jobs";

                                            }} className={`nav-link ${currentPath === "/jobs" ? "active" : ""
                                                }`} to="/jobs" data-placement="left"

                                        >
                                            <i className="bi bi-briefcase nav-icon"></i>
                                            <span className="nav-link-title">Jobs</span>
                                        </NavLink>
                                        <ReactTooltip
                                            id="jobs-id"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="mytooltip"
                                        >Jobs</ReactTooltip>
                                    </div>}

                                    <div className="nav-item"
                                        data-tip
                                        data-for="library-id"
                                        id="library-id">
                                        
                                        <span
                                            className="nav-link"
                                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
                                            onClick={() => {
                                                this.setState({ showLibraryMenu: !this.state.showLibraryMenu })
                                            } }
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <span className="material-symbols-outlined nav-icon" style={{ fontSize: "1.2rem" }}>
                                                    folder
                                                </span>
                                                <span className="nav-link-title library-sidebar-menu">Library</span>
                                            </div>
                                            {this.state.showLibraryMenu ? <i className="nav-link-title fas fa-chevron-up f-gray"></i>
                                                :
                                                <i className="nav-link-title fas fa-chevron-down f-gray"></i>

                                            }
                                        </span>
                                        <ReactTooltip
                                            id="library-id"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="mytooltip"
                                        >
                                            Library
                                        </ReactTooltip>
                                    </div>

                                    {this.state.showLibraryMenu && (
                                        <>
                                    {this.state.permissionviewvideolibrary &&
                                            <div className={isCollapsed ? "nav-item " : "nav-item mr-librarymenu"}
                                            data-tip
                                            data-for="videolisting-id"
                                            id="videolisting-id">
                                            <Link className={`nav-link ${currentPath === "/videolisting" ? "active" : ""
                                                }`} to="/videolisting" data-placement="left"


                                            >
                                                <i className="bi bi-file-earmark-play nav-icon"></i>
                                                <span className="nav-link-title">Video Listing </span>
                                            </Link>
                                            <ReactTooltip
                                                id="videolisting-id"
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="mytooltip"
                                            >Video Listing</ReactTooltip>
                                        </div>}
                                   
                                            {this.state.permissioncreatequestionset && <div className={isCollapsed ? "nav-item " :"nav-item mr-librarymenu" }
                                        data-tip
                                        data-for="questionsets-id"
                                        id="questionsets-id">
                                        <NavLink
                                            exact
                                            isActive={(match, location) => {
                                                const pattern = /^\/viewquestionset\/\d+|^\/updatequestionset\/\d+/;
                                                return pattern.test(location.pathname) || currentPath === "/questionsets";

                                            }}


                                            className={`nav-link ${currentPath === "/questionsets" || currentPath === "/createquestionset" ? "active" : ""
                                                }`} to="/questionsets" data-placement="left" >
                                            <i className="bi bi-book nav-icon"></i>
                                            <span className="nav-link-title">Question Sets</span>
                                        </NavLink>
                                        <ReactTooltip
                                            id="questionsets-id"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="mytooltip"
                                        >Question Sets</ReactTooltip>
                                    </div>}

                                            {this.state.permissionviewquestion && <div className={isCollapsed ? "nav-item " : "nav-item mr-librarymenu"}
                                        data-tip
                                        data-for="questions-id"
                                        id="questions-id">
                                        <NavLink
                                            exact
                                            to="/questions"
                                            isActive={(match, location) => {
                                                const pattern = /^\/updatequestion\/\d+\/\w+\/\w+/;
                                                return pattern.test(location.pathname) || currentPath === "/questions";
                                            }}
                                            className={`nav-link ${currentPath === "/questions" || currentPath === "/createquestion" ? "active" : ""}`}
                                            data-placement="left"
                                        >
                                            <i className="bi-layers nav-icon"></i>
                                            <span className="nav-link-title">Question Templates</span>
                                        </NavLink>


                                        <ReactTooltip
                                            id="questions-id"
                                            place="top"
                                            type="dark"
                                            effect="float"
                                            className="mytooltip"
                                        >Questions</ReactTooltip>
                                            </div>}

                                        </>

                                )}



                                    {process.env.REACT_APP_ATS_ENABLE === "true" &&
                                        <div className="nav-item" style={{ paddingBottom: "5vh" }}>
                                            <Link className={(window.location.href.indexOf("/searchapplicants") !== -1
                                                ? "nav-link link link-primary" :
                                                "nav-link")} to="/searchapplicants" data-placement="left">
                                                <i class="bi bi-person-lines-fill nav-icon"></i>
                                                <span className="nav-link-title">Search Applicants</span>
                                            </Link>
                                        </div>
                                    } <div className="bottom-menu mt-0 ">
                                        <hr className="mt-2 mb-2"></hr>
                                        <div className="nav-item ">

                                            <div className="nav-item"
                                                data-tip
                                                data-for="settings-id"
                                                id="settings-id">
                                                <NavLink
                                                    exact
                                                    isActive={(match, location) => {
                                                        const pathsToMatch = ["/userprofile", "/tenantprofile", "/edituser", "/createuser", "/viewgroupmembers", "/creategroup", "/editgroup", "/createskill", "/createjobtitle", "/usermanagement", "/usergroup", "/userplan", "/tenantindustrysettings", "/bonussettings", "/skills", "/jobtitles", "/rejectionreasons"];
                                                        return pathsToMatch.some(path => location.pathname.startsWith(path));
                                                    }}
                                                    className={`nav-link ${currentPath === "/userprofile" ? "active" : ""}`}
                                                    to="/userprofile"
                                                    data-placement="left">
                                                    <i className="bi bi-gear nav-icon"></i>
                                                    <span className="nav-link-title">Settings <span className="" style={{ visibility: "hidden" }}>SettingSettingSSS</span></span>
                                                </NavLink>

                                                <ReactTooltip
                                                    id="settings-id"
                                                    place="top"
                                                    type="dark"
                                                    effect="float"
                                                    className="mytooltip">
                                                    Settings
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>
                            {/* <!-- End Collapse -->  */}




                        </div>
                        {/* <!-- End Content --> */}


                    </div>
                </aside>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>

            //   <!-- End Navbar Vertical -->
        );
    }
}
export default withRouter(SideBar);
