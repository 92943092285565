import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { ToastContainer } from 'react-toastify';
import { CheckPermission } from '../../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import { ApiCall } from '../../../modules/ApiCall';
import axios from 'axios';
import Empty from '../../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../../Loader/BeatLoader";
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';



class UserGroup extends Component {
    constructor() {
        super();
        this.state = {
            users: [],
            groups: [],
            selectedUsers: [],
            groupName: "",
            groupDescription: "",
            status: false,
            isGroupsLoaded: true,
            companyName: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    async componentDidMount() {
        document.title = "Tenant App - Settings";

        await this.getGroups();
        await this.getUsers();
    }



    async getGroups() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles == "TenantAdmin") {
                let tenantId = parseJwt(bearer).tenantid;

                let url = process.env.REACT_APP_IDENTITY_API + "/TenantUserGroup/GetTenantUserGroupsList?tenantId=" + tenantId;
                await axios(url,
                    {
                        method: 'GET',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        }
                    }).then(async (response) => {
                        this.setState({
                            groups: response.data,
                            isGroupsLoaded: false
                        })
                    })
                    .catch(err => {
                        console.error("Unable to get user groups == >" + err);
                    });


            }
            else
                console.error("You do not have permission on user groups, only tenant admin s allowed");

        }
    }


    async getUsers() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/tenant/GetUsersWithRoles";

            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({
                        users: result
                    })
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching users!', "top-center");

                });
        }
    }


    async handleSubmit() {
        {
            if (this.state.selectedUsers.length <= 0 || this.state.selectedUsers == null && this.state.selectedUsers == []) {
                displayWarningMessage('Please select atleast 1 user from the bottom table!', "top-center");

                return;
            }
            if (this.state.selectedUsers.length > 0 && this.state.selectedUsers != null && this.state.selectedUsers != [] && this.state.groupName != "" && this.state.groupDescription != undefined && this.state.groupName != "" && this.state.groupDescription != undefined) {
                let bearer = validateAndGetFormatedToken();
                if (bearer != "" || bearer != null) {
                    let tenantId = parseJwt(bearer).tenantid;
                    let url = process.env.REACT_APP_CORE_API + '/api/tenantUserGroup';

                    let temparr = [];
                    this.state.selectedUsers.forEach(
                        x => temparr.push({ applicationUserId: x.userId, username: x.email })
                    )
                    let inputModel = {
                        TenantUserGroup: {
                            name: this.state.groupName,
                            description: this.state.groupDescription,
                            IsActive: this.state.status,
                            TenantId: tenantId
                        },

                        TenantUserInGroups: temparr
                    }

                    var model = JSON.stringify(inputModel);

                    const requestOptions =
                    {
                        method: 'POST',
                        headers:
                        {
                            'Authorization': bearer,
                            'Content-Type': 'application/json'
                        },
                        body: model
                    };
                    await fetch(url, requestOptions)
                        .then(response => response.json())
                        .then(async (result) => {
                            await this.getUsers();
                            this.setState({
                                groupName: "",
                                groupDescription: "",
                                status: false,
                                createUserGroup: false
                            });
                            displaySuccessMessage(' Tenant Group Created!', "top-center");

                        })
                        .catch((err) => {
                            console.log("Error During Tenant Group Creation!", err);
                            displayErrorMessage('Error During Tenant Group Creation!', "top-center");
                        });

                }
                else {
                    displayWarningMessage('Please fill all required fields!', "top-center");

                    return;

                }

                //refresh group list when save record
                await this.getGroups();


            }
            else {
                displayWarningMessage('Please add required field !', "top-center");

                return;
            }
        }
    }

    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }
    render() {
        const onRowSelectforusers = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedUsers;


                selectedsets.push(row);

                this.setState({ selectedUsers: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedUsers;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.userId === selectedsets[i].userId) {



                        selectedsets.splice(i, 1);

                        this.setState({ selectedUsers: selectedsets })
                        return;
                    }
                }

            }
        };

        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>
                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/* <!--Enter row below here--> */}
                        <div className="row justify-content-lg-center">
                            {/* <!--Enter cole below here--> */}
                            <div className="col-lg-12">
                                {/* <!-- Page Header --> */}
                                <div className="page-header">
                                    <div className="row align-items-center">
                                        <div className="col">

                                            <h1 className="page-header-title">Settings</h1>
                                        </div>
                                        <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">


                                            <SettingsTabNavigations />
                                        </div>
                                    </div>
                                    {/* <!-- End Row --> */}
                                </div>
                                {/* <!-- End Page Header --> */}
                                <div className="card p-3">
                                    <div className="col-auto d-flex justify-content-end mb-3">
                                        <Link className="btn btn-primary" to="/creategroup">
                                            <i className="bi bi-plus me-1"></i> Create User Group
                                        </Link>
                                    </div>

                                    {this.state.isGroupsLoaded ?
                                        <BeatLoaderComponent />
                                        :
                                        <>
                                            {this.state.groups.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                                <div className="card-body py-10">
                                                    <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                    <div className="text-center">

                                                        <h1>You have not created anything  yet.</h1>
                                                        <p>Once you create, they'll show up here.</p>
                                                        <Link className="btn btn-primary" to="/creategroup"><i className="bi bi-plus-lg me-1"></i>Get started now!</Link>
                                                    </div>

                                                </div>
                                            </div>
                                                : <>


                                                    {/* <!-- Tab Content --> */}
                                                    <div className="tab-content" id="connectionsTabContent">
                                                        {/* <!-- Connections --> */}
                                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3">

                                                            {this.state.groups.length > 0 && this.state.groups.map((group, index) => {
                                                                return <div className="col mb-3 mb-lg-5" key={index}>
                                                                    {/* <!-- Card 1 Grid--> */}
                                                                    <div className="card">

                                                                        <Link className="text-dark" to={"/viewgroupmembers/" + group.userGroupId}>
                                                                            {/* <!-- Body --> */}
                                                                            <div className="card-body text-center">
                                                                                {/* <!-- Avatar --> */}
                                                                                <div className="avatar avatar-soft-primary avatar-circle avatar-centered mb-3">
                                                                                    <span className="avatar-initials">{index + 1}</span>

                                                                                </div>
                                                                                {/* <!-- End Avatar --> */}

                                                                                <h3 className="mb-1">
                                                                                    <Link className="text-dark" to={"/viewgroupmembers/" + group.userGroupId}>{group.name}</Link>
                                                                                </h3>

                                                                                {/* <!-- Badges --> */}
                                                                                <ul className="list-inline mb-0">
                                                                                    <li className="list-inline-item mb-1">
                                                                                        <a href="javascript:void(0)" className="badge bg-soft-secondary text-secondary p-2"> Members: {group.totalMembers}</a>
                                                                                    </li>
                                                                                    <br />
                                                                                    <span className={(group.isActive === true
                                                                                        ? "legend-indicator bg-success" :
                                                                                        "legend-indicator bg-danger")}></span> {group.isActive === true ? "Active" : "Inactive"}
                                                                                </ul>
                                                                                {/* <!-- End Badges --> */}


                                                                            </div>
                                                                        </Link>
                                                                        {/* <!-- End Body --> */}

                                                                    </div>
                                                                    {/* <!-- End Card 1 Grid--> */}
                                                                </div>
                                                                {/* <!-- End Col --> */ }

                                                            })}




                                                        </div>
                                                        {/* <!-- End Connections --> */}
                                                    </div>

                                                    {/* <!-- End Tab Content --> */}
                                                    {/* <!-- End Content for question sets --> */}
                                                </>
                                            }
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        {/* <!--enter row above here--> */}
                    </div>


                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        )
    }
}
export default UserGroup;