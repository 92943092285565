import React, { Component } from 'react';
import NavDropdown from './NavDropdown';
import { Link } from 'react-router-dom';
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';

import axios from 'axios';
import SideBar from './SideBar';
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tenantLogo: null,
            showMenu: false,
            permissioncreatejob: true,
            permissioncreateinterview: true,
            permissionviewinterview: true,
            permissioncreatequestionset: true,
            permissioncreatevideolibrary: true,
            permissionviewquestion: true,
            permissionviewjobs: true,
            permissionviewvideolibrary: true,
            tenantSubscriptionId: 0,
            tenantSubscriptionDataPermissionLeads: {},
            tenantSubscriptionDataPermissionCandidateListing: {},
            sfc_AllowedJobsCount: 0
        }
    }


    componentDidMount()
    {
        if (verifyTokenOnLocal())
        { 
            this.logoSet();
            this.getTenantSubscriptionid();
        }
        else return;
    }


    /**
   * Logo fetch and set 
   * */
    logoSet() {
        var url = null;
        var tenantId = null;

        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null)
        {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then(result => {
                    this.setState({ tenantLogo: result.tenantLogoUrl });

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error (Header) while setting tenant details!', "top-center")
                });
        }
    }



    /**
     * Get Role base permissions
     * */
    getPermissions() {
        var tenantId = null;
        var permissioncreatejob = false;
        var permissioncreateinterview = false;
        var permissionviewinterview = false;
        var permissioncreatequestionset = false;
        var permissioncreatevideolibrary = false;
        var permissionviewquestion = false;
        var permissionviewjobs = false;
        var permissionviewvideolibrary = false;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {



                tenantId = parseJwt(bearer).tenantid;


                permissioncreatejob = CheckPermission(parseToken(bearer).roles, "Permissions.Job.Create", bearer).then(() => {
                    this.setState({ permissioncreatejob: permissioncreatejob });
                });

                permissioncreateinterview = CheckPermission(parseToken(bearer).roles, "Permissions.Interview.Create", bearer).then(() => {
                    this.setState({ permissioncreateinterview: permissioncreateinterview });
                });

                permissionviewinterview = CheckPermission(parseToken(bearer).roles, "Permissions.Interview.View", bearer).then(() => {
                    this.setState({ permissionviewinterview: permissionviewinterview });
                });

                permissioncreatequestionset = CheckPermission(parseToken(bearer).roles, "Permissions.QuestionSet.Create", bearer).then(() => {
                    this.setState({ permissioncreatequestionset: permissioncreatequestionset });
                });

                permissioncreatevideolibrary = CheckPermission(parseToken(bearer).roles, "Permissions.VideoLibraray.Create", bearer).then(() => {
                    this.setState({ permissioncreatevideolibrary: permissioncreatevideolibrary });
                });

                permissionviewquestion = CheckPermission(parseToken(bearer).roles, "Permissions.Question.View", bearer).then(() => {
                    this.setState({ permissionviewquestion: permissionviewquestion });
                });

                permissionviewjobs = CheckPermission(parseToken(bearer).roles, "Permissions.Job.View", bearer).then(() => {
                    this.setState({ permissionviewjobs: permissionviewjobs });
                });

                permissionviewvideolibrary = CheckPermission(parseToken(bearer).roles, "Permissions.VideoLibraray.View", bearer).then(() => {
                    this.setState({ permissionviewvideolibrary: permissionviewvideolibrary });
                });

            }
        }
    }

    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Permissions.Leads.View");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "CandidateListing");
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error (Header) while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }



    /**
 * Get the tenant subscriotion feature key value 
 * @param {number} tenantSubscriptionId
 * @param {string} featureKey
 */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.featureKey === "Permissions.Leads.View") {
                        this.setState({
                            tenantSubscriptionDataPermissionLeads: response.data
                        })
                    }
                    if (response.data.featureKey === "CandidateListing") {
                        this.setState({
                            tenantSubscriptionDataPermissionCandidateListing: response.data
                        })
                    }
                    if (response.data.featureKey === "CandidateListing") {
                        this.setState({
                            tenantSubscriptionDataPermissionCandidateListing: response.data
                        })
                    }
                    if (response.data.featureKey == "Jobs") {
                        let tenantSubscriptionData = response.data;
                        let jobsCount = parseInt(tenantSubscriptionData.value);
                        this.setState({
                            sfc_AllowedJobsCount: jobsCount

                        })

                    }

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error (Header) while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }




    render() {
        return (
            <>
            <header id="header" 
             className={`${this.props.isCollapsed ? 'swift-m-default' : ''} navbar-fixed navbar-height navbar-container navbar-bordered bg-white`}
            >
                <div className="navbar-nav-wrap">



                        <div className="navbar-nav-wrap-content-start">
                            {/*<!-- Navbar Vertical Toggle -->*/}
                            <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler opacity-100" onClick={() => {
                                this.props.toggleMenu()
                                if (this.state.showMenu) {
                                    this.setState({ showMenu: false })
                                }
                                else {
                                    this.setState({ showMenu: true })

                                }
                            }}>
                                <i className=" bi bi-list"></i>

                            </button>

                            {/*<!-- End Navbar Vertical Toggle -->*/}

                            {/*<!-- Nav Menu -->*/}


                        </div>



                        {/* <Link className="navbar-brand mt-2 ms-2" to="/" aria-label="Front">
                            {this.state.tenantLogo == "" || this.state.tenantLogo == null ? "SwiftRecruit" : <div></div>
                            }

                        </Link> */}
                        {/*<!-- End Logo -->*/}





                        <div className="navbar-nav-wrap-content-end">
                            {/* <!-- Navbar --> */}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    {/*<a className="btn btn-ghost-secondary btn-sm" href="documentation/index.html" target="_blank">*/}
                                    {/*  Documentation <i className="bi-box-arrow-up-right ms-1"></i>*/}
                                    {/*</a>*/}
                                </li>
                                <li className="nav-item d-none d-sm-inline-block">
                                    {/* <!-- Notification --> */}
                                    {/*<div className="dropdown">*/}
                                    {/*    <button type="button" className="btn btn-ghost-secondary btn-icon rounded-circle" id="navbarNotificationsDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" data-bs-dropdown-animation>*/}
                                    {/*        <i className="bi-bell"></i>*/}
                                    {/*        <span className="badge bg-primary rounded-pill ms-1">2</span>*/}
                                    {/*    </button>*/}

                                    {/*    <div className="dropdown-menu dropdown-menu-end dropdown-card navbar-dropdown-menu navbar-dropdown-menu-borderless" aria-labelledby="navbarNotificationsDropdown" style={{ width: "25rem" }}>*/}
                                    {/*        <div className="card-header card-header-content-between">*/}
                                    {/*            <h4 className="card-title mb-0">Notifications</h4>*/}

                                    {/*            <div className="dropdown">*/}
                                    {/*                <button type="button" className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle" id="navbarNotificationsDropdownSettings" data-bs-toggle="dropdown" aria-expanded="false">*/}
                                    {/*                    <i className="bi-three-dots-vertical"></i>*/}
                                    {/*                </button>*/}

                                    {/*                <div className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless" aria-labelledby="navbarNotificationsDropdownSettings">*/}
                                    {/*                    <span className="dropdown-header">Settings</span>*/}
                                    {/*                    <a className="dropdown-item" href="#">*/}
                                    {/*                        <i className="bi-archive dropdown-item-icon"></i> Archive all*/}
                                    {/*                    </a>*/}
                                    {/*                    <a className="dropdown-item" href="#">*/}
                                    {/*                        <i className="bi-check2-all dropdown-item-icon"></i> Mark all as read*/}
                                    {/*                    </a>*/}
                                    {/*                    <a className="dropdown-item" href="#">*/}
                                    {/*                        <i className="bi-toggle-off dropdown-item-icon"></i> Disable notifications*/}
                                    {/*                    </a>*/}
                                    {/*                    <a className="dropdown-item" href="#">*/}
                                    {/*                        <i className="bi-gift dropdown-item-icon"></i> What's new?*/}
                                    {/*                    </a>*/}
                                    {/*                    <div className="dropdown-divider"></div>*/}
                                    {/*                    <span className="dropdown-header">Feedback</span>*/}
                                    {/*                    <a className="dropdown-item" href="#">*/}
                                    {/*                        <i className="bi-chat-left-dots dropdown-item-icon"></i> Report*/}
                                    {/*                    </a>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <ul className="nav nav-tabs nav-justified" id="notificationTab" role="tablist">*/}
                                    {/*            <li className="nav-item">*/}
                                    {/*                <a className="nav-link active" href="#notificationNavOne" id="notificationNavOne-tab" data-bs-toggle="tab" data-bs-target="#notificationNavOne" role="tab" aria-controls="notificationNavOne" aria-selected="true">Messages (3)</a>*/}
                                    {/*            </li>*/}
                                    {/*            <li className="nav-item">*/}
                                    {/*                <a className="nav-link" href="#notificationNavTwo" id="notificationNavTwo-tab" data-bs-toggle="tab" data-bs-target="#notificationNavTwo" role="tab" aria-controls="notificationNavTwo" aria-selected="false">Archived</a>*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}

                                    {/*        <div className="card-body-height">*/}
                                    {/*            <div className="tab-content" id="notificationTabContent">*/}
                                    {/*                <div className="tab-pane fade show active" id="notificationNavOne" role="tabpanel" aria-labelledby="notificationNavOne-tab">*/}
                                    {/*                    <ul className="list-group list-group-flush navbar-card-list-group">*/}
                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck1" checked />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck1"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <img className="avatar avatar-sm avatar-circle" src={imgdescription} alt="Image Description" />*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Brian Warner</h5>*/}
                                    {/*                                    <p className="text-body fs-5">changed an issue from "In Progress" to <span className="badge bg-success">Review</span></p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">2hr</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck2" checked />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck2"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-soft-dark avatar-circle">*/}
                                    {/*                                            <span className="avatar-initials">K</span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Klara Hampton</h5>*/}
                                    {/*                                    <p className="text-body fs-5">mentioned you in a comment</p>*/}
                                    {/*                                    <blockquote className="blockquote blockquote-sm">*/}
                                    {/*                                        Nice work, love! You really nailed it. Keep it up!*/}
                                    {/*                                    </blockquote>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">10hr</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck3" checked />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck3"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-circle">*/}
                                    {/*                                            <img className="avatar-img" src={imgdescription1} alt="Image Description" />*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Ruby Walter</h5>*/}
                                    {/*                                    <p className="text-body fs-5">joined the Slack group HS Team</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">3dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck4" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck4"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-circle">*/}
                                    {/*                                            <img className="avatar-img" src={imgdescription2} alt="Image Description" />*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">from Google</h5>*/}
                                    {/*                                    <p className="text-body fs-5">Start using forms to capture the information of prospects visiting your Google website</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">17dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}
                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck5" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck5"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-circle">*/}
                                    {/*                                            <img className="avatar-img" src={imgdescription3} alt="Image Description" />*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Sara Villar</h5>*/}
                                    {/*                                    <p className="text-body fs-5">completed <i className="bi-journal-bookmark-fill text-primary"></i> FD-7 task</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">2mn</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}
                                    {/*                    </ul>*/}
                                    {/*                </div>*/}

                                    {/*                <div className="tab-pane fade" id="notificationNavTwo" role="tabpanel" aria-labelledby="notificationNavTwo-tab">*/}
                                    {/*                    <ul className="list-group list-group-flush navbar-card-list-group">*/}
                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck6" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck6"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-soft-dark avatar-circle">*/}
                                    {/*                                            <span className="avatar-initials">A</span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Anne Richard</h5>*/}
                                    {/*                                    <p className="text-body fs-5">accepted your invitation to join Notion</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">1dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck7" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck7"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-circle">*/}
                                    {/*                                            <img className="avatar-img" src={imgdescription4} alt="Image Description" />*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Finch Hoot</h5>*/}
                                    {/*                                    <p className="text-body fs-5">left Slack group HS projects</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">1dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck8" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck8"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-dark avatar-circle">*/}
                                    {/*                                            <span className="avatar-initials">HS</span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Htmlstream</h5>*/}
                                    {/*                                    <p className="text-body fs-5">you earned a "Top endorsed" <i className="bi-patch-check-fill text-primary"></i> badge</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">6dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck9" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck9"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-circle">*/}
                                    {/*                                            <img className="avatar-img" src={imgdescription5} alt="Image Description" />*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Linda Bates</h5>*/}
                                    {/*                                    <p className="text-body fs-5">Accepted your connection</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">17dy</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}

                                    {/*                        <li className="list-group-item form-check-select">*/}
                                    {/*                            <div className="row">*/}
                                    {/*                                <div className="col-auto">*/}
                                    {/*                                    <div className="d-flex align-items-center">*/}
                                    {/*                                        <div className="form-check">*/}
                                    {/*                                            <input className="form-check-input" type="checkbox" value="" id="notificationCheck10" />*/}
                                    {/*                                            <label className="form-check-label" for="notificationCheck10"></label>*/}
                                    {/*                                            <span className="form-check-stretched-bg"></span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                        <div className="avatar avatar-sm avatar-soft-dark avatar-circle">*/}
                                    {/*                                            <span className="avatar-initials">L</span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </div>*/}
                                    {/*                                </div>*/}

                                    {/*                                <div className="col ms-n2">*/}
                                    {/*                                    <h5 className="mb-1">Lewis Clarke</h5>*/}
                                    {/*                                    <p className="text-body fs-5">completed <i className="bi-journal-bookmark-fill text-primary"></i> FD-134 task</p>*/}
                                    {/*                                </div>*/}

                                    {/*                                <small className="col-auto text-muted text-cap">2mts</small>*/}
                                    {/*                            </div>*/}

                                    {/*                            <a className="stretched-link" href="#"></a>*/}
                                    {/*                        </li>*/}
                                    {/*                    </ul>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}

                                    {/*        <a className="card-footer text-center" href="#">*/}
                                    {/*            View all notifications <i className="bi-chevron-right"></i>*/}
                                    {/*        </a>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </li>



                                <li className="nav-item">
                                    <NavDropdown />
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
                {
                    this.state.showMenu &&

                    <div  onClick={() => {
                        if (this.state.showMenu) {
                            this.setState({ showMenu: false })
                        }
                        else {
                            this.setState({ showMenu: true })

                        }
                    }} id="navbarVerticalMenu" className=" card-navbar-nav bg-white d-lg-none">
                        {/* <!-- Collapse past here 1--> */}
                        <div className="nav-item">
                            <span className="dropdown-header mt-4">DASHBOARD</span>

                            <div className="nav-item">
                                <Link className={(window.location.href.indexOf("/dashboard") !== -1
                                    ? "nav-link link link-primary" :
                                    "nav-link")} to="/" data-placement="left">
                                    <i className="bi bi-house-door-fill nav-icon me-2"></i>
                                    <span className="nav-link-title">Dashboard </span>
                                </Link>
                            </div>
                        </div>

                            {process.env.REACT_APP_ANALYTICS_ENABLE === "true" && <div className="nav-item">
                                <Link className={(window.location.href.indexOf("/analytics") !== -1
                                    ? "nav-link link link-primary" :
                                    "nav-link")} to="/analytics" data-placement="left">
                                    <i className="bi bi-briefcase nav-icon me-2"></i>
                                    <span className="nav-link-title"> Analytics </span>
                                </Link>
                            </div>}


                        {this.state.permissionviewjobs && <div className="nav-item">
                            <Link className={(window.location.href.indexOf("/jobs") !== -1
                                ? "nav-link link link-primary" :
                                "nav-link")} to="/jobs" data-placement="left">
                                <i className="bi bi-briefcase nav-icon me-2"></i>
                                <span className="nav-link-title"> Jobs </span>
                            </Link>
                        </div>}


                       




                        {this.state.permissionviewvideolibrary &&
                            <div className="nav-item">
                                <Link className={(window.location.href.indexOf("/videolisting") !== -1
                                    ? "nav-link link link-primary" :
                                    "nav-link")} to="/videolisting" data-placement="left">
                                    <i className="bi bi-file-earmark-play nav-icon me-2"></i>
                                    <span className="nav-link-title">Video Listing </span>
                                </Link>
                            </div>}



                        {this.state.permissioncreatequestionset && <div className="nav-item">
                            <Link className={(window.location.href.indexOf("/questionsets") !== -1
                                ? "nav-link link link-primary" :
                                "nav-link")} to="/questionsets" data-placement="left">
                                <i className="bi bi-book nav-icon me-2"></i>
                                <span className="nav-link-title">Question Sets</span>
                            </Link>
                        </div>}

                        {this.state.permissionviewquestion && <div className="nav-item">
                            <Link className={(window.location.href.indexOf("/questions") !== -1
                                ? "nav-link link link-primary" :
                                "nav-link")} to="/questions" data-placement="left">
                                <i className="bi-layers nav-icon me-2"></i>
                                <span className="nav-link-title">Question Templates</span>
                            </Link>
                        </div>}
                            {this.state.permissionviewquestion && <div className="nav-item">
                                <Link className={(window.location.href.indexOf("/userprofile") !== -1
                                    ? "nav-link link link-primary" :
                                    "nav-link")} to="/userprofile" data-placement="left">
                                    <i className="bi bi-gear nav-icon  me-2"></i> 
                                    <span className="nav-link-title ">Settings</span>
                                </Link>
                            </div>}
                       



                    </div>

                }
            </>
        );
    }
}
export default Header;