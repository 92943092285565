import React, { Component } from 'react';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { validateAndGetFormatedToken } from '../../modules/CheckToken';
import { CheckPermission } from '../../modules/CheckPermission';
import { Link, withRouter } from 'react-router-dom';
import { parseToken, parseJwt } from '../../modules/TokenParser';
import axios from 'axios';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import Refresh from '../../assets/svg/illustrations/gui-refresh.svg'
import { getParameterByName } from '../../modules/Utilities';
import BeatLoaderComponent from "../Loader/BeatLoader";
import { ToastContainer } from 'react-toastify';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import AutoInterview from "../../assets/img/others/auto_interview_jobs.png";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "../DataPicker/index"
import "react-datepicker/dist/react-datepicker.css";
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import ReviewInterviewDetailsComponent from './ReviewInterviewDetailsComponent';
import EvaluateInterviewComponent from './EvaluateInterviewComponent';
import ReviewSummaryComponent from './ReviewSummaryComponent';
import InterviewDetailsComponent from './InterviewDetailsComponent';
import { Oval } from 'react-loader-spinner'

class JobInterviewsComponent extends Component {

    constructor() {
        super();
        this.state = {
            interviews: [],

            oneway: [],
            onewayopen: [],
            live: [],
            Requested: [],
            Accepted: [],
            Expired: [],
            Completed: [],
            //List Item Collections {value: 1, label: Job 1}
            jobDdlDataSource: [],
            selectedJob: { value: "Select", label: "Select" },
            viewInterviewDetails: false,
            selectedInterviewForDetails: {},
            selectedInterviewInviteForDetails: {},
            selectedJobName: "",
            isloaded: true,
            isloadedgetJobs: true,
            isloadedgetJobInterviews: true,
            isloadedgetInterviewInvites: true,
            infoMessage: null,
            filter: "",
            interviewReviewers: [],
            existedReviewers: [],
            selectedInterviewReviewers: [],
            editReviewers: false,
            editInterviewId: 0,
            editReviewPermission: true,
            viewReviewPermission: true,
            interviewsHeaders: [
                {
                    label: "Job Name", key: "jobName"
                },
                {
                    label: "Interview Type", key: "interviewType"
                },
                {
                    label: "Expiry Date", key: "expiryDate"
                },
                {
                    label: "Is Active", key: "isActive"
                },
                {
                    label: "Open Interview Link", key: "openInterviewInvite"
                }
            ],
            tenantSubscriptionDataPermissionDownloadInterviews: {},
            tenantSubscriptionId: 0,
            order: "DSC",
            filteredData: [],
            hideFilteration: false,
            itemOffset: 0,
            jobTitle: "",
            filterInterviewType: "",
            startFilterDate: null,
            endFilterDate: null,
            interviewsFilterModal: false,
            showFilterInterviewType: "",
            showStartFilterDate: null,
            showEndFilterDate: null,
            createURL: "",
            selectedInterviewInvites: [],
            selectedRowIndex: 0,
            reviewInterviewModal: false,
            setInterviewInviteId: "",
            setInterviewId: "",
            evaluateInterviewModal: false,
            summaryInterviewModal: false,
            activeInterviewWidget: "total",
            activeInterviews: 0,
            inActiveInterviews: 0,
            openInterviews: 0,
            closedInterviews: 0,
            liveInterviews: 0,
            totalInterviewInvites: 0,
            topFilteredData: [],
            filteredInvites: [],
            rowClicked: true,
            activeFilterInvite: "all",
            buttonbadgeclass:"all",
            interviewIdRowClicked: "",
            isRefreshed: false,
            selectedInterviewStatus: "allinterviews",
            loggedUserEmail: "",
            canEditReviewer: true,
            interviewIdFromQueryString: "",
            interviewStatusRadioButtonClicked: false,
            interviewInviteIdFromQueryString: "",
            applicantNameCaughtFromQueryString: "",
            interviewIdForDetailPage: "",
            interviewDetailsModal: false,
            itemOffsetInvites: 0,
            searchedApplicant:"",

        }
        this.updateReviewers = this.updateReviewers.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getJobInterviews = this.getJobInterviews.bind(this)
        this.handleEvaluateModalOpen = this.handleEvaluateModalOpen.bind(this)
        this.sectionRef = React.createRef();


    }

    componentDidMount() {
        document.title = "Tenant App - Interviews";

        this.getLoggedUserProfile();
        if (this.props.jobId != -1 && this.props.jobId != "" && this.props.jobId != null && this.props.jobId != undefined) {

            this.getTenantSubscriptionid();
            this.getSpecificJobDetails(this.props.jobId);
            this.showInfomrationMessage();
        }
        else {
            this.getTenantSubscriptionid();
            this.showInfomrationMessage();
        }
        this.getJobs();
        this.handleInterviewInviteRedirectedFromDashboard()
      

    }
  

    async getLoggedUserProfile() {
        var url = null;
        var subId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer !== "" || bearer != null) {
            subId = parseJwt(bearer).sub;
            url = process.env.REACT_APP_CORE_API + "/api/UserProfileName?userId=" + subId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ loggedUserEmail: response.data.email })
                    console.log("view email", response.data.email)
                })
                .catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");
                });
        }
    }

    /** get query string variable and show as information message */
    showInfomrationMessage() {
        try {
            var openInterviewUrl = null;
            openInterviewUrl = getParameterByName("openInterviewUrl"); //first segment

            if (openInterviewUrl != null && openInterviewUrl != '' && openInterviewUrl != undefined && openInterviewUrl != 'null') {
                openInterviewUrl += "&interviewId=" + getParameterByName("interviewId");
                this.setState({ infoMessage: openInterviewUrl });
            }
        }
        catch (e) {
            console.error("No url parameter found");
        }

    }

    /** Get Interview review Permission */
    interviewReviewPermissions() {
        let bearer = validateAndGetFormatedToken();
        var viewReviewPermission = false;
        var editReviewPermission = false;
        if (bearer !== "" || bearer != null) {
            if (parseToken(bearer).roles !== "TenantAdmin") {

                viewReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.View", bearer);
                this.setState({ viewReviewPermission: viewReviewPermission });

                editReviewPermission = CheckPermission(parseToken(bearer).roles, "Permissions.InterviewReviewPermission.Edit", bearer);
                this.setState({ editReviewPermission: editReviewPermission });
            }

        }
    }



    getJobInterviews(jobId) {
        // Validate jobId
        if (!jobId) {
            console.error("jobId is undefined, it should be a valid value");
            alert("Validation error");
            return;
        }
        
        let bearer = validateAndGetFormatedToken();

        if (bearer) {
            let url = `${process.env.REACT_APP_CORE_API}/api/Interview/${jobId}`;
            this.setState({ IsInterviewLoading : 1 });
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result.length > 0) {
                        this.setState(prevState => ({
                            interviews: result,
                            filteredData:  result,
                            isloadedgetJobInterviews: false
                        }), () => {
                            this.getWidgetsDetails();
                            if (!this.state.isRefreshed) {
                                this.widgetsFilteration();
                            }

                            result.forEach((interview) => {
                                if (interview.interviewId === this.state.interviewIdRowClicked || interview.interviewId == this.state.interviewIdFromQueryString) {
                                    console.log("interviewIdRowClicked matched and view invites now:", interview.interviewInvites);
                                    this.setState({
                                        selectedInterviewInvites: interview.interviewInvites
                                    }, () => {
                                        this.invitesFilteration(this.state.activeFilterInvite);
                                    });
                                }
                            });
                        });

                        this.interviewReviewPermissions();
                    } else {
                        this.setState({
                            interviews: [],
                            filteredData: [],
                            isloadedgetJobInterviews: false
                        });
                    }
                    this.setState({ IsInterviewLoading: 0 });
                })
                .catch((err) => {
                    console.error('Error while fetching jobs:', err);
                    this.setState({
                        interviews: [],
                        filteredData: [],
                        isloadedgetJobInterviews: false,
                        IsInterviewLoading: 0
                    });
                    displayErrorMessage('Error while fetching jobs!', "top-center");
                });
        } else {
            console.error('Bearer token is invalid or missing');
            displayErrorMessage('Authentication error', "top-center");
        }
    }

    /** Clear Interviews Cache */
    ClearInterviewsCache() {
        var jobId = this.props.jobId;
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = `${process.env.REACT_APP_CORE_API}/api/CacheClear/ClearInterviewsCache/${jobId}`;
            
            const requestOptions = {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            };

            fetch(url, requestOptions)

                .then((response) => response.json())
                .then((result) => {
                    this.getJobInterviews(this.props.jobId);
                })
                .catch((error) => {
                    console.log("error during ClearInterviewsCache", error);
                });
        }
    }

    /**
     * Get Rviewers by job
     * @param {any} jobId
     */

    getReviewers(jobId, interviewId) {
        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {

            let url = process.env.REACT_APP_CORE_API + "/api/InterviewReviewers?jobId=" + jobId;
            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ interviewReviewers: response.data.users })
                }).then(() => {
                    let url = process.env.REACT_APP_CORE_API + "/api/InterviewRerviewersOps/" + interviewId;
                    axios(url,
                        {
                            method: 'GET',
                            headers:
                            {
                                'Authorization': bearer,
                                'Content-Type': 'application/json'
                            }
                        }).then((response) => {
                            if (response.data.length > 0) {
                                let tempReviewers = [];
                                let tempSelectedInterviewReviewers = [];
                                this.state.interviewReviewers.forEach((interviewreviewer, i) => {
                                    tempReviewers.push({ isPresent: false, reviewerId: interviewreviewer.userId, reviewerUsername: interviewreviewer.userName })
                                })
                                response.data.forEach((item, index) => {
                                    this.state.interviewReviewers.forEach((item1, index1) => {
                                        if (item.username === item1.userName) {

                                            tempReviewers[index1].isPresent = true;
                                            tempSelectedInterviewReviewers.push({ userId: item.userId, userName: item.username });
                                        }

                                    })
                                })
                                this.setState({
                                    interviewReviewers: tempReviewers,
                                    selectedInterviewReviewers: tempSelectedInterviewReviewers,
                                    editInterviewId: interviewId,
                                    editReviewers: true
                                })
                                document.body.classList.add('modal-open');


                            }

                        })
                        .catch(err => {

                            if (err.response.data == "No interview reviewers found") {
                                let tempReviewers = [];
                                let tempSelectedInterviewReviewers = [];
                                this.state.interviewReviewers.forEach((interviewreviewer, i) => {
                                    tempReviewers.push({ isPresent: false, reviewerId: interviewreviewer.userId, reviewerUsername: interviewreviewer.userName })
                                })
                                this.state.interviewReviewers.forEach((item, index1) => {

                                    tempReviewers[index1].isPresent = true;
                                    tempSelectedInterviewReviewers.push({ userId: item.userId, userName: item.username });

                                })

                                this.setState({
                                    interviewReviewers: tempReviewers,
                                    selectedInterviewReviewers: tempSelectedInterviewReviewers,
                                    editInterviewId: interviewId,
                                    editReviewers: true
                                })
                            }
                            else {
                                console.error("Existed Interview Reviewers" + err);
                                displayErrorMessage('Error while fetching Existed Reviewers!', "top-center");
                            }

                        });
                })
                .catch(err => {
                    console.error("Interview Reviewers" + err);
                    displayErrorMessage('Error while fetching Reviewers!', "top-center");


                });
        }


    }




    /** Get the filtered jobs
     * Jobs are filtered by permissions
     * Permission Level is set on job when it get created
     * Everyone, manager, group
     * */
    getJobs() {
        let bearer = validateAndGetFormatedToken();


        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/Jobs";

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => response.json())
                .then((result) => {
                    let jobsListItems = [];
                    if (result.length > 0) {

                        //This loop is for setting the values in specific pattern for React-select to accept
                        //Load interview of the particular job and update this.state.Interviews array

                        result.forEach((jobObject) => {
                            jobsListItems.push({ value: jobObject.jobId, label: jobObject.jobId + " - " + jobObject.title + " (" + jobObject.location + ")" });
                        });


                        //When done with loop then set job dropdown datasource.
                        this.setState
                            ({
                                jobDdlDataSource: jobsListItems,
                                isloadedgetJobs: false
                            });

                    }
                    else {
                        this.setState(
                            {
                                jobDdlDataSource: [],
                                isloadedgetJobs: false,
                                isloadedgetJobInterviews: false,
                                isloadedgetInterviewInvites: false,

                            });
                    }

                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Unale to load jobs', "top-center");


                });
        }
    }


    /**
     * When job id is being given*/
    getSpecificJobDetails(jobId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();
        let jobTitle = "";
        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/Jobs/" + jobId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then((response) => response.json())
                .then((result) => {
                    if (result != null || result != undefined) {
                        jobTitle = result.title;
                        this.setState({
                            jobTitle: result.title,
                            selectedJob: ({ value: result.jobId, label: result.jobId + " - " + result.title + " (" + result.location + ")" })
                        })

                        if (result.jobVisibilityLevel === "manager" && result.jobManager !== this.state.loggedUserEmail) {
                            this.setState({ canEditReviewer: false });
                        }
                    }

                })
                .then(() => {
                    if (jobTitle != "") {
                        this.getJobInterviews(jobId);
                        this.setState
                            ({
                                isloadedgetJobs: false,
                            });

                    }
                    else {
                        this.setState(
                            {
                                isloadedgetJobs: false,
                                isloadedgetJobInterviews: false,
                                isloadedgetInterviewInvites: false,

                            });
                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Job title!', "top-center");

                });
        }
    }


    /**
* Get the tenant subscription id 
*/
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "DownloadInterviews");
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }



    /**
 * Get the tenant subscriotion feature key value 
 * @param {number} tenantSubscriptionId
 * @param {string} featureKey
 */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({
                        tenantSubscriptionDataPermissionDownloadInterviews: response.data
                    })

                    console.log("tenantSubscriptionData", response.data)
                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }




    renderInfoMessage() {
        return (
            <div className="text-white bg-lightBlue-600 border-t-4 border-teal-500 rounded-b  px-4 py-3 shadow-md" role="alert" style={{ marginBottom: '2%' }}>
                <div className="flex">
                    <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                    <div>
                        <p className="font-bold">Open Interview Invitation Link, Please copy the link and share with candidates by email.</p>
                        <p className="text-sm">{this.state.infoMessage}</p>
                    </div>
                </div>
            </div>
        );
    }
    updateReviewers() {
        let bearer = validateAndGetFormatedToken();

        if (this.state.editInterviewId == "" || this.state.editInterviewId == 0) {
            displayWarningMessage('Interview Id Not Found!', "top-center");
            return;

        }
        if (this.state.selectedInterviewReviewers.length <= 0) {
            displayErrorMessage("Please slect reviewers", "top-center");
            return;
        }


        if (bearer != "" || bearer != null) {

            //this.toggleButton(true);
            let url = process.env.REACT_APP_CORE_API + '/api/InterviewRerviewersOps/' + this.state.editInterviewId;

            var model = JSON.stringify(this.state.selectedInterviewReviewers);


            const requestOptions = {
                method: 'PUT',
                headers:
                {
                    'Authorization': bearer,
                    'Content-Type': 'application/json'
                },
                body: model
            };
            fetch(url, requestOptions).then((response) => {
                if (response.status === 400) {
                    displayErrorMessage('Unable to remove reviewer as they have already provided reviews.', "top-center");
                }
                else {
                    displaySuccessMessage("Reviewers has been updated", "top-center");
                    this.setState({
                        selectedInterviewReviewers: [],
                        editReviewers: false
                    });
                    document.body.classList.remove('modal-open');
                }

            })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Updating Interview Reviewer!', "top-center");
                    //this.toggleButton(false);

                });
        }
    }


    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }

    handleChange = (selectedOption) => {
        this.setState({
            selectedJob: selectedOption,
            interviews: []
        });
        let createURL = "/createinterview/" + selectedOption.value;
        this.setState({
            createURL: createURL,
        });

        this.getSpecificJobDetails(selectedOption.value);
    }


    /*
    *Copy text invitation link
    * */
    invitationLinkCopy(e, id) {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);

        /* Alert the copied text */
        displaySuccessMessage('Link Copied :' + copyText.value, "top-center");

    }

    //get the top widgets details such as the total count of activeInterviews etc.
    getWidgetsDetails = () => {
        let activeInterviews = 0;
        let inActiveInterviews = 0;
        let openInterviews = 0;
        let closedInterviews = 0;
        let liveInterviews = 0;
        let totalInterviewInvites = 0;

        this.state.interviews.forEach(interview => {
            if (interview.isActive) {
                activeInterviews++
            }
            if (!interview.isActive) {
                inActiveInterviews++
            }
            if (interview.interviewType === "onewayopen") {
                openInterviews++
            }
            if (interview.interviewType === "oneway") {
                closedInterviews++
            }
            if (interview.interviewType === "live") {
                liveInterviews++
            }
            totalInterviewInvites += interview.interviewInvites.length


        })
        this.setState({
            activeInterviews: activeInterviews,
            inActiveInterviews: inActiveInterviews,
            openInterviews: openInterviews,
            closedInterviews: closedInterviews,
            liveInterviews: liveInterviews,
            totalInterviewInvites: totalInterviewInvites,
        })
    }

    //Top widget filteration, pass status(active, inactive etc) to the function to get the relevant data
    widgetsFilteration = () => {

        let filteredData = this.state.interviews.filter((interview) => {
            if (this.state.selectedInterviewStatus === "onewayopen") {
                return interview.interviewType.toLowerCase() === "onewayopen";
            }
            else if (this.state.selectedInterviewStatus === "onewayclosed") {
                return interview.interviewType.toLowerCase() === "oneway";
            } else if (this.state.selectedInterviewStatus === "live") {
                return interview.interviewType.toLowerCase() === "live";
            } else if (this.state.selectedInterviewStatus === "active") {
                return interview.isActive === true;
            } else if (this.state.selectedInterviewStatus === "inactive") {
                return interview.isActive === false;
            } else {
                return true; // No specific status filter
            }
        });



        this.setState({
            filteredData: filteredData,
            topFilteredData: filteredData,
            itemOffset: 0,
        });


        if (filteredData.length > 0) {
            this.setState({
                selectedInterviewInvites: filteredData[0].interviewInvites,
                interviewIdRowClicked: filteredData[0].interviewId
            }, () => {
                this.invitesFilteration("all")

            });

        } else {

            this.setState({
                selectedInterviewInvites: [],
            }, () => {
                this.invitesFilteration("all")
            });
        }

        if (this.state.interviewIdFromQueryString != "" && !this.state.interviewStatusRadioButtonClicked) {
            for (let i = 0; i < filteredData.length; i++) {
                if (filteredData[i].interviewId == this.state.interviewIdFromQueryString) {
                    this.setState({ selectedRowIndex: i })
                    setTimeout(() => {
                        this.scrollToSection()
                    }, 4000
                    )

                }

            }
        }


    }
    //function to filter interview invites on the basis of the selected interview invite status
    invitesFilteration(selectedInvite) {
        let filteredInvites;

        if (this.state.interviewInviteIdFromQueryString) {
            filteredInvites = this.state.selectedInterviewInvites.filter((invite) => {
                if (invite.interviewInviteId == this.state.interviewInviteIdFromQueryString) {
                    this.setState({
                        applicantNameCaughtFromQueryString: invite.firstName + " " + invite.lastName,
                    });
                }

                return (
                    invite.interviewInviteId == this.state.interviewInviteIdFromQueryString &&
                    (selectedInvite.toLowerCase() === "all" ||
                        invite.inviteStatus.toLowerCase() === selectedInvite.toLowerCase())
                    );
            });


        } else {
            if (selectedInvite === "all") {
                filteredInvites = this.state.selectedInterviewInvites;
            } else {
                filteredInvites = this.state.selectedInterviewInvites.filter((invite) => {
                    return invite.inviteStatus.toLowerCase() === selectedInvite.toLowerCase();
                });
            }
        }


        

        if (this.state.searchedApplicant) {
            filteredInvites = filteredInvites.filter((invite) => {
                const applicantName = invite.firstName + " " +invite.lastName
                return invite.candidateEmail.toLowerCase().indexOf(this.state.searchedApplicant.trim().toLowerCase()) > -1 ||
                    applicantName.trim().toLowerCase().indexOf(this.state.searchedApplicant.trim().toLowerCase()) > -1
            });
        }

        this.setState({
            filteredInvites: filteredInvites,
            itemOffsetInvites:0
        });

    }

    scrollToSection = () => {
        // Scroll to the section using the sectionRef
        this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };



    handleInterviewStatusChange = (event) => {
        this.setState({
            selectedInterviewStatus: event.target.value,
            selectedRowIndex: 0,
            rowClicked: true,
            interviewStatusRadioButtonClicked: true,
            interviewInviteIdFromQueryString: "",
            interviewIdFromQueryString: "",
            activeFilterInvite: "all",
            buttonbadgeclass:"all"
        },
            () => {
                this.widgetsFilteration()
                this.removeInterviewIdAndinterviewInviteId()

            }
        );
        this.setState({ showFilterInterviewType: '', filterInterviewType: "", showStartFilterDate: null, startFilterDate: null, showEndFilterDate: null, endFilterDate: null })
    };
    //close evaluate interview modal on a certain condition
    closeEvaluateInterviewModal = () => {
        this.setState({ evaluateInterviewModal: !this.state.evaluateInterviewModal })
        document.body.classList.remove('modal-open');

    }
    //remove interviewId from the URL's query string 
    removeInterviewIdAndinterviewInviteId = () => {
        const { history, location } = this.props;
        const searchParams = new URLSearchParams(location.search);

        // Remove the interviewId parameter
        searchParams.delete('interviewId');
        searchParams.delete('interviewInviteId');

        // Update the URL without reloading the page
        history.push({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    }

    //open the evalute modal in place of review modal
    handleEvaluateModalOpen = () => {
        this.setState({
            reviewInterviewModal: false,
            evaluateInterviewModal: true,
        })
    }
    //only get interview invites when the page number changes on th pagination of interviews
    getInterviewInvitesOnPageClick = () => {
        const endOffset = this.state.itemOffset + 10;

        const currentItems = Array.isArray(this.state.filteredData) ? this.state.filteredData.slice(this.state.itemOffset, endOffset) : [];
        this.setState({
            selectedInterviewInvites: currentItems[0].interviewInvites,
            activeFilterInvite:"all"
        }, () => {
            this.invitesFilteration("all")
        })
       
    }

    //function to handle interview invited redirected from dashboard
    handleInterviewInviteRedirectedFromDashboard = () => {
        // Get the URL parameters
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // Get the interviewId and interviewInviteId parameter values
        const interviewId = urlParams.get('interviewId');
        const interviewInviteId = urlParams.get('interviewInviteId');

        this.setState({
            interviewIdFromQueryString: interviewId,
            interviewInviteIdFromQueryString: interviewInviteId,
        })

    }

    render() {
        const { interviews } = this.state;
        const filteration = () => {
            let filteredData = this.state.topFilteredData.length > 0 && this.state.topFilteredData.filter((item) => {
                var isRecordFind;
                if (this.state.filterInterviewType.toLowerCase() === "") {
                    isRecordFind = true;
                }
                else {
                    isRecordFind = item.interviewType.toLowerCase() === this.state.filterInterviewType.toLowerCase();
                }
                if (this.state.startFilterDate == null && this.state.endFilterDate == null) {
                    return isRecordFind
                }
                else {
                    return isRecordFind
                        && new Date(this.state.startFilterDate) <= new Date(item.createdDate)
                        && new Date(new Date(this.state.endFilterDate).setDate(new Date(this.state.endFilterDate).getDate() + 1)) >= new Date(item.createdDate)
                }
            }
            );
            this.setState({
                filteredData: filteredData,
            })
        }


        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC"
                })
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC"
                })
            }
        }

        const onRowSelectforinterviewreviewersonewayclose = (row, isSelected) => {
            if (isSelected.target.checked) {
                let selectedsets = this.state.selectedInterviewReviewers;
                selectedsets.push({ userId: row.reviewerId, userName: row.reviewerUsername });

                this.setState({ selectedInterviewReviewers: selectedsets })
            }
            else {
                let selectedsets = this.state.selectedInterviewReviewers;

                for (let i = 0; i < selectedsets.length; i++) {
                    if (row.reviewerId === selectedsets[i].userId) {


                        selectedsets.splice(i, 1);

                        this.setState({ selectedInterviewReviewers: selectedsets });
                        return;
                    }
                }


            }

        }
        //for interviews pagination 
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = Array.isArray(this.state.filteredData) ? this.state.filteredData.slice(this.state.itemOffset, endOffset) : [];
        const pageCount = Math.ceil(this.state.filteredData.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredData.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({
                itemOffset: newOffset,
                selectedRowIndex: 0,
                rowClicked: true,
              
            }, () => {       
   
                this.getInterviewInvitesOnPageClick()
            });
            if (this.state.interviewInviteIdFromQueryString) {
                this.setState({
                    interviewInviteIdFromQueryString: "",
                    interviewIdFromQueryString: ""
                }, () => {
                    this.invitesFilteration("all");
                    this.removeInterviewIdAndinterviewInviteId()
                })

            }
          
        };

        //for interview invites pagination 
        const endOffsetInvites = this.state.itemOffsetInvites + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const filteredInvites = Array.isArray(this.state.filteredInvites) ? this.state.filteredInvites.slice(this.state.itemOffsetInvites, endOffsetInvites) : [];
        const pageCountInvites = Math.ceil(this.state.filteredInvites.length / 10);

        // Invoke when user click to request another page.
        const handlePageClickInvites = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredInvites.length;
            this.setState({ itemOffsetInvites: newOffset });
        };
       

        return (
            <>




                {/* <!-- Content --> */}
                {/* <!-- Page Header --> */}
                {this.props.displayHeader && (
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">

                                <h1 className="page-header-title">{this.state.jobTitle} Interviews </h1>
                            </div>
                            {/* <!-- End Col --> */}


                        </div>
                        {/* <!-- End Row --> */}
                    </div>
                )}




                {/* <!-- End Page Header --> */}
                {/*<!--Card--> */}
                {(this.state.isloadedgetJobInterviews) ?
                    <BeatLoaderComponent />
                    :
                    <>


                        {/*<!-- Start Job Data table-->*/}
                        {(this.props.jobId == -1 && this.props.jobId == "" && this.props.jobId == null && this.props.jobId == undefined) ?
                            null :
                            !this.state.isloadedgetJobInterviews &&
                            (this.state.interviews.length > 0 ?
                                <>

                                    <div className="row my-0 d-none d-lg-block">

                                        <div className="col-md-12">

                                            <div className="card">

                                                {/*<!-- Header --> */}

                                                <div className="p-3">

                                                    <div className="row  g-0 mb-2" >

                                                        <div className="col-10">
                                                            <div className="btn-group   applicants-status-radio-group" role="group" aria-label="Status Radio button group">
                                                                <div className="form-check me-3">
                                                                    <input className="form-check-input" type="radio" name="allinterviews" id="allinterviews" value="allinterviews" checked={this.state.selectedInterviewStatus === 'allinterviews'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="allinterviews">
                                                                        All
                                                                    </label>
                                                                </div>
                                                                <div className="form-check me-3">
                                                                    <input className="form-check-input" type="radio" name="active" id="active" value="active" checked={this.state.selectedInterviewStatus === 'active'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="active">
                                                                        Active
                                                                    </label>
                                                                </div>
                                                                <div className="form-check me-3">
                                                                    <input className="form-check-input" type="radio" name="inactive" id="inactive" value="inactive" checked={this.state.selectedInterviewStatus === 'inactive'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="inactive">
                                                                        Inactive
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ">
                                                                    <input className="form-check-input" type="radio" name="onewayopen" id="onewayopen" value="onewayopen" checked={this.state.selectedInterviewStatus === 'onewayopen'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="onewayopen">
                                                                        Oneway Open
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ">
                                                                    <input className="form-check-input" type="radio" name="onewayclosed" id="onewayclosed" value="onewayclosed" checked={this.state.selectedInterviewStatus === 'onewayclosed'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="onewayclosed">
                                                                        Oneway Closed
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ">
                                                                    <input className="form-check-input" type="radio" name="live" id="live" value="live" checked={this.state.selectedInterviewStatus === 'live'}
                                                                        onChange={this.handleInterviewStatusChange} />
                                                                    <label className="form-check-label" htmlFor="live">
                                                                        Live
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col d-flex justify-content-end align-items-center">



                                                            <div class="icon-wrapper me-2 " >

                                                                <i className="bi bi-funnel-fill filter-icon-hover" style={{ fontSize: '1.5rem', color: '#3FB5C3', cursor: 'pointer', padding: "7px" }}
                                                                    onClick={() => {
                                                                        this.setState({ interviewsFilterModal: true })
                                                                        document.body.classList.add('modal-open');

                                                                    }}
                                                                >

                                                                </i>
                                                            </div>
                                                            <div class="icon-wrapper " >


                                                                {this.state.tenantSubscriptionDataPermissionDownloadInterviews.value === "true" &&
                                                                    <CSVLink data={this.state.filteredData.length > 0 ? this.state.filteredData : []} filename={"Interviews.csv"}
                                                                        headers={this.state.interviewsHeaders} className=" float-end">
                                                                        <i class="bi bi-filetype-csv filter-icon-hover" style={{ fontSize: "1.5rem", color: "green", padding: "7px" }}></i>
                                                                    </CSVLink>}
                                                            </div>


                                                            <div className="col-md-auto d-flex align-items-center  mx-2">
                                                                <img className="Refresh-Button" src={Refresh} alt="Refresh Jobs" data-hs-theme-appearance="default" data-tip data-for="refreshIcon"
                                                                    onClick={() => {
                                                                        this.ClearInterviewsCache();
                                                                        this.setState({ activeFilterInvite: "all", isRefreshed: true });
                                                                        this.invitesFilteration("all");
                                                                    }}
                                                                />
                                                                <ReactTooltip id="refreshIcon" place="top" type="dark" effect="float">
                                                                    <span>Refresh</span>
                                                                </ReactTooltip>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div className="col d-flex justify-content-end">
                                                        {this.state.showFilterInterviewType && (
                                                            <div className="filtered-div f-size-14 mx-1 d-flex align-items-center" >
                                                                {this.state.showFilterInterviewType === "oneway" ? "Closed Interview" : this.state.showFilterInterviewType === "onewayopen" ? "Open Interview" : this.state.showFilterInterviewType === "live" ? "Live Interview" : ""}
                                                                <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ marginLeft: '8px' }}
                                                                    onClick={(e) => {
                                                                        this.setState({ showFilterInterviewType: '', filterInterviewType: "" },
                                                                            () => { filteration(); }
                                                                        )
                                                                    }}
                                                                >
                                                                </button>
                                                            </div>
                                                        )}


                                                        {this.state.showStartFilterDate && (
                                                            <div className="filtered-div f-size-14 mx-1 d-flex align-items-center" >
                                                                {this.state.showStartFilterDate.toDateString()}, {this.state.showEndFilterDate.toDateString()}
                                                                <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ marginLeft: '8px' }}
                                                                    onClick={(e) => {
                                                                        this.setState({ showStartFilterDate: null, startFilterDate: null, showEndFilterDate: null, endFilterDate: null },
                                                                            () => { filteration(); }
                                                                        )
                                                                    }}
                                                                >
                                                                </button>
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>


                                                {/*<!-- Table --> */}
                                                {currentItems.length !== 0 && (
                                                    <div className="table-responsive card m-3 mt-0 mb-4 pb-4 datatable-custom">
                                                        <table id="datatable" className="table  table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th style={{ width: "2%" }}></th>
                                                                    <th onClick={() => sorting("interviewType")} style={{ cursor: 'pointer' }}><strong>Title <i className="bi bi-sort-alpha-down"></i> </strong></th>
                                                                    <th  ><strong>Interview Level </strong></th>
                                                                    <th ><strong>Created Date </strong></th>
                                                                    <th ><strong>Expiry Date </strong></th>
                                                                    <th  ><strong>Actions </strong></th>
                                                                    {/*<th  ><strong>Details</strong></th>*/}


                                                                </tr>

                                                            </thead>

                                                            <tbody>
                                                                {currentItems.length > 0 && currentItems.map((interview, index) => {
                                                                    return <tr role="row" className={this.state.selectedRowIndex === index ? 'table-active-row' : 'odd'}>
                                                                        <td style={{ width: "2%" }}>
                                                                            <span style={{ float: 'left', paddingRight: '0.4rem' }}>
                                                                                {interview.isActive ?
                                                                                    <i class="bi bi-circle-fill text-success">
                                                                                    </i>
                                                                                    : <i class="bi bi-circle-fill text-danger"></i>
                                                                                }
                                                                            </span>
                                                                        </td>

                                                                        <td
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    selectedInterviewInvites: interview.interviewInvites,
                                                                                    selectedRowIndex: index,
                                                                                    rowClicked: true,
                                                                                    activeFilterInvite: "all",
                                                                                    interviewIdRowClicked: interview.interviewId,
                                                                                    interviewInviteIdFromQueryString: "",
                                                                                    interviewIdFromQueryString: "",
                                                                                    buttonbadgeclass:"all",


                                                                                }, () => {

                                                                                    this.invitesFilteration("all")
                                                                                    this.scrollToSection()
                                                                                    this.removeInterviewIdAndinterviewInviteId()

                                                                                })

                                                                            }}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >

                                                                            <div className="d-flex  align-items-center inter-ff">
                                                                                {interview.interviewType === "oneway" &&
                                                                                    <span>Closed Interview</span>
                                                                                }
                                                                                {interview.interviewType === "onewayopen" &&
                                                                                    <span>Open Interview</span>
                                                                                }
                                                                                {interview.interviewType === "live" &&
                                                                                    <span>Live Interview</span>
                                                                                }

                                                                            </div>

                                                                        </td>
                                                                        <td onClick={() => {
                                                                            this.setState({
                                                                                selectedInterviewInvites: interview.interviewInvites,
                                                                                selectedRowIndex: index,
                                                                                rowClicked: true,
                                                                                activeFilterInvite: "all",
                                                                                interviewIdRowClicked: interview.interviewId,
                                                                                interviewInviteIdFromQueryString: "",
                                                                                interviewIdFromQueryString: "",


                                                                            }, () => {

                                                                                this.invitesFilteration("all")
                                                                                this.scrollToSection()
                                                                                this.removeInterviewIdAndinterviewInviteId()

                                                                            })

                                                                        }}
                                                                            style={{ cursor: 'pointer' }}>
                                                                            {interview.interviewLevel}
                                                                        </td>

                                                                        <td className="inter-ff" onClick={() => {
                                                                            this.setState({
                                                                                selectedInterviewInvites: interview.interviewInvites,
                                                                                selectedRowIndex: index,
                                                                                rowClicked: true,
                                                                                activeFilterInvite: "all",
                                                                                interviewIdRowClicked: interview.interviewId,
                                                                                interviewInviteIdFromQueryString: "",
                                                                                interviewIdFromQueryString: "",


                                                                            }, () => {

                                                                                this.invitesFilteration("all")
                                                                                this.scrollToSection()
                                                                                this.removeInterviewIdAndinterviewInviteId()

                                                                            })

                                                                        }}
                                                                            style={{ cursor: 'pointer' }}>
                                                                            {convertUTCDateToBrowserLocale(interview.createdDate.toString())}
                                                                        </td>
                                                                        <td className="inter-ff" onClick={() => {
                                                                            this.setState({
                                                                                selectedInterviewInvites: interview.interviewInvites,
                                                                                selectedRowIndex: index,
                                                                                rowClicked: true,
                                                                                activeFilterInvite: "all",
                                                                                interviewIdRowClicked: interview.interviewId,
                                                                                interviewInviteIdFromQueryString: "",
                                                                                interviewIdFromQueryString: "",


                                                                            }, () => {

                                                                                this.invitesFilteration("all")
                                                                                this.scrollToSection()
                                                                                this.removeInterviewIdAndinterviewInviteId()

                                                                            })

                                                                        }}
                                                                            style={{ cursor: 'pointer' }}>
                                                                            {convertUTCDateToBrowserLocale(interview.expiryDate.toString())}
                                                                        </td>

                                                                        <td >
                                                                            {this.state.editReviewPermission && this.state.canEditReviewer && (
                                                                                <>
                                                                                    <Link className="btn btn-outline-primary btn-icon btn-sm me-2" id={"editReview" + index} data-tip data-for={"editReview" + index}>
                                                                                        <i class="bi bi-pencil-square" style={{ cursor: 'pointer' }} onClick={async () => {
                                                                                            await this.getReviewers(interview.jobId, interview.interviewId);

                                                                                        }}></i>
                                                                                    </Link>
                                                                                    <ReactTooltip id={"editReview" + index} place="right" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">Edit interview reviewer</span>
                                                                                    </ReactTooltip>
                                                                                </>
                                                                            )}

                                                                            <button className="btn btn-outline-primary btn-icon btn-sm me-2" id={"interviewDetails" + index} data-tip data-for={"interviewDetails" + index}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        interviewDetailsModal: true,
                                                                                        interviewIdForDetailPage: interview.interviewId
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i class="bi bi-info-square" style={{ cursor: 'pointer' }}></i>
                                                                            </button>
                                                                            <ReactTooltip id={"interviewDetails" + index} place="right" type="dark" effect="float">
                                                                                <span className="d-block fs-5">View Interview Details</span>
                                                                            </ReactTooltip>
                                                                            {interview.openInterviewInvite != null &&
                                                                                <div id={"copyLink" + index} data-tip data-for={"copyLink" + index} className=" flex-nowrap btn btn-outline-primary btn-icon btn-sm me-2">
                                                                                    <i className="bi bi-clipboard-fill " style={{ cursor: 'pointer' }} onClick={(e) => this.invitationLinkCopy(e, interview.interviewId)}></i>
                                                                                    <input type="text" className="form-control" value={interview.openInterviewInvite} id={interview.interviewId} hidden />
                                                                                    <ReactTooltip id={"copyLink" + index} place="right" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">Copy open interview link</span>
                                                                                    </ReactTooltip>
                                                                                </div>
                                                                            }
                                                                            {interview.isAutoScheduled ?
                                                                                <><img src={AutoInterview} style={{ cursor: 'pointer', width: '2rem', height: '2rem' }} id={"isAutoInterviewSchedule" + index} data-tip data-for={"isAutoInterviewSchedule" + index}></img>
                                                                                    <ReactTooltip id={"isAutoInterviewSchedule" + index} place="right" type="dark" effect="float">
                                                                                        {interview.isAutoScheduled ?
                                                                                            "Automatic interview is scheduled" :
                                                                                            "No automatic interview is scheduled"
                                                                                        }
                                                                                    </ReactTooltip>
                                                                                </>
                                                                                : null}





                                                                        </td>

                                                                    </tr>
                                                                })}


                                                            </tbody>
                                                        </table>
                                                    </div>

                                                )}
                                                {/*<!-- End Table --> */}


                                                {/*<!-- Footer --> */}
                                                {currentItems.length === 0 ? (
                                                    <div className="card card-centered m-3 mt-0 mb-3 mb-lg-5">
                                                        <div className="card-body py-10">
                                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                            <div className="text-center">

                                                                <h1>No interviews found. </h1>

                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="card-footer m-3 d-none"  >
                                                        <div className="d-flex justify-content-center">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination mb-0'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                                initialPage={!this.state.interviewInviteIdFromQueryString ? 0 : undefined}
                                                                forcePage={!this.state.interviewInviteIdFromQueryString ? this.state.itemOffset / 10 : undefined}
                                                            />
                                                        </div>

                                                    </div>
                                                )}

                                                {/*<!-- End Footer --> */}


                                                <div className="card m-3">

                                                {this.state.rowClicked && currentItems.length !== 0 && (
                                                    <>
                                                        <h1 className="m-3 mt-3 mb-0 h3" ref={this.sectionRef}>Interview Invites</h1>


                                                        <div className="row m-2 mt-3 mb-3 g-0 gap-2 px-2">
                                                            <div className="col-md card">
                                                                <ul className="nav nav-tabs">
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "all" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "all", itemOffsetInvites: 0, buttonbadgeclass: "all" }); this.invitesFilteration("all") }}>All <span className={`${this.state.buttonbadgeclass === "all" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}> {currentItems[this.state.selectedRowIndex].totalInvitesCount} </span></button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "requested" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "requested", itemOffsetInvites: 0, buttonbadgeclass: "requested" }); this.invitesFilteration("requested") }}>Requested <span className={`${this.state.buttonbadgeclass === "requested" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}> {currentItems[this.state.selectedRowIndex].requestedInvitesCount} </span></button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "rescheduled" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "rescheduled", itemOffsetInvites: 0, buttonbadgeclass: "rescheduled" }); this.invitesFilteration("rescheduled") }}>Rescheduled <span className={`${this.state.buttonbadgeclass === "rescheduled" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}> {currentItems[this.state.selectedRowIndex].rescheduledInvitesCount} </span> </button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "accepted" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "accepted", itemOffsetInvites: 0, buttonbadgeclass: "accepted" }); this.invitesFilteration("accepted") }}>Accepted <span className={`${this.state.buttonbadgeclass === "accepted" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}>{currentItems[this.state.selectedRowIndex].acceptedInvitesCount}</span></button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "completed" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "completed", itemOffsetInvites: 0, buttonbadgeclass: "completed" }); this.invitesFilteration("completed") }}>Completed <span className={`${this.state.buttonbadgeclass === "completed" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}>{currentItems[this.state.selectedRowIndex].completedInvitesCount}</span></button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "reviewed" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "reviewed", itemOffsetInvites: 0, buttonbadgeclass: "reviewed" }); this.invitesFilteration("reviewed") }}>Reviewed <span className={`${this.state.buttonbadgeclass === "reviewed" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}>{currentItems[this.state.selectedRowIndex].reviewedInvitesCount}</span></button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "evaluated" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "evaluated", itemOffsetInvites: 0, buttonbadgeclass: "evaluated" }); this.invitesFilteration("evaluated") }}>Evaluated <span className={`${this.state.buttonbadgeclass === "evaluated" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}>{currentItems[this.state.selectedRowIndex].evaluatedInvitesCount} </span> </button>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <button className={`nav-link invite-link ${this.state.activeFilterInvite === "expired" ? "actived" : ""}`} onClick={() => { this.setState({ activeFilterInvite: "expired", itemOffsetInvites: 0, buttonbadgeclass: "expired" }); this.invitesFilteration("expired") }}>Expired <span className={`${this.state.buttonbadgeclass === "expired" ? "badge bg-light text-black" : "badge bg-secondary text-white"}`}>{currentItems[this.state.selectedRowIndex].expiredInvitesCount} </span> </button>
                                                                    </li>
                                                                </ul>


                                                            </div>
                                                            <div className="col-md-auto d-flex align-items-center  mx-2">
                                                                <img className="" src={Refresh} alt="Image Description" data-hs-theme-appearance="default" data-tip data-for="refreshIcon"
                                                                    onClick={() => {
                                                                        this.ClearInterviewsCache();
                                                                        this.setState({
                                                                            activeFilterInvite: "all", isRefreshed: true, buttonbadgeclass: "all",

                                                                        });
                                                                        this.invitesFilteration("all");
                                                                    }}
                                                                    style={{ height: "2rem", cursor: "pointer", padding: "5px" }}

                                                                />

                                                                <ReactTooltip id="refreshIcon" place="top" type="dark" effect="float">
                                                                    <span> Refresh Interview Invites </span>
                                                                </ReactTooltip>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                              
                                                <div className="row m-2 mt-0 mb-3 g-0  px-2">

                                                    {currentItems.length !== 0 && (
                                                    <div className="col-md-4 input-group input-group-merge  ">
                                                       
                                                            <>
                                                            <div className="input-group-prepend input-group-text">
                                                                <i className="bi-search"></i>
                                                            </div>
                                                            <input type="search" className="form-control search-bar-focus"
                                                                placeholder="Search applicants by name or email..."
                                                                onChange={(e) => {
                                                                    if (e.target.value === "") {
                                                                        this.setState({ searchedApplicant: "" },
                                                                            () => {
                                                                                this.invitesFilteration(this.state.activeFilterInvite);
                                                                            }
                                                                        );
                                                                    }
                                                                }}

                                                                onKeyDown={(event) => {
                                                                    if (event.key === 'Enter') {
                                                                        this.setState({ searchedApplicant: event.target.value },
                                                                            () => {
                                                                                this.invitesFilteration(this.state.activeFilterInvite);
                                                                            })
                                                                        event.preventDefault();

                                                                    }
                                                                }}
                                                                />
                                                            </>
                                                       
                                                        </div>
                                                    )}
                                                   
                                                        <div className="col-md-8  d-flex justify-content-end">
                                                            {this.state.interviewInviteIdFromQueryString && (
                                                                <div className="filtered-div-interview-invites f-size-14 mx-1 ">
                                                                    {this.state.applicantNameCaughtFromQueryString}
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '8px' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState(
                                                                                {
                                                                                    interviewInviteIdFromQueryString: "",
                                                                                    interviewIdFromQueryString: "",
                                                                                },
                                                                                () => {
                                                                                    this.invitesFilteration(this.state.activeFilterInvite);
                                                                                    this.removeInterviewIdAndinterviewInviteId()
                                                                                }
                                                                            );
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>

                                               
                                              
                                                {filteredInvites.length !== 0 && currentItems.length !== 0 && (
                                                    <div className="table-responsive card m-3 mb-4 pb-4 mt-0 datatable-custom" >

                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table " style={{ width: '100%' }}  >
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th  ><strong>Applicant  </strong></th>
                                                                    <th  ><strong>Invite Date </strong></th>
                                                                    <th ><strong>Expiry Date </strong></th>
                                                                    <th ><strong>Status </strong></th>
                                                                    <th  ><strong>Review </strong></th>
                                                                    <th  ><strong>Evaluate </strong></th>
                                                                    <th  ><strong>Summary </strong></th>


                                                                </tr>

                                                            </thead>

                                                            <tbody>


                                                                {filteredInvites.map(interviewInvite => (
                                                                    <tr key={interviewInvite.interviewInviteId}  >
                                                                        <td >
                                                                            <span data-tip data-for={"candidateName" + interviewInvite.interviewInviteId}> {interviewInvite.firstName} {interviewInvite.lastName}</span>
                                                                            <ReactTooltip id={"candidateName" + interviewInvite.interviewInviteId} place="top" type="dark" effect="float">
                                                                                <span className="d-block fs-5">{interviewInvite.candidateEmail}</span>
                                                                            </ReactTooltip>

                                                                        </td>
                                                                        <td>{convertUTCDateToBrowserLocale(interviewInvite.inviteRequestedDate)}</td>
                                                                        <td>{convertUTCDateToBrowserLocale(interviewInvite.inviteExpiryDate)}</td>
                                                                        <td>
                                                                            {
                                                                                interviewInvite.inviteStatus === "Accepted" &&

                                                                                <span class="review-interviews-status status-accepted f-size-12 inter-ff f-weight-500 ">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Reviewed" &&

                                                                                <span class="review-interviews-status status-reviewed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Requested" &&

                                                                                <span class="review-interviews-status status-requested f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Evaluated" &&

                                                                                <span class="review-interviews-status status-evaluated f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Completed" &&

                                                                                <span class="review-interviews-status status-completed f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Rescheduled" &&

                                                                                <span class="review-interviews-status status-rescheduled f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                            {
                                                                                interviewInvite.inviteStatus === "Expired" &&

                                                                                <span class="review-interviews-status status-expired f-size-12 inter-ff f-weight-500">{interviewInvite.inviteStatus}</span>
                                                                            }
                                                                        </td>
                                                                        <td>


                                                                            {interviewInvite.inviteStatus === "Completed" || interviewInvite.inviteStatus === "Reviewed" ?
                                                                                <>

                                                                                    <button class="btn btn-primary review-enable" data-tip data-for='reviewInterivew'
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                reviewInterviewModal: true,
                                                                                                setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                setInterviewId: interviewInvite.interviewId,
                                                                                                isRefreshed: true,

                                                                                            })
                                                                                            document.body.classList.add('modal-open');

                                                                                        }}
                                                                                    ><i className="bi bi-clipboard-check me-1 f-size-12"></i><span className="f-size-12">Review</span></button>
                                                                                    <ReactTooltip id="reviewInterivew" place="top" type="dark" effect="float">
                                                                                        <span> Review Interview </span>
                                                                                    </ReactTooltip>

                                                                                </>
                                                                                :
                                                                                <button class="btn btn-primary disabled-btn" disabled> <i className="bi bi-clipboard-check me-1 f-size-12"></i><span className="f-size-12">Review</span></button>
                                                                            }



                                                                        </td>
                                                                        <td>

                                                                            {interviewInvite.inviteStatus === "Reviewed" && this.state.editReviewPermission ?
                                                                                <>

                                                                                    <button class="btn btn-primary evaluate-enable" data-tip data-for='evaluateInterview'
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                evaluateInterviewModal: true,
                                                                                                setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                setInterviewId: interviewInvite.interviewId
                                                                                            })
                                                                                            document.body.classList.add('modal-open');

                                                                                        }}
                                                                                    ><i className="bi bi-person-check me-1 "></i><span className="f-size-12">Evaluate</span></button>
                                                                                    <ReactTooltip id="evaluateInterview" place="top" type="dark" effect="float">
                                                                                        <span> Evaluate Interview </span>
                                                                                    </ReactTooltip>

                                                                                </>
                                                                                :
                                                                                <button class="btn btn-primary disabled-btn" disabled> <i className="bi bi-person-check me-1 "></i><span className="f-size-12">Evaluate</span></button>
                                                                            }
                                                                        </td>
                                                                        <td>

                                                                            {interviewInvite.inviteStatus === "Reviewed" || interviewInvite.inviteStatus === "Evaluated" ?
                                                                                <>

                                                                                    <button class="btn btn-primary d-flex align-items-center summary-enable " data-tip data-for='summaryOfInterview'
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                summaryInterviewModal: true,
                                                                                                setInterviewInviteId: interviewInvite.interviewInviteId,
                                                                                                setInterviewId: interviewInvite.interviewId
                                                                                            })
                                                                                            document.body.classList.add('modal-open');
                                                                                        }}

                                                                                    ><span class="material-symbols-outlined f-size-16 me-1">
                                                                                            summarize
                                                                                        </span>
                                                                                        <span className="f-size-12">Summary</span></button>
                                                                                    <ReactTooltip id="summaryOfInterview" place="top" type="dark" effect="float">
                                                                                        <span> Summary of Interview </span>
                                                                                    </ReactTooltip>

                                                                                </>
                                                                                :
                                                                                <button class="btn btn-primary disabled-btn d-flex align-items-center" disabled> <span class="material-symbols-outlined f-size-16 me-1">
                                                                                    summarize
                                                                                </span>
                                                                                    <span className="f-size-12">Summary</span>
                                                                                </button>
                                                                            }
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                )}
                                                {this.state.filteredInvites.length === 0 && this.state.rowClicked && currentItems.length > 0 && (
                                                    <div className="card card-centered m-3 mt-1 mb-3 mb-lg-5">
                                                        <div className="card-body py-10">
                                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                            <div className="text-center">

                                                                <h1>No Interview Invites found. </h1>

                                                            </div>

                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.filteredInvites.length > 0 && currentItems.length > 0 && (
                                                    <div className="card-footer m-3 " >
                                                        <div className="d-flex justify-content-center">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClickInvites}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCountInvites}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination mb-0'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                                initialPage={0}
                                                                forcePage={this.state.itemOffsetInvites / 10} // Force the active page to reset to the current offset
                                                            />
                                                        </div>

                                                    </div>
                                                )}
                                                </div>
                                                {this.state.IsInterviewLoading > 0 && (
                                                    <div class="overlay">
                                                        <div className="overlay-content">
                                                            <Oval
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                color="#4fa94d"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {/*<!-- End Footer --> */}

                                            </div>

                                        </div>
                                    </div>

                                    {/*Mobile start here*/}
                                    <div className="row justify-content-center align-items-center g-0 p-0 mt-1 d-lg-none">
                                        <div className="col-md-12 d-flex d-lg-none mb-2" style={{ paddingRight: '8px' }}>
                                            <div className="col" style={{ marginRight: '.6rem' }}>
                                                <div className="col">

                                                </div>
                                            </div>
                                            <button className="btn  no-border py-2 me-2 " style={{ backgroundColor: '#3FB5C3' }}
                                                onClick={() => {
                                                    this.setState({ interviewsFilterModal: true })
                                                    document.body.classList.add('modal-open');

                                                }}
                                            >
                                                <i className="bi bi-funnel" style={{ fontSize: '1rem', color: 'white' }}></i>
                                            </button>

                                        </div>
                                        <div class="d-flex  mb-1 filter-chips-mb d-lg-none">
                                            {this.state.showFilterInterviewType && (
                                                <div className="filtered-div-mb  f-size-14 mx-1">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <span className="filter-overflow " >
                                                            {this.state.showFilterInterviewType === "oneway" ? "Closed Interview" : this.state.showFilterInterviewType === "onewayopen" ? "Open Interview" : this.state.showFilterInterviewType === "live" ? "Live Interview" : ""}
                                                        </span>
                                                        <button
                                                            type="button"
                                                            className="btn-close f-size-12"
                                                            aria-label="Close"
                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                            onClick={(e) => {
                                                                this.setState({ showFilterInterviewType: '', filterInterviewType: "" },
                                                                    () => { filteration(); }
                                                                )
                                                            }}

                                                        ></button>
                                                    </div>
                                                </div>


                                            )}
                                            {this.state.showStartFilterDate && (
                                                <div className="filtered-div-mb  f-size-14 mx-1">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <span className="filter-overflow " >
                                                            {this.state.showStartFilterDate.toDateString()}, {this.state.showEndFilterDate.toDateString()}
                                                        </span>
                                                        <button
                                                            type="button"
                                                            className="btn-close f-size-12"
                                                            aria-label="Close"
                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                            onClick={(e) => {
                                                                this.setState({ showStartFilterDate: null, startFilterDate: null, showEndFilterDate: null, endFilterDate: null },
                                                                    () => { filteration(); }
                                                                )
                                                            }}

                                                        ></button>
                                                    </div>
                                                </div>


                                            )}




                                        </div>

                                        <div className=" col-md-12">
                                            <div className="card border-0 jc-radius pb-3">
                                                <table className="table jobs-overview-table">
                                                    <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                        <tr>
                                                            <th scope="col " className="p-3" colSpan={3}>
                                                                <span className="f-size-16 f-weight-600" style={{ color: "#101828" }}>
                                                                    {this.state.jobTitle}
                                                                </span>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {currentItems.length > 0 && currentItems.map((interview, index) => (

                                                            <tr key={index}>
                                                                <td className="p-3">
                                                                    <div className="d-flex align-items-center mb-0">
                                                                        <div className="col-8 ">

                                                                            <div className=" align-items-center">
                                                                                <div>
                                                                                    <h2 className="inter-ff f-size-14 f-weight-500 t-overflow">{this.state.jobTitle}</h2>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3 mb-2 align-middle d-flex gap-0 justify-content-end offset-1">

                                                                            <Link className="btn btn-outline-primary btn-icon btn-sm me-2" id={"editReview" + index} data-tip data-for={"editReview" + index}>
                                                                                <i class="bi bi-pencil-square" style={{ cursor: 'pointer' }} onClick={async () => {
                                                                                    await this.getReviewers(interview.jobId, interview.interviewId);

                                                                                }}></i>
                                                                            </Link>



                                                                            <Link className="btn btn-outline-primary btn-icon btn-sm me-2" to={`/interviewdetails/${interview.interviewId}`} id={"interviewDetails" + index} data-tip data-for={"interviewDetails" + index}>
                                                                                <i class="bi bi-info-square" style={{ cursor: 'pointer' }}></i>
                                                                            </Link>


                                                                            <div>


                                                                                {interview.openInterviewInvite != null &&
                                                                                    <div id={"copyLink" + index} data-tip data-for={"copyLink" + index} className=" flex-nowrap btn btn-outline-primary btn-icon btn-sm me-2">
                                                                                        <i className="bi bi-clipboard-fill " style={{ cursor: 'pointer' }} onClick={(e) => this.invitationLinkCopy(e, interview.interviewId)}></i>
                                                                                        <input type="text" className="form-control" value={interview.openInterviewInvite} id={interview.interviewId} hidden />
                                                                                        <ReactTooltip id={"copyLink" + index} place="right" type="dark" effect="float">
                                                                                            <span className="d-block fs-5">Copy open interview link</span>
                                                                                        </ReactTooltip>
                                                                                    </div>
                                                                                }
                                                                                {interview.isAutoScheduled ?
                                                                                    <><img src={AutoInterview} style={{ cursor: 'pointer', width: '2rem', height: '2rem' }} id={"isAutoInterviewSchedule" + index} data-tip data-for={"isAutoInterviewSchedule" + index}></img>
                                                                                        <ReactTooltip id={"isAutoInterviewSchedule" + index} place="right" type="dark" effect="float">
                                                                                            {interview.isAutoScheduled ?
                                                                                                "Automatic interview is scheduled" :
                                                                                                "No automatic interview is scheduled"
                                                                                            }
                                                                                        </ReactTooltip>
                                                                                    </>
                                                                                    : null}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12 ">
                                                                                    note_alt
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Interview Type:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {interview.interviewType === "oneway" &&
                                                                                        <span>Closed Interview</span>
                                                                                    }
                                                                                    {interview.interviewType === "onewayopen" &&
                                                                                        <span>Open Interview</span>
                                                                                    }
                                                                                    {interview.interviewType === "live" &&
                                                                                        <span>Live Interview</span>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    calendar_clock
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Created Date:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {convertUTCDateToBrowserLocale(interview.createdDate.toString())}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    event_busy
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Expiry Date:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {convertUTCDateToBrowserLocale(interview.expiryDate.toString())}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className=" mb-1 ">
                                                                        <div className="row g-0">
                                                                            <div className="col-5 d-flex align-items-center ">
                                                                                <span class="material-symbols-outlined f-size-12">
                                                                                    airplanemode_active
                                                                                </span>
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter mx-1 ">
                                                                                    Interview Status:
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-7 align-items-center ">
                                                                                <p className="f-size-11  mb-0 f-gray-2 f-weight-400 f-inter  text-overflow-leads">
                                                                                    {interview.isActive ?

                                                                                        <div className="bg-success-icon-mb  f-size-10 inter-ff f-weight-500  justify-content-center d-flex align-items-center">
                                                                                            Active
                                                                                        </div> :
                                                                                        <div className="bg-danger-icon-mb  f-size-10  inter-ff f-weight-500  justify-content-center d-flex align-items-center">
                                                                                            Inactive
                                                                                        </div>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>


                                                {/*<!-- Footer --> */}
                                                {currentItems.length === 0 ? (
                                                    <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                        <div className="card-body py-10">
                                                            <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                            <div className="text-center">

                                                                <h1>No search results were found </h1>
                                                                <p>Please refine your search</p>

                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : (
                                                        <div className="card-footer" style={{ display: "none" }}>
                                                        <div className="d-flex justify-content-center">
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                renderOnZeroPageCount={null}
                                                                breakClassName={'page-item'}
                                                                breakLinkClassName={'page-link'}
                                                                containerClassName={'pagination mb-0'}
                                                                pageClassName={'page-item'}
                                                                pageLinkClassName={'page-link'}
                                                                previousClassName={'page-item'}
                                                                previousLinkClassName={'page-link'}
                                                                nextClassName={'page-item'}
                                                                nextLinkClassName={'page-link'}
                                                                activeClassName={'active'}
                                                            />
                                                        </div>

                                                    </div>
                                                )}
                                                {/*<!-- End Footer --> */}
                                            </div>

                                        </div>
                                    </div>

                                    {/*Mobile end here*/}

                                </>
                                : <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>No interviews found.</h1>
                                        </div>

                                    </div>
                                </div>
                            )

                        }



                    </>
                }

                {/*<!--End Card--> */}

                {/* <!-- End Content --> */}

                {
                    this.state.editReviewers && <div id="ModalCenteredScrollable" className="modal d-block " tabindex="-1" role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                            <div className="modal-content" style={{ backgroundColor: '#F6F4F2' }} >
                                <div className="modal-header">
                                    <h4 className="modal-title whiteColor" id="exampleModalCenteredScrollableTitle">Edit Reviewers</h4>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                        this.setState({ editReviewers: false })
                                        document.body.classList.remove('modal-open');
                                    }}></button>
                                </div>
                                <div className="modal-body" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                    <div className="table-responsive datatable-custom w-100">
                                        <table className="js-datatable-checkboxes table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table" data-hs-datatables-options='{ "columnDefs": [{ "targets": [0], "orderable": false }], "order": [] }'>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th
                                                    >
                                                        Sr no
                                                    </th>
                                                    <th
                                                    >
                                                        Select
                                                    </th>
                                                    <th
                                                    >
                                                        Reviewer Email
                                                    </th>




                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.interviewReviewers.map((interviewreviewer, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td >

                                                                    <input type="checkbox"
                                                                        className="form-checkbox cursor-pointer"
                                                                        name="interviewreviewer"
                                                                        id={index}
                                                                        defaultChecked={interviewreviewer.isPresent}
                                                                        value={interviewreviewer.reviewer}
                                                                        onChange={e => {
                                                                            onRowSelectforinterviewreviewersonewayclose(interviewreviewer, e);

                                                                        }
                                                                        }

                                                                    />

                                                                </td>

                                                                <td >
                                                                    {interviewreviewer.reviewerUsername}

                                                                </td>



                                                            </tr>
                                                        )

                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={this.updateReviewers}>Update</button>
                                    <button type="button" className="btn btn-white" data-bs-dismiss="modal" onClick={() => {
                                        this.setState({
                                            editReviewers: false
                                        })
                                        document.body.classList.remove('modal-open');

                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }



                {/*Review Interview Modal start here*/}

                {this.state.reviewInterviewModal && (
                    <div className="modal d-block" id="reviewInterviewModal" tabIndex="-1">
                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Review Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({ reviewInterviewModal: false })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>

                                </div>
                                <div className="modal-body pt-3">

                                    <ReviewInterviewDetailsComponent interviewId={this.state.setInterviewId} jobId={this.props.jobId} interviewInviteId={this.state.setInterviewInviteId} getJobInterviews={this.getJobInterviews} handleEvaluateModalOpen={this.handleEvaluateModalOpen}></ReviewInterviewDetailsComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Review Interview Modal end here*/}



                {/*Evaluate Interview Modal Starts here*/}

                {this.state.evaluateInterviewModal && (

                    <div className="modal d-block" id="evaluateInterviewModal" tabIndex="-1">

                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Evaluate Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({ evaluateInterviewModal: false }, () => {
                                                this.getJobInterviews(this.props.jobId)

                                            } )
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>
                                </div>
                                <div className="modal-body pt-3">
                                    <EvaluateInterviewComponent interviewId={this.state.setInterviewId} interviewInviteId={this.state.setInterviewInviteId} closeEvaluateInterviewModal={this.closeEvaluateInterviewModal} jobId={this.props.jobId} getJobInterviews={this.getJobInterviews}></EvaluateInterviewComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Evaluate Interview Modal end here*/}

                {/*Summary Interview Modal start here*/}



                {this.state.summaryInterviewModal && (
                    <div className="modal d-block" id="summaryInterviewModal" tabIndex="-1">

                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Review Summary Of Interview</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({ summaryInterviewModal: false })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>
                                </div>

                                <div className="modal-body pt-3">
                                    <ReviewSummaryComponent interviewId={this.state.setInterviewId} interviewInviteId={this.state.setInterviewInviteId} ></ReviewSummaryComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Summary Interview Modal end here*/}

                {/*Interview Detail Modal start here*/}

                {this.state.interviewDetailsModal && (
                    <div className="modal d-block" id="summaryInterviewModal" tabIndex="-1">

                        <div className=" modal-dialog-scrollable set-modal-size">
                            <div className="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title text-white" id="exampleModalLabel">Interview Detail</h5>

                                    <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                        onClick={() => {
                                            this.setState({ interviewDetailsModal: false })
                                            document.body.classList.remove('modal-open');

                                        }}>
                                    </button>
                                </div>

                                <div className="modal-body pt-3">
                                    <InterviewDetailsComponent interviewId={this.state.interviewIdForDetailPage} ></InterviewDetailsComponent>

                                </div>
                            </div>
                        </div>
                    </div>


                )}
                {/*Interview Detail Modal end here*/}
                {/* <!-- Interviews filter Modal start here--> */}

                {this.state.interviewsFilterModal &&
                    <div className="d-block modal right " id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog" aria-hidden="true">
                        <div className="modal-dialog filter-modal-width jobs-filter-modal-dialog">
                            <div className="modal-content jobs-filter-modal no-border">
                                {/* Header */}
                                <div className="modal-header no-border p-3 pt-4 pb-4 d-flex align-items-center" style={{ backgroundColor: '#F0F2F7' }}>
                                    <h1 className="f-size-24 f-weight-700">Interviews Filters</h1>
                                    <button type="button" className="btn-close" aria-label="Close " onClick={() => {
                                        this.setState({ interviewsFilterModal: false, filterInterviewType: this.state.showFilterInterviewType, startFilterDate: this.state.showStartFilterDate, endFilterDate: this.state.showEndFilterDate })
                                        document.body.classList.remove('modal-open');


                                    }}></button>
                                </div>
                                {/* End Header */}
                                {/* Body */}
                                <div className="modal-body">



                                    <div className="row mb-4 g-0">
                                        <label htmlFor="created-date" className="f-gray f-weight-600 f-size-14 mb-3">
                                            INTERVIEW TYPE
                                        </label>
                                        {/* <!-- Select --> */}
                                        <div className="tom-select-custom">
                                            <select className="form-select"
                                                value={this.state.filterInterviewType}
                                                onChange={(e) => {
                                                    this.setState({ filterInterviewType: e.target.value })
                                                }}>
                                                <option key="0" value="" >Select Interview Type</option>

                                                <option key="1" value="oneway">Closed Interview</option>
                                                <option key="2" value="onewayopen">Open Interview</option>
                                                <option key="3" value="live">Live Interview</option>

                                            </select>

                                            {/* <!-- End Select --> */}


                                        </div>
                                    </div>

                                    <div className="row mb-4 g-0">
                                        <label htmlFor="created-date" className="f-gray f-weight-600 f-size-14 mb-3">
                                            CREATED DATE
                                        </label>
                                        <div className="d-flex gap-2">
                                            <div className="col">
                                                <label htmlFor="from-date" className="f-gray f-weight-600 f-size-12 mb-1">
                                                    FROM
                                                </label>
                                                <DatePicker
                                                    selected={this.state.startFilterDate}
                                                    onChange={(date) => this.setState({ startFilterDate: date, endFilterDate: date })}
                                                    selectsStart
                                                    startDate={this.state.startFilterDate}
                                                    endDate={this.state.endFilterDate}
                                                    customInput={<input className="form-control"></input>}
                                                    placeholder="From Create Date"

                                                />
                                            </div>
                                            <div className="col">
                                                <label htmlFor="to-date" className="f-gray f-weight-600 f-size-12 mb-1">
                                                    TO
                                                </label>
                                                <DatePicker
                                                    selected={this.state.endFilterDate}
                                                    onChange={(date) => this.setState({ endFilterDate: date })}
                                                    selectsEnd
                                                    startDate={this.state.startFilterDate}
                                                    endDate={this.state.endFilterDate}
                                                    minDate={this.state.startFilterDate}
                                                    customInput={<input className="form-control"></input>}
                                                    placeholder="To Create Date"

                                                />
                                            </div>
                                        </div>
                                    </div>




                                    <div className="d-flex justify-content-end gap-4 mb-3">
                                        <button type="button" className="btn filter-clear-btn" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                this.setState({
                                                    filter: "",
                                                    startFilterDate: null,
                                                    endFilterDate: null,
                                                    filterInterviewType: "",
                                                    showFilterInterviewType: "",
                                                    showStartFilterDate: null,
                                                    showEndFilterDate: null,
                                                }, () =>
                                                    this.setState({ filteredData: interviews })
                                                );
                                            }}
                                        >
                                            Clear
                                        </button>
                                        <button type="button" className="btn btn-primary f-size-14 f-weight-700" data-bs-dismiss="modal" aria-label="Close"

                                            onClick={(event) => {
                                                event.preventDefault();

                                                if (this.state.startFilterDate != null) {
                                                    if (this.state.endFilterDate == null) {
                                                        displayWarningMessage('Please select end date !', "top-center");
                                                        return;
                                                    }
                                                }
                                                if (this.state.endFilterDate != null) {
                                                    if (this.state.startFilterDate == null) {
                                                        displayWarningMessage('Please select start date !', "top-center");
                                                        return;
                                                    }
                                                }
                                                if (this.state.startFilterDate > this.state.endFilterDate) {
                                                    displayErrorMessage("To date should be equal or greater than From date", "top-center");
                                                    return
                                                }
                                                this.setState({ interviewsFilterModal: false, showFilterInterviewType: this.state.filterInterviewType, showStartFilterDate: this.state.startFilterDate, showEndFilterDate: this.state.endFilterDate, })
                                                document.body.classList.remove('modal-open');

                                                filteration();
                                            }}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                {/* End Body */}
                            </div>
                        </div>
                    </div>

                }
                {/*Candidates filter modal end here*/}

            </>
        );
    }
} export default withRouter(JobInterviewsComponent);