import React from 'react';
import BaseComponent from "../Base/BaseComponent";
import { verifyTokenOnLocal } from '../../modules/TokenHelper';
import { validateAndGetFormatedToken, isActiveAccessToken } from '../../modules/CheckToken';


import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../modules/ToastMessage';
import { parseToken, parseJwt } from '../../modules/TokenParser';

import { CheckPermission } from '../../modules/CheckPermission';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Empty from '../../assets/svg/illustrations/oc-browse.svg'
import BeatLoaderComponent from "../Loader/BeatLoader";
import { ToastContainer } from 'react-toastify';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import ReactTooltip from 'react-tooltip';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "../DataPicker/index"
import "react-datepicker/dist/react-datepicker.css";
import AutoInterview from "../../assets/img/others/auto_interview_jobs.png";
import { convertUTCDateToBrowserLocale } from '../../modules/ConvertUTCDateToBrowserLocale';
import Applicants from "../Leads/Applicants"
import Refresh from '../../assets/svg/illustrations/gui-refresh.svg'
import { Oval } from 'react-loader-spinner'

class Jobs extends BaseComponent {

    constructor() {
        super();

        this.state = {
            AllJobs: [],
            AllJobsPrototype: [],
            jobDdlDataSource: [],
            viewjobdetails: false,
            selectJob: {},
            editJobPermission: true,
            createJobPermission: true,
            createInterviewPermission: true,
            isloadedgetJobs: true,
            filterModal: false,
            selectedJob: "",
            selectedJobName: "",
            filter: "",
            filterText: '',
            //subscription feature contraint
            sfc_AllowedJobsCount: 0,
            subscriptionFeatureKeys: [],
            selectedIndustry: "",
            filterColumn: "",
            filterTextJobStatus: "",
            filterTextTitle: "",
            filterTextManagerName: "",
            filterTextIndustry: "",
            hideFilteration: false,
            jobHeaders: [
                {
                    label: "Job Name", key: "title"
                },
                {
                    label: "Job Manager Name", key: "managerName"
                },
                {
                    label: "Job Manager Email", key: "jobManager"
                },
                {
                    label: "Location", key: "location"
                },
                {
                    label: "Job Visibility Level", key: "jobVisibilityLevel"
                },
                {
                    label: "Job Status", key: "status"
                }
            ],
            order: "DSC",
            filteredData: [],
            resultedData: [],
            itemOffset: 0,
            quickActionModal: false,
            isAutoInterviewSchedule: "no",
            isAutomatedInterviewScheduling: false,
            isAutomatedInterviewSchedulingCopy: false,
            jobIsActive: false,
            interviewExpiryDate: null,
            interviewExpiryDateCopy: null,
            interviewExtensionDateCopy: null,
            extensionDate: null,
            interviewExtensionDateNew: null,
            interviewExtensionDate: null,
            isAutoInterviewScheduleForModal: false,
            selectedJobId: -1,
            selectedInterviewId: -1,
            startFilterDate: null,
            endFilterDate: null,
            states: [],
            cities: [],
            countries: [],
            country: "",
            stateSelected: false,
            countrySelected: false,
            filterTextJoblocation: "",
            filterJobStatus: "",
            existingInterviewExpiryDate: null,
            existingInterviewExtensionDate: null,
            jobsFilterModal: false,
            activeJobChecked: false,
            inactiveJobChecked: false,
            showFilterDetails: false,
            showSelectedJob: '',
            showStartFilterDate: null,
            showEndFilterDate: null,
            showCity: '',
            showStateSelected: '',
            searchedJobTitle: "",
            showActiveJobChecked: false,
            showInactiveJobChecked: false,
            showSelectedCountry: "",
            showCountryValue: "",
            selectedCountryValue: "",
            showIsCountrySelected: false,
            selectedStateValue: "",
            showStateValue: "",
            activeJobWidget: "total",
            topFilteredData: [],
            applicantsModal: false,
            storedJobId: "",
            loggedUser: [],
            loggedUserEmail: "",
            IsJobLoading: 0
        }

        //Filter Code Snippet#1
        this.input = React.createRef();
        //this.handleSubmit = this.handleSubmit.bind(this);

        // this.JobSelectHandleChange = this.JobSelectHandleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }


    //Page_load

    async componentDidMount() {
        if (verifyTokenOnLocal()) {
            await isActiveAccessToken();
            document.title = "Tenant App - Jobs";
            document.body.classList.remove('modal-open');
            this.getLoggedUserProfile()
            this.getIndustrieslookup();
            this.getTenantUsers();
            this.getJobStatusLookup();

            this.getTenantSubscriptionid();
            this.checkEditJobPermission();
            this.getJobs();
            this.getSubscriptionFeaturesConstants();
            this.getCountries();

        }
    }



    async getLoggedUserProfile() {
        var url = null;
        var subId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            subId = parseJwt(bearer).sub;
            url = process.env.REACT_APP_CORE_API + "/api/UserProfileName?userId=" + subId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({ loggedUserEmail: response.data.email })
                    console.log("view email", response.data.email)
                })
                .catch(err => {
                    console.error("Unable to fetch email of tenant == >" + err);
                    displayErrorMessage('Error while Fetching email of tenant!', "top-center");
                });
        }
    }

    /** Check Permission for updating job */

    async checkEditJobPermission() {
        let bearer = validateAndGetFormatedToken();
        var editJobPermission = false;
        var createJobPermission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                editJobPermission = await CheckPermission(parseToken(bearer).roles, "Permissions.Job.Edit", bearer);
                this.setState({ editJobPermission: editJobPermission });

                createJobPermission = await CheckPermission(parseToken(bearer).roles, "Permissions.Job.Create", bearer);
                this.setState({ createJobPermission: createJobPermission });

            }
        }
    }


    async checkCreateInterviewPermission() {
        let bearer = validateAndGetFormatedToken();
        var permission = false;
        if (bearer != "" || bearer != null) {
            if (parseToken(bearer).roles != "TenantAdmin") {

                permission = await CheckPermission(parseToken(bearer).roles, "Permissions.Interview.Create", bearer);
                this.setState({ createInterviewPermission: permission });
            }
        }
    }

    /** Get the active user list of this tenant */
    getTenantUsers() {
        var url = null;
        var tenantId = null;

        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_IDENTITY_API + "/Account/GetTenantActiveUsers?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        tenantUsersList: response.data,

                    });

                })
                .catch(err => {
                    console.error("Unable to load data of dropdown list == >" + err);
                    displayErrorMessage('Error while Fetching load data of dropdown list!', "top-center");

                });


        }

    }

    /**
     * Industries from look up*/
    getIndustrieslookup() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            url = process.env.REACT_APP_CORE_API + "/api/TenantIndustry/" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data != null) {
                        this.setState({
                            industries: response.data,
                        });
                    }
                    else
                        console.error("Unale to get industries");
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Industy lookup!', "top-center");

                });
        }

    }


    /**
     * Get job statuses*/
    getJobStatusLookup() {

        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/JobStatusLookup";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {

                    this.setState({
                        jobStatuses: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Job Status lookup!', "top-center");

                });
        }
    }

    /** Get all jobs of tenant */
    async getJobs() {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/Jobs";
            this.setState({ IsJobLoading: 1 });
            await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(async response => await response.json())
                .then(async result => {
                    let jobsListItems = [];
                    let tempJobs = [];
                    if (result.length > 0) {
                        await result.forEach(async (jobObject) => {
                            jobsListItems.push({ value: jobObject.jobId, label: jobObject.title });
                            jobObject.createdDate = convertUTCDateToBrowserLocale(jobObject.createdDate);
                            tempJobs.push(jobObject);
                        });

                        this.setState({
                            AllJobs: tempJobs,
                            filteredData: tempJobs,
                            topFilteredData: tempJobs,
                            jobDdlDataSource: jobsListItems,
                            isloadedgetJobs: false
                        })
                    }
                    else {
                        this.setState({
                            AllJobs: [],
                            filteredData: [],
                            topFilteredData: [],
                            jobDdlDataSource: [],
                            isloadedgetJobs: false
                        })

                    }

                    this.setState({ IsJobLoading: 0 });
                })
                .catch(err => {
                    this.setState({
                        AllJobs: [],
                        filteredData: [],
                        jobDdlDataSource: [],
                        isloadedgetJobs: false,
                        IsJobLoading: 0
                    })
                    console.log(err);
                    displayErrorMessage('Error while fetching jobs!', "top-center");
                });
        }
    }

    /** Clear Jobs Cache */
    ClearJobsCache() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + "/api/CacheClear/JobsCacheClear";
            const requestOptions = {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            };

            fetch(url, requestOptions)

                .then((response) => response.json())
                .then((result) => {
                    this.getJobs();
                })
                .catch((error) => {
                    console.log("error during ClearJobsCache", error);
                });
        }
    }

    /**
     * Get Subscription Features
     * ke values like Key:Value
     */
    getSubscriptionFeaturesConstants() {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/SubscriptionFeatureKeys";

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    console.log("subscriptionFeatureKeys", response);
                    this.setState({
                        subscriptionFeatureKeys: response.data,
                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Subscription Feature Keys!', "top-center");

                });
        }
    }


    /**
    * Get the tenant subscription id 
    */
    getTenantSubscriptionid() {
        var url = null;
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();
        let tenantSubscriptionId = 0;
        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;

            url = process.env.REACT_APP_IDENTITY_API + "/TenantSubscription/GetTenantSubscriptions?tenantId=" + tenantId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.length > 0) {
                        this.setState({ tenantSubscriptionId: response.data[0].tenantSubscriptionId });
                        tenantSubscriptionId = response.data[0].tenantSubscriptionId;
                    }
                }).then(() => {
                    this.getTenantSubscriptionData(tenantSubscriptionId, "Jobs");
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Id', "top-center");

                });
        }
    }

    /**
     * Get the tenant subscriotion feature key value 
     * @param {number} tenantSubscriptionId
     * @param {string} featureKey
     */
    getTenantSubscriptionData(tenantSubscriptionId, featureKey) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/TenantSubscriptionData/" + tenantSubscriptionId + "/" + featureKey;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let tenantSubscriptionData = response.data;
                    let jobsCount = parseInt(tenantSubscriptionData.value);
                    this.setState({
                        sfc_AllowedJobsCount: jobsCount,

                    });

                })

                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching  Tenant Subscription Data!', "top-center");

                });
        }
    }

    /** Get the job quick action things*/
    getJobQuickSettings(jobId) {
        var url = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            url = process.env.REACT_APP_CORE_API + "/api/AutoJob/" + jobId;

            axios(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.setState({
                        isAutoInterviewSchedule: response.data.interviewIsActive ? "yes" : "no",
                        jobIsActive: response.data.jobIsActive,
                        isAutomatedInterviewScheduling: response.data.isAutoInterviewSchedule,
                        isAutomatedInterviewSchedulingCopy: response.data.isAutoInterviewSchedule,
                        interviewExpiryDate: new Date(response.data.expiryDate),
                        interviewExpiryDateCopy: new Date(response.data.expiryDate),
                        interviewExtensionDateCopy: new Date(response.data.extensionDate),
                        interviewExtensionDate: new Date(response.data.extensionDate),
                        interviewExtensionDateNew: new Date(response.data.extensionDate),

                        selectedInterviewId: response.data.interviewId,
                        existingInterviewExpiryDate: new Date(response.data.expiryDate),
                        existingInterviewExtensionDate: new Date(response.data.extensionDate)
                    });
                })
                .catch(err => {
                    console.error("Unable to fecth quick setting" + err);
                    displayErrorMessage('Error while Fetching fecth quick setting!', "top-center");

                });
        }

    }

    //Update Auto Job status
    updateAutoJobStatus(jobId, interviewId, isAuto) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });

            let url = process.env.REACT_APP_CORE_API + '/api/AutoJob';
            let inputModel = {};
            inputModel.JobId = jobId;
            inputModel.IsAutoInterviewSchedule = isAuto;
            inputModel.InterviewId = interviewId;

            axios.put(url, inputModel, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then(response => {


                this.getJobs();

                //window.location.reload();


            }).catch((error) => {
                console.log("ERROR ===> update job status error ==>", error.response);
                displayErrorMessage(error.response.data, "top-center");

            });

        }
    }

    //Auto interview status
    updateAutoInterviewStatus(interviewId, isActive) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            this.setState({ disableSaveBtn: true });

            let url = process.env.REACT_APP_CORE_API + '/api/AutoInterview';
            let inputModel = {};
            inputModel.InterviewId = interviewId;
            inputModel.InterviewIsActive = isActive;

            axios.put(url, inputModel, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                displaySuccessMessage('Job Auto Interview Status Updated Successfully!', "top-center");

                this.setState({ quickActionModal: false })
                this.getJobs();


            }).catch((error) => {
                console.log("ERROR ===> update job Auto Interview Status error ==>", error.response);
                displayErrorMessage(error.response.data, "top-center");

            });

        }
    }


    //Auto interview extension date and expiry date

    updateAutoInterviewExpiryAndExtensionDate(interviewId, expiryDate, extensionDate) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            expiryDate = new Date(expiryDate.getTime() - (expiryDate.getTimezoneOffset() * 60000)).toJSON();
            extensionDate = new Date(extensionDate.getTime() - (extensionDate.getTimezoneOffset() * 60000)).toJSON();

            let url = process.env.REACT_APP_CORE_API + '/api/AutoInterview';
            let inputModel = {};
            inputModel.InterviewId = interviewId;
            inputModel.ExtensionDate = extensionDate;
            inputModel.ExpiryDate = expiryDate;
            axios.put(url, inputModel, {
                headers: {
                    'Authorization': bearer,
                    'Content-Type': 'application/json',
                },
            }).then(response => {

                if (this.state.isAutomatedInterviewSchedulingCopy !== this.state.isAutomatedInterviewScheduling && (this.state.interviewExpiryDateCopy.getTime() == this.state.interviewExpiryDate.getTime() && this.state.interviewExtensionDateCopy.getTime() == this.state.interviewExtensionDate.getTime())) {
                    displaySuccessMessage('Interview Invite Type Updated Successfully!', "top-center");
                } else if ((this.state.interviewExpiryDateCopy.getTime() !== this.state.interviewExpiryDate.getTime() || this.state.interviewExtensionDateCopy.getTime() !== this.state.interviewExtensionDate.getTime()) && (this.state.isAutomatedInterviewSchedulingCopy === this.state.isAutomatedInterviewScheduling)) {
                    displaySuccessMessage('Interview Date Updated Successfully!', "top-center");
                } else if ((this.state.interviewExpiryDateCopy.getTime() !== this.state.interviewExpiryDate.getTime() || this.state.interviewExtensionDateCopy.getTime() !== this.state.interviewExtensionDate.getTime()) && this.state.isAutomatedInterviewSchedulingCopy !== this.state.isAutomatedInterviewScheduling) {
                    displaySuccessMessage('Both Updated Successfully!', "top-center");

                }

                this.setState({ quickActionModal: false, interviewExpiryDate: null, interviewExtensionDate: null })
            }).catch((error) => {
                console.log("ERROR ===> update job Auto Interview Extension and expiry error ==>", error.response);
                displayErrorMessage(error.response.data, "top-center");

            });

        }


    }

    handleUpdateQuickActions() {

        this.updateAutoInterviewExpiryAndExtensionDate(this.state.selectedInterviewId, this.state.interviewExpiryDate, this.state.interviewExtensionDate)
        this.updateAutoJobStatus(this.state.selectedJobId, this.state.selectedInterviewId, this.state.isAutomatedInterviewScheduling);
    }

    /**
       * Get Countries
       * @param {any} event
       */
    getCountries() {
        var tenantId = null;
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_CORE_API + '/api/TenantCountryLookup?tenantid=' + tenantId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            countries: result,

                        })

                    }
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Countries!', "top-center");

                });
        }
    }

    /**
   * Get States
   * @param {any} event
   */
    getStates(countryId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + '/api/StateLookup/' + countryId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            states: result,

                        })

                    }
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching states!', "top-center");

                });
        }
    }

    /**
* Get Cities
* @param {any} event
*/
    getCities(stateId) {
        let bearer = validateAndGetFormatedToken();

        if (bearer != "" || bearer != null) {
            let url = process.env.REACT_APP_CORE_API + '/api/CitiesLookup/' + stateId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            cities: result,
                            filterTextJoblocation: result[0].locationName

                        }, () => {
                            console.log("call is made", this.state.cities)

                        })

                    }
                }).catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching cities!', "top-center");

                });
        }
    }




    handleInputChange(event) {
        let nam = event.target.name;
        let val = event.target.value;

        this.setState({ [nam]: val });
    }
    handleRadioChange = (event) => {
        const { id } = event.target;

        this.setState({
            activeJobChecked: id === "activeJobChecked",
            inactiveJobChecked: id === "inactiveJobChecked",
        });
    };

    truncateOptionText(text, maxLength = 35) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }


    widgetsFilteration = (widgetStatus) => {
        let filteredData = this.state.AllJobs.filter((job) => {
            if (widgetStatus === "public") {
                return job.status.toLowerCase() === "public";
            }
            else if (widgetStatus === "private") {
                return job.status.toLowerCase() === "private";
            } else if (widgetStatus === "active") {
                return job.isActive === true;
            } else if (widgetStatus === "inactive") {
                return job.isActive === false;
            }
            else if (widgetStatus === "involved") {
                return job.jobManager === this.state.loggedUserEmail;
            } else {
                return true; // No specific status filter
            }
        });
        this.setState({
            filteredData: filteredData,
            topFilteredData: filteredData,
            itemOffset: 0,
        });
    }


    render() {


        const { AllJobs, filteredData } = this.state;




        const filteration = () => {
            let filteredData = this.state.topFilteredData.length > 0 && this.state.topFilteredData.filter((job) => {
                const statusFilter =
                    (this.state.activeJobChecked && job.isActive) ||
                    (this.state.inactiveJobChecked && !job.isActive) ||
                    (!this.state.activeJobChecked && !this.state.inactiveJobChecked)



                if (this.state.startFilterDate == null && this.state.endFilterDate == null) {
                    return (
                        job.title.indexOf(this.state.selectedJob) > -1 &&
                        job.title.toLowerCase().indexOf(this.state.searchedJobTitle.toLowerCase()) > -1 &&

                        job.location.indexOf(this.state.filterTextJoblocation) > -1 &&
                        statusFilter
                    );
                } else {
                    return (
                        job.title.indexOf(this.state.selectedJob) > -1 &&
                        job.title.toLowerCase().indexOf(this.state.searchedJobTitle.toLowerCase()) > -1 &&

                        job.location.indexOf(this.state.filterTextJoblocation) > -1 &&
                        new Date(this.state.startFilterDate) <= new Date(job.createdDate) &&
                        new Date(new Date(this.state.endFilterDate).setDate(new Date(this.state.endFilterDate).getDate() + 1)) >= new Date(job.createdDate) &&
                        statusFilter
                    );
                }
            });

            this.setState({
                filteredData: filteredData,
                resultedData: filteredData,
                itemOffset: 0,

            });
        }


        const sorting = (col) => {
            if (this.state.order == "ASC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "DSC"
                })
            }
            if (this.state.order == "DSC") {
                const sorted = [...this.state.filteredData].sort((a, b) =>
                    a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                );
                this.setState({
                    filteredData: sorted,
                    order: "ASC"
                })
            }
        }

        //for pagination
        const endOffset = this.state.itemOffset + 10;
        //console.log(`Loading items from ${this.state.itemOffset} to ${endOffset}`);
        const currentItems = this.state.filteredData.slice(this.state.itemOffset, endOffset);
        console.log("currentItems:", currentItems)
        const pageCount = Math.ceil(this.state.filteredData.length / 10);

        // Invoke when user click to request another page.
        const handlePageClick = (event) => {
            const newOffset = (event.selected * 10) % this.state.filteredData.length;
            //console.log(
            //    `User requested page number ${event.selected}, which is offset ${newOffset}`
            //);
            this.setState({ itemOffset: newOffset });
        };



        return (

            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>


                    {/* <!-- Content --> */}
                    <div className="content container-fluid">
                        {/*<!-- Page Header --> */}
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">

                                    <h1 className="page-header-title">Jobs</h1>

                                </div>
                                {/*<!-- End Col --> */}

                                <div className="col-auto">
                                    {this.state.createJobPermission &&

                                        <Link className={(this.state.sfc_AllowedJobsCount == 0
                                            ? "btn btn-light"
                                            : "btn btn-primary")} to="/createjob" style={this.state.sfc_AllowedJobsCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}>

                                            <i class="bi bi-plus-circle"></i> Create Job <span class="badge bg-success rounded-pill"> {this.state.sfc_AllowedJobsCount} </span>
                                        </Link>

                                    }
                                </div>
                                {/*<!-- End Col --> */}
                            </div>
                            {/*<!-- End Row --> */}
                        </div>
                        {/*<!-- End Page Header --> */}





                        {/*<!--Card--> */}
                        {this.state.isloadedgetJobs ? <BeatLoaderComponent />
                            :
                            <>
                                {AllJobs.length <= 0 ? <div className="card card-centered mb-3 mb-lg-5">
                                    <div className="card-body py-10">
                                        <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                        <div className="text-center">

                                            <h1>You have not created anything  yet.</h1>
                                            <p>Once you create, they'll show up here.</p>


                                            <Link className="btn btn-primary" to="/createjob" style={this.state.sfc_AllowedJobsCount == 0 ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}><i className="bi bi-plus-lg me-1"></i>Get started now!</Link>


                                        </div>

                                    </div>
                                </div>
                                    :
                                    <>

                                        {/*<!-- Start Jobs Overview below this line -->*/}
                                        <div className="row d-none d-lg-flex  g-0 gap-2">
                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "total" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "total" })
                                                        this.widgetsFilteration("total")

                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })


                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap ">TOTAL JOBS </small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2 mt-2">{this.state.AllJobs.length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}

                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "public" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "public", })
                                                        this.widgetsFilteration("public")

                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })


                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">
                                                            <span className="legend-indicator bg-success"></span>
                                                            Public Jobs</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2 mt-2">{this.state.AllJobs.filter(item => item.status === 'public').length}</span>

                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}

                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "private" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "private" })
                                                        this.widgetsFilteration("private")
                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">
                                                            <span className="legend-indicator bg-danger"></span>
                                                            Private Jobs</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2">{this.state.AllJobs.filter(item => item.status === 'private').length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}
                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "involved" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "involved" })
                                                        this.widgetsFilteration("involved")
                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap"> I am Involved</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2 mt-2">{this.state.AllJobs.filter(item => item.jobManager === this.state.loggedUserEmail).length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}
                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "active" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "active" })
                                                        this.widgetsFilteration("active")
                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">
                                                            <span className="legend-indicator bg-success"></span>
                                                            Active</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2">{this.state.AllJobs.filter(item => item.isActive === true).length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}
                                            <div className="col-md-6 col-lg mb-3 mb-lg-5">
                                                {/*<!-- Card --> */}
                                                <div className={`${this.state.activeJobWidget === "inactive" ? "card card-color-border b-bottom-active" : "card card-color-border"}`}
                                                    onClick={() => {
                                                        this.setState({ activeJobWidget: "inactive" })
                                                        this.widgetsFilteration("inactive")
                                                        this.setState({
                                                            selectedJob: '', showSelectedJob: "", startFilterDate: null,
                                                            showStartFilterDate: null,
                                                            endFilterDate: null,
                                                            showEndFilterDate: null,
                                                            activeJobChecked: false,
                                                            showActiveJobChecked: false,
                                                            inactiveJobChecked: false,
                                                            showInactiveJobChecked: false,
                                                            showCity: "",
                                                            countrySelected: false,
                                                            stateSelected: false,
                                                            country: "",
                                                            filterTextJoblocation: "",
                                                            selectedStateValue: "",
                                                            showSelectedCountry: "",
                                                            showCountryValue: "",
                                                            selectedCountryValue: "",
                                                            showIsCountrySelected: false,
                                                            showStateValue: "",

                                                        })
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="card-body text-center">
                                                        <small className="text-cap">
                                                            <span className="legend-indicator bg-danger"></span>
                                                            Inactive</small>
                                                        <span className="js-counter d-block display-3 text-dark mb-2">{this.state.AllJobs.filter(item => item.isActive === false).length}</span>


                                                        {/*<!-- End Row --> */}
                                                    </div>
                                                </div>
                                                {/*<!-- End Card --> */}
                                            </div>
                                            {/*<!-- End Col --> */}
                                        </div>
                                        {/*<!-- End Jobs Overview below this line -->*/}


                                        {/*<!-- Start Job Data table-->*/}

                                        <div className="row my-2 d-none d-lg-block">

                                            <div className="col-md-12">

                                                <div className="card">
                                                    <div className="p-3">

                                                        <div className="row  g-0 mb-2" >
                                                            <div className="col-md-4">
                                                                <div className="input-group input-group-merge">
                                                                    <div className="input-group-prepend input-group-text">
                                                                        <i className="bi-search"></i>
                                                                    </div>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search jobs by name..."
                                                                        onChange={(e) => {
                                                                            if (e.target.value === "") {
                                                                                this.setState({ filteredData: AllJobs, searchedJobTitle: "" },
                                                                                    () => { filteration(); });
                                                                            }

                                                                        }}

                                                                        onKeyDown={(event) => {
                                                                            if (event.key === 'Enter') {
                                                                                this.setState({ searchedJobTitle: event.target.value },
                                                                                    () => { filteration(); })
                                                                                event.preventDefault();

                                                                            }
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <div className="col-md-8 d-flex justify-content-end">
                                                                <div class="icon-wrapper me-2 " >
                                                                    <i className="bi bi-funnel-fill me-2 filter-icon-hover " style={{ padding: "7px" }}
                                                                        onClick={() => {
                                                                            this.setState({ jobsFilterModal: true })
                                                                            document.body.classList.add('modal-open');
                                                                            if (this.state.showCity) {
                                                                                this.setState({ filterTextJoblocation: this.state.showCity })
                                                                            }

                                                                        }}
                                                                    >
                                                                    </i>
                                                                </div>
                                                                <div class="icon-wrapper " >
                                                                    <CSVLink data={this.state.filteredData} filename={"Jobs.csv"}
                                                                        headers={this.state.jobHeaders} className=" float-end">
                                                                        <i className="bi bi-filetype-csv filter-icon-hover" style={{ fontSize: "1.5rem", color: "green", padding: "7px" }}></i>
                                                                    </CSVLink>
                                                                </div>
                                                                <div className="col-md-auto d-flex align-items-center  mx-2">
                                                                    <img className="Refresh-Button" src={Refresh} alt="Refresh Jobs" data-hs-theme-appearance="default" data-tip data-for="refreshIcon"
                                                                        onClick={() => {
                                                                            this.ClearJobsCache();
                                                                        }}
                                                                    />
                                                                    <ReactTooltip id="refreshIcon" place="top" type="dark" effect="float">
                                                                        <span>Refresh</span>
                                                                    </ReactTooltip>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col d-flex justify-content-end">
                                                            {this.state.showSelectedJob && (
                                                                <div className="filtered-div f-size-14 mx-1">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="filter-overflow " >
                                                                            {this.state.showSelectedJob}
                                                                        </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({ selectedJob: '', showSelectedJob: "" },
                                                                                    () => { filteration(); }
                                                                                )
                                                                            }}
                                                                        ></button>
                                                                    </div>
                                                                </div>


                                                            )}
                                                            {this.state.showStartFilterDate && (
                                                                <div className="filtered-div f-size-14 mx-1">
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className=" " >
                                                                            {this.state.showStartFilterDate.toDateString()}, {this.state.showEndFilterDate.toDateString()}                                                                            </span>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close f-size-12"
                                                                            aria-label="Close"
                                                                            style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    startFilterDate: null,
                                                                                    showStartFilterDate: null,
                                                                                    endFilterDate: null,
                                                                                    showEndFilterDate: null
                                                                                }, () => {
                                                                                    filteration();
                                                                                });
                                                                            }}

                                                                        ></button>
                                                                    </div>
                                                                </div>


                                                            )}
                                                            {this.state.showActiveJobChecked && this.state.showFilterDetails && (
                                                                <div className="filtered-div f-size-14 mx-1 ">
                                                                    Active
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '8px' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState(
                                                                                {
                                                                                    activeJobChecked: false,
                                                                                    showActiveJobChecked: false,
                                                                                },
                                                                                () => { filteration(); }
                                                                            );
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            )}
                                                            {this.state.showInactiveJobChecked && this.state.showFilterDetails && (
                                                                <div className="filtered-div f-size-14 mx-1">
                                                                    Inactive
                                                                    <button type="button" className="btn-close f-size-12" aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '8px' }}
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.setState({
                                                                                inactiveJobChecked: false,
                                                                                showInactiveJobChecked: false,
                                                                            },
                                                                                () => { filteration(); })
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            )}
                                                            {/*{this.state.showStartFilterDate && this.state.showFilterDetails && (}
                                                                {/*    <div className="filtered-div f-size-14 mx-1">*/}
                                                            {/*        {this.state.showStartFilterDate.toDateString()}, {this.state.showEndFilterDate.toDateString()}*/}
                                                            {/*        <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ fontSize: '12px', marginLeft: '8px' }}*/}
                                                            {/*            onClick={(e) => {*/}
                                                            {/*                this.setState({*/}
                                                            {/*                    startFilterDate: null, showStartFilterDate: null,*/}
                                                            {/*                    endFilterDate: null, showEndFilterDate: null*/}
                                                            {/*                },*/}
                                                            {/*                    () => { filteration(); })*/}
                                                            {/*            }}></button>*/}
                                                            {/*    </div>*/}
                                                            {/*)}*/}
                                                            {this.state.showCity && (
                                                                <div className="filtered-div f-size-14 mx-1">
                                                                    {this.state.showCity}
                                                                    <button type="button" className="btn-close f-size-12" aria-label="Close" style={{ fontSize: '12px', marginLeft: '8px' }}
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                showCity: "",
                                                                                countrySelected: false,
                                                                                stateSelected: false,
                                                                                country: "",
                                                                                filterTextJoblocation: "",
                                                                                selectedStateValue: "",
                                                                                showSelectedCountry: "",
                                                                                showCountryValue: "",
                                                                                selectedCountryValue: "",
                                                                                showIsCountrySelected: false,
                                                                                showStateValue: "",

                                                                            },
                                                                                () => { filteration(); })
                                                                        }}></button>
                                                                </div>
                                                            )}

                                                        </div>

                                                    </div>

                                                    {/*<!-- Table --> */}
                                                    <div className="table-responsive datatable-custom ">
                                                        {this.state.IsJobLoading > 0 && (
                                                            <div class="overlay">
                                                                <div className="overlay-content">
                                                                    <Oval
                                                                        visible={true}
                                                                        height="80"
                                                                        width="80"
                                                                        color="#4fa94d"
                                                                        ariaLabel="oval-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <table id="datatable" className="table table-hover table-borderless table-thead-bordered table-nowrap table-align-middle card-table" style={{ width: '100%' }} >
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th style={{ width: '0px', padding: '0px', margin: '0px' }}></th>
                                                                    <th style={{ width: '20px' }} onClick={() => sorting("title")} style={{ cursor: 'pointer' }} > <strong>Job Title <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th ><strong>Interviews</strong></th>
                                                                    <th ><strong>Applicants</strong></th>
                                                                    <th onClick={() => sorting("managerName")} style={{ cursor: 'pointer' }} ><strong>Manager <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th ><strong>Created Date</strong></th>

                                                                    <th onClick={() => sorting("location")} style={{ cursor: 'pointer' }} > <strong>Location <i className="bi bi-sort-alpha-down"></i></strong></th>
                                                                    <th style={{ width: '0px', paddingRight: '0px' }}><strong>Action</strong></th>
                                                                    {currentItems.length < 1 ?
                                                                        <th></th>
                                                                        : null}
                                                                </tr>

                                                            </thead>

                                                            <tbody>


                                                                {currentItems != [] && currentItems.map((job, index) => (
                                                                    <tr role="row" className="odd" key={index} >
                                                                        <td style={{ padding: '0px', margin: '0px' }}>
                                                                            {job.isAutoInterviewSchedule ?
                                                                                <>
                                                                                    <img src={AutoInterview} style={{ cursor: 'pointer', width: '2rem', height: '2rem' }} id={"isAutoInterviewSchedule" + index} data-tip data-for={"isAutoInterviewSchedule" + index}></img>
                                                                                    <ReactTooltip id={"isAutoInterviewSchedule" + index} place="top" type="dark" effect="float">
                                                                                        {job.isAutoInterviewSchedule ?
                                                                                            "Automatic interview is scheduled" :
                                                                                            "No automatic interview is scheduled"}
                                                                                    </ReactTooltip>
                                                                                </>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td style={{ cursor: 'pointer' }} id={"jobTitle" + index} data-tip data-for={"jobTitle" + index} className="active-label ">

                                                                            <Link className="" to={`/viewjob/${job.jobId}`} id={"viewJob" + index}
                                                                                onClick={() => {
                                                                                    sessionStorage.setItem('activeTabLink', "details");

                                                                                }}
                                                                            >

                                                                                <span className="d-block h5 text-inherit mb-0 truncate inter-ff">

                                                                                    {job.isActive ?
                                                                                        <i class="bi bi-circle-fill text-success me-2">
                                                                                        </i> :
                                                                                        <i class="bi bi-circle-fill text-danger me-2">
                                                                                        </i>
                                                                                    }
                                                                                    {job.title}</span>

                                                                                <ReactTooltip id={"jobTitle" + index} place="top" type="dark" effect="float">
                                                                                    <span className="d-block fs-5">Id: {job.jobId}</span>
                                                                                    <span className="d-block fs-5">Title: {job.title}</span>
                                                                                    <span className="d-block fs-5">Industry: {job.industry}</span>
                                                                                    <span className="d-block fs-5">Location: {job.location}</span>
                                                                                </ReactTooltip>
                                                                            </Link>


                                                                        </td>


                                                                        <td>
                                                                            <span className="d-block h5 text-inherit mb-0 inter-ff">
                                                                                <Link to={"viewjob/" + job.jobId} id={"viewInterviews" + index} data-tip data-for={"viewInterviews" + index}
                                                                                    onClick={() => {
                                                                                        sessionStorage.setItem('activeTabLink', "interviews");

                                                                                    }}
                                                                                >
                                                                                    <button className="btn btn-primary">
                                                                                        <span class="bi bi-clipboard-check me-2"></span>

                                                                                        {job.interviewCount}

                                                                                    </button>
                                                                                    <ReactTooltip id={"viewInterviews" + index} place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5"> Interviews </span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            </span>



                                                                        </td>
                                                                        <td>

                                                                            <span className="d-block h5 text-inherit mb-0 inter-ff">
                                                                                <Link to={"viewjob/" + job.jobId}
                                                                                    onClick={() => {
                                                                                        sessionStorage.setItem('activeTabLink', "applicants");

                                                                                    }}
                                                                                >
                                                                                    <button class="btn btn-primary" data-tip data-for={"viewApplicants" + index}
                                                                                    >
                                                                                        <span class="bi bi-person-lines-fill me-2"></span>
                                                                                        {job.applicantCount}

                                                                                    </button>
                                                                                    <ReactTooltip id={"viewApplicants" + index} place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5"> Applicants </span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            </span>
                                                                        </td>



                                                                        <td id={"jobManager" + index} data-tip data-for={"jobManager" + index}>
                                                                            <span className="d-block h5 text-inherit mb-0 inter-ff">{job.managerName}

                                                                            </span>
                                                                            <ReactTooltip id={"jobManager" + index} place="top" type="dark" effect="float">
                                                                                <span className="d-block fs-5">{job.managerName}</span>
                                                                                <span className="d-block fs-5">{job.jobManager}</span>
                                                                            </ReactTooltip>
                                                                        </td>
                                                                        <td >
                                                                            <span className="d-block h5 text-inherit mb-0 inter-ff">{job.createdDate}

                                                                            </span>

                                                                        </td>
                                                                        <td id={"location" + index} data-tip data-for={"location" + index}>
                                                                            <span className="d-block h5 text-inherit mb-0 inter-ff">{job.location}

                                                                            </span>
                                                                            <ReactTooltip id={"location" + index} place="top" type="dark" effect="float">
                                                                                <span className="d-block fs-5">Country: {job.country}</span>
                                                                                <span className="d-block fs-5">State: {job.state}</span>
                                                                            </ReactTooltip>
                                                                        </td>


                                                                         
                                                                       

                                                                        <td>
                                                                            <Link className="btn btn-outline-primary btn-icon btn-sm me-2" to={`/viewjob/${job.jobId}`} id={"viewJob" + index} data-tip data-for={"viewJob" + index}
                                                                                onClick={() => {
                                                                                    sessionStorage.setItem('activeTabLink', "details");

                                                                                }}

                                                                            >
                                                                                <i className="bi bi-info-square"></i>
                                                                                <ReactTooltip id={"viewJob" + index} place="top" type="dark" effect="float">
                                                                                    <span className="d-block fs-5">View Job</span>
                                                                                </ReactTooltip>
                                                                            </Link>
                                                                            {(this.state.editJobPermission || job.jobManager === this.state.loggedUserEmail) &&

                                                                                <Link to={`/updatejob/${job.jobId}`} className="btn btn-outline-primary btn-icon btn-sm me-2" id={"editJob" + index} data-tip data-for={"editJob" + index}>
                                                                                    <i className="bi bi-pencil-square"></i>
                                                                                    <ReactTooltip id={"editJob" + index} place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">Edit Job</span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            }
                                                                            {(this.state.editJobPermission || job.jobManager === this.state.loggedUserEmail) &&

                                                                                <Link to={`/clonejob/${job.jobId}`} className="btn btn-outline-primary btn-icon btn-sm me-2" id={"cloneJob" + index} data-tip data-for={"cloneJob" + index}>
                                                                                    <i className="bi bi-clipboard2-pulse-fill"></i>
                                                                                    <ReactTooltip id={"cloneJob" + index} place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">Clone Job</span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                            }
                                                                            {job.isAutoInterviewSchedule && job.isActive ?
                                                                                <Link className="btn btn-outline-primary btn-icon btn-sm me-2" id={"quickAction" + index} data-tip data-for={"quickAction" + index}
                                                                                    onClick={() => {
                                                                                        this.getJobQuickSettings(job.jobId);
                                                                                        this.setState({
                                                                                            quickActionModal: true,
                                                                                            selectedJobId: job.jobId,
                                                                                            isAutoInterviewScheduleForModal: job.isAutoInterviewSchedule
                                                                                        });
                                                                                        document.body.classList.add('modal-open');

                                                                                    }}
                                                                                >
                                                                                    <span class="material-symbols-outlined f-size-20">
                                                                                        settings
                                                                                    </span>
                                                                                    <ReactTooltip id={"quickAction" + index} place="top" type="dark" effect="float">
                                                                                        <span className="d-block fs-5">Quick Actions/Settings</span>
                                                                                    </ReactTooltip>
                                                                                </Link>
                                                                                :
                                                                                job.isAutoInterviewSchedule && !job.isActive ?

                                                                                    <button className="btn btn-outline-primary btn-icon btn-sm me-2" disabled={!job.isActive} id={"quickActionDisabled" + index} data-tip data-for={"quickActionDisabled" + index}>
                                                                                        <span class="material-symbols-outlined f-size-20">settings</span>
                                                                                        <ReactTooltip id={"quickActionDisabled" + index} place="top" type="dark" effect="float" disabled={job.isActive}>
                                                                                            <span className="d-block fs-5">Quick Actions/Settings</span>
                                                                                        </ReactTooltip>
                                                                                    </button>


                                                                                    : null
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    {/*<!-- End Table --> */}

                                                    {/*<!-- Footer --> */}
                                                    {filteredData.length === 0 ? (
                                                        <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                            <div className="card-body py-10">
                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />

                                                                <div className="text-center">

                                                                    <h1>No search results were found </h1>
                                                                    <p>Please refine your search</p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="card-footer">
                                                            <div className="d-flex justify-content-center">
                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                    onPageChange={handlePageClick}
                                                                    pageRangeDisplayed={5}
                                                                    pageCount={pageCount}
                                                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                    renderOnZeroPageCount={null}
                                                                    breakClassName={'page-item'}
                                                                    breakLinkClassName={'page-link'}
                                                                    containerClassName={'pagination'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={'active'}
                                                                    initialPage={0}
                                                                    forcePage={this.state.itemOffset / 10} // Force the active page to reset to the current offset
                                                                />
                                                            </div>

                                                        </div>
                                                    )}
                                                    {/*<!-- End Footer --> */}
                                                </div>

                                            </div>
                                        </div>
                                        {/*<!-- Desktop end here --> */}

                                        {/*<!-- Mobile start here --> */}

                                        <div className="col-md-12 d-flex d-lg-none" style={{ paddingRight: '8px' }}>
                                            <div className="col" style={{ marginRight: '.6rem' }}>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <div className="input-group-prepend input-group-text">
                                                            <i className="bi-search"></i>
                                                        </div>
                                                        <input type="search" className="form-control" placeholder="Search jobs by name..."
                                                            onChange={(e) => {
                                                                if (e.target.value === "") {
                                                                    this.setState({ filteredData: AllJobs, searchedJobTitle: "" },
                                                                        () => { filteration(); });
                                                                }

                                                            }}

                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    this.setState({ searchedJobTitle: event.target.value },
                                                                        () => { filteration(); })
                                                                    event.preventDefault();

                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn  no-border py-2" style={{ backgroundColor: '#3FB5C3' }}
                                                onClick={() => {
                                                    this.setState({ jobsFilterModal: true })
                                                    document.body.classList.add('modal-open');
                                                    this.getCities(this.state.states[this.state.showStateValue].locationId);


                                                }}
                                            >
                                                <i className="bi bi-funnel" style={{ fontSize: '0.8rem', color: 'white' }}></i>
                                            </button>
                                        </div>

                                        <div className="row g-0 d-flex filter-chips-mb mt-0 mb-2 b d-lg-none" style={{ overflowX: 'auto' }}>
                                            <div className="col-12">

                                                <div style={{ display: 'flex', flexDirection: 'row', }}>


                                                </div>
                                            </div>

                                        </div>

                                        <div className="row g-0 d-flex filter-chips-mb mt-0 mb-2 b d-lg-none" style={{ overflowX: 'auto' }}>
                                            <div className="col-12">
                                                <div style={{ display: 'flex', flexDirection: 'row', }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                                                        {this.state.showSelectedJob && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="">{this.state.showSelectedJob}</span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({ selectedJob: '', showSelectedJob: "" },
                                                                                () => { filteration(); }
                                                                            )
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.showStartFilterDate && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="">
                                                                        {this.state.showStartFilterDate.toDateString()}, {this.state.showEndFilterDate.toDateString()}

                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                startFilterDate: null,
                                                                                showStartFilterDate: null,
                                                                                endFilterDate: null,
                                                                                showEndFilterDate: null
                                                                            }, () => {
                                                                                filteration();
                                                                            });
                                                                        }}

                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {this.state.showActiveJobChecked && this.state.showFilterDetails && (
                                                            <div className="filtered-div-mb f-size-14 mx-1" style={{ whiteSpace: 'nowrap' }}>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className=" " >
                                                                        Inactive
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.setState(
                                                                                {
                                                                                    activeJobChecked: false,
                                                                                    showActiveJobChecked: false,
                                                                                },
                                                                                () => { filteration(); }
                                                                            );
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>


                                                        )}
                                                        {this.state.showInactiveJobChecked && this.state.showFilterDetails && (
                                                            <div className="filtered-div-mb f-size-14 mx-1 ">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="" >
                                                                        Inactive
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(event) => {
                                                                            event.preventDefault();
                                                                            this.setState({
                                                                                inactiveJobChecked: false,
                                                                                showInactiveJobChecked: false,
                                                                            },
                                                                                () => { filteration(); })
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>


                                                        )}

                                                        {this.state.showCity && (
                                                            <div className="filtered-div-mb f-size-14 mx-1 ">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="" >
                                                                        {this.state.showCity}
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close f-size-12"
                                                                        aria-label="Close"
                                                                        style={{ fontSize: '12px', marginLeft: '6px', lineHeight: 'normal', verticalAlign: 'middle' }}
                                                                        onClick={(e) => {
                                                                            this.setState({
                                                                                showCity: "",
                                                                                countrySelected: false,
                                                                                stateSelected: false,
                                                                                country: "",
                                                                                filterTextJoblocation: "",


                                                                            },
                                                                                () => { filteration(); })
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                            </div>


                                                        )}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row justify-content-center align-items-center g-0 p-0 mt-2  d-lg-none">
                                            <div className="col-md-12">
                                                <div className="card border-0 jc-radius pb-3">

                                                    <table className="table jobs-overview-table">
                                                        <thead className="thead" style={{ backgroundColor: "#EAECF0" }}>
                                                            <tr>
                                                                <th scope="col " className="p-3" colSpan={3}>
                                                                    <span className="f-weight-500">Jobs</span>

                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="">
                                                            {currentItems.length > 0 && currentItems.map((job, index) => (
                                                                <tr key={index}>
                                                                    <td className="p-3">
                                                                        <div className="row  mb-0">

                                                                            <div className="col d-flex">


                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="check-icon-bg-job" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                        <i className="bi bi-check check-icon-color"></i>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="f-size-14 f-weight-500 mb-0 f-inter text-break">{job.title}  </p>
                                                                                        <p className="f-size-11 mb-0 f-dark f-weight-400 f-inter">{job.industry}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={job.isAutoInterviewSchedule ? "col-5 d-flex justify-content-end" : "col-3 d-flex justify-content-end"}>
                                                                                <div>
                                                                                    <Link className="btn btn-outline-primary btn-icon btn-sm me-2" to={`/viewjob/${job.jobId}`} id={"viewJob" + index} data-tip data-for={"viewJob" + index}>
                                                                                        <i className="bi bi-info-square"></i>
                                                                                        <ReactTooltip id={"viewJob" + index} place="top" type="dark" effect="float">
                                                                                            <span className="d-block fs-5">View Job</span>
                                                                                        </ReactTooltip>
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="d-flex ">
                                                                                    {(this.state.editJobPermission || job.jobManager === this.state.loggedUserEmail) &&
                                                                                        <Link to={`/updatejob/${job.jobId}`} className="btn btn-outline-primary btn-icon btn-sm me-2" id={"editJob" + index} data-tip data-for={"editJob" + index}>
                                                                                            <i className="bi bi-pencil-square"></i>
                                                                                            <ReactTooltip id={"editJob" + index} place="top" type="dark" effect="float">
                                                                                                <span className="d-block fs-5">Edit Job</span>
                                                                                            </ReactTooltip>
                                                                                        </Link>
                                                                                    }
                                                                                    {(this.state.editJobPermission || job.jobManager === this.state.loggedUserEmail) &&

                                                                                        <Link to={`/clonejob/${job.jobId}`} className="btn btn-outline-primary btn-icon btn-sm me-2" id={"cloneJob" + index} data-tip data-for={"cloneJob" + index}>
                                                                                            <i className="bi bi-clipboard2-pulse-fill"></i>
                                                                                            <ReactTooltip id={"cloneJob" + index} place="top" type="dark" effect="float">
                                                                                                <span className="d-block fs-5">Clone Job</span>
                                                                                            </ReactTooltip>
                                                                                        </Link>
                                                                                    }
                                                                                    {job.isAutoInterviewSchedule && job.isActive ?
                                                                                        <Link className="btn btn-outline-primary btn-icon btn-sm me-2" id={"quickAction" + index} data-tip data-for={"quickAction" + index}
                                                                                            onClick={() => {
                                                                                                this.getJobQuickSettings(job.jobId);
                                                                                                this.setState({
                                                                                                    quickActionModal: true,
                                                                                                    selectedJobId: job.jobId,
                                                                                                    isAutoInterviewScheduleForModal: job.isAutoInterviewSchedule
                                                                                                });
                                                                                                document.body.classList.add('modal-open');

                                                                                            }}
                                                                                        >
                                                                                            <span class="material-symbols-outlined f-size-20">
                                                                                                settings
                                                                                            </span>
                                                                                            <ReactTooltip id={"quickAction" + index} place="top" type="dark" effect="float">
                                                                                                <span className="d-block fs-5">Quick Actions/Settings</span>
                                                                                            </ReactTooltip>
                                                                                        </Link>
                                                                                        :
                                                                                        job.isAutoInterviewSchedule && !job.isActive ?

                                                                                            <button className="btn btn-outline-primary btn-icon btn-sm me-2" disabled={!job.isActive} id={"quickActionDisabled" + index} data-tip data-for={"quickActionDisabled" + index}>
                                                                                                <span class="material-symbols-outlined f-size-20">settings</span>
                                                                                                <ReactTooltip id={"quickActionDisabled" + index} place="top" type="dark" effect="float" disabled={job.isActive}>
                                                                                                    <span className="d-block fs-5">Quick Actions/Settings</span>
                                                                                                </ReactTooltip>
                                                                                            </button>


                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {/*<!-- Footer --> */}
                                                    {filteredData.length === 0 ? (
                                                        <div className="card card-centered  mt-3 mb-3 mb-lg-5">
                                                            <div className="card-body py-10">
                                                                <img className="avatar avatar-xxl mb-3" src={Empty} alt="Image Description" data-hs-theme-appearance="default" />
                                                                <div className="text-center">
                                                                    <h1>No search results were found </h1>
                                                                    <p>Please refine your search</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="card-footer ">
                                                            <div className="d-flex justify-content-center">
                                                                <ReactPaginate
                                                                    breakLabel="..."
                                                                    nextLabel={<i className='bi bi-chevron-right'></i>}
                                                                    onPageChange={handlePageClick}
                                                                    pageRangeDisplayed={5}
                                                                    pageCount={pageCount}
                                                                    previousLabel={<i className='bi bi-chevron-left'></i>}
                                                                    renderOnZeroPageCount={null}
                                                                    breakClassName={'page-item'}
                                                                    breakLinkClassName={'page-link'}
                                                                    containerClassName={'pagination'}
                                                                    pageClassName={'page-item'}
                                                                    pageLinkClassName={'page-link'}
                                                                    previousClassName={'page-item'}
                                                                    previousLinkClassName={'page-link'}
                                                                    nextClassName={'page-item'}
                                                                    nextLinkClassName={'page-link'}
                                                                    activeClassName={'active'}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/*<!-- End Footer --> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<!-- Mobile end here --> */}




                                    </>
                                }
                            </>
                        }

                        {/*<!--End Card--> */}
                        {/*<!-- End Job Data table--> */}

                    </div>
                    {/* <!-- End Content --> */}

                    {/* <!-- Applicants modal start here --> */}


                    {this.state.applicantsModal && (
                        <div className="modal d-block" id="GFG" tabIndex="-1">
                            <div className=" modal-dialog-scrollable set-modal-size">
                                <div className="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title text-white" id="exampleModalLabel">Applicants </h5>

                                        <button type="button" className="btn-close btn-close-white" aria-label="Close"
                                            onClick={() => {
                                                this.setState({ applicantsModal: false })
                                                document.body.classList.remove('modal-open');

                                            }}>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Applicants jobId={this.state.storedJobId} displayHeader={true}></Applicants>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                    {/* <!-- Applicants modal end here --> */}


                    {/*Modal for Jobs Filter*/}

                    {this.state.jobsFilterModal &&
                        <div className="d-block modal right " id="AddIntroVideo" tabIndex="-1" aria-labelledby="accountUpdatePlanModalLabel" role="dialog" aria-hidden="true">
                            <div className="modal-dialog filter-modal-width jobs-filter-modal-dialog">
                                <div className="modal-content jobs-filter-modal no-border">
                                    {/* Header */}
                                    <div className="modal-header no-border p-3 pt-4 pb-4 d-flex align-items-center" style={{ backgroundColor: '#F0F2F7' }}>
                                        <h1 className="f-size-24 f-weight-700">Jobs Filters</h1>
                                        <button type="button" className="btn-close" aria-label="Close " onClick={() => {
                                            this.setState({
                                                jobsFilterModal: false,
                                                selectedJob: this.state.showSelectedJob,
                                                startFilterDate: this.state.showStartFilterDate,
                                                endFilterDate: this.state.showEndFilterDate,
                                                stateSelected: this.state.showStateSelected,
                                                filterTextJoblocation: this.state.showCity,
                                                activeJobChecked: this.state.showActiveJobChecked,
                                                inactiveJobChecked: this.state.showInactiveJobChecked,
                                                country: this.state.showSelectedCountry,
                                                selectedStateValue: this.state.showStateValue,
                                                countrySelected: this.state.showIsCountrySelected


                                            });
                                            document.body.classList.remove('modal-open');

                                            this.getStates(this.state.countries[this.state.showCountryValue].locationId);
                                            //this.getCities(this.state.states[this.state.showStateValue].locationId);



                                        }}></button>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="modal-body">
                                        <div className="row mb-3">
                                            <div className="swft-form-input mb-2">
                                                <label htmlFor="jobs" className="f-dark f-weight-600 f-size-14 mb-3">
                                                    JOB
                                                </label>
                                                <select
                                                    name="selectjobs"
                                                    className="form-select"
                                                    placeholder="Job Status"
                                                    onChange={(e) => {
                                                        this.setState({ selectedJob: e.target.value })
                                                    }}>
                                                    <option value="" selected={this.state.selectedJob == ""}>
                                                        Select job
                                                    </option>
                                                    {
                                                        this.state.jobDdlDataSource.map((item, index) => (
                                                            <option key={index} value={item.label} selected={item.label === this.state.selectedJob}>
                                                                {this.truncateOptionText(item.label)}
                                                            </option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                        </div>

                                        {/* End Row */}
                                        <div className="row mb-3 g-0">
                                            <label htmlFor="created-date" className="f-dark f-weight-600 f-size-14 mb-3">
                                                CREATED DATE
                                            </label>
                                            <div className="d-flex gap-2">
                                                <div className="col">
                                                    <label htmlFor="from-date" className="f-dark f-weight-600 f-size-12 mb-1">
                                                        FROM
                                                    </label>
                                                    <DatePicker
                                                        selected={this.state.startFilterDate}
                                                        onChange={(date) =>
                                                            this.setState({ startFilterDate: date, endFilterDate: date })}
                                                        selectsStart
                                                        startDate={this.state.startFilterDate}
                                                        endDate={this.state.endFilterDate}
                                                        placeholder="From Create Date"
                                                        className="form-control mr-2 f-dark f-size-14"
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="to-date" className="f-dark f-weight-600 f-size-12 mb-1">
                                                        TO
                                                    </label>
                                                    <DatePicker
                                                        selected={this.state.endFilterDate}
                                                        onChange={(date) =>
                                                            this.setState({ endFilterDate: date })}
                                                        selectsEnd
                                                        startDate={this.state.startFilterDate}
                                                        endDate={this.state.endFilterDate}
                                                        minDate={this.state.startFilterDate}
                                                        placeholder="To Create Date"
                                                        className="form-control f-dark f-size-14"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2 g-0">
                                            <div className="swft-form-input mb-2">
                                                <label htmlFor="country" className="f-dark f-weight-600 f-size-14 mb-3">
                                                    LOCATION
                                                </label>
                                                <select className="form-select"

                                                    name="country"
                                                    onChange={(e) => {
                                                        if (e.target.value !== "-1") {
                                                            this.getStates(this.state.countries[e.target.value].locationId);

                                                            console.log("countries:", this.state.countries,)
                                                            console.log("states:", this.state.states,)
                                                            this.setState({ country: this.state.countries[e.target.value].locationName });
                                                            this.setState({ countrySelected: true });
                                                            this.setState({ stateSelected: false });
                                                            this.setState({ selectedCountryValue: e.target.value })
                                                        }
                                                        else {
                                                            this.setState({ countrySelected: false });
                                                            this.setState({ stateSelected: false });
                                                        }
                                                    }}>
                                                    <option key="-1" value="-1">All Countries</option>
                                                    {this.state.countries !== [] && this.state.countries.map((country, index) =>
                                                        <option key={index} value={index} selected={country.locationName === this.state.country}>{country.locationName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        {/* End Row */}
                                        <div className="row mb-3 g-0 gap-2">
                                            {this.state.countrySelected &&
                                                <div className="col-6 mb-3">
                                                    <select className="form-select"
                                                        name="state"
                                                        onChange={(e) => {
                                                            if (e.target.value !== "-1") {
                                                                this.getCities(this.state.states[e.target.value].locationId);
                                                                this.setState({
                                                                    stateSelected: true,
                                                                    selectedStateValue: e.target.value
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    stateSelected: false,
                                                                });
                                                            }
                                                        }}>
                                                        <option key="-1" value="-1" selected={!this.state.stateSelected}>All States</option>
                                                        {this.state.states.length !== 0 && this.state.states.map((state, index) => (
                                                            <option key={index} value={index} selected={index.toString() === this.state.selectedStateValue}>{state.locationName}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            }
                                            {this.state.stateSelected &&
                                                <div className="col mb-3">
                                                    <select className="form-select"
                                                        name="city" onChange={(e) => {
                                                            this.setState({ filterTextJoblocation: e.target.value });
                                                        }}>
                                                        {this.state.cities !== [] && this.state.cities.map((city, index) =>
                                                            <option key={index} value={city.locationName} selected={city.locationName === this.state.showCity}>{city.locationName}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                        <div className="row mb-3 g-0">
                                            <label htmlFor="country" className="f-dark f-weight-600 f-size-14 mb-3">
                                                JOB STATUS
                                            </label>
                                            <div className="mb-3 col-6">
                                                <input
                                                    type="radio"
                                                    className="form-check-input mr-2 job-status-margin"
                                                    id="activeJobChecked"
                                                    checked={this.state.activeJobChecked}
                                                    onChange={this.handleRadioChange}
                                                />
                                                <label className="form-check-label mx-2" htmlFor="activeJobChecked">
                                                    Active {this.state.activeJobChecked}
                                                </label>
                                            </div>
                                            <div className="mb-2 col-6">
                                                <input
                                                    type="radio"
                                                    className="form-check-input mr-2 job-status-margin"
                                                    id="inactiveJobChecked"
                                                    checked={this.state.inactiveJobChecked}
                                                    onChange={this.handleRadioChange}
                                                />
                                                <label className="form-check-label mx-2" htmlFor="inactiveJobChecked">
                                                    Inactive
                                                </label>
                                            </div>
                                        </div>
                                        {/* End Row */}

                                        <div className="d-flex justify-content-end gap-4 mb-3">
                                            <button type="button" className="btn filter-clear-btn" data-bs-dismiss="modal" aria-label="Close"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    this.setState({
                                                        filterTextTitle: "",
                                                        filterTextIndustry: "",
                                                        filterTextManagerName: "",
                                                        filterTextJobStatus: "",
                                                        filterTextJoblocation: "",
                                                        startFilterDate: null,
                                                        endFilterDate: null,
                                                        selectedJob: "",
                                                        selectedStateValue: "",
                                                        activeJobChecked: false,
                                                        inactiveJobChecked: false,
                                                        countrySelected: false,
                                                        stateSelected: false,
                                                        showSelectedJob: "",
                                                        country: "",
                                                        showStartFilterDate: null,
                                                        showEndFilterDate: null,
                                                        showCity: "",
                                                        showActiveJobChecked: false,
                                                        showInactiveJobChecked: false,
                                                        showSelectedCountry: "",
                                                        showStateValue: "",
                                                        showCountryValue: "",
                                                        selectedCountryValue: "",
                                                        showIsCountrySelected: false,


                                                    }, () => {
                                                        this.setState({ filteredData: this.state.resultedData })
                                                        filteration();

                                                    }
                                                    );
                                                }}

                                            >
                                                Clear
                                            </button>
                                            <button type="button" className="btn btn-primary f-size-14 f-weight-700" data-bs-dismiss="modal" aria-label="Close"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    if (this.state.startFilterDate != null) {
                                                        if (this.state.endFilterDate == null) {
                                                            displayWarningMessage('Please select end date !', "top-center");
                                                            return;
                                                        }
                                                    }
                                                    if (this.state.endFilterDate != null) {
                                                        if (this.state.startFilterDate == null) {
                                                            displayWarningMessage('Please select start date !', "top-center");
                                                            return;
                                                        }
                                                    }
                                                    if (this.state.startFilterDate > this.state.endFilterDate) {
                                                        displayErrorMessage("To date should be equal or greater than From date", "top-center");
                                                        return
                                                    }
                                                    filteration();
                                                    this.setState({
                                                        jobsFilterModal: false, showFilterDetails: true,
                                                        showSelectedJob: this.state.selectedJob,
                                                        showStartFilterDate: this.state.startFilterDate,
                                                        showEndFilterDate: this.state.endFilterDate,
                                                        showCity: this.state.filterTextJoblocation,
                                                        showStateSelected: this.state.stateSelected,
                                                        showActiveJobChecked: this.state.activeJobChecked,
                                                        showInactiveJobChecked: this.state.inactiveJobChecked,
                                                        showSelectedCountry: this.state.country,
                                                        showStateValue: this.state.selectedStateValue,
                                                        showCountryValue: this.state.selectedCountryValue,
                                                        showIsCountrySelected: this.state.countrySelected


                                                    }
                                                    );
                                                    document.body.classList.remove('modal-open');

                                                }}
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                    {/* End Body */}
                                </div>
                            </div>
                        </div>

                    }
                    {/*Jobs filter modal end here*/}

                    {/*Modal for quick settings*/}
                    {
                        this.state.quickActionModal &&

                        <div id="quickActions" className="modal d-block " role="dialog" aria-labelledby="ModalCenteredScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered dailogMaxWidth" role="document">
                                <div className="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title text-white" id="exampleModalLabel">Quick Actions</h5>

                                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            this.setState({ quickActionModal: false, interviewExpiryDate: null })
                                            document.body.classList.remove('modal-open');

                                        }}></button>

                                    </div>
                                    <div className="modal-body pt-2 pb-0">
                                        <div id="jobIsActive" className="row pt-0 g-0 d-flex">
                                            <label className=" mb-2">Would you like to send an automated interview invite? <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='autoInterviewRadio' id="autoInterviewRadio"></i></label>

                                            <div className="col-lg-7 form-check" onChange={(e) => {
                                                this.setState({ isAutomatedInterviewScheduling: e.target.value === "true" })
                                            }}>
                                                <input
                                                    type="radio"
                                                    value="true"
                                                    name="isAutomatedInterviewScheduling"
                                                    disabled={!this.state.jobIsActive}
                                                    checked={this.state.isAutomatedInterviewScheduling}
                                                    aria-label="Job is active"
                                                />
                                                <span className="form-check-label mx-2">Yes</span>

                                                <input
                                                    type="radio"
                                                    value="false"
                                                    name="isAutomatedInterviewScheduling"
                                                    disabled={!this.state.jobIsActive}
                                                    checked={!this.state.isAutomatedInterviewScheduling}
                                                    aria-label="Job is not active"
                                                />
                                                <span className="form-check-label mx-2">No</span>

                                                <ReactTooltip id="autoInterviewRadio" place="top" type="dark" effect="float">
                                                    <span className="d-block fs-5">Update automatic scheduled interview status</span>
                                                </ReactTooltip>
                                            </div>

                                        </div>
                                        <hr />

                                        {this.state.isAutoInterviewScheduleForModal &&
                                            <>
                                                {/*  minDate={this.state.existingInterviewExpiryDate}*/}
                                                {/*  minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}*/}
                                                <div className="row mb-0 pt-0">
                                                    <label className="col-auto ">Interview expiry date <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='interviewExpiryDate' id="interviewExpiryDate"></i></label>
                                                    <div className="col-auto">
                                                        <div className="row">
                                                            <div className="mb-sm-0">
                                                                {/* <DatePicker
                                                                    selected={this.state.interviewExpiryDate}
                                                                    onChange={(date) => {
                                                                        this.setState({ interviewExpiryDate: date });
                                                                    }}
                                                                    minDate={new Date(new Date().getTime() + (24 * 60 * 60 * 1000))}
                                                                    locale={navigator.language}
                                                                    inline
                                                                    dateFormat="yyyy-dd-MM"
                                                                    placeholderText="Select Date"
                                                                /> */}
                                                                <DatePicker selected={this.state.interviewExpiryDate}
                                                                    inline
                                                                    onChange={(date) => {
                                                                        // Validation: Prevent selecting dates earlier than the current date
                                                                        const currentDate = new Date();
                                                                        if (date < currentDate) {
                                                                            // If selected date is before current date, don't update state
                                                                            return;
                                                                        }
                                                                        this.setState({ interviewExpiryDate: date });
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Row --> */}
                                                        <ReactTooltip id="interviewExpiryDate" place="top" type="dark" effect="float">
                                                            <span className="d-block fs-5">Update automatic interview expiry date</span>
                                                        </ReactTooltip>
                                                    </div>


                                                    <label className="col-auto ">Interview extension date <i className="bi-question-circle text-body ms-1" style={{ cursor: 'pointer' }} data-tip data-for='interviewExtentionDate' id="interviewExtentionDate"></i></label>
                                                    <div className="col-auto">
                                                        <div className="row">
                                                            <div className=" mb-sm-0">
                                                                {/* <DatePicker
                                                                    onChange={(date) => {

                                                                        this.setState({ interviewExtensionDate: date });
                                                                    }}
                                                                    selected={this.state.interviewExtensionDate}
                                                                    minDate={this.state.existingInterviewExpiryDate}
                                                                    locale={navigator.language}
                                                                    inline
                                                                    dateFormat="yyyy-dd-MM"
                                                                    placeholderText="Select Date"
                                                                /> */}
                                                                <DatePicker selected={this.state.interviewExtensionDate}
                                                                    inline
                                                                    onChange={(date) => {
                                                                        if (date <= this.state.interviewExpiryDate) {
                                                                            return;
                                                                        }
                                                                        this.setState({ interviewExtensionDate: date });
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Row --> */}
                                                        <ReactTooltip id="interviewExtentionDate" place="top" type="dark" effect="float">
                                                            <span className="d-block fs-5">Update automatic interview extension date</span>
                                                        </ReactTooltip>
                                                    </div>

                                                </div>


                                            </>
                                        }

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {

                                            if (this.state.interviewExtensionDate < this.state.interviewExpiryDate) {
                                                displayWarningMessage("Extension date should be greater than expiry date", 'top-center');

                                                return;
                                            }
                                            this.handleUpdateQuickActions()
                                            document.body.classList.remove('modal-open');

                                        }}>Update</button>
                                        <button type="button" className="btn btn-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                            this.setState({ quickActionModal: false, interviewExpiryDate: null, interviewExpiryDate: null })
                                            document.body.classList.remove('modal-open');
                                        }}>Close</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }





                </main>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover


                />

            </>

        );
    }
} export default Jobs;