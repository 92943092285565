import React from 'react';
import { parseJwt } from '../../../modules/TokenParser';
import { ToastContainer } from 'react-toastify';
import { displayErrorMessage, displaySuccessMessage, displayWarningMessage } from '../../../modules/ToastMessage';
import { validateAndGetFormatedToken } from '../../../modules/CheckToken';
import { Link } from 'react-router-dom';
import profileImage from "../../../assets/img/160x160/img1.jpg";
import SettingsTabNavigations from '../../Layout/_SettingsTabNavigations';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import ReactTooltip from 'react-tooltip';


class TenantProfile extends React.Component {

    constructor() {
        super();
        this.state =
        {
            tenantId: 0,
            tenantName: "",
            contactPerson: "",
            country: "",
            tenantEmail: "",
            tenantPhone: "",
            editTenantName: "",
            editContactPerson: "",
            editCountry: "",
            editTenantPhone: "",
            formDataObject: null,
            tenantImageUrl: "",
            edittenantImageUrl: "",
            tenantPhoneCopy: "",
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadImage = this.uploadImage.bind(this);

    }


    componentDidMount() {
        document.title = "Tenant App - Settings";

        this.getCompanyProfile();
    }




    /** Get the company profile data and update the state variable tenantProfile */
    getCompanyProfile() {
        let bearer = validateAndGetFormatedToken();
        if (bearer != "" || bearer != null) {
            var tenantId = null;
            tenantId = parseJwt(bearer).tenantid;
            let url = process.env.REACT_APP_IDENTITY_API + "/Tenant/GetTenant?tenantId=" + tenantId;

            fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Authorization': bearer,
                        'Content-Type': 'application/json',
                    }
                }).then(response => response.json())
                .then((tenantObjectResult) => {
                    this.setState({
                        tenantId: tenantObjectResult.tenantId,
                        tenantName: tenantObjectResult.tenantName,
                        country: tenantObjectResult.country,
                        contactPerson: tenantObjectResult.createdBy,
                        tenantEmail: tenantObjectResult.tenantEmail,
                        tenantPhone: tenantObjectResult.tenantPhone ? tenantObjectResult.tenantPhone.trim() : '',
                        tenantLogoUrl: tenantObjectResult.tenantLogoUrl,
                        tenantImageUrl: tenantObjectResult.tenantLogoUrl,
                        edittenantImageUrl: tenantObjectResult.tenantLogoUrl,
                        editTenantName: tenantObjectResult.tenantName,
                        editCountry: tenantObjectResult.country,
                        editContactPerson: tenantObjectResult.createdBy,
                        editTenantEmail: tenantObjectResult.tenantEmail,
                        editTenantPhone: tenantObjectResult.tenantPhone ? tenantObjectResult.tenantPhone.trim() : '',
                        tenantPhoneCopy: tenantObjectResult.tenantPhone ? tenantObjectResult.tenantPhone.trim() : '',
                    });
                    localStorage.setItem('Tenant', JSON.stringify(tenantObjectResult));
                })
                .catch(err => {
                    console.log(err);
                    displayErrorMessage('Error while Fetching Company Profile!', "top-center");

                });
        }
    }




    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    /**
    * Chekc the token
    * Case 1: Update Profile
    * Case 2: Update Password
    * @param {any} event
    */
    handleSubmit(event) {

        var updateTenantEndpoint = null;
        var message = "";
        let bearer = validateAndGetFormatedToken();

        //Update the tenant information
        if (this.state.editTenantPhone && !isValidPhoneNumber(this.state.editTenantPhone)) {
            displayErrorMessage("Phone no is not valid", 'top-center');
            return; // Do not proceed with saving if validation fails
        }


        //------------------------------  Validate it  --------------------------------//
        if (this.state.tenantId != null && this.state.tenantId != '' && this.state.tenantId != undefined) {
            console.log("Tenant Id is =>" + this.state.tenantId);
        }
        else
            message += " Tenant Email required.";

        if (this.state.editTenantName != null && this.state.editTenantName != '' && this.state.editTenantName != undefined) {
            console.log("Tenant Name is =>" + this.state.editTenantName);
        }
        else {
            message += " Tenant Name required.";
        }
        if (this.state.editTenantEmail != null && this.state.editTenantEmail != '' && this.state.editTenantEmail != undefined) {
            console.log("Tenant Email is =>" + this.state.editTenantEmail);
        }
        else
            message += " Tenant Email required.";

        //if (this.state.editTenantPhone != null && this.state.editTenantPhone != '' && this.state.editTenantPhone != undefined) {
        //    console.log("Tenant Phone is =>" + this.state.editTenantPhone);
        //}
        //else message += " Tenant Phone required.";

        if (this.state.editCountry != null && this.state.editCountry != '' && this.state.editCountry != undefined) {
            console.log("Country is =>" + this.state.editCountry);
        }
        else
            message += " Country required.";
        //if (isValidPhoneNumber((this.state.editTenantPhone.toString()))) {

        //}
        //else {
        //    message += " Invalid Phone Number ";

        //}
        if (message != "") {
            displayErrorMessage(message, 'top-center');
            return;
        }
        //------------------------------  End Validate it  --------------------------------//


        //Submit updates to identity server

        if (bearer != "" || bearer != null) {
            //this.toggleButton(true);
            const { tenantId, editTenantName, editTenantEmail, editTenantPhone, editCountry } = this.state;
            const baseURL = process.env.REACT_APP_IDENTITY_API;
            const queryParams = new URLSearchParams({
                tenantId,
                tenantName: editTenantName,
                tenantEmail: editTenantEmail,
                tenantPhone: editTenantPhone,
                country: editCountry,
            });

            const updateTenantEndpoint = `${baseURL}/Tenant/UpdateTenantInfo?${queryParams.toString()}`;

            //Update tenant endpoint

            fetch(updateTenantEndpoint).then((response) => {
                return response.json();
            }).then((result) => {
                if (this.state.formDataObject != null) {
                    //displayErrorMessage("Please select a file to upload", "top-center");
                    var coreApiBaseUri = process.env.REACT_APP_CORE_API;;
                    var profileImageEndpoint = coreApiBaseUri + '/api/TenantProfile/';
                    var formData = this.state.formDataObject;
                    let bearer = validateAndGetFormatedToken();
                    this.uploadImageToServer(formData, profileImageEndpoint, bearer);
                    displaySuccessMessage("Successfully updated", 'top-center');
                    this.setState({ editUserModal: false });
                }
                else {
                    displaySuccessMessage("Successfully updated", 'top-center');
                    this.setState({ editUserModal: false });
                    this.getCompanyProfile();
                }
                //console.log("result fronm update", result);
                //if (result) {

                //this.toggleButton(false);
                //}
                //else {
                //    displayErrorMessage("An error occured", 'top-center');
                //    this.setState({ editUserModal: false });
                //this.toggleButton(false);
                //}


            }).catch(() => {

                console.error("an error occured");
                //this.toggleButton(false);
            });

        }

    }





    /**
  * When data input change then set the FormDataObject in state
  * @param {any} e file input control
  */
    handleImageChange(e) {

        e.preventDefault();
        let file = e.target.files[0];
        let size = file.size;
        if (size > 5242880) {
            displayWarningMessage("File size exceeds", "top-center")
            return;
        }
        if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {

            let formData = new FormData();
            for (var index = 0; index < e.target.files.length; index++) {
                var element = e.target.files[index];
                formData.append('imageFile', element);
            }

            formData.append('tenantId', this.state.tenantId);
            this.setState({
                formDataObject: formData,
            });

            let reader = new FileReader();
            reader.readAsDataURL(file);

            //file reader on load end event
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    tenantImageUrl: reader.result,
                    edittenantImageUrl: reader.result
                });

            };
        } else {
            displayWarningMessage("Invalid file type", "top-center")
            return;

        }
    };



    /**
 * Prevent default event 
 * Get the FormDataObject from state
 * Send formData to api endpoint
 * @param {any} event
 * @summary Upload image to server
 */
    uploadImage(event) {
        event.preventDefault();
        if (this.state.formDataObject == null) {
            displayErrorMessage("Please select a file to upload", "top-center");
            return false;
        }

        var coreApiBaseUri = process.env.REACT_APP_CORE_API;;
        var profileImageEndpoint = coreApiBaseUri + '/api/TenantProfile/';
        var formData = this.state.formDataObject;
        let bearer = validateAndGetFormatedToken();
        this.uploadImageToServer(formData, profileImageEndpoint, bearer);
    };

    /**
     * Post data to endpoint
     * @param {any} formData   this is FormData object that was build to send to server
     * @param {URL} profileImageEndpoint this is endpoint where we will send data
     * @summary Upload image to server (api endpoint)
     */
    uploadImageToServer = (formData, profileImageEndpoint, bearerToken) => {

        fetch(profileImageEndpoint,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': bearerToken
                },
                body: formData
            }
        ).then(() => {
            this.getCompanyProfile();
        }).then(() => {
        }).catch(() => {
            displayErrorMessage("Error: Unable to Upload Image", "top-center");
        });
    }
    handlePhoneInputChange = (value) => {
        this.setState({ editTenantPhone: value || '' });

    };


    render() {


        return (
            <>
                <main id="content" role="main" className={`main ${this.props.isCollapsed ? 'swift-p-default' : ''}`}>

                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">

                                    <h1 className="page-header-title">Settings</h1>

                                </div>

                            </div>
                            <div className="js-nav-scroller hs-nav-scroller-horizontal nav-tabs-custom">
                                <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-left"></i>
                                    </a>
                                </span>

                                <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                    <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                        <i className="bi-chevron-right"></i>
                                    </a>
                                </span>



                                <SettingsTabNavigations></SettingsTabNavigations>

                            </div>
                        </div>
                        <div id="addUserStepProfile" className="card">
                            <div className="card-header card-header-content-between">
                                <h4 className="card-header-title">Tenant Information</h4>

                                <button className="btn btn-white btn-sm" data-bs-toggle="modal" data-bs-target="#editUserModal" onClick={() => {
                                    this.setState({ editUserModal: true })
                                    document.body.classList.add('modal-open');

                                }}>
                                    <i className="bi-pencil-fill me-1"></i> Edit
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="row mb-4">
                                    <label className="col-sm-3 col-form-label form-label">Company Logo</label>

                                    <div className="col-sm-9">
                                        <div className="d-flex align-items-center">
                                            {this.state.tenantLogoUrl != null ?
                                                <img src={this.state.tenantLogoUrl} width="185" height="40" className="rounded rounded-3" /> :
                                                <img src={profileImage} type="file" className="avatar avatar-xl avatar-circle avatar-uploader me-5" id="avatarUploader" />

                                            }
                                            {/*<button type="button" className="js-file-attach-reset-img btn btn-white">Delete</button>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="tenantnamelabel" className="col-sm-3 col-form-label form-label">Name <i data-tip
                                        data-for="tenantTooltip" className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Displayed on public forums, such as Front." aria-label="Displayed on public forums, such as Front."></i><ReactTooltip id="tenantTooltip" >Enter company name here</ReactTooltip></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="form-control" name="tenantName" id="tenantnamelabel" placeholder="Tenant Name" aria-label="Clarice" value={this.state.tenantName} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <label for="tenantEmail" className="col-sm-3 col-form-label form-label">Email</label>

                                    <div className="col-sm-9">
                                        <input type="email" className="form-control" name="tenantEmail" id="tenantEmail" placeholder="Tenant Email" aria-label="clarice@site.com" value={this.state.tenantEmail} disabled />
                                    </div>
                                </div>
                                <div className="js-add-field row mb-4">
                                    <label for="tenantPhone" className="col-sm-3 col-form-label form-label">Phone Number <span className="form-label-secondary">(Optional)</span></label>

                                    <div className="col-sm-9">
                                        <div className="input-group input-group-sm-vertical">
                                            <input type="text" className="js-input-mask form-control" name="tenantPhone" id="tenantPhone" placeholder="+x(xxx)xxx-xx-xx" aria-label="+x(xxx)xxx-xx-xx" value={this.state.tenantPhone} disabled />


                                        </div>


                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="country" className="col-sm-3 col-form-label form-label">Country</label>

                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="country" id="country" placeholder="Country" value={this.state.country} aria-label="Htmlstream" disabled />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <label for="contactPerson" className="col-sm-3 col-form-label form-label">Contact Person</label>

                                    <div className="col-sm-9">
                                        <input type="text" className="form-control" name="contactPerson" id="contactPerson" placeholder="Contact Person" Value={this.state.contactPerson} aria-label="Human resources" disabled />
                                    </div>
                                </div>


                            </div>


                        </div>

                    </div>
                    {this.state.editUserModal && <div className="modal d-block" id="editUserModal" tabindex="-1" aria-labelledby="editUserModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="editUserModalLabel"><span className="whiteColor"
                                    >Edit Company</span></h5>
                                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                                        document.body.classList.remove('modal-open');

                                        this.setState({ editUserModal: false, editTenantPhone: this.state.tenantPhoneCopy, editTenantName: this.state.tenantName, edittenantImageUrl: this.state.tenantLogoUrl, formDataObject: null })
                                    }}></button>
                                </div>

                                <div className="modal-body">
                                    <div className="js-nav-scroller hs-nav-scroller-horizontal">
                                        <span className="hs-nav-scroller-arrow-prev" style={{ display: 'none' }}>
                                            <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                                <i className="bi-chevron-left"></i>
                                            </a>
                                        </span>

                                        <span className="hs-nav-scroller-arrow-next" style={{ display: 'none' }}>
                                            <a className="hs-nav-scroller-arrow-link" href="javascript:;">
                                                <i className="bi-chevron-right"></i>
                                            </a>
                                        </span>
                                    </div>

                                    <div className="tab-content" id="editUserModalTabContent">
                                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                            <form>

                                                <div className="row mb-4">
                                                    <label className="col-sm-3 col-form-label form-label">Company Logo</label>

                                                    <div className="col-sm-9">
                                                        <div className="d-flex align-items-center">
                                                            {this.state.edittenantImageUrl != null ?
                                                                <img src={this.state.edittenantImageUrl} width="190" height="50" className="rounded" /> :
                                                                <img src={profileImage} type="file" className="avatar avatar-xl avatar-circle avatar-uploader me-5" id="avatarUploader" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-sm-3">
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <input id="imgTenantLogoUrl"
                                                            name="imgTenantLogoUrl"
                                                            accept="image/x-png,image/jpeg,image/jpg"
                                                            type="file" onChange={(e) => this.handleImageChange(e)}
                                                            className="form-control" />
                                                    </div>

                                                </div>
                                                {/*<div className="col-sm-3">*/}
                                                {/*        <button type="button" className="js-file-attach-reset-img w-100 btn btn-white"*/}
                                                {/*            onClick={this.uploadImage}>Save Photo</button>*/}
                                                {/*    </div>*/}


                                                <div className="row mb-2">
                                                    <hr />
                                                </div>

                                                <div className="row mb-4">
                                                    <label for="tenantnamelabel" className="col-sm-3 col-form-label form-label">Name <i className="bi-question-circle text-body ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Displayed on public forums, such as Front." aria-label="Displayed on public forums, such as Front."></i></label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-sm-vertical">
                                                            <input type="text" className="form-control" name="editTenantName" id="tenantnamelabel" placeholder="Tenant Name" aria-label="Clarice" value={this.state.editTenantName} onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <label for="editTenantEmail" className="col-sm-3 col-form-label form-label"> Email</label>

                                                    <div className="col-sm-9">
                                                        <input type="email" className="form-control" name="editTenantEmail" id="editTenantEmail" placeholder="Tenant Email" aria-label="clarice@site.com" value={this.state.editTenantEmail} disabled />
                                                    </div>
                                                </div>
                                                <div className="js-add-field row mb-4" >
                                                    <label for="editTenantPhone" className="col-sm-3 col-form-label form-label">Phone Number <span className="form-label-secondary">(Optional)</span></label>

                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-sm-vertical">
                                                            <PhoneInput
                                                                value={this.state.editTenantPhone}
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry="US"
                                                                placeholder="Enter phone number"
                                                                name="editTenantPhone"
                                                                id="editTenantPhone"
                                                                onChange={this.handlePhoneInputChange} />

                                                        </div>


                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <label for="editCountry" className="col-sm-3 col-form-label form-label">Country</label>

                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" name="editCountry" id="editCountry" placeholder="Country" value={this.state.editCountry} onChange={this.handleInputChange} aria-label="Htmlstream" readonly disabled />
                                                    </div>
                                                </div>

                                                <div className="row mb-4">
                                                    <label for="editContactPerson" className="col-sm-3 col-form-label form-label">Contact Person</label>

                                                    <div className="col-sm-9">
                                                        <input type="text" className="form-control" name="editContactPerson" id="editContactPerson" placeholder="Contact Person" Value={this.state.editContactPerson} aria-label="Human resources" readonly />
                                                    </div>
                                                </div>



                                                
                                            </form>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <div className="d-flex gap-3">
                                        <button type="button" className="btn btn-white" onClick={() => {
                                            document.body.classList.remove('modal-open');

                                            this.setState({ editUserModal: false, editTenantPhone: this.state.tenantPhoneCopy, editTenantName: this.state.tenantName, edittenantImageUrl: this.state.tenantLogoUrl, formDataObject: null })
                                        }}>Cancel</button>
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}
                                        >Save changes</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    }
                </main>

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ width: '35%' }}

                />
            </>
        );
    }

}

export default TenantProfile;
